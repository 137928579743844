import * as React from 'react';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import Overflow from 'baseui/icon/overflow';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import { navigate } from '@reach/router';
import { switchToOrg } from '../../auth';

function fomatCount(count) {
  if (count === 0) return 0;
  if (`${count}`.length == 1) return `0${count}`;
  return count;
}

export default ({ name, imageSrc, id, domain, selectedOrg, ...props }) => {
  const [css, theme] = useStyletron();
  const [popActive, setPopActive] = React.useState(true);
  const isSelected = selectedOrg.id === id
  return (
    <>
      <div
        onClick={() => {
          if (id === selectedOrg.id) return;
          switchToOrg(id);
          // navigate(`/home/${domain}`);
          // window.open(
          //   `${window.location.origin}/login/organizations/${domain}`,
          //   '_blank'
          // );
        }}
        className={css({
          width: '294px',
          height: '230px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          // borderRadius: '6%',
          background: `white`,
          backgroundSize: 'cover !important',
          position: 'relative',
          float: 'left',
          marginRight: '16px',
          padding: '1rem',
          margin: '0.4rem'
        })}
      >
        {
          isSelected && (
            <div
              className={css({
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.4)'
              })}
            />
          )
        }
        <div
          className={css({
            position: 'relative',
            width: '100%',
            height: '280px',
            top: 0,
            left: 0,
            padding: theme.sizing.scale800,
          })}
        >
          <div
            className={css({
              // background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%), url(${imageSrc})`,
              width: '100%',
              height: '100%',
            })}
          >



            <div
              className={css({
                position: 'absolute',
                width: theme.sizing.scale1200,
                height: theme.sizing.scale1200,
                right: theme.sizing.scale300,
                top: theme.sizing.scale0,
              })}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >

              <StatefulPopover
                initialState={{ isOpen: false }}
                dismissOnEsc={true}
                dismissOnClickOutside={true}
                overrides={{
                  Body: {
                    style: {
                      zIndex: 3
                    }
                  }
                }}
                content={() => {
                  return popActive ?
                    <NestedMenus>
                      <StatefulMenu
                        items={[
                          { label: 'Update' },
                          { label: 'Delete' },
                          { label: 'Generate Sign Up Link' }
                        ]}
                        overrides={{
                          List: {
                            style: {
                              width: '120px',
                              maxHeight: '1000px',
                              overflow: 'auto',
                            },
                          },
                        }}
                        onItemSelect={({ item, event }) => {
                          setPopActive(false)
                          if (item.label === 'Update') {
                            console.log('Update')
                            props.setSelectedOrg({ id })
                            props.setUpdateOpen(true)
                          }
                          if (item.label === 'Delete') {
                            console.log('Update')
                            props.setSelectedOrg({ id })
                            props.setDeleteOpen(true)
                          };
                          if (item.label == 'Generate Sign Up Link') {
                            let link = window.location.origin + `/signup/?organization_id=${id}`;
                            window.open(link, '_blank');
                          }
                        }}
                      />
                    </NestedMenus>
                    : null
                }}
                placement={PLACEMENT.top}
                onClick={e => {
                  setPopActive(true)
                }}
              >
                <Overflow
                  className={css({
                    top: '-10px',
                    right: '-7px',
                    color: 'black !important',
                    position: 'absolute',
                    cursor: 'pointer',
                    marginBottom: '0px',
                  })}
                  size={36}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    setPopActive(true)
                  }}
                />
              </StatefulPopover>
            </div>

            <div>
              <img src={imageSrc} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
              <HeadingSmall
                className={css({
                  color: 'black !important',
                  fontSize: '14px !important',
                })}
              >{name}</HeadingSmall>
            </div>
          </div>
        </div>


        {/* <div
          className={css({
            paddingLeft: theme.sizing.scale800,
            width: '200px',
            height: '150px',
            paddingRight: theme.sizing.scale800,
            float: 'left',
            position: 'relative',
          })}
        >
          <ParagraphMedium></ParagraphMedium>
        </div>
        <div
          className={css({
            background: '#EEEEEE',
            width: '100%',
            height: '8px',
            position: 'absolute',
            bottom: 0,
          })}
        ></div> */}
      </div>
    </>
  );
};
