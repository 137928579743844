import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import gql from 'graphql-tag';

import { import_card_help } from "../help/helpTexts";
import HelpQuestionMark from '../help/questionMarkHelp';
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { LabelSmall } from "baseui/typography";
import { importCopyCard } from "../../quries";
import { showAlert } from "../../redux/actions/appBasicControls";
import { Skeleton } from "baseui/skeleton";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import styled from "styled-components";
import { withTheme } from "@emotion/react";
import { getSrc } from "../../util";


const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  maxHeight: '70vh',
  overflowY: 'auto'
};

const MainCardDiv = styled.div`
  background-image: ${props => props.src};
  position: relative;
  border: 3px solid #4A4CA0;
  width: 100%;
  height: 40vh;
  background-size: ${props => props.full_bleed ? 'cover' : 'contain'};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  padding: 10px;
`;

const ImportCardModal = ({
  isModalOpen = false,
  modalProps = {},
  onSuccessfullyImport = () => { },
  onCloseModal = () => { },
  deckId,
  cards,
  home_component_id
}) => {
  const [css, theme] = useStyletron();
  const dispatch = useDispatch();
  const organization = useSelector(state => state.appBasicControls.organization);
  const { data: copyCards, loading: copyCardsLoading } = useQuery(gql`
    query copy_cards_by_org ($organization_id: ID!) {
      copy_cards_by_org(organization_id: $organization_id) {
        id
        card_id
        home_component_id
        card_back_home_component_card {
          id
          title
          sub_title
          card_type
          file_data
          front_image_data
          archived
          full_bleed
        }
      }
    }
  `,
    {
      variables: {
        organization_id: organization.id
      },
      fetchPolicy: 'network-only'
    }
  );
  const [selectedCard, setSelectedCard] = useState();
  const [listMode, setListMode] = useState('list_view');

  const [
    importCard,
    {
      data: importedCardData,
      loading: loadingImportedCard
    }
  ] = useMutation(importCopyCard)

  //////////////////////////////////////////// Event Function //////////////////////////////////////////////////////////

  const onClickImport = async () => {
    try {
      if (!selectedCard || selectedCard == null) {
        dispatch(showAlert({
          msg: 'Please select a card',
          error: true,
        }))
        return;
      }
      const res = await importCard({
        variables: {
          card_id: selectedCard.card_id,
          deck_id: deckId,
          card_stack_id: home_component_id,
          position: cards.length
        }
      });

      dispatch(showAlert({
        msg: 'Successfully imported card',
        error: false,
      }));
      onSuccessfullyImport();
    } catch (err) {
      console.log('onClickImport', err)
      dispatch(showAlert({
        msg: JSON.stringify(err),
        error: true,
      }))
    }

  }


  //////////////////////////////////////////// Render Function //////////////////////////////////////////////////////////

  const renderCardItem = (el, index) => {
    const {
      card_id,
      card_back_home_component_card
    } = el || {};

    const onClick = () => {
      if (selectedCard?.id == el?.id) {
        setSelectedCard(null);
        return;
      }
      setSelectedCard(el)
    }

    return (
      <div
        key={index}
        className={css({
          position: 'relative',
          width: '100%',
          height: '50px',
          lineHeight: '50px',
          background: '#5D64AB',
          margin: '0',
          display: 'flex',
          padding: '16px',
          color: 'white',
          background: '#0D0E13',
          marginBottom: '8px !important',
          alignItems: 'center'
        })}
        onClick={onClick}
      >
        <Radio
          value={el.id}
          overrides={{
            RadioMarkOuter: {
              style: {
                backgroundColor: '#5D64AB',
                marginRight: '10px'
              },
            },
          }}
          checked={selectedCard?.id === el?.id}
        />
        <LabelSmall
          className={css({
            color: 'white !important'
          })}
        >
          {card_back_home_component_card?.title}
        </LabelSmall>
      </div>
    )
  }

  const CopyCardListViewItem = (card, idx) => {
    const itemProps = {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    let title = card?.card_back_home_component_card?.default_title || card?.card_back_home_component_card?.title;
    let imageSrc = getSrc(card?.card_back_home_component_card?.file_data || card?.card_back_home_component_card?.front_image_data);
    let card_id = card.card_id;
    let full_bleed = card?.card_back_home_component_card?.full_bleed;
    const onClick = () => {
      if (selectedCard?.id == card?.id) {
        setSelectedCard(null);
        return;
      }
      setSelectedCard(card)
    }
    return (
      <FlexGridItem {...itemProps}>
        <MainCardDiv
          src={`linear-gradient(180deg, rgba(0, 0, 0, 0.17), hsla(235, 32%, 52%, 0.514)), url("${imageSrc}"), linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255))`}
          onClick={onClick}
        >
          <Radio
            className={css({
              position: 'absolute',
              top: '10px',
              left: '10px'
            })}
            value={card.id}
            overrides={{
              RadioMarkOuter: {
                style: {
                  backgroundColor: '#5D64AB',
                  marginRight: '10px'
                },
              },
            }}
            checked={selectedCard?.id === card?.id}
          />
          <LabelSmall
            className={css({
              color: 'white !important', //theme.colors.primaryA'',
              lineHeight: '13px',
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              fontFamily: 'Roboto !important',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
              position: 'relative',
              float: 'left',
              height: '100%',
              boxSizing: 'border-box',
              padding: '8px',
              fontWeight: '400',
              fontSize: '11px',
              position: 'absolute',
              bottom: '10px',
              height: 'auto'
            })}
          >
            {title}
          </LabelSmall>
        </MainCardDiv>
      </FlexGridItem>
    )
  }

  const CopyCardList = () => {
    return copyCards?.copy_cards_by_org?.map(renderCardItem)
  };

  const CopyCardListView = () => {
    return (
      <FlexGrid
        flexGridColumnCount={[1, 2, 4, 6]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        overrides={{
          Root: {
            style: {
              minHeight: '50%'
            }
          }
        }}
      >
        {copyCards?.copy_cards_by_org?.map(CopyCardListViewItem)}
      </FlexGrid>
    )
  };

  const ListSkeleton = () => {
    if (!copyCardsLoading) return null;
    return (
      <div style={{ marginTop: '2rem' }}>
        {new Array(10).fill('').map((item, index) => (
          <Skeleton
            width="100%"
            height="50px"
            animation
            overrides={{
              Root: {
                style: {
                  marginBottom: '8px',
                },
              },
            }}
          />))}
      </div>
    )
  }

  const CardListSkeleton = () => {
    const itemProps = {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    if (!copyCardsLoading) return null;
    return (
      <div style={{ marginTop: '2rem' }}>
        <FlexGrid
          flexGridColumnCount={[1, 2, 4, 6]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          overrides={{
            Root: {
              style: {
                minHeight: '50%'
              }
            }
          }}
        >
          {new Array(10).fill('').map((item, index) => (
            <FlexGridItem {...itemProps}>
              <Skeleton
                width="100%"
                height="40vh"
                animation
                overrides={{
                  Root: {
                    style: {

                    },
                  },
                }}
              />
            </FlexGridItem>
          ))
          }
        </FlexGrid>

      </div>
    )
  }
  // console.log('CopyCards', copyCards, selectedCard)

  const CardsList = useMemo(() => {
    if (listMode === 'list_view') {
      return (
        <>
          <CardListSkeleton />
          <CopyCardListView />
        </>
      );
    }

    return (
      <>
        <ListSkeleton />
        <CopyCardList />
      </>
    )
  }, [copyCards, selectedCard, listMode])

  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          }
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        }
      }}
      onClose={onCloseModal}
      closeable
      isOpen={isModalOpen}
      animate
      autoFocus
      {...modalProps}
      size={SIZE.full}
      role={ROLE.dialog}
    >
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
          })}
        >
          Import Card
          <HelpQuestionMark text={import_card_help}></HelpQuestionMark>
        </div>
      </ModalHeader>
      <ModalBody>
        <Grid
          gridGaps={[12, 12, 24]}
          overrides={{
            Grid: {
              style: ({ $theme }) => outerGridOverride,
            },
          }}
        >
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                  paddingRight: '0px !important',
                }),
              },
            }}
          >
            {CardsList}
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onClickImport}
          isLoading={loadingImportedCard}
        >
          Import
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default ImportCardModal;