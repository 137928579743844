import * as React from 'react';
import { Grid } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { showAlert } from '../../redux/actions/appBasicControls';
import { deleteOrganization as deleteOrganizationMutation } from '../../quries';

export default ({ organization, onClose, dispatch }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  if (isOpen === false) onClose();

  const [
    deleteOrganization,
    { loading: deleteOrgLoading, data: deletedOrg },
  ] = useMutation(deleteOrganizationMutation);
  React.useEffect(() => {
    setIsLoading(false);
    if (deletedOrg) {
      dispatch(
        showAlert({
          msg: 'Successfully deleted organization!',
          error: false,
        })
      );
      onClose();
    }
  }, [deletedOrg]);

  const onDelete = () => {
    setIsLoading(true);
    deleteOrganization({
      variables: {
        id: organization.id,
      },
    });
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
            Backdrop: {
                style: ({ $theme }) => ({
                backdropFilter: $theme.modalBackDropFilter,
                }),
            },
            Root: {
                style: {
                    zIndex: 3
                }
            }
        }}
        isOpen={isOpen}
        onClose={() => {
            onClose();
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>Are you sure that you want to delete this org? </H5>
            <H6>Please note that this action will destroy all content in the organization and can not be undone.</H6>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
                onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={deleteOrgLoading}
            onClick={() => {
              onDelete();
            }}
          >
            Delete
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
