import * as React from 'react';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { Button as BaseButton } from 'baseui/button';
import Builder from './builder';
import { useStyletron } from 'baseui';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import moment from 'moment';
import { Spinner } from 'baseui/spinner';
import { toaster } from 'baseui/toast';
import { Plus } from 'baseui/icon/plus';
import Button from '../styledButton';
import { sourceByType } from '../../util';
import { navigate } from '@reach/router';
import { ProgressBar } from 'baseui/progress-bar';
import { editorBtnOptions as editorBtnOptionsOriginal } from '../../../data/cardBuilderOptions';
import { getQuizCardByQuestionId } from './builderUtils/query';
import { saveCardBackData } from './builderUtils/save';
import {
  dropSubscriptions,
} from './builderUtils/subscription';
import RightSidePageHeader from '../pageHeaders';
// import AutoSave from './autoSave';
import {
  setIsLoading, showAlert,
} from '../../redux/actions/appBasicControls';
import store from '../../redux/store';
import WizardStyleBuilder from './wizardStyleBuilder';
import MainPageModal from './mainPageModal';
import TabStyleBuilder from './tabStyleBuilder';
import { func, object } from 'prop-types';
const dispatch = store.dispatch;

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const TAB_STATUS = {
  BUILD: 'Build',
  COVER: 'Cover',
  // STATS: 'Stats',
  SPECIFICATION: 'Filters',
};

const mainPageStyle = 'modal'; // page
const builderStyle = 'wizard'; //tab;
const BuilderComponent = builderStyle == 'wizard' ? WizardStyleBuilder : TabStyleBuilder;

const options = [
  {
    name: 'Text Input Card',
    display: 'Text Input Card',
    id: 'text_quiz_question',
    icon: <Plus></Plus>,
    parent: 'quiz',
    __typename: 'QuizTextQuestion',
    inputs: [
      {
        type: 'BaseUiTextArea',
        key: 'question_text',
        dataType: 'String',
        title: 'Question Text',
        value: '',
        placeholder: 'Enter Question Text',
      },
      {
        type: 'TextResponse',
        key: 'valid_text_responses',
        dataType: 'String',
        title: 'Answers',
        value: '',
        placeholder: 'Enter Answer Text'
      }
    ]
  },
  {
    name: 'Matching Card',
    display: 'Matching Card',
    id: 'matching_quiz_question',
    icon: <Plus></Plus>,
    parent: 'quiz',
    __typename: 'QuizMatchingQuestion',
    inputs: [
      {
        type: 'Input',
        key: 'question_text',
        dataType: 'String',
        title: 'Question Text',
        value: '',
        placeholder: 'Enter Question Text',
      },
      {
        type: 'MatchingPair',
        key: 'pairs',
        dataType: 'String',
        title: 'Answers',
        value: '',
        placeholder: 'Enter Answer Text'
      }
    ]
  },
  {
    name: 'Multiple Choice Card',
    display: 'Multiple Choice Card',
    id: 'option_quiz_question',
    icon: <Plus></Plus>,
    parent: 'quiz',
    __typename: 'QuizOptionQuestion',
    inputs: [
      {
        type: 'Input',
        key: 'question_text',
        dataType: 'String',
        title: 'Question Text',
        value: '',
        placeholder: 'Enter Question Text',
      },
      {
        type: 'Checkbox',
        key: 'has_multiple_answers',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Answer has multiple correct answers?',
      },
      {
        type: 'Input',
        key: 'no_of_correct_answers',
        dataType: 'Int',
        title: 'No of correct answers',
        value: '',
        placeholder: 'Enter no of correct answers',
      },
      {
        type: 'QuizOption',
        key: 'options',
        dataType: 'String',
        title: 'Answers',
        value: '',
        placeholder: 'Enter text here'
      }
    ]
  }
];

const BuilderP = ({ cardId, organization_id, isLoading, questionCards, cards, dispatch, ...rest }) => {
  // OVERLAY HANDLING
  document.getElementById('overlay') &&
    (document.getElementById('overlay').style.display = 'none');
  // TAB
  const [tab, setTab] = React.useState(TAB_STATUS.BUILD);
  // THEME
  const [css, theme] = useStyletron();
  // TAB KEY
  const [closeToast, setCloseToast] = React.useState(false);
  const [value] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const onModalClose = () => {
    console.log('modal close', data);
    if (data && data.quiz_question_quiz_deck_by_question_id) {
      const isMultupleQuizType = data.quiz_question_quiz_deck_by_question_id;
      if (isMultupleQuizType.quiz_question_type === 'QuizOptionQuestion') {
        // const isCorrect = checkQuizOptionQuestionAnswer(isMultupleQuizType.quiz_question_data);
        // console.log('isCorrect', isCorrect);
        // if (!isCorrect) {
        //   alertUser();
        //   return;
        // }
      }
    }
    const navPath = `/app/${organization_id}/groups/${rest.groupId}/channels/${rest.channelId}/deck/${rest.deckId}`
    setIsModalOpen(false);
    navigate(navPath)
  };

  function alertUser() {
    console.log('You must select one answer as correct before continuing.')
    dispatch(
      showAlert({
        msg: 'You must select one answer as correct before continuing.',
        error: true,
      })
    );
  }

  const checkQuizOptionQuestionAnswer = (data = {}) => {
    const {
      options='[]'
    } = data || {};
    const optionsJson = JSON.parse(options);
    let isCorrectExist = false ;
    if (Array.isArray(optionsJson)) {
      const selectedOption = optionsJson.find(el => {
        if (el.correct === true) {
          return el;
        }
      });
      if (selectedOption) {
        isCorrectExist = true;
      }
    }
    return isCorrectExist;
  }

  React.useEffect(() => {
    setIsModalOpen(true);
    return () => {
      setIsModalOpen(false);
    }
  }, []);

  // PREVIEW CONTAINER
  const [
    previewContainerVisibility,
    setPreviewContainerVisibility,
  ] = React.useState(true);

  const editorBtnOptions = editorBtnOptionsOriginal.filter(option => option.addInQuiz);

  // QUERY CARD DATA WITH MODULES
  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    getQuizCardByQuestionId(cardId, editorBtnOptionsOriginal)
  );


  const onExit = () => {
    navigate(
      `/app/${organization_id}/groups/${rest.groupId}/channels/${rest.channelId}/deck/${rest.deckId}`
    )
  }

  const onSave = async (dataToSave) => {
    // debugger;
    console.log('SAVING CARD BACK DATA >>>>>>>>>>>>>>>>');
    // if (isLoading || isLoadingCardModule) return;
    dispatch(setIsLoading(true));
    dropSubscriptions();
    let toastKey;
    if (!closeToast && !toastKey) {
      const msg = 'Saving the card module data...';
      setCloseToast(true)
      toastKey = toaster.info(<>
        <ProgressBar
          value={value}
          getProgressLabel={() => msg}
          showLabel={false}
          infinite
          overrides={{
            BarProgress: {
              style: () => ({
                backgroundColor: 'white'
              })
            }
          }}
        />
        <p style={{ textAlign: 'center', margin: '0 !important' }}>
          {msg}
        </p>
      </>, {
        onClose: () => setCloseToast(false),
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
    }
    // debugger;
    saveCardBackData(organization_id, cardId, [...options, ...editorBtnOptions], data, dataToSave).then(() => {
      if (closeToast || toastKey) {
        toaster.update(toastKey, {
          children: <>Card data saved.</>,
        });
        setTimeout(() => {
          toaster.clear(toastKey);
          setCloseToast(false)
        }, 1000);
      }
      refetch();
      dispatch(setIsLoading(false));
    }).catch((err) => {
      console.error(err);
      refetch();
      dispatch(setIsLoading(false));
      setTimeout(() => {
        toaster.clear(toastKey);
        setCloseToast(false)
      }, 1000);
    });
  };


  const getIconForCard = logo_data => {
    if (!logo_data) return null;
    let url = getSrc(logo_data);
    return url;
  };

  const getActiveTab = () => {
    switch (tab) {
      case "Build":
        return 0;
      case "Cover":
        return 1;
      case "Data":
        return 2;
      default:
        return 0;
    }
  }

  const reOrderQuizDataIntoCardData = () => {
    let customData = data;
    const cards =
    {
      title: data?.quiz_question_quiz_deck_by_question_id?.quiz_question_data?.question_text,
      sub_title: data ? data.quiz_question_quiz_deck_by_question_id.quiz_question_data.sub_title : '',
      updated_at: data?.quiz_question_quiz_deck_by_question_id?.quiz_question_data?.updated_at,
      file_data: null,
      front_image_data: null,
      full_bleed: null,
      id: data ? data.quiz_question_quiz_deck_by_question_id.quiz_question_id : null,
      archived: false,
      card_type: 'quiz',
      link: null,
      visible_time: null,
      source_start_day: null,
      source_end_day: null,
      source_type: null,
      other_type: null,
      source_date: null,
      custom_field_id: null,
      custom_field: [],
      card_decks: [
        {
          deck_id: data ? data.quiz_question_quiz_deck_by_question_id.quiz_deck_id : null,
          published: data ? data.quiz_question_quiz_deck_by_question_id.published : false,
        }
      ],
      card_content_modules: data ? data.quiz_question_quiz_deck_by_question_id.card_content_modules : [],
      quiz_question_modules: data ? [data.quiz_question_quiz_deck_by_question_id.quiz_question_data] : []
    }
    customData = { ...customData, card: cards }
    // debugger;
    // console.log('customData', customData);
    return customData;
  }


  // const mergeQuestionDataAndContentModule = (data) => {
  //   // debugger;
  //   let question_data = data ? [data.quiz_question_quiz_deck_by_question_id.quiz_question_data] : [];
  //   let content_module = data ? data.quiz_question_quiz_deck_by_question_id.card_content_modules : [];
  //   let a = content_module.map(a => a.position);
  //   // assign position to 
  //   let position;
  //   for (var i = 1; i <= content_module.length; i++) {
  //     if (a.indexOf(i) == -1) {
  //       position = i;
  //       break;
  //     }
  //   };
  //   if (position === undefined) position = content_module.length;
  //   question_data = JSON.parse(JSON.stringify(question_data)).map(el => {
  //     el.position = position;
  //     return el;
  //   });
  //   return [...question_data, ...content_module].sort((a, b) => a.position - b.position);
  // }

  const BuilderHeadComponent = () => {
    //const { flag, noTitles } = props;
    const icon = getIconForCard(data?.quiz_question_quiz_deck_by_question_id?.quiz_question_data?.file_data);
    return (
      <RightSidePageHeader
        icon={icon}
        title={data?.quiz_question_quiz_deck_by_question_id?.quiz_question_data?.question_text}
        previewIcon={false}
        onPreviewIconClick={() =>
          setPreviewContainerVisibility(!previewContainerVisibility)
        }
        rightContent={builderStyle == 'wizard' ? null :
          <StatefulButtonGroup
            mode={MODE.radio}
            initialState={{ selected: getActiveTab() }}
          >
            {Object.keys(TAB_STATUS).map(el => {
              return (
                <Button onClick={() => setTab(TAB_STATUS[el])}>
                  {TAB_STATUS[el]}
                </Button>
              );
            })}
            {/* <BaseButton onClick={onSave} style={{marginRight: '16px'}}>Save</BaseButton> */}
          </StatefulButtonGroup>
        }
        headerDescription={`Last updated at ${moment(
          data?.quiz_question_quiz_deck_by_question_id?.quiz_question_data?.updated_at
        ).format('h:mm a on MMMM Do YYYY')}`}
      />
    )
  };

  const Blank = ({ children }) => {
    return <>{children}</>;
  }
  const MainPageComponentByStyle = mainPageStyle == 'modal' ? MainPageModal : Blank;

  return (
    <MainPageComponentByStyle
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      {typeof data === "undefined" ? <div
        className={css({
          position: 'absolute',
          width: theme.sizing.scale1200,
          height: theme.sizing.scale1200,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        })}
      >
        <Spinner />
      </div>
        : <BuilderComponent
          noPreview={true}
          dispatch={dispatch}
          tab={tab}
          BuilderHeadComponent={BuilderHeadComponent()}
          options={[
            ...editorBtnOptions,
            ...options
          ]}
          quizOptions={options}
          cardInfo={{
            title: data ? data.quiz_question_quiz_deck_by_question_id.quiz_question_data.question_text : '',
            sub_title: data ? data.quiz_question_quiz_deck_by_question_id.quiz_question_data.sub_title : '',
          }}
          onSave={onSave}
          refetch={refetch}
          data={reOrderQuizDataIntoCardData()}
          previewContainerVisibility={previewContainerVisibility}
          isQuizType={true}
          TAB_STATUS={TAB_STATUS}
          isModal={mainPageStyle == 'modal'}
          onModalClose={onModalClose}
          refetch={refetch}
          rest={rest}
          onModalClose={onModalClose}
          card_content_modules={data ? [{ content_module: data.quiz_question_quiz_deck_by_question_id.quiz_question_data }] : []}
          organization_id={organization_id}
          questionCards={questionCards}
          cards={cards}
        ></BuilderComponent>
      }
    </MainPageComponentByStyle>
  );
};


const mapStateToProps = props => {
  let { channels, cards, questionCards, theme } = props.appBasicControls;
  return {
    questionCards,
    cards,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(BuilderP);
