import React from 'react';
import { Spinner } from 'baseui/spinner';
import { handleAuthentication } from '../auth/index';

const Callback = () => {
  handleAuthentication();
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner size={96} />
    </div>
  );
};

export default Callback;
