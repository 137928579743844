import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useStyletron } from 'baseui';
import { organization_help, } from '../help/helpTexts';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import AddContentButton from '../addContentButton';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { Select } from "baseui/select";
import { SketchPicker } from 'react-color'
import { client } from '../../apollo/client';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { FileUploader } from 'baseui/file-uploader';
import {
  HeadingXLarge,
  ParagraphSmall,
  LabelSmall,
  LabelLarge,
} from 'baseui/typography';
import { createOrganization as createOrganizationMutation, createPwaTemplate, updatePwaTemplate } from '../../quries';
import { navigate } from '@reach/router';
import { getProfile } from '../../auth/index';
import SelectComponent from '../builder/cardBackEditorComponents/select';

export default ({ onNewOrgAdd, organization, organizations, templates }) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [activeKey, setActiveKey] = React.useState(0);


  console.log(organization);

  const [name, setName] = React.useState('');
  const [logo, SetLogo] = React.useState(null);

  const [domain, setDomain] = React.useState('');

  const [auth0Domain, setAuth0Domain] = React.useState('');
  const [client_id, setClientId] = React.useState('');
  const [client_secret, setClientSecret] = React.useState('');

  const [preview_image, setPreview] = React.useState(null);
  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const [createOrganization, { loading, data: createdOrg }] = useMutation(
    createOrganizationMutation
  );

  const [isCopyCurrentOrgAuth, setCopyCurrentOrgAuth] = React.useState(false);

  // PWA
  const [selectedOrganization, setOrganization] = React.useState([]);
  const [organization_id, setOrganizationId] = React.useState([]);
  const [app_icon, setAppIcon] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [app_name, setAppName] = React.useState(null);
  const [short_name, setShortName] = React.useState(null);
  const [start_url, setStartUrl] = React.useState(null);
  const [scope, setScope] = React.useState(null);
  const [background_color, setBackgroundColor] = React.useState(null);
  const [display, setDisplay] = React.useState(null);
  const [preview_image1, setPreview1] = React.useState(null);
  const [color_picker_active, setColorPickerActive] = React.useState(false);
  const [selectedDisplay, setDisplayOption] = React.useState([]);
  const displayOptions = ['minimal-ui', 'standalone', 'minimal-ui', 'browser'];
  React.useEffect(() => {
    if (organization_id && templates) {
      const filter = templates.find(item => item.organization_id === organization_id)
      if (filter) {
        const { id, icon, description, display, name, short_name, start_url, scope, background_color } = filter
        let displayId = 0;
        displayOptions.map((item, index) => {
          if (item == display) {
            displayId = index
          }
        })
        setId(id);
        setAppIcon(icon);
        setDescription(description);
        setAppName(name);
        setShortName(short_name);
        setStartUrl(start_url);
        setScope(scope);
        setBackgroundColor(background_color);
        setDisplay(display)
        setDisplayOption({ label: display, id: displayId })
      }
    }
  }, [organization_id])

  const previewIcon = file => {
    setPreview1(URL.createObjectURL(file));
  };

  React.useEffect(() => {
    if (isCopyCurrentOrgAuth) {
      setAuth0Domain(organization.auth0.domain);
      setClientId(organization.auth0.client_id);
      setClientSecret(organization.auth0.client_secret);
    } else {
      setAuth0Domain('');
      setClientId('');
      setClientSecret('');
    }
  }, [isCopyCurrentOrgAuth]);

  React.useEffect(() => {
    setIsLoading(false);
    if (createdOrg) {
      setIsOpen(false);
      onNewOrgAdd(createdOrg);
    }
  }, [createdOrg]);

  const { idTokenPayload } = getProfile();
  const onSave = () => {
    setIsLoading(true);
    createOrganization({
      variables: {
        name,
        logo_data: logo,
        domain: domain,
        admin: {
          email: idTokenPayload.email,
          domain: window.location.origin
        },
        auth0: {
          domain: auth0Domain,
          client_id,
          client_secret,
        },
      },
    });
  };

  const savePWATemplate = async () => {
    if (!organization_id || !background_color || !display || !start_url || !app_name || !short_name) {
      return;
    }
    setIsLoading(true);
    let input = {
      organization_id,
      background_color,
      display,
      scope,
      start_url,
      name: app_name,
      short_name,
      description,
      icon: app_icon
    }
    await client.mutate({
      mutation: createPwaTemplate,
      variables: {
        input: input,
      },
    });
    setIsLoading(false);
    setIsOpen(false)
  }

  const updatePWATemplate = async () => {
    if (!organization_id || !background_color || !display || !start_url || !app_name || !short_name) {
      return;
    }
    setIsLoading(true);
    let input = {
      organization_id,
      background_color,
      display,
      scope,
      start_url,
      name: app_name,
      short_name,
      description,
      icon: app_icon
    }
    await client.mutate({
      mutation: updatePwaTemplate,
      variables: {
        id,
        input: input,
      },
    });
    setIsLoading(false);
    setIsOpen(false)
  }

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const get_PWA_Form = () => {
    const textMargin = { marginBottom: '0.5rem' }
    return (
      <div>
        <Grid
          gridGaps={[12, 12, 24]}
          overrides={{
            Grid: {
              style: ({ $theme }) => outerGridOverride,
            },
          }}
        >
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Organization</H6>
            <Select
              options={organizations.map(item => ({ label: item.organization.name, id: item.organization.id }))}
              value={selectedOrganization}
              placeholder="Select organization"
              onChange={params => { setOrganizationId(params.value[0].id); setOrganization(params.value) }}
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>App Name </H6>
            <Input
              value={app_name}
              onChange={e => setAppName(e.target.value)}
              placeholder="App Name"
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Short Name </H6>
            <Input
              value={short_name}
              onChange={e => setShortName(e.target.value)}
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>App Description </H6>
            <Input
              value={description}
              onChange={e => setDescription(e.target.value)}
              clearOnEscape
            />
          </Cell>

          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Background Color</H6>
            <Input
              value={background_color}
              onChange={e => setBackgroundColor(e.target.value)}
              onFocus={e => setColorPickerActive(true)}
              clearOnEscape
            />
            {color_picker_active && (
              <SketchPicker
                color={background_color || ''}
                onChangeComplete={(color) => {
                  setBackgroundColor(color.hex);
                  setTimeout(() => setColorPickerActive(false), 500)
                }}
              />
            )}
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Display</H6>
            <Select
              options={displayOptions.map((item, index) => ({ label: item, id: index }))}
              value={selectedDisplay}
              onChange={params => { setDisplay(params.value[0].label); setDisplayOption(params.value) }}
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Scope</H6>
            <Input
              value={scope}
              onChange={e => setScope(e.target.value)}
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>Start URL</H6>
            <Input
              value={start_url}
              onChange={e => setStartUrl(e.target.value)}
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <H6 style={textMargin}>App Icon </H6>
            {preview_image1 && <img src={preview_image1}></img>}
            <FileUploader
              onDrop={(acceptedFiles, rejectedFiles) => {
                setAppIcon(acceptedFiles);
                previewIcon(acceptedFiles[0]);
              }}
            />
          </Cell>
        </Grid>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              id ? updatePWATemplate() : savePWATemplate();
            }}
            isLoading={isLoading}
          >
            {id ? 'Update PWA' : 'Add PWA'}
          </ModalButton>
        </ModalFooter>
      </div>
    )
  }

  return (
    <React.Fragment>
      {/* <Button onClick={() => setIsEditOpen(true)}>Add Category</Button> */}
      <div
        className={css({
          float: 'right',
        })}
      >
        <AddContentButton
          style={{
            marginTop: '5px',
            padding: '13px 17px',
          }}
          buttonText={'Add Organization'}
          helpText={organization_help}
          buttonClickOverride={() => setIsOpen(true)}
        />
      </div>

      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {/* <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey.toString());
            }}
            activateOnFocus
            fill={FILL.fixed}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  marginTop: '2rem'
                })
              }
            }}
          >
            <Tab title="General Information"> */}
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Name of Organization</H6>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name of Organization"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Domain of Organization</H6>
              <Input
                value={domain}
                onChange={e => setDomain(e.target.value)}
                placeholder="Domain of Organization"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Logo</H6>
              {preview_image && <img src={preview_image}></img>}
              <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  SetLogo(acceptedFiles);
                  previewImage(acceptedFiles[0]);
                }}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <Checkbox
                checked={isCopyCurrentOrgAuth}
                onChange={e => setCopyCurrentOrgAuth(e.target.checked)}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                Copy from current Organization {organization.name}
              </Checkbox>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Domain</H6>
              <Input
                value={auth0Domain}
                onChange={e => setAuth0Domain(e.target.value)}
                placeholder="Auth0 Domain"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Client Id</H6>
              <Input
                value={client_id}
                onChange={e => setClientId(e.target.value)}
                placeholder="Auth0 Client ID"
                type="password"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Client Secret</H6>
              <Input
                value={client_secret}
                onChange={e => setClientSecret(e.target.value)}
                placeholder="Auth0 Client Secret"
                type="password"
                clearOnEscape
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onSave();
            }}
            isLoading={isLoading}
          >
            Add Organization
          </ModalButton>
        </ModalFooter>
        {/* </Tab>
            <Tab title="Progressive Web App"><ModalBody style={{ flex: '1 1 0' }}>{get_PWA_Form()}</ModalBody></Tab>
          </Tabs> */}
      </Modal>
    </React.Fragment>
  );
};
