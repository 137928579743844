import * as React from 'react';
import { connect } from 'react-redux';
import { useStyletron } from 'baseui';
import {Skeleton} from 'baseui/skeleton'
import { quizzes_responses as getQuizResponses } from '../../quries';
import { client } from '../../apollo/client';
import Table from '../intake/table';
import Button from '../styledButton';
import { Drawer } from "baseui/drawer";
import {
  LabelSmall,
  ParagraphSmall,
  LabelMedium
} from 'baseui/typography';
import { Tag, KIND } from 'baseui/tag';
import moment from "moment";
import { getServerUrl } from '../../apollo/client';

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};

const getPagesCount =  count => {
  const pagesCount = parseInt(count/20);
  const pagesCountRemainder = parseInt(count%20);
  if(pagesCountRemainder > 0) {
    return pagesCount + 1;
  }
  return pagesCount;
}

const QuizResponseTable = ({ organization_id, close }) => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [pagesCount, setPagesCount] = React.useState(0);
  const [css, theme] = useStyletron();

  const [selectedQuiz, setSelectedQuiz] = React.useState(null);
  const [openResponseDrawer, setOpenResponseDrawer] = React.useState(false);

  React.useEffect(() => {
    loadData(page);
  }, []);

  const loadData = (page, noLoading) => {
    if (loading) return;
    setPage(page);
    !noLoading && setLoading(true);
    return client.query({
      query: getQuizResponses(organization_id, page)
    }).then((result) => {
      setLoading(false);
      setData(data.concat(result.data.quizzes_by_org_paginated.rows));
      setCount(result.data.quizzes_by_org_paginated.count);
      setPagesCount(getPagesCount(result.data.quizzes_by_org_paginated.count));
      if (result.data.quizzes_by_org_paginated.count == 0) close();
    });
  };

  const loadMoreData = () => {
    let p = page;
    if (pagesCount >= p) return loadData(++p, true /* no loading */);
  }

  const onQuizClick = (quiz) => {
    setSelectedQuiz(quiz);
    setOpenResponseDrawer(true);
  }

  const onDrawerClose = () => setOpenResponseDrawer(false);

  const columns = [
    {
      display: 'Deck',
      key: 'deck',
      formatter: (v) => v.title
    },
    {
      display: 'App User',
      key: 'app_user',
      formatter: (v) => v ? v.name || 'NA' : 'NA'
    },
    {
      display: 'Last Interaction at',
      key: 'updated_at',
      formatter: (v) => moment(v).format('ll')
    },
    {
      display: 'Total Quiz Questions',
      key: 'deck',
      formatter: (v) => v.quiz_question_quiz_decks.length
    },
    {
      display: 'Total Responses',
      key: 'quiz_responses',
      formatter: (v) => v.length
    },
    {
      display: 'Correct Responses',
      key: 'quiz_responses',
      formatter: (v) => {
        let correct = v.filter(el => el.is_correct).length;
        return <Tag kind={KIND.positive} closeable={false}>{correct}</Tag>
      }
    },
    {
      display: 'Incorrect Responses',
      key: 'quiz_responses',
      formatter: (v) => {
        let correct = v.filter(el => el.is_correct).length;
        let incorrect = v.length - correct;
        return <Tag kind={KIND.negative} closeable={false}>{incorrect}</Tag>
      }
    },
    {
      display: '',
      key: '__root',
      formatter: (v) => {
        // if (v.quiz_responses.length == 0) return <></>;
        const onClick = () => onQuizClick(v);
        return (
          <Button 
            className={css({
              height: 'auto !important',
              lineHeight: 'normal !important'
            })}
            onClick={onClick}
            overrides={{
              Root: {
                style: {
                  height: 'auto !important',
                  lineHeight: 'normal !important'
                }
              }
            }}
            isSelected
          >
            View Answers
          </Button>
        )
      }
    }
  ];

  function getQuizQuestionAndAnswers() {
    let array = [];
    if (selectedQuiz && selectedQuiz.deck && selectedQuiz.quiz_responses) {
      selectedQuiz.quiz_responses.forEach((el) => {
        let question = selectedQuiz.deck.quiz_question_quiz_decks.find((el1) => el1.quiz_question_data.id == el.quiz_question_id);
        if (question) {
          question = question.quiz_question_data.question_text;
        } else question = '';
        array.push({
          question,
          is_correct: el.is_correct,
          created_at: el.created_at,
          answer: el
        })
      })
    };
    console.log(array);
    return array;
  }

  function renderAnswerResponse(element) {
    console.log(element);
    let component = <></>;
    switch (element.answer.quiz_question_type) {
      case 'QuizOptionQuestion':
      case 'QuizTextQuestion':
        component = <div style={{ display: 'flex', marginTop: '8px', marginBottom: '32px', border: '1px solid #E2E2E2', padding: '8px', borderRadius: '2px' }}>
          <div style={{ width: '50%', paddingRight: '8px', borderRight: '1px solid #E2E2E2' }}>
            <ParagraphSmall style={{ marginBottom: '4px' }}>User Answer</ParagraphSmall>
            <LabelMedium>{JSON.parse(element.answer.value).toString()}</LabelMedium>
          </div>
          <div style={{ width: '50%', paddingLeft: '8px' }}>
            <ParagraphSmall style={{ marginBottom: '4px' }}>Correct Answer</ParagraphSmall>
            <LabelMedium>{JSON.parse(element.answer.correct_answer).toString()}</LabelMedium>
          </div>
        </div>;
        break;
      case 'QuizMatchingQuestion':
        try {
          component = <div style={{ display: 'flex', marginTop: '8px', marginBottom: '32px', border: '1px solid #E2E2E2', padding: '8px', borderRadius: '2px' }}>
          <div style={{ width: '50%', paddingRight: '8px', borderRight: '1px solid #E2E2E2' }}>
            <ParagraphSmall style={{ marginBottom: '4px' }}>User Answer</ParagraphSmall>
            {JSON.parse(element.answer.value).forEach((el) => {
              return <div style={{ display: 'flex' }}>
                {el.map((el1) => <LabelMedium>{el1}</LabelMedium>)}
              </div>
            })}
          </div>
          <div style={{ width: '50%', paddingLeft: '8px' }}>
            <ParagraphSmall style={{ marginBottom: '4px' }}>Correct Answer</ParagraphSmall>
            {JSON.parse(element.answer.correct_answer).forEach((el) => {
              return <div style={{ display: 'flex' }}>
                {el.map((el1) => <LabelMedium>{el1}</LabelMedium>)}
              </div>
            })}
          </div>
        </div>
        } catch (e) {

        }
        break;
    }
    return <>
      {component}
    </>;
  }

  if (loading) {
    return (
      <div style={{marginTop: '2rem'}}>
        {new Array(4).fill('').map((item, index) => (
          <Skeleton
            width="100%"
            height="94px"
            animation
            overrides={{
              Root: {
                style: {
                  marginBottom: '15px',
                },
              },
            }}
        />))}
      </div>
    )
  }
  // console.log(selectedQuiz);
  return <>
    <Table data={data} count={count} columns={columns} loadMoreData={loadMoreData}></Table>
    {openResponseDrawer &&
      <Drawer
        isOpen={openResponseDrawer}
        autoFocus
        onClose={onDrawerClose}
        overrides={{
          Root: {
            style: {
              zIndex: 2
            }
          }
        }}
      > <div style={{ width: '100%', position: 'relative', height: '72px' }}>
          <div style={{ position: 'relative', float: 'right' }}>
            {selectedQuiz.quiz_responses.length > 0 && <Button
              onClick={() => {
                window.open(`${getServerUrl()}/quiz_response_data/download/${organization_id}/${selectedQuiz.id}`, '_blank').focus();
              }}
              isSelected
            >
              Download Data
            </Button>}
          </div>
        </div>
        {getQuizQuestionAndAnswers().map((el, index) => {
          return <div style={{
            position: 'relative',
            width: '100%',
            background: 'white',
            marginBottom: '16px'
          }}>
            <LabelSmall>{el.question}</LabelSmall>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <Tag
                kind={el.is_correct ? KIND.positive : KIND.negative}
                closeable={false}
              >
                {el.is_correct ? 'Correct' : 'Incorrect'} Answer
              </Tag>
              <ParagraphSmall style={{ marginTop: '6px', marginLeft: '4px' }}>
                Answered at {moment(el.created_at).format('llll')}
              </ParagraphSmall>
            </div>
            <div>
              {renderAnswerResponse(el)}
            </div>
            <hr style={{ height: '2px', margin: 0, marginTop: '0px', background: '#E2E2E2' }} />
          </div>
        })}
      </Drawer>
    }
  </>;
}

export default connect(mapStateToProps)(QuizResponseTable);