import * as React from 'react';
import { connect } from 'react-redux';
import Profile from './profile';
import { useStyletron } from 'baseui';

const ProfileWrapper = ({ organization, organization_id, dispatch }) => {
  const [css, theme] = useStyletron();
  return (
    <Profile
      organization_id={organization_id}
      dispatch={dispatch}
      organization={organization}
    ></Profile>
  );
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};
export default connect(mapStateToProps)(ProfileWrapper);
