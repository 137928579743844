import React from 'react';
import _ from 'lodash';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { arrayMove } from 'baseui/dnd-list';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Portal from './portal';
import Card from '../cards/card';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { FlexGrid } from 'baseui/flex-grid';
import { DragDropContext } from 'react-beautiful-dnd';
import AddSlide from './addSlide';
import { sourceByType } from '../../util';
import styled from '@emotion/styled';
import EditSlide from './editSlide';
import ArchiveSlide from './archiveSlide';
import UnArchiveSlide from './unArchiveSlide';
import CloneSlide from './cloneSlide';
import MoveSlide from './moveSlide';
import svgIcons from '../../svg';
import {showAlert} from '../../redux/actions/appBasicControls';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'small');
}

const Slide = ({
  selectedComponent,
  appContentScopeId,
  organization_id,
  home_component_app_content_scopes,
  refetchHomeComponents,
  tabFilter,
  dispatch,
  defaultTheme
}) => {
  const [css, theme] = useStyletron();
  const [slides, setSlides] = React.useState([]);
  console.log('Slides: ', slides)

  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(selectedComponent.home_component.slide_home_components));
    s = s.filter((el) => {
      if (tabFilter === 'published') {
        return el.published == true && el.slide_home_component_slide.archived !== true;
      }
      // if (tabFilter === 'archived') {
      //   return el.slide_home_component_slide.archived === true
      // }
      if (tabFilter === 'all') {
        return el.slide_home_component_slide.archived !== true
      }
      return true;
    });
    setSlides(s.sort((a, b) => a.position - b.position));
  }, [selectedComponent]);

  const [selectedSlide, setSelectedSlide] = React.useState(null);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isCloneOpen, setIsCloneOpen] = React.useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);
  const [isUnArchiveOpen, setIsUnArchiveOpen] = React.useState(false);
  const [isMoveSlideOpen, setIsMoveSlideOpen] = React.useState(false);
  const [selectedHomeComponent, setSelectedComponent] = React.useState(false);

  const onClose = () => {
    setIsEditOpen(false);
    setIsCloneOpen(false);
    setIsArchiveOpen(false);
    setIsUnArchiveOpen(false);
    setIsMoveSlideOpen(false);
    setSelectedSlide(null);
    setSelectedComponent(null);
  };

  const [updateSlide, { loading: onUpdateLoader, data: updatedSlide }] = useMutation(
    gql`
        mutation updateSlide(
            $id: ID
            $input: SlideInput
        ) {
            updateSlide(id:$id, input: $input) {
                title
            }
        }
    `
  );
  // const refetchHomeComponentsDebounced = _.debounce(refetchHomeComponents, 500);
  React.useEffect(() => {
    updatedSlide && refetchHomeComponents();
  }, [updatedSlide]);

  const AddDeckContainer = styled.div`
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        height: calc(100% + 21px);
        top: 0;
        box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
        background: ${theme.colors.primary50};
        padding-left: 25px;
    `;
  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <div id={`wrapper_deck${selectedComponent.id}`} className="wrapper" style={{
            paddingTop: theme.sizing.scale600,
            minHeight: '274px'
          }}>
            <FlexGrid>
              <DragDropContext
                onDragEnd={({ destination, source, ...rest }) => {
                  if (!destination) {
                    return;
                  }
                  let newArr = arrayMove(
                    JSON.parse(JSON.stringify(slides)),
                    source.index,
                    destination.index
                  );
                  newArr = newArr.map((el, i) => {
                    el.position = i;
                    delete el.isNew;
                    return el;
                  });
                  newArr.forEach(el => {
                    let old = slides.find(el1 => el.id == el1.id);
                    if (old.position !== el.position) {
                      updateSlide({
                        variables: {
                          id: el.slide_home_component_slide.id,
                          input: {
                            position: el.position
                          }
                        }
                      });
                    }
                  });
                  setSlides(newArr);
                }}
              >
                <Droppable
                  droppableId={selectedComponent.id}
                  type={'CARD'}
                  direction="horizontal"
                  isCombineEnabled={false}
                >
                  {dropProvided => (
                    <div
                      // className="scrolls"
                      className="scrolls_v1"
                      ref={dropProvided.innerRef}
                      style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexGrow: 1,
                        paddingTop: '3px',
                        marginRight: '200px',
                        scrollbarWidth: 'thin',
                        scrollbarColor: defaultTheme === "dark" ? 'black #282828' : "white black"
                      }}
                    >
                      {slides.map((el, index) => {
                        return (
                          <Draggable
                            key={el.id}
                            draggableId={el.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <>
                                <Portal
                                  snapshot={snapshot}
                                  child={
                                    <div
                                      {...dragProvided.dragHandleProps}
                                      {...dragProvided.draggableProps}
                                      ref={dragProvided.innerRef}
                                      onClick={() => {
                                        setSelectedSlide(el.slide_home_component_slide);
                                        setIsEditOpen(true);
                                      }}
                                    >
                                      <Card
                                        key={index}
                                        // icon_name='image'
                                        custom_icon={svgIcons.SliderDeckIcon}
                                        cover_type='icon'
                                        full_bleed
                                        title={el.slide_home_component_slide.title}
                                        toggle={el.published}
                                        showMenuOptions={true}
                                        showSettingsIcon={false}
                                        width={'148px'}
                                        tabFilter={tabFilter}
                                        full_bleed={true}
                                        onToggleClick={(e) => {
                                          let s = JSON.parse(JSON.stringify(slides));
                                          s[index].published = e.target.checked;
                                          setSlides(s);
                                          dispatch(showAlert({
                                            msg: 'Updating Slide'
                                          }))
                                          updateSlide({
                                            variables: {
                                              id: el.slide_home_component_slide.id,
                                              input: {
                                                published: e.target.checked
                                              }
                                            }
                                          }).then(() => dispatch(showAlert({
                                            msg: 'Slide Updated'
                                          })))
                                        }}
                                        isHomeComponent={true}
                                        home_component_app_content_scopes={home_component_app_content_scopes}
                                        currentSlideComponent={selectedComponent}
                                        onClone={e => {
                                          setSelectedSlide(el.slide_home_component_slide);
                                          setIsCloneOpen(true)
                                        }}
                                        onSettingsClick={() => {
                                          setSelectedSlide(el.slide_home_component_slide);
                                          setIsEditOpen(true);
                                        }}
                                        onArchive={() => {
                                          setSelectedSlide(el.slide_home_component_slide);
                                          setIsArchiveOpen(true);
                                        }}
                                        onUnArchive={() => {
                                          setSelectedSlide(el.slide_home_component_slide);
                                          setIsUnArchiveOpen(true);
                                        }}
                                        onMoveSlide={(targetComponent) => {
                                          setSelectedSlide(el.slide_home_component_slide);
                                          setSelectedComponent(targetComponent);
                                          setIsMoveSlideOpen(true);
                                        }}
                                        imageSrc={getSrc(el.slide_home_component_slide.image_data)}
                                      ></Card>
                                    </div>
                                  }
                                ></Portal>
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                      <AddDeckContainer>
                        <AddSlide
                          position={slides.length}
                          tabFilter={tabFilter}
                          app_content_scope_id={appContentScopeId}
                          organization_id={organization_id}
                          home_component_id={selectedComponent.home_component.id}
                          refetchHomeComponents={refetchHomeComponents}
                        ></AddSlide>
                        {isEditOpen && (
                          <EditSlide
                            onClose={onClose}
                            refetchHomeComponents={refetchHomeComponents}
                            slide={selectedSlide}
                          ></EditSlide>
                        )}
                        {isCloneOpen && (
                          <CloneSlide
                            onClose={onClose}
                            refetchHomeComponents={refetchHomeComponents}
                            slide={selectedSlide}
                          ></CloneSlide>
                        )}
                        {isArchiveOpen && (
                          <ArchiveSlide
                            onClose={onClose}
                            refetchHomeComponents={refetchHomeComponents}
                            slide={selectedSlide}
                            dispatch={dispatch}
                          ></ArchiveSlide>
                        )}
                        {isUnArchiveOpen && (
                          <UnArchiveSlide
                            onClose={onClose}
                            refetchHomeComponents={refetchHomeComponents}
                            slide={selectedSlide}
                            dispatch={dispatch}
                          ></UnArchiveSlide>
                        )}
                        {isMoveSlideOpen && (
                          <MoveSlide
                            onClose={onClose}
                            refetchHomeComponents={refetchHomeComponents}
                            component={selectedHomeComponent}
                            slide={selectedSlide}
                            dispatch={dispatch}
                          ></MoveSlide>
                        )}
                      </AddDeckContainer>
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FlexGrid>
          </div>
        </Cell>
      </Grid>
    </>
  );
};

export default Slide;
