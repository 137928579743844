import * as React from 'react';
import BaseClass from './base';
import ChannelPickerCXL from '../../channelPickerCXL';

class SelectComponent extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        };
    }

    render() {
        const selectedChannelId = this.state.value;
        const onCardSelect = (selectedChannelId) => {
            this.setState({
                value: selectedChannelId
            })
        };
        return <ChannelPickerCXL
            selectedChannelId={selectedChannelId}
            onChannelSelect={onCardSelect}
            title={this.props.title || ''}
            theme={this.props.theme}
            placeholder={this.props.placeholder}
        />
    }
}

export default SelectComponent;