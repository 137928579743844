import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { useMutation } from '@apollo/client';
import { Select } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { sourceByType } from '../../util';
import { moveDeckMutation } from '../../quries';
import store from '../../redux/store';
import { showAlert } from '../../redux/actions/appBasicControls';

export default ({ deck, channel, onClose, dispatch }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedCategory, setMoveCategory] = React.useState(0);

  const categoryOptions = (store.getState().appBasicControls.category || [])
    .map((el, i) => {
      return {
        name: el.category.name,
        id: el.id,
        index: i,
        app_content_scope_id: el.app_content_scope_id,
        category_id: el.category_id,
      };
    })
    .filter(item => item.app_content_scope_id === channel.id);
  if (isOpen === false) onClose();

  const [
    moveDeck,
    { loading: onMoveDeckLoader, data: movedDeck },
  ] = useMutation(moveDeckMutation);

  const onSave = () => {
    if (!selectedCategory) return;
    setIsLoading(true);
    moveDeck({
      variables: {
        id: deck.id,
        category_id: selectedCategory.category_id,
      },
    });
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (movedDeck) {
      dispatch(
        showAlert({
          msg: 'Successfully moved deck!',
          error: false,
        })
      );
      onClose();
    }
  }, [movedDeck]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
          // Root: {
          //   style: {
          //     zIndex: 100
          //   }
          // },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>
              Are you sure you want to move this deck -{' '}
              <b style={{ color: 'rgb(118, 95, 237)' }}>{deck.title || ''}</b>{' '}
              to new channel - <b>{channel.name}</b>?{' '}
            </H5>
            <H6>
              This process may take a few moments and all changes are final. If
              you need to move this back, you will be able to do so.
            </H6>

            <H5>Category</H5>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              {categoryOptions.length > 0 ? (
                <>
                  <H6>Select category</H6>
                  <Select
                    options={categoryOptions}
                    labelKey="name"
                    valueKey="id"
                    placeholder=""
                    onChange={({ value }) => {
                      setMoveCategory(value[0]);
                    }}
                    value={selectedCategory}
                  />
                </>
              ) : (
                <H6>There is no category to move. Please create new category first.</H6>
              )}
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={onMoveDeckLoader}
            onClick={() => {
              onSave();
            }}
            disabled={!selectedCategory}
          >
            Move this anyway
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
