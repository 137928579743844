import React, { Component, Node } from 'react';
import { navigate } from '@reach/router';

class Wrap extends Component {
  componentDidMount() {
    navigate(`${this.props.organization_id}/groups`);
  }
  render() {
    return <></>;
  }
}

export default Wrap;
