import React, { Component, Node, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Droppable, Draggable } from 'react-beautiful-dnd';

import { useStyletron } from 'baseui';
import { LabelMedium, LabelXSmall } from 'baseui/typography';
import styled from '@emotion/styled';

import { editorBtnOptionsHeader } from '../../../data/cardBuilderOptions';
import HelpQuestionMark from '../help/questionMarkHelp';

const StyledIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-bottom: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');

  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }

  document.body.appendChild(portal);
}

class PortalAwareItem extends Component {
  render() {
    const { dragProvided, card, index, theme, snapshot, css, hoverStatus, setHoverStatus, destinationIndex, addNewToTheList } = this.props;
    const usePortal = snapshot.isDragging;
    // const [hoverStatus, setHoverStatus] = React.useState(false);

    const op = () => { };
    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
        className={css({
          backgroundColor: 'white',
          width: '150px',
          padding: theme.sizing.scale500,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
          marginBottom: theme.sizing.scale500 + ' !important',
          marginTop: '18px',
          marginLeft: '15px'
        })}
      >
        {
          (card.builderIcon) && (
            <StyledIcon src={card.builderIcon} />
          ) || (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                position: 'relative',
                left: '50%',
                transform: 'translateX(-50%)',
              })}
            >
              <path
                d="M2.55 17.54L3.96 18.95L5.75 17.15L4.34 15.74L2.55 17.54ZM10 21.45H12V18.5H10V21.45ZM3 9.5H0V11.5H3V9.5ZM14 5.31V0.5H8V5.31C6.21 6.35 5 8.28 5 10.5C5 13.81 7.69 16.5 11 16.5C14.31 16.5 17 13.81 17 10.5C17 8.28 15.79 6.35 14 5.31ZM19 9.5V11.5H22V9.5H19ZM16.24 17.16L18.03 18.96L19.44 17.55L17.64 15.76L16.24 17.16Z"
                fill={"black" || theme.colors.primaryB}
              />
            </svg>
          )
        }
        <LabelMedium
          className={css({
            textAlign: 'center',
            color: "black !important" || theme.colors.primaryB,
          })}
        >
          {card.display}
        </LabelMedium>
        <div
          onMouseEnter={() => setHoverStatus({
            ...hoverStatus,
            [index]: true
          })}
          onMouseLeave={() => setHoverStatus({
            ...hoverStatus,
            [index]: false
          })}
          className={css({
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '16px',
            backgroundColor: hoverStatus && hoverStatus[index] ? 'rgb(98, 70, 238)' : '#E2E2E2' || theme.colors.backgroundTertiary,
            color: 'black',
            marginTop: '8px',
            borderRadius: '200px',
            padding: '14px',
            cursor: 'pointer',
          })}
        >
          <svg
            width="33"
            height="10"
            viewBox="0 0 33 10"
            fill='none'
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              addNewToTheList(index, destinationIndex)
            }}
            className={css({
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            <path
              d="M2.832 8.244V6.036H0.804V4.62H2.832V2.412H4.368V4.62H6.396V6.036H4.368V8.244H2.832ZM16.1153 9L15.5153 7.008H12.7193L12.1193 9H10.2713L13.0312 0.624H15.2873L18.0113 9H16.1153ZM14.1353 2.28H14.0753L13.1513 5.46H15.0713L14.1353 2.28ZM22.9381 7.944H22.8781C22.8301 8.112 22.7541 8.272 22.6501 8.424C22.5461 8.568 22.4181 8.696 22.2661 8.808C22.1221 8.912 21.9541 8.992 21.7621 9.048C21.5781 9.112 21.3861 9.144 21.1861 9.144C20.3861 9.144 19.7821 8.864 19.3741 8.304C18.9661 7.736 18.7621 6.916 18.7621 5.844C18.7621 4.772 18.9661 3.956 19.3741 3.396C19.7821 2.836 20.3861 2.556 21.1861 2.556C21.5941 2.556 21.9541 2.668 22.2661 2.892C22.5861 3.116 22.7901 3.404 22.8781 3.756H22.9381V0.12H24.7141V9H22.9381V7.944ZM21.7741 7.704C22.1101 7.704 22.3861 7.624 22.6021 7.464C22.8261 7.296 22.9381 7.068 22.9381 6.78V4.92C22.9381 4.632 22.8261 4.408 22.6021 4.248C22.3861 4.08 22.1101 3.996 21.7741 3.996C21.4381 3.996 21.1581 4.116 20.9341 4.356C20.7181 4.588 20.6101 4.904 20.6101 5.304V6.396C20.6101 6.796 20.7181 7.116 20.9341 7.356C21.1581 7.588 21.4381 7.704 21.7741 7.704ZM30.2389 7.944H30.1789C30.1309 8.112 30.0549 8.272 29.9509 8.424C29.8469 8.568 29.7189 8.696 29.5669 8.808C29.4229 8.912 29.2549 8.992 29.0629 9.048C28.8789 9.112 28.6869 9.144 28.4869 9.144C27.6869 9.144 27.0829 8.864 26.6749 8.304C26.2669 7.736 26.0629 6.916 26.0629 5.844C26.0629 4.772 26.2669 3.956 26.6749 3.396C27.0829 2.836 27.6869 2.556 28.4869 2.556C28.8949 2.556 29.2549 2.668 29.5669 2.892C29.8869 3.116 30.0909 3.404 30.1789 3.756H30.2389V0.12H32.0149V9H30.2389V7.944ZM29.0749 7.704C29.4109 7.704 29.6869 7.624 29.9029 7.464C30.1269 7.296 30.2389 7.068 30.2389 6.78V4.92C30.2389 4.632 30.1269 4.408 29.9029 4.248C29.6869 4.08 29.4109 3.996 29.0749 3.996C28.7389 3.996 28.4589 4.116 28.2349 4.356C28.0189 4.588 27.9109 4.904 27.9109 5.304V6.396C27.9109 6.796 28.0189 7.116 28.2349 7.356C28.4589 7.588 28.7389 7.704 29.0749 7.704Z"
              fill={hoverStatus && hoverStatus[index] ? 'white' : '#4D4D4D' || theme.colors.primaryA}
            />
          </svg>
        </div>
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

export default ({ data, selectedMainOptionId, destinationIndex, addNewToTheList }) => {
  const [css, theme] = useStyletron();
  const [hoverStatus, setHoverStatus] = React.useState({});
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const getOptionsWithHeader = (options) => {
    let optionsWithHeader = {};
    options && options.map((ele, index) => {
      let header = ele.header || '';
      if (!optionsWithHeader[header]) optionsWithHeader[header] = [];
      optionsWithHeader[header].push({ ...ele, position: index });
    })
    return optionsWithHeader;
  }

  useEffect(() => {
    const options = data.options
      .filter(el => {
        if (el.parent == selectedMainOptionId) {
          return el;
        }
      });
    setSelectedOptions(options)
  }, [selectedMainOptionId])

  const renderOptions = () => {
    return selectedOptions.map((card, index) => (
      <Draggable
        key={card.id}
        draggableId={card[data.idKey]}
        index={index}
        disableInteractiveElementBlocking={true}
      >
        {(dragProvided, snapshot) => (
          <PortalAwareItem
            hoverStatus={hoverStatus}
            card={card}
            index={index}
            destinationIndex={destinationIndex}
            addNewToTheList={addNewToTheList}
            theme={theme}
            dragProvided={dragProvided}
            snapshot={snapshot}
            css={css}
            setHoverStatus={setHoverStatus}
          ></PortalAwareItem>
        )}
      </Draggable>
    ))
  }

  const renderOptionsWithHeader = () => {
    const optionsWithHeader = getOptionsWithHeader(selectedOptions);
    return Object.keys(optionsWithHeader).map(ele => {
      let optionList = optionsWithHeader[ele] && optionsWithHeader[ele].map((card, index) => (
        <Draggable
          key={card.id}
          draggableId={card[data.idKey]}
          index={card.position}
          disableInteractiveElementBlocking={true}
        >
          {(dragProvided, snapshot) => (
            <PortalAwareItem
              hoverStatus={hoverStatus}
              card={card}
              index={card.position}
              destinationIndex={destinationIndex}
              addNewToTheList={addNewToTheList}
              theme={theme}
              dragProvided={dragProvided}
              snapshot={snapshot}
              css={css}
              setHoverStatus={setHoverStatus}
            ></PortalAwareItem>
          )}
        </Draggable>
      ))

      if (!ele) {
        return optionList;
      }

      const header = editorBtnOptionsHeader.find(ele1 => ele1.id === ele);
      return (
        <>
          <div
          className={
            css({
              display: 'flex',
              alignItems: 'center',
              padding: '12px',
              paddingBottom: '0px'
            })
          }
          >
            <LabelXSmall className={css({
              lineHeight: '28px',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '11px',
              lineHeight: '28px',
              color: '#1E1F2C'
            })}> {`${header.name}`}</LabelXSmall>
            <HelpQuestionMark
              text={header.quesMarkText}
              iconHeight={'14px'}
            />
          </div>
          {optionList}
        </>
      );
    })
  }

  return (
    <Droppable
      droppableId={data.droppableId}
      type={data.type}
      direction="vertical"
      isCombineEnabled={false}
      isDropDisabled={true}
    >
      {dropProvided => (
        <div {...dropProvided.droppableProps}>
          <div>
            <div>
              <div ref={dropProvided.innerRef}>
                {/* {renderOptions()} */}
                {renderOptionsWithHeader()}
                {dropProvided.placeholder}
              </div>
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};
