import Cookies from 'universal-cookie';
const cookies = new Cookies();
const BASE_COOKIE_NAME = 'catalyst_admin_v_2_session_by_org';
const BASE_COOKIE_NAME_V1 = 'cardware';
const EMAIL_COOKIE = 'cardware_admin_email';

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

export const setLastLoggedInOrgId = (organization_id) => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_last`;
  cookies.set(cookie_name, organization_id, { path: '/' });
  return;
};

export const setAuth0Token = (token) => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_auth0_token`;
  cookies.set(cookie_name, token, { path: '/' });
  return;
};

export const setEmailToCookie = (email) => {
  cookies.set(EMAIL_COOKIE, email, { path: '/' });
  return;
};

export const removeEmailCookie = () => {
  cookies.remove(EMAIL_COOKIE, { path: '/' });
};

export const getEmailFromCookie = () => {
  let _c = cookies.get(EMAIL_COOKIE);
  if (!_c) return null;
  return _c;
}

export const lastLoggedInOrgId = () => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_last`;
  let _c = cookies.get(cookie_name);
  if (!_c) return null;
  return _c;
};

export const clearLastLoggedInOrgId = () => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_last`;
  cookies.remove(cookie_name, { path: '/' });
  return;
};

export const clearAuth0Token = () => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_auth0_token`;
  cookies.remove(cookie_name, { path: '/' });
  return;
}

export const getAuth0Token = () => {
  let cookie_name = `${BASE_COOKIE_NAME_V1}_auth0_token`;
  let _c = cookies.get(cookie_name);
  if (!_c) return null;
  return _c;
}

export const getOrganizationIdFromPath = () => {
  let path = window.location.pathname;
  let regex = /(app)(\/)(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)/gm;
  let matches = [];
  let m;
  while ((m = regex.exec(path)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match) => {
      matches.push(match)
    });
  };
  return matches[3];
};


export const dropOldCookies = () => {
  // drop old cookies to make space is cookies storage
  let all = cookies.getAll();
  Object.keys(all).forEach((key) => {
    if (key.includes(BASE_COOKIE_NAME)) {
      console.log('clearing cookie ', key);
      cookies.remove(key, { path: '/' });
    }
  })
};