import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { slide_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import Button from '../styledButton';
import gql from 'graphql-tag';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { saveModalOnEnterPress } from '../../util';

export default ({ position, app_content_scope_id, organization_id, home_component_id, onClose, defaultTheme, refetchHomeComponents, tabFilter }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const [title, setTitle] = React.useState('');
  const [addSlide, { loading: onUpdateLoader, data: createdSlide }] = useMutation(
    gql`
        mutation addSlide(
            $input: SlideInput
        ) {
            addSlide(input: $input) {
                title
            }
        }
    `
  );

  

  const onSave = () => {
    setIsLoading(true);
    if (title) {
        addSlide({
            variables : {
                input: {
                        title: title,
                        published: false,
                        position,
                        organization_id,
                        home_component_id
                    }
                }
            });
        }
    };

  React.useEffect(() => {
    setIsLoading(false);
    if (createdSlide) {
      setIsOpen(false);
      setTitle('');
      refetchHomeComponents();
    }
  }, [createdSlide]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  // const bgColor = defaultTheme === 'dark' ? 'white' : '#5D64AB';
  const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';

  return (
    <React.Fragment>
      {tabFilter !== 'archived' && <div
        className={css({
          width: '180px !important',
          minWidth: '180px !important',
          height: '250px',
          marginBottom: '5px',
          marginRight: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          float: 'left',
          border: '2px solid #5D64AB',
          borderRadius: '5px',
          // background: defaultTheme === 'white' ? '#ECEBEB' : 'rgba(74, 76, 160, 0.2)',
          background: '#FAFAFA',
          border: '3px solid #4A4CA0',
          cursor: 'pointer',
        })}
        onClick={() => {
          setIsOpen(true);
          setTitle('');
        //   setDescription('');
        //   onClose();
        }}
      >
        <div
          className={css({
            position: 'absolute',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          })}
        >
          <svg
            width="15"
            height="15.75"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={css({
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              cursor: 'pointer',
            })}
          >
            <path
              d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
              fill={bgColor}
            />
          </svg>
          <p
            className={css({
              textAlign: 'center',
              // marginTop: '72px',
              color: bgColor,
              // fontFamily: 'IBM Plex Sans',
              fontFamily: 'Roboto',
              fontWeight: '700',
              fontSize: '14px',
              // textTransform: 'capitalize',
              maxWidth: '180px !important',
              wordBreak: 'break-all !important',
              marginBottom: '0px'
            })}
          >
            Add Slide
          </p>
        </div>
      </div>}
      <Modal
        // animate={true}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3,
            }
          },
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setTitle('');
        //   setDescription('');
        //   onClose();
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <div
              className={css({
                display: 'flex',
              })}
            >
              <H5>Add Slide</H5>
              <HelpQuestionMark text={slide_help}></HelpQuestionMark>
            </div>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Title</H6>
              <Input
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Your title"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSave })
                }
              />
              {title && title.length > 30 && <p style={{marginTop: '1rem', color: 'red'}}>Max 30 characters allowed</p>}
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={isLoading}
            onClick={() => {
              onSave();
            }}
            disabled={!title || title === "" || title.length > 30}
          >
            Add Slide
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};


