import React, { useState } from 'react';
// import Tour from 'reactour';
import ReactPlayer from 'react-player';
import { Button } from 'baseui/button';
// import tourData from './tourData.json';
import { useStyletron } from 'baseui';
import { tourClient } from '../../apollo/client';
import { createTourStatus, tours, updateTourStatus, user_tours } from '../settings/tour-queries';
import { connect, useDispatch } from 'react-redux';
import { useStaticQuery } from 'gatsby';
import { setIsTourPopStatus } from '../../redux/actions/appBasicControls';
// import Loadable from 'react-loadable';


let Tour;

if (typeof window !== `undefined`) {
  Tour = require('reactour').default;
};

const TourCard = (props) => {
  // const [Tour, setTour] = React.useState(null);
  const [css, theme] = useStyletron();
  const [reactourOpen, setReactourOpen] = React.useState(true);
  const [tourData, setTourData] = useState([]);
  const dispatch = useDispatch();
  const siteData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          version
        }
      }
    }
  `)

  // React.useEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     const _H = require('reactour');
  //     setTour(_H);
  //   };
  // }, []);

  // const getTourModule = () => {
  //   const _H = require('reactour');
  //   return (_H);
  // }

  React.useEffect(() => {
    // markTourPopupAsShown();
    getToursList();
  }, [props.isTourPopEnabled]);

  React.useEffect(() => {
    if (tourData && tourData.length > 0) {
      const currentStep = tourData[0];
      createTourStatusQuery(currentStep.id, getUserId(), true);
    }
  }, [tourData]);

  const getToursList = () => {

    let user_id = getUserId();
    tourClient.query({
      query: user_tours,
      variables: {
        userId: user_id,
        version: siteData.site.siteMetadata.version
      }
    }).then(({ data }) => {
      let {
        user_tours = []
      } = data || {};
      user_tours = user_tours.slice().sort(function (a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      });
      setTourData(user_tours);
    }).catch(err => {
      console.log('Error', err);
    })
  }

  const markTourPopupAsShown = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => localStorage.setItem('isTourPopupShown', true), 3000)
      return resolve();
    })
  }

  const closeTour = () => {
    setReactourOpen(false);
    dispatch(
      setIsTourPopStatus(false)
    )
  };

  const onChangeStep = (indx) => {
    const currentStep = tourData[indx];

    let user_id = getUserId();

    createTourStatusQuery(currentStep.id, user_id, true);
  }

  const createTourStatusQuery = (tourModelId, userId, seen) => {
    tourClient.mutate({
      mutation: createTourStatus,
      variables: {
        tourModelId,
        userId,
        seen
      }
    }).catch((err) => console.log('createTourStatusQuery error', err))
  }

  const getUserId = () => {
    const {
      idTokenPayload
    } = props.user;

    const {
      user_id
    } = idTokenPayload || {}
    let trim_user_id = user_id && user_id.replace('auth0|', '') || '';
    return trim_user_id;
  }

  const steps = tourData.map((item) => {
    return {
      selector: "." + item.name,
      content: () => (
        <div>
          <h1>{item.title}</h1>
          <h3>{item.subtitle}</h3>
          <p>{item.text}</p>
          <Button style={{ background: theme.colors.primaryA }} >Button</Button>
          <div style={{ marginTop: "20px", width: "100%", height: '40vh', display: 'contents' }}>
            <ReactPlayer width="100%" height="40vh" url={item.link} style={{ marginTop: '20px', height: '40vh' }} />
          </div>
        </div>
      ),
    }
  });
  return (
    <div>

      {steps.length > 0 && (
        <Tour
          steps={steps}
          isOpen={reactourOpen}
          onRequestClose={closeTour}
          getCurrentStep={onChangeStep}
          onAfterOpen={(target) => { console.log('after open') }}
        />
      )}


    </div>
  )
}

const mapStateToProps = (props) => {
  const { user, isTourPopEnabled } = props.appBasicControls;
  return { user, isTourPopEnabled };
}

export default connect(mapStateToProps)(TourCard)