import React from 'react';
import { Textarea } from "baseui/textarea";
import BaseClass from './base';

class BaseUiTextArea extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render() {
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        <Textarea
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          placeholder={this.props.placeholder}
          clearOnEscape
        />
      </>
    );
  }
}

export default BaseUiTextArea;
