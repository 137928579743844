import * as React from 'react';
import _ from 'lodash';
import './preview.css';
import { debounce, throttle } from 'lodash';
import { connect } from 'react-redux';
import store from '../../redux/store';
class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: null,
      onSave: null,
      lastSavedAt: null,
      hasDataInQueue: false,
      savingInProgress: false,
      cardBackData: []
    };
    this.throttleHandleChange = debounce(this.throttleHandleChange.bind(this), 1000);
  }

  throttleHandleChange() {
    // debugger;
    let newVal = (store.getState().appBasicControls.cardBackData || []).map((el) => {return {...el}});
    if (this.state.savingInProgress) {
      this.setState({hasDataInQueue: true, queuedData: newVal});
      return;
    }
    let dataToSave = newVal.filter((el) => {
      if(!el.updatedAt) return false;
      if (el.updatedAt < this.state.lastSavedAt) return false;
      return true;
    });
    this.setState({lastSavedAt: new Date().getTime(), savingInProgress: true, cardBackData: newVal});
    console.log('IAM');
    console.log(dataToSave);
    // this.setState({savingInProgress: false});
    this.state.onSave && this.state.onSave(dataToSave)
    .then(() => {
      // debugger;
      this.setState({savingInProgress: false}, () => {
        if (this.state.hasDataInQueue) {
          this.setState({hasDataInQueue: false}, () => this.throttleHandleChange(this.state.queuedData));
        }
      });
    })
  };

  componentDidMount() {
    this.setState({
      onSave: this.props.onSave,
      lastSavedAt: new Date().getTime()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let onSave = this.throttleHandleChange;
    if (!this.props.cardBackData || !prevProps.cardBackData) {
      return;
    }
    const isEqual = _.isEqual(this.props.cardBackData, this.state.cardBackData);
    if (isEqual) {
      console.log('BOTH EQUAL');
      return;
    }
    this.props.cardBackData && onSave && onSave();
  }

  render() {
    return (
      <></>
    );
  }
}

export default (AutoSave);

