import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { useMutation } from '@apollo/client';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import { useStyletron } from 'baseui';
import GroupsDropDown from './groupsDropdown';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMove } from 'baseui/dnd-list';
import { FlexGrid } from 'baseui/flex-grid';
import CollapsedCard from '../channel/channelCollpasedCard';
import { sourceByType } from '../../util';
import { updateChannel } from '../../quries';
import { setChannels } from '../../redux/actions/appBasicControls';
import AddChannelV1 from './../channel/addChannel_v1';
import ReorderInModal from '../reorderInModal';
import RightSidePageHeader from '../pageHeaders';

let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
}

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const TAB_STATUS = {
  ALL: 'all',
  LIVE: 'published',
  ARCHIVE: 'archived',
};

const gridPaddingOverrides = {
  Grid: {
    style: () => ({
      padding: '0px !important',
    }),
  },
};

class PortalAwareItem extends Component {
  render() {
    const {
      dragProvided,
      card,
      index,
      organization_id,
      snapshot,
      onToggleClick,
      app_content_scope_group_id,
      dispatch,
      isCollapsed,
      css,
      count,
      theme,
      onCollapseButtonClick,
    } = this.props;

    const usePortal = snapshot.isDragging;
    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        {/* {isCollapsed ?   */}
        <CollapsedCard
          card={card}
          usePortal={usePortal}
          key={index}
          isCollapsed={isCollapsed}
          organization_id={organization_id}
          app_content_scope_group_id={app_content_scope_group_id}
          count={count}
          title={card.name}
          imageSrc={getSrc(card.logo_data)}
          toggle={card.public}
          onToggleClick={v => onToggleClick(v.target.checked, card.id)}
          channel={card}
          dispatch={dispatch}
          onCollapseButtonClick={onCollapseButtonClick}
        ></CollapsedCard>
        {/* </div><Card 
        //   key={index}
          organization_id={organization_id}
          app_content_scope_group_id={app_content_scope_group_id}
          count={count}
          title={card.name}
          imageSrc={getSrc(card.logo_data)}
          toggle={card.public}
          onToggleClick={(v) => onToggleClick(v.target.checked, card.id)}
          channel={card}
          dispatch={dispatch}
        ></Card>} */}
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    }),
  },
};

const MainChannelPage = ({
  selectedGroup,
  organization_id,
  dispatch,
  channels,
  channelGroups,
  defaultTheme
}) => {
  const [tab, setTab] = React.useState(TAB_STATUS.ALL);
  const [css, theme] = useStyletron();
  const [filtered, setFiltered] = React.useState([]);
  const [
    updateAppContentScope,
    { loading: onUpdateLoader, data: updatedChannel },
  ] = useMutation(updateChannel);
  const onToggleClick = (status, id) => {
    updateAppContentScope({
      variables: {
        id,
        public: status,
      },
    });
  };
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  React.useEffect(() => {
    let data = channels.filter(channel => {
      let status1 = channel.app_content_scope_group_id == selectedGroup.id;
      let status2 = true;
      if (tab == TAB_STATUS.ALL) status2 = true;
      if (tab == TAB_STATUS.LIVE) status2 = channel.public == true;
      if (tab == TAB_STATUS.ARCHIVE) status2 = channel.archived == true;
      return status1 && status2;
    });
    setFiltered(data);
  }, [channels, selectedGroup, tab]);

  const onClose = () => {
    setIsCollapsed(false);
  };
  const onCollapseButtonClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <RightSidePageHeader
          icon={null}
          title={selectedGroup.name}
          rightContent={null}
          headerDescription={`${filtered.length} Channels`}
          dropDownContent={({ close }) => {
            return (
              <GroupsDropDown
                channelGroups={channelGroups}
                organization_id={organization_id}
                close={close}
                defaultTheme={defaultTheme}
                dispatch={dispatch}
              />
            );
          }}
          rightContent={
            <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 0 }}
            >
              <Button onClick={() => setTab(TAB_STATUS.ALL)}>All</Button>
              <Button onClick={() => setTab(TAB_STATUS.LIVE)}>Live</Button>
              <Button onClick={() => setTab(TAB_STATUS.ARCHIVE)}>
                Archives
              </Button>
              {/* <div style={{marginTop: '8px', marginLeft: '8px' }}>
                <AddChannelV1
                  organization_id={organization_id}
                  organization_id={organization_id}
                  app_content_scope_group_id={selectedGroup.id}
                  totalCount={filtered.length}
                ></AddChannelV1>
              </div> */}
            </StatefulButtonGroup>
          }
        ></RightSidePageHeader>
        <Cell span={12}>
          <div
            className={css({
              marginTop: '16px',
              float: 'right',
            })}
          >
            <AddChannelV1
              channelGroups={channelGroups}
              organization_id={organization_id}
              app_content_scope_group_id={selectedGroup.id}
              totalCount={filtered.length}
            ></AddChannelV1>
          </div>
        </Cell>
        <Cell span={12}>
          <div
            id="wrapper"
            className="wrapper"
            style={{
              paddingTop: theme.sizing.scale600,
            }}
          >
            <FlexGrid>
              <ReorderInModal
                isCollapsed={isCollapsed}
                onClose={onClose}
                title={'Reorder Channels'}
              >
                <DragDropContext
                  onDragEnd={({ destination, source }) => {
                    // // dropped outside the list
                    if (!destination) {
                      return;
                    }
                    let newArr = arrayMove(
                      JSON.parse(JSON.stringify(filtered)),
                      source.index,
                      destination.index
                    );
                    newArr = newArr.map((el, i) => {
                      delete el.isNew;
                      el.position = i;
                      return el;
                    });
                    // here update the position on server side
                    let oChannels = JSON.parse(JSON.stringify(channels));
                    newArr.forEach(el => {
                      let fIndex = oChannels.findIndex(el1 => el.id == el1.id);
                      delete oChannels[fIndex].isNew;
                      oChannels[fIndex].position = el.position;
                    });
                    dispatch(setChannels(oChannels));
                    newArr.forEach(el => {
                      let old = filtered.find(el1 => el.id == el1.id);
                      if (old.position !== el.position) {
                        updateAppContentScope({
                          variables: {
                            id: el.id,
                            position: el.position,
                          },
                        });
                      }
                    });
                    setFiltered(newArr);
                  }}
                >
                  <Droppable
                    droppableId={'row1'}
                    type={'channels'}
                    direction={
                      'vertical'
                      // isCollapsed ? "vertical" : "horizontal"
                    }
                    isCombineEnabled={false}
                  >
                    {dropProvided => (
                      <div
                        className="scrolls"
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                        style={{
                          display: 'block',
                          // isCollapsed ? "block" : "flex"
                        }}
                      >
                        {/* {!isCollapsed && <AddChannel
                  isCollapsed={true}
                  organization_id={organization_id}
                  app_content_scope_group_id={selectedGroup.id}
                  count={filtered.length}
                  ></AddChannel>} */}
                        {filtered
                          .sort((a, b) => {
                            let aPosition = a.position;
                            let bPosition = b.position;
                            if (a.isNew) aPosition = -1;
                            return aPosition - bPosition;
                          })
                          .map((card, index) => (
                            <Draggable
                              key={card.id}
                              draggableId={card.id}
                              index={index}
                            >
                              {(dragProvided, snapshot) => (
                                <>
                                  <PortalAwareItem
                                    dragProvided={dragProvided}
                                    card={card}
                                    index={index}
                                    snapshot={snapshot}
                                    organization_id={organization_id}
                                    app_content_scope_group_id={
                                      selectedGroup.id
                                    }
                                    count={filtered.length}
                                    dispatch={dispatch}
                                    isCollapsed={isCollapsed}
                                    css={css}
                                    theme={theme}
                                    onCollapseButtonClick={
                                      onCollapseButtonClick
                                    }
                                    onToggleClick={onToggleClick}
                                  />
                                </>
                              )}
                            </Draggable>
                          ))}
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </ReorderInModal>
            </FlexGrid>
          </div>
        </Cell>
      </Grid>
    </>
  );
};

export default MainChannelPage;