import * as React from 'react';
import styled from '@emotion/styled';
import {
    LabelLarge
} from 'baseui/typography';
import { Button as BaseButton } from 'baseui/button';

const PrevButton = styled(BaseButton)`
  background-color: ${p => (p.isSelected ? 'white' : (p.background || '#131313'))};
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 3px;
  border-radius: 2px;
`;

const NextButton = styled(BaseButton)`
  background-color: ${p => (p.isSelected ? '#5D64AB' : '#131313')};
  color: 'white';
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 3px;
  border-radius: 2px;
  float: right;
`;

const StepCounterDiv = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
`;

const MainDiv = styled.div`
    background: white;
    height: 52px;
    position: fixed;
    bottom: 0px;
    background: ${props => props.theme.colors.builderNavigationBackground};
    width: 100vw;
    box-shadow: ${props => props.theme.lighting.builderNavigationShadow};
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 8px;
`;

export default ({ onNextClick, onPrevClick, stepCount, totalSteps, lastStepButtonOverride, isModal, theme }) => {
    return <MainDiv isModal={isModal} theme={theme}>
        <PrevButton onClick={onPrevClick} isSelected={true}>Previous</PrevButton>
        <StepCounterDiv>
            <LabelLarge>Step {stepCount}/{totalSteps}</LabelLarge>
        </StepCounterDiv>
        {stepCount === totalSteps ? (
            lastStepButtonOverride ?  lastStepButtonOverride():
                <NextButton isSelected={true} onClick={onNextClick} disabled={stepCount === totalSteps}>Next</NextButton>
        ) :
            <NextButton isSelected={true} onClick={onNextClick} disabled={stepCount === totalSteps}>Next</NextButton>
        }
    </MainDiv>
};