import * as React from 'react';
import { Grid } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { moveCardMutation } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';

export default ({ deck, card, onCancel, dispatch }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  if (isOpen === false) onCancel();

  const [moveCard, { loading: moveCardLoading, data: movedCard }] = useMutation(
    moveCardMutation
  );
  React.useEffect(() => {
    setIsLoading(false);
    if (movedCard) {
      dispatch(
        showAlert({
          msg: 'Successfully moved card!',
          error: false,
        })
      );
      onCancel();
    }
  }, [movedCard]);

  const onMove = () => {
    setIsLoading(true);
    moveCard({
      variables: {
        id: card.card_id,
        deck_id: deck.deck_id,
      },
    });
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 100
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          onCancel();
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>
              Are you sure you want to move this card -{' '}
              <b style={{ color: 'rgb(118, 95, 237)' }}>
                {card.card.title || ''}
              </b>{' '}
              to new deck - {deck ? deck.deck_category_deck.title : 'NA'}?{' '}
            </H5>
            <H6>
              This process may take a few moments and all changes are final. If
              you need to move this back, you will be able to do so.
            </H6>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={moveCardLoading}
            onClick={() => {
              onMove();
            }}
          >
            Move this anyway
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
