import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useStyletron } from 'baseui';
import { useMutation } from '@apollo/client';
import { isFunction } from 'lodash';
import { addGroup, updateGroup } from '../../quries';
import { channel_group_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import { cellPaddingOverrides } from '../overrides';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import ModalStateContainer from '../modalStateContainer';
import store from '../../redux/store';

import titlesLength from '../../../data/titlesLength.json';

export default ({ totalCount, organization_id, onClose, redirectChannel, ...rest }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const id = rest.id;
  const [name, setName] = React.useState(rest.name || '');
  const [publicS, setPublicS] = React.useState(rest.public || false);
  const [primary_color, set_primary_color] = React.useState(rest.primary_color || '');
  const [secondary_color, set_secondary_color] = React.useState(rest.secondary_color || '');
  const [deck_cover_radius_top_left, set_deck_cover_radius_top_left] = React.useState(rest.deck_cover_radius_top_left || 0);
  const [deck_cover_radius_top_right, set_deck_cover_radius_top_right] = React.useState(rest.deck_cover_radius_top_right || 0);
  const [deck_cover_radius_bottom_left, set_deck_cover_radius_bottom_left] = React.useState(rest.deck_cover_radius_bottom_left || 0);
  const [deck_cover_radius_bottom_right, set_deck_cover_radius_bottom_right] = React.useState(rest.deck_cover_radius_bottom_right || 0);

  const [deck_shadow_radius_top_left, set_deck_shadow_radius_top_left] = React.useState(rest.deck_shadow_radius_top_left || 0);
  const [deck_shadow_radius_top_right, set_deck_shadow_radius_top_right] = React.useState(rest.deck_shadow_radius_top_right || 0);
  const [deck_shadow_radius_bottom_left, set_deck_shadow_radius_bottom_left] = React.useState(rest.deck_shadow_radius_bottom_left || 0);
  const [deck_shadow_radius_bottom_right, set_deck_shadow_radius_bottom_right] = React.useState(rest.deck_shadow_radius_bottom_right || 0);

  const { is_group_color_settings_on, is_group_radius_settings_on } = store.getState().appBasicControls.organization_settings;


  const archived = rest.archived;
  if (isOpen === false) onClose();
  const [
    updateAppContentScopeGroup,
    { loading: onUpdateLoader, data: updatedGroup },
  ] = useMutation(updateGroup);

  const [
    createAppContentScopeGroup,
    { loading: onCreateLoader, data: createdGroup },
  ] = useMutation(addGroup);

  React.useEffect(() => {
    if (updatedGroup || createdGroup) {
      setIsLoading(false);
      isFunction(redirectChannel) && redirectChannel();
      setIsOpen(false);
    }
  }, [createdGroup, updatedGroup]);

  const onSave = (req = {}) => {
    // debugger;
    let archived = req.archived;
    if (name.length > titlesLength.group) return;
    if (id) {
      setIsLoading(true);
      updateAppContentScopeGroup({
        variables: {
          id,
          name,
          position: rest.position,
          public: publicS,
          primary_color,
          secondary_color,
          deck_cover_radius_top_left,
          deck_cover_radius_top_right,
          deck_cover_radius_bottom_left,
          deck_cover_radius_bottom_right,
          deck_shadow_radius_top_left,
          deck_shadow_radius_top_right,
          deck_shadow_radius_bottom_left,
          deck_shadow_radius_bottom_right,
          archived
        },
      });
    } else {
      setIsLoading(true);
      createAppContentScopeGroup({
        variables: {
          name,
          position: totalCount,
          organization_id,
          public: publicS,
          primary_color,
          secondary_color,
          deck_cover_radius_top_left,
          deck_cover_radius_top_right,
          deck_cover_radius_bottom_left,
          deck_cover_radius_bottom_right,
          deck_shadow_radius_top_left,
          deck_shadow_radius_top_right,
          deck_shadow_radius_bottom_left,
          deck_shadow_radius_bottom_right
        },
      });
    }
  };

  const outerGridOverride = {
    marginTop: '0px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const [loading, setLoading] = React.useState(false);

  return (
    <ModalStateContainer>
      {({ isConfirmationOpen, toggleConfirm }) => (
        <React.Fragment>
          {/* <Button onClick={() => setIsEditOpen(true)}>Add Category</Button> */}
          <Modal
            overrides={{
              Dialog: {
                style: {
                  width: (is_group_color_settings_on || is_group_radius_settings_on) ? '70vw' : '30vw',
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3
                }
              }
            }}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <ModalHeader>
              <div
                className={css({
                  display: 'flex',
                })}
              >
                <LabelMedium>{id ? 'Update Group' : 'Add Group'}</LabelMedium>
                {!id && <HelpQuestionMark text={channel_group_help} embedCss={{ marginTop: '0px' }}></HelpQuestionMark>}
              </div>
            </ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 12]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <Cell span={(is_group_color_settings_on || is_group_radius_settings_on) ? 6 :12} overrides={cellPaddingOverrides}>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                    <Input
                      value={name}
                      onChange={e => {
                        e.target.value.length <= titlesLength.group && setName(e.target.value)
                      }}
                      placeholder="Your title"
                      positive={name && name.length > 0 && name.length < titlesLength.group}
                      clearOnEscape
                    />
                    <ParagraphSmall style={{ marginBottom: '0px' }}>{name.length}/{titlesLength.group}</ParagraphSmall>
                    {name.length > titlesLength.group && <p style={{ color: 'red', marginTop: '1rem' }}>Max {titlesLength.group} characters allowed</p>}
                  </Cell>
                  {/* <Cell
                    span={[1, 6]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                    <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                      <Checkbox
                        checked={publicS}
                        onChange={e => setPublicS(!publicS)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Public</ParagraphSmall>
                      </Checkbox>
                    </div>
                  </Cell> */}
                </Cell>
                {is_group_color_settings_on && <Cell span={6} overrides={cellPaddingOverrides}>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Primary Color</LabelMedium>
                    <Input
                      value={primary_color}
                      onChange={e => set_primary_color(e.target.value)}
                      placeholder="Primary Color"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Secondary Color</LabelMedium>
                    <Input
                      value={secondary_color}
                      onChange={e => set_secondary_color(e.target.value)}
                      placeholder="Secondary Color"
                      clearOnEscape
                    />
                  </Cell>
                </Cell>}
                {is_group_radius_settings_on && <Cell span={6} overrides={cellPaddingOverrides}>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <hr style={{ height: '2px', margin: 0, marginBottom: '24px', background: '#E2E2E2', width: 'calc(100%)' }} />
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Cover Radius Top Left</LabelMedium>
                    <Input
                      value={deck_cover_radius_top_left}
                      onChange={e => set_deck_cover_radius_top_left(1 * e.target.value)}
                      placeholder="Enter Deck Cover Radius Top Left"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Cover Radius Top Right</LabelMedium>
                    <Input
                      value={deck_cover_radius_top_right}
                      onChange={e => set_deck_cover_radius_top_right(1 * e.target.value)}
                      placeholder="Enter Deck Cover Radius Top Right"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Cover Radius Bottom Left</LabelMedium>
                    <Input
                      value={deck_cover_radius_bottom_left}
                      onChange={e => set_deck_cover_radius_bottom_left(1 * e.target.value)}
                      placeholder="Enter Deck Cover Radius Bottom Left"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Cover Radius Bottom Right</LabelMedium>
                    <Input
                      value={deck_cover_radius_bottom_right}
                      onChange={e => set_deck_cover_radius_bottom_right(1 * e.target.value)}
                      placeholder="Enter Deck Cover Radius Bottom Right"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                </Cell>}


                {is_group_radius_settings_on && <Cell span={6} overrides={cellPaddingOverrides}>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <hr style={{ height: '2px', margin: 0, marginBottom: '24px', background: '#E2E2E2', width: 'calc(100%)' }} />
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Shadow Radius Top Left</LabelMedium>
                    <Input
                      value={deck_shadow_radius_top_left}
                      onChange={e => set_deck_shadow_radius_top_left(1 * e.target.value)}
                      placeholder="Enter Deck Shadow Radius Top Left"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Shadow Radius Top Right</LabelMedium>
                    <Input
                      value={deck_shadow_radius_top_right}
                      onChange={e => set_deck_shadow_radius_top_right(1 * e.target.value)}
                      placeholder="Enter Deck Shadow Radius Top Right"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Shadow Radius Bottom Left</LabelMedium>
                    <Input
                      value={deck_shadow_radius_bottom_left}
                      onChange={e => set_deck_shadow_radius_bottom_left(1 * e.target.value)}
                      placeholder="Enter Deck Shadow Radius Bottom Left"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Shadow Radius Bottom Right</LabelMedium>
                    <Input
                      value={deck_shadow_radius_bottom_right}
                      onChange={e => set_deck_shadow_radius_bottom_right(1 * e.target.value)}
                      placeholder="Enter Deck Shadow Radius Bottom Right"
                      type="number"
                      clearOnEscape
                    />
                  </Cell>
                </Cell>}
              </Grid>
            </ModalBody>
            <ModalFooter>
              {id && <ModalButton
                onClick={() => {
                  toggleConfirm(true);
                }}
                isLoading={isLoading}
              >
                {archived ? 'Unarchive' : 'Archive'} Group
          </ModalButton>}
              <ModalButton
                onClick={() => {
                  onSave();
                }}
                isLoading={isLoading}
                disabled={!name || name === '' || name.length > titlesLength.group}
              >
                {id ? 'Update' : 'Add'} Group
          </ModalButton>
            </ModalFooter>
          </Modal>
          <Modal
            onClose={() => toggleConfirm(false)}
            isOpen={isConfirmationOpen}
            overrides={{
              Root: {
                style: {
                  zIndex: 4,
                }
              }
            }}
          >
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to {archived ? 'un archive' : 'archive'} this group?</ModalBody>
            <ModalFooter>
              <ModalButton
                kind="tertiary"
                onClick={() => toggleConfirm(false)}
              >
                No
              </ModalButton>
              <ModalButton
                onClick={() => {
                  onSave({ archived: !archived })

                }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Yes
              </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </ModalStateContainer>
  );
};
