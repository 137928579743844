import * as React from 'react';
import { useMutation } from '@apollo/client';
import { addChannel } from '../../quries';
import { channel_help, channel_group_help } from '../help/helpTexts';
import AddContentButton from '../addContentButton';
import AddEditChannel from './addEditChannel';
import AddEditChannelGroup from '../groups/addEditGroup';

// to switch between the add category type and the
// modal means on button click modal will appear which will add new category
// in inline style input box with with only name will appear on screen at same place of button
const ADD_CATEGORY_TYPE = 'modal'; // inline;

export default ({
  totalCount,
  app_content_scope_group_id,
  organization_id,
  channelGroups
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [
    createAppContentScope,
    { loading: onCreateLoader, data: createdChannel },
  ] = useMutation(addChannel);

  React.useEffect(() => {
    setIsLoading(false);
    setName('');
    createdChannel && setIsOpen(false);
  }, [createdChannel]);
  
  const [addInModal, setAddInModal] = React.useState(false);
  const [isAddChannelGroupOpen, setIsAddGroupChannel] = React.useState(false);

  const onClose = () => {
    setAddInModal(false);
    setIsAddGroupChannel(false);
  }

  const onSave = () => {
    setIsLoading(true);
    createAppContentScope({
      variables: {
        name,
        public: true,
        logo: null,
        position: totalCount,
        app_content_scope_group_id,
        position: totalCount,
        organization_id,
        description: '',
        media_url: '',
        content_controlled: false,
        pincode: '',
      },
    });
  };

  return (
    <>
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <AddContentButton
        buttonText={'Add Group'}
        helpText={channel_group_help}
        inputPlaceholder={'Add New Group'}
        inputValue={name}
        setInputValue={setName}
        onSave={onSave}
        isLoading={isLoading}
        buttonClickOverride={
          ADD_CATEGORY_TYPE == 'modal' ? () => setIsAddGroupChannel(true) : null
        }
      ></AddContentButton>
      <span style={{width: '10px'}}></span>
      <AddContentButton
        buttonText={'Add Channel'}
        helpText={channel_help}
        inputPlaceholder={'Add New Channel'}
        inputValue={name}
        setInputValue={setName}
        onSave={onSave}
        isLoading={isLoading}
        buttonClickOverride={
          ADD_CATEGORY_TYPE == 'modal' ? () => setAddInModal(true) : null
        }
      ></AddContentButton>
    </div>
      {addInModal && (
        <AddEditChannel
          totalCount={totalCount}
          app_content_scope_group_id={app_content_scope_group_id}
          organization_id={organization_id}
          onClose={onClose}
        ></AddEditChannel>
      )}
       {isAddChannelGroupOpen && (
        <AddEditChannelGroup
          onClose={onClose}
          totalCount={channelGroups.length}
          organization_id={organization_id}
        ></AddEditChannelGroup>
      )}
    </>
  );
};
