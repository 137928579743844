import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../redux/store';

const getChannels = (channel) => {
  let myChannel = Object.assign({ selected: false }, channel);
  let groups = store.getState().appBasicControls.groups || [];
  debugger
  let group;
  console.log('channel', channel);
  if (channel) {
    group = groups.find((group) => group.id === channel.app_content_scope_group_id);
  }
  console.log('group', group);
  
  if (group && channel) {
    let groupName =  group && group.name ? group.name : "Undefined";
    let channelName = channel && channel.name ? channel.name : "Undefined";
    myChannel.title = `${channelName} (${groupName})`
    return myChannel
  } else {
    return null
  }
};

export default ({
  selectedChannelId,
  onChannelSelect,
  title,
  theme,
  marginTop,
  placeholder
}) => {
  let options = store.getState().appBasicControls.channels || [];
  debugger
  options = options.filter((el) => !el.archived && el.public).map(getChannels).filter((el) => el);
  let matchingOption = options.filter((el) => el.id == selectedChannelId);

  const onOptionChange = ({ option }) => {
    if (option) onChannelSelect(option.id)
    else onChannelSelect(null)
  }

  return (
    <>
      {title ? <h5
        style={{
          color: theme.colors.primaryA,
          marginTop: marginTop || theme.sizing.scale1200,
        }}
      >
        {title}
      </h5> : null}
      <Select
        options={options}
        onChange={onOptionChange}
        value={matchingOption}
        placeholder={selectedChannelId ? '' : placeholder}
        multi={false}
        labelKey={'title'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </>
  )
}
