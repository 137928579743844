import React from 'react';
import ReactHighcharts from 'react-highcharts';

export default function AreaChartElevated(props) {
  var dataToPlot = [];
  var categories = [];
  props.data.forEach(el => {
    categories.push(el.x);
    dataToPlot.push(el.y);
  })
  return (
    <ReactHighcharts config={{
      chart: {
        type: 'spline',
        backgroundColor: props.backgroundColor,
        style: {
          "border-radius": "5px",
        }
      },
      exporting: { enabled: false },
      title: {
        text: '',
        style: {
          color: "white"
        }
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          formatter: function () {
            return this.value; // clean, unformatted number for year
          }
        },
        type: 'category',
        lineWidth: 1,
        lineColor: "#334257",
        categories: categories,
        tickWidth: 0,
        labels: {
          style: {
            color: "rgba(118, 95, 237, 1)",
            "font-size": "10px",
          }
        }

      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {

          style: {
            color: "rgba(118, 95, 237, 1)",
            "font-size": "10px",
          },

          formatter: function () {
            return this.value / 1000 + 'k';
          }
        },
        gridLineColor: 'grey',
        gridLineDashStyle: 'ShortDot',
        lineWidth: 1,
        lineColor: "#334257",
        opposite: false,
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'rgb(52, 69, 236)'],
              [1, 'rgb(93, 100, 171)']
            ]
          },
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [{
        name: 'App Engagement',
        data: dataToPlot
      }]
    }}></ReactHighcharts>
  );
}
