import * as React from 'react';
import HelpQuestionMark from '../help/questionMarkHelp';
import {
    ParagraphLarge
} from 'baseui/typography';

export default ({ children, title, helpText, width = 365, height = 700, cardCoverPreview, margin }) => {
    const titleStyle = {
        textAlign: 'center',
        marginBottom: '16px',
        marginLeft: '89px',
    }
    if (cardCoverPreview) {
        titleStyle['color'] = 'black'
    }
    return <div
        style={{
            width,
            height,
            margin: margin || 'auto'
        }}
    >
        <div style={{ display: 'flex' }}>
            <ParagraphLarge style={titleStyle}>{title}</ParagraphLarge>
            <HelpQuestionMark
                text={helpText}
                cardCoverPreview={cardCoverPreview}
                embedCss={{ marginTop: '4px', marginLeft: '8px' }}
            ></HelpQuestionMark>
        </div>
        <div className="phone-wrap">
            <div className="phone-inner-wrap">
                {children}
            </div>
        </div>
    </div>
};