import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import Gallery from 'react-grid-gallery';
import { useStyletron } from 'baseui';
import { FileUploader } from 'baseui/file-uploader';
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag';
import {
  HeadingMedium,
  ParagraphSmall,
  HeadingSmall,
  LabelXSmall,
  LabelSmall,
  LabelMedium
} from 'baseui/typography';
import { Input, StyledInput } from 'baseui/input';
import { Textarea } from "baseui/textarea";
import { cellPaddingOverrides } from '../overrides';
import HelpQuestionMark from '../help/questionMarkHelp';

const InputReplacement = React.forwardRef(
  ({ tags, removeTag, ...restProps }, ref) => {
    const [css] = useStyletron();
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {tags.map((tag, index) => (
          <Tag
            variant={TAG_VARIANT.solid}
            onActionClick={() => removeTag(tag)}
            key={index}
          >
            {tag}
          </Tag>
        ))}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);


const Step3 = ({ messageType, messageTitle, setMessageTitle, frontImage, setFrontImage, tags, setTags, tagValue, setTagValue, messageBody, setMessageBody }) => {
  const [css, theme] = useStyletron();
  const [previewImage, setPreviewImage] = React.useState(null);

  React.useEffect(() => {
    frontImage && setPreviewImage(URL.createObjectURL(frontImage));
  }, [frontImage]);

  const addTag = tag => {
    tag = `#${tag}`;
    setTags([...tags, tag]);
  };

  const removeTag = tag => {
    setTags(tags.filter(t => t !== tag));
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!tagValue) return;
        addTag(tagValue);
        setTagValue('');
        return;
      }
      // Backspace
      case 8: {
        if (tagValue || !tags.length) return;
        removeTag(tags[tags.length - 1]);
        return;
      }
    }
  };
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          width: '100%',
          paddingTop: '29px'
        }),
      },
    }} gridMaxWidth={'unset'}>
      {/* <Cell span={12}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Message Cover and Tags
          </HeadingMedium>
        <ParagraphSmall>
          Design overview of message
          </ParagraphSmall>
      </Cell> */}
      <Cell overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>General Information</LabelSmall>
          <LabelSmall
            style={{
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            Message Title
              </LabelSmall>
          <Input
            value={messageTitle}
            onChange={e => {
              if (e.target.value.length <= 200)
                setMessageTitle(e.target.value)
            }}
            placeholder="Title"
            clearOnEscape
          />
          <ParagraphSmall>{messageTitle.length}/200</ParagraphSmall>

          {messageType.includes(0) && <div>
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Message Body
              </LabelSmall>
            <Textarea
              value={messageBody}
              onChange={e => {
                if (e.target.value.length <= 1000)
                  setMessageBody(e.target.value)
              }}
              placeholder="Message Body"
              clearOnEscape
            />
            <ParagraphSmall>{messageBody.length}/1000</ParagraphSmall>
            {/* <ParagraphSmall style={{ marginBottom: '0px' }}>{messageBody.length}/200</ParagraphSmall> */}
          </div>}

          <LabelSmall
            style={{
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            Tags
              </LabelSmall>
          <Input
            placeholder={tags.length ? '' : 'Enter a Tag and hit Enter'}
            value={tagValue}
            onChange={e => setTagValue(e.currentTarget.value)}
            overrides={{
              Input: {
                style: { width: 'auto', flexGrow: 1 },
                component: InputReplacement,
                props: {
                  tags: tags,
                  removeTag: removeTag,
                  onKeyDown: handleKeyDown,
                },
              },
            }}
          ></Input>
          <ParagraphSmall style={{ marginBottom: '0px' }}>Type name of your tag and hit "Enter" to select it</ParagraphSmall>
        </div>

        <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginTop: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '4px',
            }}
          >
            Cover Image
              </LabelMedium>
          <div style={{ display: 'flex' }}>
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              File
                </LabelSmall>
            <HelpQuestionMark
              text={"Add a cover image to you message"}
              embedCss={{ marginTop: '10px', marginLeft: '8px' }}
            ></HelpQuestionMark>
          </div>
          <ParagraphSmall>
            For standard orientation file size for image must by 1620x1280.  For more design guidelines <a href='#'>click here</a>.
              </ParagraphSmall>
          {previewImage && <div style={{ width: '92px', height: '104px' }}>
            <Gallery
              images={[
                {
                  caption: '',
                  src: previewImage,
                  thumbnail: previewImage,
                  thumbnailWidth: 24,
                  thumbnailHeight: 24,
                },
              ]}
              rowHeight={92}
            />
            <LabelXSmall style={{ fontWeight: 400, marginLeft: '4px', position: 'relative', float: 'left', width: '100%' }} onClick={() => {
              // setIsViewerOpen(true);
            }}>View full image</LabelXSmall>
          </div> }
          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploader
              overrides={{
                FileDragAndDrop: {
                  style: props => ({
                    borderLeftColor: '#4A4CA0',
                    borderRightColor: '#4A4CA0',
                    borderTopColor: '#4A4CA0',
                    borderBottomColor: '#4A4CA0',
                  }),
                },
                ContentMessage: {
                  style: props => ({
                    color: '#4A4CA0',
                  }),
                },
                ContentSeparator: {
                  style: props => ({
                    color: '#4A4CA0',
                  }),
                },
              }}
              onDrop={(acceptedFiles, rejectedFiles) => {
                setFrontImage(acceptedFiles[0]);
              }}
            ></FileUploader>
          </div>
        </div>
      </Cell>
    </Grid>
  )
};
export default Step3;