import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import PropTypes from 'prop-types';
import { useStyletron } from 'baseui';
import { isFunction } from 'lodash';

const overrideDialogStyle = {
  style: {
    width: '70vw',
    display: 'flex',
    flexDirection: 'column',
  },
};

const overrideBackdropStyle = {
  style: ({ $theme }) => ({
    backdropFilter: $theme.modalBackDropFilter,
  }),
};

const overrideRootStyle = {
  style: {
    zIndex: 3
  }
};

const CustomModal = props => {
  const {
    onCloseModal,
    isOpen,
    renderModelHeader,
    renderModelBody,
    renderModelFooter,
    width
  } = props || {}
  const [css, theme] = useStyletron();

  const overrides = {
    Dialog: {...overrideDialogStyle, width},
    Backdrop: overrideBackdropStyle,
    Root: overrideRootStyle
  }

  const onClose = () => {
    onCloseModal()
  }

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={overrides}
    >
      <ModalHeader style={{ textTransform: 'capitalize' }}>
        {isFunction(renderModelHeader) && renderModelHeader()}
      </ModalHeader>
      <ModalBody>
        {isFunction(renderModelBody) && renderModelBody()}
      </ModalBody>
      {
        isFunction(renderModelFooter) &&
        (
          <ModalFooter>
            {renderModelFooter()}
          </ModalFooter>
        )
      }
    </Modal>
  );
};

CustomModal.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
  renderModelHeader: PropTypes.func,
  renderModelBody: PropTypes.func,
  renderModelFooter: PropTypes.func,
  width: PropTypes.string
}

CustomModal.defaultProps = {
  onCloseModal: () => { },
  isOpen: false,
  renderModelHeader: undefined,
  renderModelBody: undefined,
  renderModelFooter: undefined,
  width: '70vw'
}

export default CustomModal;