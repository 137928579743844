import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { client } from '../../apollo/client';
import { apiQuery, getData, createCommon, updateCommon } from './query';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import Button from '../styledButton';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const buttonOverrides = {
    BaseButton: {
        style: () => ({
            fontFamily: 'Roboto'
        })
    }
}

const ChannelMappings = ({ selectedChannel, organization_id, set_record_0 = () => { } }) => {

    const [mappings, setMappings] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [paths, setPaths] = React.useState([]);

    React.useEffect(() => {
        client.query({
            query: apiQuery(),
            fetchPolicy: 'network-only',
            variables: {
                data: {
                    model: "api_paths",
                    query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
                }
            }
        }).then((result) => {
            let response = result.data.cxl_internal_common_query;
            if (response.status) {
                setPaths(JSON.parse(response.data));
            }
        });
    }, []);

    React.useEffect(() => {
        client.query({
            query: apiQuery(),
            fetchPolicy: 'network-only',
            variables: {
                data: {
                    model: "channel_api_mappings",
                    query: `{\"where\": {\"app_content_scope_id\": \"${selectedChannel.id}\"}}`
                }
            }
        }).then((result) => {
            let response = result.data.cxl_internal_common_query;
            if (response.status) {
                setMappings(JSON.parse(response.data));
            }
        });
    }, [selectedChannel]);

    const onSave = () => {
        if (mapping_id == null) {
            // create
            setIsLoading(true);
            client.mutate({
                mutation: createCommon,
                variables: {
                    data: {
                        model: "channel_api_mappings",
                        data: JSON.stringify({
                            api_path_id,
                            name_field,
                            organization_id,
                            app_content_scope_id: selectedChannel.id
                        })
                    }
                }
            }).then(() => setIsLoading(false))
        } else {
            // update
            setIsLoading(true);
            client.mutate({
                mutation: updateCommon,
                variables: {
                    data: {
                        data: JSON.stringify({
                            id: mapping_id,
                            api_path_id,
                            name_field,
                        }),
                        model: "channel_api_mappings",
                    }
                }
            }).then(() => setIsLoading(false))
        }
    }


    const [mapping_id, set_mapping_id] = React.useState(null);
    const [api_path_id, set_api_path_id] = React.useState(null);
    const [name_field, set_name_field] = React.useState(null);
    const [sample_data, set_sample_data] = React.useState({});


    React.useEffect(() => {
        if (mappings.length > 0) {
            let map = mappings[0];
            set_mapping_id(map.id);
            set_api_path_id(map.api_path_id);
            set_name_field(map.name_field);
        }
    }, [mappings]);


    React.useEffect(() => {
        console.log('hittt');
        if (api_path_id) {
            client.query({
                query: getData(api_path_id)
            })
                .then((result) => {
                    let response = result.data.get_data_from_api_path;
                    if (response.status) {
                        set_sample_data(JSON.parse(response.data));
                        set_record_0(JSON.parse(response.data));
                    }
                })
        }
    }, [api_path_id]);


    console.log('OKAY', api_path_id, sample_data, mappings, paths);


    return <Grid
        overrides={{
            Grid: {
                style: {
                    padding: '10px 13px 0 7px !important'
                }
            }
        }} gridMaxWidth={'unset'}
    >
        <Cell
            span={6}
            overrides={{
                Cell: {
                    style: () => ({
                        padding: '0px !important',
                        height: '100% '
                    }),
                }
            }}
        >
            <Cell span={10}>
                <div style={{ marginBottom: '32px' }}>
                    <LabelMedium style={{ marginBottom: '16px' }}>Api Path</LabelMedium>
                    <Select
                        options={paths}
                        value={paths.filter(el => el.id == api_path_id)}
                        labelKey="name"
                        valueKey="id"
                        placeholder=''
                        onChange={({ value }) => set_api_path_id(value[0].id)}
                        overrides={{
                            Popover: {
                                props: {
                                    overrides: {
                                        Body: { style: { zIndex: 3 } },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </Cell>

            <Cell span={10}>
                <div style={{ marginBottom: '32px' }}>
                    <LabelMedium style={{ marginBottom: '16px' }}>Map Name</LabelMedium>
                    <Input
                        value={name_field}
                        onChange={e => {
                            set_name_field(e.target.value);
                        }}
                        placeholder="Select mapping of name"
                        clearOnEscape
                    />
                </div>
            </Cell>

            <Cell span={10}>
                <Button
                    style={{
                        fontSize: '14px !important'
                    }}
                    overrides={buttonOverrides}
                    isSelected
                    isLoading={isLoading}
                    onClick={onSave}>Save</Button>
            </Cell>
        </Cell>
        <Cell
            span={6}
            overrides={{
                Cell: {
                    style: () => ({
                        padding: '0px !important',
                        height: '100% '
                    }),
                }
            }}
        >
            <p>This is one sample record so you can map title and description fields.</p>
            <JSONInput
                id='a_unique_id'
                placeholder={sample_data}
                theme="dark_vscode_tribute"
                colors={{
                    string: "#DAA520" // overrides theme colors with whatever color value you want
                }}
                locale={locale}
                height='650px'
                width={'100%'}
            />
        </Cell>
    </Grid>
};

export default ChannelMappings;