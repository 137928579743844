import React from 'react';
import { Input } from "baseui/input";
import BaseClass from './base';
import { isValidHttpUrl } from '../builderUtils/util'
import CustomInput from '../../common/input';


class InputComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  isValid = () => {
    console.log('eckig validddd');
    if (this.state.value && this.props.placeholder && (this.props.placeholder.toLowerCase().includes('url') || this.props.placeholder.toLowerCase().includes('link'))) {
      return isValidHttpUrl(this.state.value);
    };
    return true;
  }

  render() {
    let error = !(this.isValid());
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        {/* <Input
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          placeholder={this.props.placeholder}
          clearOnEscape
          error={error}
        /> */}
        <CustomInput
          value={this.state.value}
          onChange={e => {
            console.log(e.target.value);
            this.setState({ value: e.target.value })
          }}
          placeholder={this.props.placeholder}
          clearOnEscape
          error={error}
          maxLength={this.props.maxLength}
        />
        {error && (
          <p style={{color: 'red', fontSize: '0.6rem', margin: 0, paddingTop: '0.6rem'}}>Invalid web URL</p>
        )}
      </>
    );
  }
}

export default InputComponent;
