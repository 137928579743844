import * as React from 'react';
import { connect } from 'react-redux';
import Feedback from './feedback';
import {getAnalyticsGraphQLClient} from '../../apollo/client';

const FeedbackWrapper = ({ organization, organization_id, dispatch }) => {
  const [graphQLClient, setGraphQLClient] = React.useState(null);
  React.useEffect(() => {
    if (!organization) return;
    let client = getAnalyticsGraphQLClient(organization.analytic_integration.schema_name);
    setGraphQLClient(client);
  }, [organization]);
  if (!organization) return <></>;
  if (!organization.analytic_integration || !organization.analytic_integration.schema_name) {
    return <>No Integration found. Goto Settings to add a integration</>;
  };
  if (!graphQLClient) return <>Loading client...</>;
  return <Feedback dispatch={dispatch} client={graphQLClient} organization_id={organization_id}></Feedback>;
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};
export default connect(mapStateToProps)(FeedbackWrapper);
