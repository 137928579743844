import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Cell } from 'baseui/layout-grid';
import { ToasterContainer, PLACEMENT, toaster } from 'baseui/toast';
import { Input } from 'baseui/input';
import { ArrowLeft, Search } from 'baseui/icon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HeadingXLarge, ParagraphSmall, LabelSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { sourceByType } from '../../util';
import { FlexGrid } from 'baseui/flex-grid';
import OrganizationCard from './organizationCard';
import logo from '../sidebar/sideNavIcons/horizontal_cardwarelogo_gradient_white_text.svg';
import styled from '@emotion/styled';
import AddOrg from './addOrg';
import UpdateOrg from './updateOrg';
import DeleteOrg from './deleteOrg';
import { getProfile } from '../../auth';


const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const gridPaddingOverrides = {
  Grid: {
    style: () => ({
      // padding: '0px !important'
      marginBottom: '1rem'
    }),
  },
};




const LogoContainer = styled.div`
  height: 59px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`
const VersionText = styled.p`
  color: #FFFFFF66;
  font-size: 9px;
  margin-bottom: 0px;
  width: 80%;
  padding-left: 10px;
  line-height: 11px;
  margin-top: 9px;
`

const MainOrganizationListPage = ({
  organizations,
  refetchOrganizations,
  organization,
  templates,
  ...props
}) => {
  const [css, theme] = useStyletron();

  const logoCss = css({
    width: '80%',
    paddingTop: '20px',
    marginBottom: '0px !important'
  });

  const [isUpdateOpen, setUpdateOpen] = React.useState(false);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [currentOrg, setCurrentOrg] = React.useState('');

  const [data, setData] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);

  React.useEffect(() => {
    const profile = getProfile();
    let { organization_id } = profile;
    setSelectedOrg({ id: organization_id });
  }, [])

  const onClose = () => {
    setUpdateOpen(false);
    setDeleteOpen(false);
    refetchOrganizations();
  }

  const searchOnChange = (e) => {
    setSearchText(e.target.value)
  }

  const onClear = () => {
    setSearchText("")
  }

  const onClickBack = () => window.history.go(-1);

  const InfiniteScrollLoader = () => {
    return (
      <div>
        <b>Loading...</b>
      </div>
    )
  }

  React.useEffect(() => {
    if (searchText) {
      return setData(organizations.filter((data, index) => {
        const { domain, organization, } = data;
        const { name, logo_data, id, auth0Domain, client_id, client_secret } = organization
        if (domain.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        ) {
          return true;
        }
        return false;
      }))
    }
    setData(organizations.filter((i, index) => index < page * 15))
  }, [organizations, page, searchText])
  console.log(organizations);
  return (
    <div id='organization-container'>
      <ToasterContainer placement={PLACEMENT.bottomRight} overrides={{
        Root: {
          style: {
            zIndex: 100
          }
        }
      }}
        autoHideDuration={3000}
      />
      {/* <Grid
      gridMaxWidth={'unset'}
      overrides={{
        Grid: {
          style: () => ({
            background: theme.leftNavBackgroundColor,
            height: '59px',
            color: 'white',
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            marginBottom: '1rem'
          }),
        },
      }}
    >
      <LogoContainer>
        <img src={logo} className={logoCss}></img>
        <VersionText>Version 3.1.10_3</VersionText>
      </LogoContainer>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px', position: 'relative', margin: 'auto' }}>
        <Search size={28} color={'white'}/>
        <Input
            id={'search-box'}
            value={searchText}
            onChange={searchOnChange}
            placeholder="Search for organizations with name and domain"
            onKeyDown={e => {
              if (e.key === "Escape") {
                setSearchText("")
              }
              return true;
            }}
            overrides={{
                Input: {
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        background: 'transparent !important',
                        paddingLeft: '16px',
                        caretColor: 'white'
                    }
                },
                InputContainer: {
                    style: {
                        background: 'transparent !important',
                    }
                },
                Root: {
                    style: {
                        width: '333px',
                        padding: 0,
                        background: 'transparent !important',
                        border: '0 !important',
                    }
                },
                ClearIcon: {
                    style: {
                        background: '#E2E2E2 !important',
                        color: 'black'
                    }
                },
                ClearIconContainer: {
                    style: {
                        background: 'transparent !important',
                        color: 'black'
                    }
                }
            }}
        />
        {searchText && (
            <svg width="10" height="10"
                onClick={onClear}
                viewBox="0 0 10 10"
                style={{ 'stroke': 'white', cursor: 'pointer' }}
                mlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 1L5 5M1 9L5 5M5 5L1 1M5 5L9 9" stroke-width="2" stroke-linecap="round"></path>
            </svg>
        )}
    </div>
    </Grid> */}
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>
          <div
            className={css({
              position: 'relative',
              float: 'left',
              width: '100%',
              paddingTop: '25px'
            })}
          >
            <div
              className={css({
                width: '40px',
                height: '40px',
                borderRadius: '20px',
                backgroundColor: 'black',
                position: 'relative',
                float: 'left',
                display: 'flex'
              })}
            >
              <ArrowLeft
                className={css({
                  // position: 'relative',
                  // float: 'left'
                  display: 'block',
                  margin: 'auto',
                  cursor: 'pointer'
                })} 
                size={32}
                color={'white'}
                onClick={onClickBack}
              />
            </div>
            {/* ICON */}
            {/* <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                position: 'relative',
                float: 'left',
                marginTop: theme.sizing.scale100,
              })}
            >
              <circle cx="16" cy="16" r="16" fill="#192F57" />
              <path
                d="M13.4 14.5L18.17 6.24C17.47 6.09 16.75 6 16 6C13.6 6 11.4 6.85 9.68 8.25L13.34 14.6L13.4 14.5ZM25.54 13C24.62 10.08 22.39 7.74 19.54 6.66L15.88 13H25.54ZM25.8 14H18.31L18.6 14.5L23.36 22.75C25 20.97 26 18.61 26 16C26 15.31 25.93 14.65 25.8 14ZM12.54 16L8.64 9.25C7.01 11.03 6 13.39 6 16C6 16.69 6.07 17.35 6.2 18H13.69L12.54 16ZM6.46 19C7.38 21.92 9.61 24.26 12.46 25.34L16.12 19H6.46ZM17.73 19L13.83 25.76C14.53 25.91 15.25 26 16 26C18.4 26 20.6 25.15 22.32 23.75L18.66 17.4L17.73 19Z"
                fill="#00A3FF"
              />
            </svg> */}

            {/* HEADING */}
            <HeadingXLarge
              className={css({
                position: 'relative',
                float: 'left',
                marginLeft: theme.sizing.scale550,
                marginBottom: '0px !important',
                textTransform: 'capitalize'
              })}
            >
              Organizations
            </HeadingXLarge>
          </div>
        </Cell>
        <Cell span={12} className={css({
          display: 'flex',
          justifyContent: 'space-between',
        })}>
          <ParagraphSmall
            className={css({
              color: theme.colors.primary500,
              margin: '0 !important',
              marginLeft: '10px !important'
            })}
          >
            {organizations.length} Organizations
          </ParagraphSmall>
          <div
            className={css({
              display: 'flex',
              marginTop: '15px'
            })}
          >
            <div
              className={css({
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                marginTop: '5px',
                position: 'relative',
                // margin: 'auto',
                marginRight: '20px'
                
              })}
            >
              {/* <Search size={28} color={'white'} />
              <Input
                id={'search-box'}
                value={searchText}
                onChange={searchOnChange}
                placeholder="Search for organizations with name and domain"
                onKeyDown={e => {
                  if (e.key === "Escape") {
                    setSearchText("")
                  }
                  return true;
                }}
                overrides={{
                  Input: {
                    style: {
                      fontSize: '12px',
                      color: 'white',
                      background: 'transparent !important',
                      paddingLeft: '16px',
                      caretColor: 'white'
                    }
                  },
                  InputContainer: {
                    style: {
                      background: 'transparent !important',
                    }
                  },
                  Root: {
                    style: {
                      width: '333px',
                      padding: 0,
                      background: 'transparent !important',
                      border: '0 !important',
                    }
                  },
                  ClearIcon: {
                    style: {
                      background: '#E2E2E2 !important',
                      color: 'black'
                    }
                  },
                  ClearIconContainer: {
                    style: {
                      background: 'transparent !important',
                      color: 'black'
                    }
                  }
                }}
              /> */}
              <Input
                value={searchText}
                onChange={searchOnChange}
                placeholder="Search for organizations with name and domain"
                clearOnEscape
                clearable
                endEnhancer={<Search size="18px" />}
              />
              {/* {searchText && (
                <svg width="10" height="10"
                  onClick={onClear}
                  viewBox="0 0 10 10"
                  style={{ 'stroke': 'white', cursor: 'pointer' }}
                  mlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 1L5 5M1 9L5 5M5 5L1 1M5 5L9 9" stroke-width="2" stroke-linecap="round"></path>
                </svg>
              )} */}
            </div>
            <AddOrg
              key={'add'}
              onNewOrgAdd={refetchOrganizations}
              organization={organization}
              organizations={organizations}
              templates={templates}
            ></AddOrg>
          </div>

        </Cell>
        <Cell span={12}>
          <div
            id="wrapper"
            className="wrapper"
            style={{
              paddingTop: theme.sizing.scale600,
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <FlexGrid>
              <div className="scrolls" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {isUpdateOpen && (
                  <UpdateOrg
                    key={'update'}
                    data={organizations.find(i => i.organization.id == selectedOrg.id)}
                    organization={organization}
                    organizations={organizations}
                    dispatch={props.dispatch}
                    onClose={onClose}
                  />
                )}
                {isDeleteOpen && (
                  <DeleteOrg
                    organization={selectedOrg}
                    dispatch={props.dispatch}
                    onClose={onClose}
                  />
                )}

                <InfiniteScroll
                  dataLength={data.length}
                  next={() => {
                    if (searchText) return;
                    if (data.length >= organizations.length) {
                      return setHasMore(false);
                    }
                    let p = page;
                    setPage(++p)

                  }}
                  height={720}
                  hasMore={searchText ? false : hasMore}
                  loader={<InfiniteScrollLoader />}
                  // scrollableTarget={'organization-container'}
                  endMessage={
                    <p style={{ textAlign: 'center', marginTop: '32px', color: theme.colors.primaryA }}>
                      {data.length <= 0 ? <b>No organization have been created.  Click Add Organization to get started.</b> : ''}
                    </p>
                  }
                >
                  {data.map((el, i) => {
                    return (
                      <OrganizationCard
                        name={el.organization.name}
                        imageSrc={getSrc(el.organization.logo_data)}
                        id={el.organization.id}
                        key={i}
                        domain={el.domain}
                        setUpdateOpen={setUpdateOpen}
                        setSelectedOrg={setSelectedOrg}
                        selectedOrg={selectedOrg}
                        setDeleteOpen={setDeleteOpen}
                      ></OrganizationCard>
                    );
                  })}
                </InfiniteScroll>

              </div>
            </FlexGrid>
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};
export default connect(mapStateToProps)(MainOrganizationListPage);
