
import React from 'react';
import { HeadingXSmall } from 'baseui/typography';
import icon from './svg/no_content_found.svg'
import './no-data-found.css';
export default function NoDataFound (props) {
    return (
        <div className="no-data-found-container">
            <img
                className='no-data-image'
                src={icon}
            />
            <HeadingXSmall
                $style={{
                    fontWeight: '700',
                    marginBottom: '5px'
                }}
            >
                Your app needs content
            </HeadingXSmall>
            <p className='subtitle-txt'>
                Click the add buttons above to start creating <br />
                your content in Cardware.
            </p>
        </div>
    )
}