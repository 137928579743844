import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Skeleton } from 'baseui/skeleton'
import { organizations, organizationsByEmail, pwa_manifest_templates } from '../../quries';
import Organization from './mainPage';
import { getProfile } from '../../auth/index';

const OrganizationWrapper = ({ userId, organization }) => {
  console.log('Loading.....');
  let profile = getProfile();
  console.log(profile);
  const [organizationsData, setOrganizations] = React.useState([]);
  const { data, loading, refetch } = useQuery(
    organizationsByEmail(profile.idTokenPayload.email),
    { fetchPolicy: 'network-only' }
  );
  const { data: templates, isLoading, refetchTemplates } = useQuery(
    pwa_manifest_templates(),
    { fetchPolicy: 'network-only' }
  );
  console.log(templates);
  React.useEffect(() => {
    data && setOrganizations(data.organization_domains_by_email);
  }, [data]);
  const refetchOrganizations = org => {
    refetch();
  };
  if (loading) {
    return (
    <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem'}}>
      {new Array(2).fill('').map(item => (
        <Skeleton
          width="60%"
          height="40px"
          animation
          overrides={{
            Root: {
              style: {
                marginBottom: '15px',
                borderRadius: '0'
              },
            },
          }}
      />))}
      <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '2rem', marginBottom: '1rem'}}>
        <Skeleton
          width="197px"
          height="54px"
          animation
          overrides={{
            Root: {
              style: {
                borderRadius: '38px',
              },
            },
          }}
        />
      </div>
      
      {new Array(3).fill('').map(item => (
        <>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {new Array(5).fill('').map(item => <Skeleton
              width="294px"
              height="230px"
              animation
              overrides={{
                  Root: {
                      style: {
                        margin: '0.4rem',
                        borderRadius: '6%',
                      },
                  },
              }}
            />)}
          </div>
        </>
      )
      )}
  </div>
  )}
  return (
    <Organization
      organizations={organizationsData}
      refetchOrganizations={refetchOrganizations}
      organization={organization}
      templates={templates ? templates.pwa_manifest_templates : []}
    ></Organization>
  );
};

export default OrganizationWrapper;
