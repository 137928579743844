
import * as React from 'react';
import ReactPlayer from 'react-player';
import { MdPlayCircleOutline, MdOutlineClose } from 'react-icons/md'
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from '@emotion/styled';
import { Spinner } from 'baseui/spinner';
import { Button } from 'baseui/button';

const CloseIcon = styled(MdOutlineClose)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 1002; 
`;

const VideoContainer = styled.div`
  position: fixed;
  width: 98%;
  height: 96%;
  background: white;
  margin: 1rem;
  left: 0;
  top: 0;
  z-index: 1002;
  overflow: hidden;
`
const VideoBackDropContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  left: 0;
  top: 0;
  z-index: 1001;
  overflow: hidden;
`

const VideoCard = styled.div`
  width: 360px;
  height: 440px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 10px;
  margin: 14px;
  padding: 5px;
  float: left;
`

const VideoPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
`

const VideoMetaDataContainer = styled.div`
  height: 180px;
  padding: 0.5rem;
  position: relative;
`

const VideoPlayIcon = styled(MdPlayCircleOutline)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1000
`

const VideoPlayerBackDropContainer = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
`

const VideoListComponent = ({ ...props }) => {
  console.log('Video item data______', props)
  const cardNumberPerPage = 8
  const { data } = props
  const [options, setOptions] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [playingIndex, setPlayingIndex] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);

  const onClose = () => {
    props.onClose()
  };

  const onStartVideo = async (index, id) => {
    setPlayingIndex(index);
  }

  const onPauseVideo = (index) => {
    setPlayingIndex(null);
  }

  const onSelectVideo = (selectedItem) => {
    props.onSelectVideo(selectedItem)
    props.onClose()
  }

  const infiniteScrollLoader = () => {
    if (!hasMore) return;
    return (
      <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2rem' }}>
        <Spinner />
      </div>
    )
  };

  const fetchAndSetOptions = async (page) => {
    setLoading(true)
    setHasMore(true)
    let s = (page - 1) * cardNumberPerPage;
    let end = s + cardNumberPerPage;
    if (data.length == options.length) {
      return setHasMore(false)
    }
    let next = [];
    for (var i = s; i < end; i++) {
      if (data[i])
        next.push(data[i]);
    };
    if (props.videoType?.toLowerCase() == 'jwplayer') {
      next = await Promise.all(next.map(async n => {
        const apiUrl = "https://cdn.jwplayer.com/v2/media/" + n.id;
        const videoResult = await fetch(apiUrl);
        const videoData = await videoResult.json();
        const url = videoData.playlist && videoData.playlist[0].sources[1].file || '';
        n.url = url;
        return n;
      }));
    }
    setOptions([...options, ...next]);
    setLoading(false);
  };

  React.useEffect(() => {
    setPage(1)
  }, [data]);

  React.useEffect(() => {
    fetchAndSetOptions(page);
  }, [page]);


  // console.log('has more',options, options.length != data.length, options.length);
  return (
    <>
      <VideoContainer>
        <div style={{ position: 'relative', }}>
          <CloseIcon color='black' size={20} onClick={onClose} />
          <div id="scrollableVideoContainer" style={{ width: '100%', height: '90vh', overflow: 'auto', padding: '1rem', display: 'flex' }}>
            <InfiniteScroll
              dataLength={options.length}
              next={() => {
                console.log('next callled');
                if (loading || !hasMore) { return; }
                setLoading(true);
                let p = page;
                setPage(++p);
              }}
              style={{ display: 'block', width: '85%', height: '90vh', minWidth: '500px', margin: '1rem auto', padding: '1rem', }}
              hasMore={hasMore}
              // loader={infiniteScrollLoader()}
              // scrollableTarget={'scrollableVideoContainer'}
              height={'90vh'}
            >
              {options.map((item, index) => {
                const { label, id, url, tags } = item
                return (
                  <VideoCard key={index}>
                    <VideoPlayerContainer>
                      {playingIndex != index && <>
                        <VideoPlayerBackDropContainer />
                        <VideoPlayIcon
                          size={43}
                          color='white'
                          onClick={() => onStartVideo(index, url)}
                        />
                      </>}
                      <ReactPlayer
                        // ref={(e) => ref(e, index)}
                        playing={playingIndex == index}
                        onPause={e => onPauseVideo(index)}
                        controls={true}
                        url={url}
                        width="100%"
                        height="230px"
                        style={{ width: '100%', height: '230px', position: 'absolute', background: 'rgba(0,0,0,0.5)', zIndex: 98 }}
                      />
                    </VideoPlayerContainer>
                    <VideoMetaDataContainer>
                      <p style={{ fontSize: '19px', fontWeight: 600, marginBottom: '0.7rem' }}>{label.length > 28 ? label.substring(0, 28) + '...' : label}</p>
                      <p style={{ marginBottom: '0.5rem' }}>{item.description || ''}</p>
                      <p style={{ marginBottom: '0.5rem' }}>{item.duration ? parseInt(item.duration) + ' seconds' : ''}</p>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: 0, width: '95%'}}>
                        <p style={{margin: 0}}>{item.tags?.length > 0 ? item.tags.map(item => (<span style={{fontSize: '12px', background: 'black', color: 'white', border: '1px solid #eee', padding: '4px', borderRadius: '3px', marginRight: '10px'}}>{item}</span>)) : ''}</p>
                        <Button
                          style={{ float: 'right' }}
                          onClick={e => onSelectVideo(item)}
                        >
                          Select Video
                          </Button>
                      </div>
                    </VideoMetaDataContainer>
                  </VideoCard>
                )
              })}
              {loading && infiniteScrollLoader()}
            </InfiniteScroll>
          </div>
        </div>
      </VideoContainer>
      <VideoBackDropContainer />
    </>
  )
}

export default VideoListComponent