import React from 'react';
import BaseClass from './base';
import {Select} from 'baseui/select';
import Masonry from 'react-masonry-component';

const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }


class InputComponent extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            value: '',
            options: [
                { id: 'file', label: 'Upload Image'},
                { id: 'library', label: 'Select from library'},
            ],
            libraries: [],
            isLoading: false,
            showLibrary: false,
        };
    }


    getLibraryList() {
        const { libraries } = this.props;
        if (libraries && libraries.libraries && libraries.libraries.length > 0) {
            let nextOptions = [];
            libraries.libraries.map(item => {
                if (item.type == 'image') {
                    nextOptions.push({ id: item.id, label: item.title, library: item.library});
                }
            });
            this.setState({ libraries: nextOptions });
        }
    }

    onClick(library) {
        this.setState({value: library})
        setTimeout(() => {
            this.setState({ selectedOption: 'file'})
        }, 1000)
    }

    render() {
        if (this.state.libraries.length == 0) this.getLibraryList()
        
        return (
            <>
                <h5
                    style={{
                        color: this.props.theme.colors.primaryA,
                        marginTop: this.props.theme.sizing.scale1200,
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '16px'
                    }}
                >
                    {this.props.title || ''}
                </h5>
                <Select
                    isLoading={this.state.isLoading}
                    options={this.state.options}
                    onChange={({value}) => {
                        this.setState({selectedOption: value &&  value[0]? value[0].id : null})
                    }}
                    value={this.state.options.filter(i => i.id == this.state.selectedOption)}
                />
                {this.state.selectedOption == 'library' && (
                    <div style={{ position: 'absolute', right: 0, top: 0, paddingTop: '1rem', height: '100%', overflowY: 'auto', maxWidth: '100%', width: '43%', zIndex: 100, background: 'rgb(76 76 76)'}}>
                        <Masonry
                            className={'my-gallery-class'}
                            elementType={'ul'}
                            options={masonryOptions}
                            disableImagesLoaded={false} 
                            updateOnEachImageLoad={false}
                            imagesLoadedOptions={imagesLoadedOptions}
                        >
                        {this.state.libraries.map((item) => {
                            let url
                            try {
                                url =  item && item.library ? JSON.parse(item.library).metadata.url : null;
                            } catch(error) {
                                console.log('Error: ', error)
                            }
                            if (url) {
                                return (
                                    <li className="image-element-class" onClick={() => this.onClick(item.library)}  style={{ width: '180px', marginTop: '0.7rem', height: '230px', display: 'flex', flexDirection: 'column', marginLeft: '20px', boxShadow: '0 1px 10px 0 rgba(0,0,0,.1)', cursor: 'pointer'}}>
                                        <img src={url} alt='library' style={{ margin: 0, height: '200px', objectFit: 'cover', border: '3px solid #b1b1b1'}} />
                                        <div style={{ marginTop: '0.2rem', background: '#b1b1b1'}}>
                                            <p style={{ color: this.state.value == item.library ? 'white' : 'black', padding: '0.5rem', wordBreak: 'break-all', fontSize: '14px'}}>{item.label.length > 19 ? item.label.substring(0, 18) + '...' : item.label}</p>
                                        </div>
                                    </li>
                                )
                            }
                        })}
                        </Masonry>
                    </div>
                )}
                    
            </>
        );
    }
}

export default InputComponent;