import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  LabelLarge,
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Notification } from "baseui/notification";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import { arrayMove } from 'baseui/dnd-list';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { useLocation } from '@reach/router';

import { useMutation, useQuery } from '@apollo/client';
import { updateCategoryMutation, updateDeckMutation, updateComponentMutation, deck_categories } from '../../quries';

import EditCategory from './editCategory';
import AddCategory from './addCategory';
import Decks from '../deck/index';
import ReorderInModal from '../reorderInModal';
import {
  setDecks,
} from '../../redux/actions/appBasicControls';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import Slides from '../home-components/slides';
import EditComponent from '../category/addOrEditComponent';
import Portal from '../home-components/portal';
import CardBackHomeComponent from '../home-components/cardBackHomeComponent';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import { showAlert } from '../../redux/actions/appBasicControls';
import NoDataFound from '../../pages/NoDataFound';
import CardStack from '../home-components/cardStack';

let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
};

class PortalAwareItem extends Component {
  render() {
    const {
      dragProvided,
      title,
      index,
      css,
      snapshot,
      setIsCollapsed,
      isCollapsed,
    } = this.props;
    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        <div
          key={index}
          className={css({
            position: 'relative',
            width: '600px',
            height: '50px',
            lineHeight: '50px',
            background: snapshot.isDragging ? '#5D64AB' : 'white',
            margin: '0',
            display: 'flex',
            padding: '16px',
            color: 'white',
            background: '#0D0E13',
            marginBottom: '8px !important'
          })}
        >
          <StatefulTooltip
            content={() => <div padding={'20px'}>Reorder</div>}
            placement={PLACEMENT.bottom}
            returnFocus
            autoFocus
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                cursor: 'pointer',
              })}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <path
                d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                fill={snapshot.isDragging ? 'white' : 'white'}
              />
            </svg>
          </StatefulTooltip>
          <LabelSmall
            className={css({
              marginLeft: '16px',
              color: 'white !important'
            })}
          >
            {title}
          </LabelSmall>
        </div>
      </div>
    );
    return <Portal snapshot={snapshot} child={child}></Portal>
  }
};

const Category = ({
  dispatch,
  categoryList,
  decks,
  cards,
  organization_id,
  app_content_scope_id,
  tabFilter,
  defaultTheme,
  refreshQuizQuestions,
  home_component_app_content_scopes,
  refetchHomeComponents,
  isLoading,
  deckIdFromPath,
  searchText,
  jwPlayerData,
  libraries,
  refreshCategories,
  refreshDecks,
  refreshCards,
}) => {
  const [css, theme] = useStyletron();

  const [isCategoryEditOpen, setIsCategoryEditOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [selectedHomeComponent, setSelectedHomeComponent] = React.useState(null);
  const [isEditComponentOpen, setIsEditComponentOpen] = React.useState(false);

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState(false)

  const [updateDeck] = useMutation(updateDeckMutation);
  const [updateQuizDeck, { data: updatedQuizDeck, }] = useMutation(gql`
  mutation updateQuizDeck($id: ID!, $position: Int, $published: Boolean) {
    updateQuizDeck(position: $position, id: $id, published: $published) {
      title
    }
  }
`);
  const [updateCategory] = useMutation(updateCategoryMutation);
  const [updateComponent, { data: updatedComponent }] = useMutation(updateComponentMutation);

  const location = useLocation();

  let isDeckOpen = false;
  if (location && location.pathname.indexOf('selected') > -1) {
    isDeckOpen = true;
  }

  const isSearchDataNotFound = () => (searchStatus && searchText && searchText != "" && filtered.length === 0);

  const isEmptyCategories = () => !(filtered && filtered.length > 0)

  let combinedList = () => categoryList.map((el, index) => {
    const categoryName = el.category ? el.category.name : el.title;
    return {
      title: `${categoryName} (Category)`,
      id: el.category_id,
      position: el.position,
      published: el.published,
      isNew: el.isNew,
      _raw: el,
      type: 'category',
      updatePositionMutation: updateCategory,
      refetch: () => { },
      onSettingsClick: () => {
        setSelectedCategory(el.category);
        setIsCategoryEditOpen(true);
      },
      titlesForSearch: () => {
        let arr = [];
        let categoryId = el.category_id;
        const categoryTitle = `${categoryName}(Category)`;
        arr.push(categoryTitle.toLowerCase());
        decks
          .filter(el => el.category_id == categoryId).forEach((el) => {
            arr.push(el.deck_category_deck.title.toLowerCase());
            cards.filter(item => item.quiz_deck_id === el.deck_id || item.deck_id === el.deck_id).forEach(item => {
              arr.push(item.card.title.toLowerCase());
            });
          });
        return arr;
      },
      children: (
        <Decks
          categoryId={el.category.id}
          organization_id={organization_id}
          isAddDeckOpen={false}
          onClose={onClose}
          selectedCategory={null}
          index={index}
          tabFilter={tabFilter}
          refreshQuizQuestions={refreshQuizQuestions}
          defaultTheme={defaultTheme}
          searchText={searchText}
          isDeckOpen={isDeckOpen}
          deckIdFromPath={deckIdFromPath}
        />
      )
    }
  })
    .concat(home_component_app_content_scopes ? home_component_app_content_scopes.map((el, index) => {
      return {
        title: `${el.home_component.title} (${el.home_component.component_type.match(/[A-Z][a-z]+/g).join(' ')})`,
        id: el.home_component.id,
        position: el.position,
        published: el.published,
        isNew: el.isNew,
        _raw: el,
        type: 'home_components',
        updatePositionMutation: updateComponent,
        refetch: refetchHomeComponents,
        onSettingsClick: () => {
          setSelectedHomeComponent(el.home_component);
          setIsEditComponentOpen(true);
        },
        titlesForSearch: () => {
          const isComponentType = CardBuilderOptions.filter(
            (el) => el.addInHomeComponent).map((el) => { return el.__typename }
            ).includes(el.home_component.component_type);
          let arr = [];
          const homeComponentTitle = `${el.home_component.title}(${el.home_component.component_type.match(/[A-Z][a-z]+/g).join(' ')})`;
          arr.push(homeComponentTitle);
          if (isComponentType) {
            el.home_component.card_content_modules.map(item => {
              if (item && item.content_module) {
                const { content_module } = item;
                if (content_module) {
                  arr.push((content_module.title || '').toLowerCase())
                  arr.push((content_module.phone_number || '').toLowerCase())
                }
              }
            })
          }
          return arr.concat(el.home_component.slide_home_components.map((el) => el.slide_home_component_slide.title.toLowerCase()));
        },
        children: (function () {
          switch (true) {
            case el.home_component.component_type == 'Slide':
              return <Slides
                selectedComponent={el}
                dispatch={dispatch}
                tabFilter={tabFilter}
                appContentScopeId={app_content_scope_id}
                organization_id={organization_id}
                home_component_app_content_scopes={home_component_app_content_scopes}
                refetchHomeComponents={refetchHomeComponents}
                defaultTheme={defaultTheme}
              ></Slides>
            case CardBuilderOptions.filter((el) => el.addInHomeComponent).map((el) => { return el.__typename }).includes(el.home_component.component_type):
              return <CardBackHomeComponent
                selectedComponent={el}
                jwPlayerData={jwPlayerData}
                libraries={libraries}
                dispatch={dispatch}
                tabFilter={tabFilter}
                appContentScopeId={app_content_scope_id}
                organization_id={organization_id}
                home_component_app_content_scopes={home_component_app_content_scopes}
                refetchHomeComponents={refetchHomeComponents}
                defaultTheme={defaultTheme}
                searchText={searchText}
              ></CardBackHomeComponent>
            case el.home_component.component_type == 'CardStackModule':
              return <CardStack
                selectedComponent={el}
                dispatch={dispatch}
                tabFilter={tabFilter}
                appContentScopeId={app_content_scope_id}
                organization_id={organization_id}
                home_component_app_content_scopes={home_component_app_content_scopes}
                refetchHomeComponents={refetchHomeComponents}
                defaultTheme={defaultTheme}
                orgId={organization_id}
              />
              case el.home_component.component_type == 'CopyCardModule':
                return <CardStack
                  selectedComponent={el}
                  dispatch={dispatch}
                  tabFilter={tabFilter}
                  appContentScopeId={app_content_scope_id}
                  organization_id={organization_id}
                  home_component_app_content_scopes={home_component_app_content_scopes}
                  refetchHomeComponents={refetchHomeComponents}
                  defaultTheme={defaultTheme}
                  orgId={organization_id}
                  isCopyCard={true}
                />
          }
        }())
      }
    }) : []).sort((a, b) => a.position - b.position);

  React.useEffect(() => {
    let _combinedList = combinedList();
    if (searchText) {
      _combinedList = _combinedList.filter((element) => {
        let titles = element.titlesForSearch().filter((el) => {
          return el.includes(searchText.toLowerCase());
        });
        return titles.length > 0 ? true : false;
      });
      if (_combinedList.length === 0) setSearchStatus(true)
      else setSearchStatus(false)
    };
    setFiltered(_combinedList);
  }, [categoryList, home_component_app_content_scopes, searchText]);

  const onClose = async (req) => {
    setIsCollapsed(false);
    setIsEditComponentOpen(false);
    setSelectedHomeComponent(null);
    setIsCategoryEditOpen(false);
    setSelectedCategory(null);
    if (req && req.isSavedFromReorder) {
      await Promise.all(filtered.map(el => {
        return el.updatePositionMutation({
          variables: {
            id: el.id,
            position: el.position,
          },
        });
      }));
      refetchHomeComponents();
    };
    if (req && !req.isSavedFromReorder) setFiltered(combinedList());
  };

  const getDecksByCategoryAndFilter = (categoryId) => {
    const selectedDecks = decks
      .filter(el => el.category_id === categoryId)
      .filter(el => {
        if (tabFilter === 'archived')
          return el.deck_category_deck.archived === true;
        if (tabFilter === 'published')
          return el.published === true && el.deck_category_deck.archived != true;
        return true;
      })
      .sort((a, b) => {
        let aPosition = a.position;
        let bPosition = b.position;
        // if (a.isNew) aPosition = -1;
        return aPosition - bPosition;
      });
    return selectedDecks
  }

  const isNoDeck = (el) => {
    if (el.type === 'category') {
      const categoryId = el.id;
      const categoryName = el.category ? el.category.name : el.title;
      let filteredDecks = decks
        .filter(el => el.category_id == categoryId)
        .filter(el => {
          if (el.deck_category_deck.archived === true) {
            return true;
          }
          const filteredCards = cards.filter(item =>
            item.quiz_deck_id === el.deck_id || item.deck_id === el.deck_id)
            .filter(item => item.card.archived === true)
          return filteredCards.length > 0;
        })
      return filteredDecks.length === 0;
    }
    return true;
  }

  const isNoSlide = (item) => {
    if (item.type === 'home_components') {
      let s = JSON.parse(JSON.stringify(item._raw.home_component.slide_home_components));
      s = s.filter((el) => {
        if (tabFilter === 'published') {
          return el.published == true && el.slide_home_component_slide.archived !== true;
        }
        if (tabFilter === 'archived') {
          return el.slide_home_component_slide.archived === true
        }
        if (tabFilter === 'all') {
          return el.slide_home_component_slide.archived !== true
        }
        return true;
      });
      return s.length === 0;
    }
    return true;
  }

  const onDeckMove = ({ destination, source, ...rest }) => {
    if (!destination) {
      return;
    }
    const isSameCategory = destination.droppableId === source.droppableId
    const isNoChange = isSameCategory && destination.index === source.index
    if (isNoChange) {
      return;
    }

    const deckId = rest.draggableId;

    if (isSameCategory) {
      // move deck position
      const selectedDecks = getDecksByCategoryAndFilter(source.droppableId);
      let newArr = arrayMove(
        JSON.parse(JSON.stringify(selectedDecks)),
        source.index,
        destination.index
      );
      newArr = newArr.map((el, i) => {
        delete el.isNew;
        el.position = i;
        return el;
      });
      // update in frontend
      const newDecks = decks.map(deck => {
        const findItem = newArr.find(item => item.deck_id === deck.deck_id)
        if (!!findItem) {
          return findItem
        }
        return deck;
      })
      dispatch(setDecks(newDecks || []))
      // update in backend
      newArr.map(el => {
        if (el.deck_type === 'QuizDeck') {
          return updateQuizDeck({
            variables: {
              id: el.deck_id,
              position: el.position,
            }
          });
        }
        updateDeck({
          variables: {
            id: el.deck_id,
            position: el.position,
          },
        });
      });
      setTimeout(() => {
        dispatch(
          showAlert({
            msg: `Successfully updated deck`,
            error: false,
          })
        );
      }, 1000)
    } else {
      // move to another category
      // source deck update

      let sourceDecks = getDecksByCategoryAndFilter(source.droppableId);
      let updatedSourceDecks = sourceDecks.filter((el, i) => i !== source.index).map((el, i) => ({
        ...el,
        position: i,
        deck_id: el.deck_id
      }));

      // target deck update
      let targetDecks = getDecksByCategoryAndFilter(destination.droppableId);
      const movedDeck = sourceDecks.find(deck => deck.deck_id === deckId)
      targetDecks.splice(destination.index, 0, { ...movedDeck, category_id: destination.droppableId })
      targetDecks = targetDecks.map((el, i) => ({
        ...el,
        position: i,
        deck_id: el.deck_id
      }));
      // update in frontend
      const newArr = updatedSourceDecks.concat(targetDecks);
      const newDecks = decks.map(deck => {
        const findItem = newArr.find(item => item.deck_id === deck.deck_id)
        if (!!findItem) {
          return findItem
        }
        return deck;
      })
      dispatch(setDecks(newDecks || []))
      let updatedTargetDecks = targetDecks.map((el, i) => {
        if (el.category_id) {
          return {
            position: el.position,
            deck_id: el.deck_id,
            category_id: el.category_id
          }
        }
        return {
          position: el.position,
          deck_id: el.deck_id,
        };
      });
      updatedSourceDecks.map(el => {
        updateDeck({
          variables: {
            id: el.deck_id,
            position: el.position,
          },
        });
      });
      updatedTargetDecks.map(el => {
        if (el.category_id) {
          return updateDeck({
            variables: {
              id: el.deck_id,
              position: el.position,
              category_id: el.category_id
            },
          });
        }
        updateDeck({
          variables: {
            id: el.deck_id,
            position: el.position,
          },
        });
      });
      setTimeout(() => {
        dispatch(
          showAlert({
            msg: `Successfully updated deck`,
            error: false,
          })
        );
      }, 1000)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', padding: '0 31px 0 25px' }}>
            {tabFilter !== "archived" && (
              <AddCategory
                organization_id={organization_id}
                position={filtered.length}
                app_content_scope_id={app_content_scope_id}
                home_component_app_content_scopes={home_component_app_content_scopes}
                refetchHomeComponents={refetchHomeComponents}
              />
            )}
          </div>
          <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
        </Cell>
      </Grid>
      <ReorderInModal
        isCollapsed={isCollapsed}
        onClose={onClose}
        title={'Reorder Categories'}
      >
        {isCollapsed ? (
          <DragDropContext
            onDragEnd={({ destination, source, ...rest }) => {
              if (!destination) {
                return;
              }
              let newArr = arrayMove(
                [...filtered],
                source.index,
                destination.index
              );
              newArr = newArr.map((el, i) => {
                el.position = i;
                delete el.isNew;
                return el;
              });
              setFiltered(newArr);
            }}
          >
            <Droppable
              droppableId={'row1'}
              type={'category'}
              direction={isCollapsed ? 'vertical' : 'horizontal'}
              isCombineEnabled={false}
            >
              {dropProvided => (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                  {filtered.map((el, index) => {
                    return (
                      <Grid
                        overrides={gridPaddingOverrides}
                        key={index}
                        gridMaxWidth={'unset'}
                      >
                        <Cell span={12} overrides={cellPaddingOverrides}>
                          <Draggable
                            key={el.id}
                            draggableId={el.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <PortalAwareItem
                                dragProvided={dragProvided}
                                title={el.title}
                                css={css}
                                theme={theme}
                                snapshot={snapshot}
                                index={index}
                                setIsCollapsed={setIsCollapsed}
                                isCollapsed={isCollapsed}
                                refreshQuizQuestions={refreshQuizQuestions}
                              />
                            )}
                          </Draggable>
                        </Cell>
                      </Grid>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div
            className={css({
              width: '100%', height: '100%'
            })}
          >
            <DragDropContext onDragEnd={e => onDeckMove(e)}>
              {filtered.map((el, index) => {
                // if (el && el.type === 'category' && tabFilter === "archived" && isNoDeck(el)) {
                //   return null;
                // }
                // if (el && el.type === 'home_components' && tabFilter === "archived" && isNoSlide(el)) {
                //   return null;
                // }
                return (
                  <Grid
                    overrides={gridPaddingOverrides}
                    key={index}
                    gridMaxWidth={'unset'}
                  >
                    <Cell span={12} overrides={cellPaddingOverrides}>
                      <div
                        // onMouseEnter={() => setHoveredCategory(el.id)}
                        // onMouseLeave={() => setHoveredCategory(null)}
                        className={css({
                          width: '80vw',
                          height: '24px',
                          paddingLeft: '1.5rem',
                          marginTop: '8px'
                        })}
                      >
                        {// hoveredCategory == el.id
                          // show reorder button always
                          // if want to rollback remove true and uncomment above line
                          true && (
                            <StatefulTooltip
                              content={() => (
                                <div padding={'20px'}>Reorder</div>
                              )}
                              placement={PLACEMENT.bottom}
                              returnFocus
                              autoFocus
                            >
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={css({
                                  position: 'relative',
                                  float: 'left',
                                  marginRight: theme.sizing.scale550,
                                  marginTop: theme.sizing.scale100,
                                  cursor: 'pointer',
                                })}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                              >
                                <path
                                  d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                                  fill={theme.colors.primaryA}
                                />
                              </svg>
                            </StatefulTooltip>
                          )}
                        <LabelMedium
                          className={css({
                            marginTop: '4px',
                            position: 'relative',
                            float: 'left',
                            textTransform: 'capitalize',
                            fontFamily: 'Roboto',
                            maxWidth: '600px',
                            textOverflow: 'ellipsis',
                            maxHeight: '24px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          })}
                        >
                          {el.title}
                        </LabelMedium>
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={css({
                            position: 'relative',
                            float: 'left',
                            marginLeft: theme.sizing.scale550,
                            marginTop: theme.sizing.scale100,
                          })}
                          onClick={el.onSettingsClick}
                        >
                          <path
                            d="M16.4731 10.536C16.5091 10.236 16.5331 9.924 16.5331 9.6C16.5331 9.276 16.5091 8.964 16.4611 8.664L18.4891 7.08C18.6691 6.936 18.7171 6.672 18.6091 6.468L16.6891 3.144C16.5691 2.928 16.3171 2.856 16.1011 2.928L13.7131 3.888C13.2091 3.504 12.6811 3.192 12.0931 2.952L11.7331 0.408C11.6971 0.168 11.4931 0 11.2531 0H7.41311C7.17311 0 6.98112 0.168 6.94512 0.408L6.58511 2.952C5.99711 3.192 5.45712 3.516 4.96512 3.888L2.57711 2.928C2.36111 2.844 2.10911 2.928 1.98911 3.144L0.0691144 6.468C-0.0508856 6.684 -0.00288541 6.936 0.189115 7.08L2.21711 8.664C2.16911 8.964 2.13311 9.288 2.13311 9.6C2.13311 9.912 2.15711 10.236 2.20511 10.536L0.177114 12.12C-0.0028857 12.264 -0.0508856 12.528 0.0571144 12.732L1.97711 16.056C2.09711 16.272 2.34911 16.344 2.56511 16.272L4.95311 15.312C5.45711 15.696 5.98511 16.008 6.57311 16.248L6.93312 18.792C6.98111 19.032 7.17311 19.2 7.41311 19.2H11.2531C11.4931 19.2 11.6971 19.032 11.7211 18.792L12.0811 16.248C12.6691 16.008 13.2091 15.684 13.7011 15.312L16.0891 16.272C16.3051 16.356 16.5571 16.272 16.6771 16.056L18.5971 12.732C18.7171 12.516 18.6691 12.264 18.4771 12.12L16.4731 10.536ZM9.33311 13.2C7.35312 13.2 5.73311 11.58 5.73311 9.6C5.73311 7.62 7.35312 6 9.33311 6C11.3131 6 12.9331 7.62 12.9331 9.6C12.9331 11.58 11.3131 13.2 9.33311 13.2Z"
                            fill={defaultTheme === "light" ? "#1E1F2C" : "#4A4CA0"}
                          />
                        </svg>
                        <StatefulTooltip
                          content={() => (
                            <div padding={'20px'}>{el.published ? 'Toggle off to Unpublish' : 'Toggle on to Publish'}</div>
                          )}
                          returnFocus
                          autoFocus
                          placement={PLACEMENT.bottom}
                        >
                          <div className='category-publish-container' style={{ width: 'auto', float: 'left', paddingBottom: '5px', marginLeft: '1rem' }}>
                            <Toggle
                              id="cheese-status"
                              defaultChecked={el.published}
                              icons={false}
                              checked={el.published}
                              overrides={{
                                ToggleTrack: {
                                  style: el.published ? {
                                    background: 'red !important'
                                  } : {},
                                }
                              }}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onChange={e => {
                                if (tabFilter === "archived") {
                                  return dispatch(
                                    showAlert({
                                      msg: 'Archived category can\'t be published!',
                                      error: true,
                                    })
                                  );
                                };
                                dispatch(
                                  showAlert({
                                    msg: `${e.target.checked ? 'Publishing' : 'Unpublishing'} category...`,
                                    error: false,
                                  })
                                );
                                el.updatePositionMutation({
                                  variables: {
                                    id: el.id,
                                    published: e.target.checked,
                                  },
                                }).then(() => {
                                  return el.refetch()
                                })
                                  .then(() => {
                                    dispatch(
                                      showAlert({
                                        msg: `Category Updated`,
                                        error: false,
                                      })
                                    );
                                  });
                              }}
                            />
                          </div>
                        </StatefulTooltip>
                      </div>
                      <hr style={{ height: '2px', margin: 0, marginTop: '10px', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
                    </Cell>
                    <Cell span={12} overrides={cellPaddingOverrides}>
                      <div style={{ paddingLeft: '2rem', paddingRight: '25px' }}>
                        {el.children}
                      </div>
                      <hr style={{ height: '2px', margin: '2px 0 0', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
                    </Cell>
                  </Grid>
                )
              })}
            </DragDropContext>
            {isSearchDataNotFound() && (
              <div>
                <Notification
                  overrides={{
                    Body: { style: { width: 'auto', paddingLeft: '25px', cursor: 'pointer', textDecoration: 'none' } },
                  }}
                >No results found</Notification>
              </div>
            )}
            {!isSearchDataNotFound() && isEmptyCategories() && !isLoading && (
              <Notification
                overrides={{
                  Body: {
                    style: {
                      width: 'auto',
                      paddingLeft: '25px',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      height: '100%',
                      backgroundColor: 'tranparent'
                    }
                  },
                  InnerContainer: {
                    style: {
                      width: '100%',
                      height: '100%',
                    }
                  }
                }}
              >
                <NoDataFound />
              </Notification>
            )}
          </div>
        )}
      </ReorderInModal>
      {/* {!isSearchDataNotFound() && isEmptyCategories() && !isLoading && tabFilter === "all" && (
        <>
          <Stepper
            organization_id={organization_id}
            refreshCategories={refreshCategories}
            refreshDecks={refreshDecks}
            refreshCards={refreshCards}
          />
        </>
      )} */}
      {isCategoryEditOpen && (
        <EditCategory
          organization_id={organization_id}
          position={filtered.length}
          category={selectedCategory}
          app_content_scope_id={app_content_scope_id}
          onClose={onClose}
          refetch={refreshDecks}
        />
      )}
      {isEditComponentOpen && <EditComponent
        component={selectedHomeComponent}
        onClose={onClose}
        position={filtered.length}
        refetchHomeComponents={refetchHomeComponents}
        organization_id={organization_id}
      ></EditComponent>}
      <div className="app-overlay" id="app-overlay"></div>
    </div>
  );
};

export default Category;