import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { client } from '../../apollo/client';
import { apiQuery, getData, createCommon, updateCommon } from './query';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import Button from '../styledButton';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const buttonOverrides = {
  BaseButton: {
    style: () => ({
      fontFamily: 'Roboto'
    })
  }
}

const CategoryMappings = ({ card_id, paths, organization_id, api_path_id}) => {

  const [mappings, setMappings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "card_api_mappings",
          query: `{\"where\": {\"card_id\": \"${card_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setMappings(JSON.parse(response.data));
      }
    });
  }, [card_id]);

  const onSave = () => {
    if (mapping_id == null) {
      // create
      setIsLoading(true);
      client.mutate({
        mutation: createCommon,
        variables: {
          data: {
            model: "card_api_mappings",
            data: JSON.stringify({
              api_path_id,
              title_field,
              subtitle_field,
              is_full_bleed,
              cover_url_field,
              organization_id,
              card_id
            })
          }
        }
      }).then(() => setIsLoading(false))
    } else {
      // update
      setIsLoading(true);
      client.mutate({
        mutation: updateCommon,
        variables: {
          data: {
            data: JSON.stringify({
              id: mapping_id,
              api_path_id,
              title_field,
              subtitle_field,
              is_full_bleed,
              cover_url_field
            }),
            model: "card_api_mappings",
          }
        }
      }).then(() => setIsLoading(false))
    }
  }


  const [mapping_id, set_mapping_id] = React.useState(null);
  // const [api_path_id, set_api_path_id] = React.useState(null);
  const [title_field, set_title_field] = React.useState(null);
  const [subtitle_field, set_subtitle_field] = React.useState(null);
  const [cover_url_field, set_cover_url_field] = React.useState(null);
  const [is_full_bleed, set_is_full_bleed] = React.useState(false);
  const [sample_data, set_sample_data] = React.useState({});


  React.useEffect(() => {
    if (mappings.length > 0) {
      let map = mappings[0];
      set_mapping_id(map.id);
      // set_api_path_id(map.api_path_id);
      set_title_field(map.title_field);
      set_subtitle_field(map.subtitle_field);
      set_cover_url_field(map.cover_url_field);
      set_is_full_bleed(map.is_full_bleed)
    }
  }, [mappings]);


  React.useEffect(() => {
    if (api_path_id) {
      client.query({
        query: getData(api_path_id),
        variables: {
          parent_record: JSON.stringify({})
        }
      })
        .then((result) => {
          let response = result.data.get_data_from_api_path;
          if (response.status) {
            set_sample_data(JSON.parse(response.data));
            // set_record_2(JSON.parse(response.data))
          }
        })
    }
  }, [api_path_id]);


  console.log('OKAY', api_path_id, title_field, subtitle_field, sample_data, mappings);


  return <Grid
    overrides={{
      Grid: {
        style: {
          padding: '10px 13px 0 7px !important'
        }
      }
    }} gridMaxWidth={'unset'}
  >
    <Cell
      span={6}
      overrides={{
        Cell: {
          style: () => ({
            padding: '0px !important',
            height: '100% '
          }),
        }
      }}
    >
      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Api Path</LabelMedium>
          <Select
            options={paths}
            value={paths.filter(el => el.id == api_path_id)}
            labelKey="name"
            valueKey="id"
            placeholder=''
            disabled
            // onChange={({ value }) => set_api_path_id(value[0].id)}
          />
        </div>
      </Cell>

      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Map Title</LabelMedium>
          <Input
            value={title_field}
            onChange={e => {
              set_title_field(e.target.value);
            }}
            placeholder="Select mapping of title"
            clearOnEscape
          />
        </div>
      </Cell>

      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Map Sub Title</LabelMedium>
          <Input
            value={subtitle_field}
            onChange={e => {
              set_subtitle_field(e.target.value);
            }}
            placeholder="Select mapping of title"
            clearOnEscape
          />
        </div>
      </Cell>

      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Map Cover Image Url</LabelMedium>
          <Input
            value={cover_url_field}
            onChange={e => {
              set_cover_url_field(e.target.value);
            }}
            placeholder="Select cover url field"
            clearOnEscape
          />
        </div>
      </Cell>

      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Is Full Bleed?</LabelMedium>
          <Checkbox
            checked={is_full_bleed}
            onChange={e => set_is_full_bleed(!is_full_bleed)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Full Bleed</ParagraphSmall>
          </Checkbox>
        </div>
      </Cell>




      <Cell span={10}>
        <Button
          style={{
            fontSize: '14px !important'
          }}
          overrides={buttonOverrides}
          isSelected
          isLoading={isLoading}
          onClick={onSave}>Save</Button>
      </Cell>
    </Cell>
    <Cell
      span={6}
      overrides={{
        Cell: {
          style: () => ({
            padding: '0px !important',
            height: '100% '
          }),
        }
      }}
    >
      <p>This is one sample record so you can map title and description fields.</p>
      <JSONInput
        id='a_unique_id'
        placeholder={sample_data}
        theme="dark_vscode_tribute"
        colors={{
          string: "#DAA520" // overrides theme colors with whatever color value you want
        }}
        locale={locale}
        height='650px'
        width={'100%'}
      />
    </Cell>
  </Grid>
};

export default CategoryMappings;