import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { FileUploader } from 'baseui/file-uploader';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Select } from 'baseui/select';
import Button from '../styledButton';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { sourceByType } from '../../util';
import { cloneDeckMutation, cloneQuizDeckMutation } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';
import store from '../../redux/store';
import SelectIcon from '../icons/index';
import RenderIcon from '../icons/renderIcon';

const getSrc = data => sourceByType(data);

export default ({
  deck,
  position,
  category_id,
  organization_id,
  onClose,
  published,
  dispatch,
  isQuizType,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState(deck.title || '');
  const [description, setDescription] = React.useState(deck.description || '');
  const [full_bleed, setFullBleed] = React.useState(deck.full_bleed || false);
  const [home_featured, setHomeFeatured] = React.useState(
    deck.home_featured || false
  );
  const [organization_color_palette_index, setColorIndex] = React.useState(
    deck.organization_color_palette_index || 0
  );
  const [cover_image_data, setImage] = React.useState(
    deck.cover_image_data || null
  );
  const [cover_image, setUpload] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);

  const [cover_type, set_cover_type] = React.useState(deck.cover_type || 'photo');

  const [icon_name, set_icon_name] = React.useState(deck.icon_name);

  const colorOptions = (
    store.getState().appBasicControls.organization.color_palette || []
  ).map((el, i) => {
    return {
      color: el,
      id: (
        <div
          style={{
            backgroundColor: `${el}`,
            width: '50px',
            height: '20px',
            borderRadius: '4px',
          }}
        ></div>
      ),
      index: i,
    };
  });

  const [cloneDeck, { loading: onCloneLoader, data: clonedDeck }] = useMutation(
    cloneDeckMutation
  );
  const [cloneQuizDeck, { loading: onQuizCloneLoader, data: clonedQuizDeck }] = useMutation(
    cloneQuizDeckMutation
  );
  const id = deck.id;

  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const onClone = () => {
    setIsLoading(true);
    if (isQuizType) {
      const quizObj = {
        id,
        title,
        description,
        full_bleed,
        home_featured,
        icon_name,
        organization_color_palette_index,
        cover_image_data,
        cover_image,
        cover_type,
        published: published,
        position,
        category_id,
        organization_id,
      };
      cloneQuizDeck({ variables: quizObj });
      return;
    }
    cloneDeck({
      variables: {
        id,
        title,
        description,
        full_bleed,
        home_featured,
        icon_name,
        organization_color_palette_index,
        cover_image_data,
        cover_image,
        cover_type,
        published: published,
        position,
        category_id,
        organization_id,
      },
    });
  };

  if (isOpen === false) onClose();

  const getCoverImageUrlBasedOnCoverType = () => {
    if (cover_type == 'photo') {
      return preivew_image || getSrc(cover_image_data);
    };
    return icon_name;
  }

  React.useEffect(() => {
    setIsLoading(false);
    if (clonedDeck) {
      dispatch(
        showAlert({
          msg: 'Successfully cloned deck!',
          error: false,
        })
      );
      onClose();
      return;
    }
    if (clonedQuizDeck) {
      dispatch(
        showAlert({
          msg: 'Successfully cloned deck!',
          error: false,
        })
      );
      onClose();
    }
  }, [clonedDeck, clonedQuizDeck]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>Clone Information</H5>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Title</H6>
              <Input
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Your title"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Description</H6>
              <Input
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Your description"
                clearOnEscape
              />
            </Cell>
            <H5>Appearance</H5>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Deck Color</H6>
              <Select
                options={colorOptions}
                labelKey="id"
                valueKey="color"
                onChange={({ value }) => {
                  return setColorIndex(value[0].index);
                }}
                value={colorOptions[organization_color_palette_index]}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
            <H6 style={{ marginBottom: '0px' }}>Deck Cover</H6>
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: cover_type == 'photo' ? 1 : 0 }}
              >
                <Button onClick={() => set_cover_type('icon')}>{'Icon'}</Button>
                <Button onClick={() => set_cover_type('photo')}>{'Photo'}</Button>
              </StatefulButtonGroup>
              <div
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.44) 0%, rgba(0, 0, 0, 0.88) 100%), url(${getCoverImageUrlBasedOnCoverType()}), ${store.getState().appBasicControls.organization['color_palette'][organization_color_palette_index]}`,
                  border: '0px solid #4A4CA0',
                  marginTop: '8px',
                  marginBottom: '8px'
                }}
                className={'card-new-gradient-style'}
              >
                {cover_type == 'icon' && icon_name && <RenderIcon iconName={icon_name}></RenderIcon>}
              </div>
              {cover_type == 'photo' && <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setUpload(acceptedFiles[0]);
                  previewImage(acceptedFiles[0]);
                }}
              ></FileUploader>}
              {cover_type == 'icon' && <div style={{
                width: '100%',
                position: 'relative',
                float: 'left'
              }}><SelectIcon value={icon_name} onChange={(value) => {
                set_icon_name(value);
              }}></SelectIcon></div>}
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6></H6>
              <Checkbox
                checked={full_bleed}
                onChange={e => setFullBleed(!full_bleed)}
                labelPlacement={LABEL_PLACEMENT.left}
              >
                Full Bleed Image
              </Checkbox>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6></H6>
              <Checkbox
                checked={home_featured}
                onChange={e => setHomeFeatured(!home_featured)}
                labelPlacement={LABEL_PLACEMENT.left}
              >
                Home Featured
              </Checkbox>
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={onCloneLoader}
            onClick={() => {
              onClone();
            }}
          >
            Clone
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
