export const gridPaddingOverrides = {
  Grid: {
    style: () => ({
      padding: '0px !important',
      marginTop: '1px'
    }),
  },
};

export const gridPaddingMarginOverrides = {
  Grid: {
    style: () => ({
      padding: '0px !important',
      margin: '0px !important',
      width: '100%',
    }),
  },
};

export const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      padding: '0px !important',
    }),
  },
};

export const tabOverrides = {
  TabPanel: {
    style: () => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      overflow: 'auto',
      height: 'calc(100vh - 190px)',
    }),
  },
};
