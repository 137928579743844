import * as React from 'react';
import BaseClass from './base';
import CardPickerCXL from '../../cardPickerCXL';

class SelectComponent extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        };
    }

    render() {
        const selectedCardId = this.state.value;
        const onCardSelect = (selectedCardId) => {
            this.setState({
                value: selectedCardId
            })
        };
        return <CardPickerCXL
            selectedCardId={selectedCardId}
            onCardSelect={onCardSelect}
            title={this.props.title || ''}
            theme={this.props.theme}
            placeholder={this.props.placeholder}
        />
    }
}

export default SelectComponent;