import * as React from 'react';
import { Button, SHAPE } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Select } from 'baseui/select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { getFilesUrls, sourceByType } from '../../util';
import ImageUploadCXL from '../imageUploaderCXL';
import { cardBackImageGuideline } from '../help/imageSizeGuidelines.json';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import styled from '@emotion/styled';

import svgIcons from './cardBackEditorComponents/images';
import CustomInput from '../common/input';
import _ from 'lodash';
import { SelectCXL } from './cardBackEditorComponents/select';
import { ParagraphSmall } from 'baseui/typography';
import { showAlert } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';
import TagsInput from '../common/tagsInput';

const HeaderTitle = styled.h5`
  color: black;
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const BtnImageSvg = styled.svg`
  position: relative;
  float: right;
  margin-right: 18px;
  cursor: pointer;
  margin-top: 6px;
`;

const FormActionButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;

const AddAdditionalContainer = styled.div`
  width: 90%;
  max-width: 405px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 8%;
  display: flex;
  justify-content: center;
`;

const getSrc = data => {
  try {
    if (!data) return data;
    return sourceByType(data);
  } catch (e) {
    return null;
  }
};

const getPreviewImage = (file, dbImage) => {
  if (file) {
    return URL.createObjectURL(file[0]);
  };
  return getSrc(dbImage);
};

const formFieldType = [{
  id: 'String',
  label: 'String'
}, {
  id: 'Number',
  label: 'Number',
}, {
  id: 'Boolean',
  label: 'Boolean',
}, {
  id: 'Date',
  label: 'Date',
}, {
  id: 'Select',
  label: 'Select',
}];
class FormBuilderComponent extends React.Component {
  constructor(props) {
    super(props);
    const { selectedModule, selectedComponentType } = props
    const { display, inputs, __typename } = selectedComponentType;
    let schemaArr = selectedModule.content_module['schema'] && selectedModule.content_module['schema'] !== null && selectedModule.content_module['schema'] || '';
    schemaArr = schemaArr && JSON.parse(schemaArr) || [];
    this.state = {
      isOpen: this.props.isOpen,
      inputs,
      formElements: schemaArr || [],
      isLoading: false,
      inputValues: {}
    };
    this.onAdd = this.onAdd.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps)) {
      return;
    }
    this.setUpInputValues();
  }

  componentDidMount() {
    this.setUpInputValues();
  }

  setUpInputValues = () => {
    const { selectedModule } = this.props
    if (selectedModule && selectedModule.content_module) {
      let inputValues = {}
      let updatedInputs = this.state.inputs.map(el => {
        inputValues[el.key] = selectedModule.content_module[el.key];
        return ({
          ...el,
          value: selectedModule.content_module[el.key]
        })
      });
      this.setState({
        inputs: updatedInputs,
        inputValues
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  onAdd() {
    let options = this.state.formElements || [];
    options = options.map(el => ({ ...el }));
    options.push({
      name: '',
      displayName: '',
      type: '',
      position: options.length,
    });
    this.setState({ formElements: options }, () => {
      const lastElement = `position_${this.state.formElements.length - 1}`;
      this.scrollToElemet(`#${lastElement}`);
    });
  }


  onMoveUp = (item) => {
    if (item.position - 1 < 0) return;
    let temp = this.state.formElements.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position - 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position - 1);
      this.scrollToElemet(ele)
    })
  }

  onMoveDowm = (item) => {
    let tempOptions = [...this.state.formElements] || []
    if (item.position + 1 >= tempOptions.length) return;
    let temp = tempOptions.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position + 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position + 1);
      this.scrollToElemet(ele)
    })
  }

  onDeleteItem = (item) => {
    let temp = this.state.formElements.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    })
  }


  scrollToElemet = (elementName) => {
    const lastContainer = document.querySelector(elementName);
    const scrollContainer = document.querySelector('.form_builder_container');
    scrollContainer.scrollTo({
      top: lastContainer.offsetTop,
      behavior: 'auto'
    });
  }

  onImageDrop = (acceptedFiles, item) => {
    const {
      inputValues
    } = this.state;
    const options = [...this.state.inputs]
    const temp = options.map(item => {
      if (item.key == 'cover_image_data') {
        return {
          ...item,
          value: acceptedFiles
        }
      }
      return item
    });

    let updateVals = inputValues || {}
    updateVals[item.mutation ? item.mutation.key : item.key] = acceptedFiles
    // setState(
    //   mutation ? mutation.key : key, acceptedFiles
    // );
    this.setState({
      inputs: temp,
      inputValues: updateVals
    })
  }

  onImageDelete = (index, item) => {
    const {
      inputValues
    } = this.state;
    const options = [...this.state.inputs]
    const temp = options.map(item => {
      if (item.key == 'cover_image_data') {
        return {
          ...item,
          value: null
        }
      }
      return item
    })
    let updateVals = inputValues || {};
    updateVals[item.mutation ? item.mutation.key : item.key] = null;
    this.setState({
      inputs: temp,
      inputValues: updateVals
    })
  }

  onSave = () => {
    const {
      inputs,
      formElements,
      inputValues
    } = this.state || {};
    let payload = {};
    if (inputValues) {
      payload = {
        ...payload,
        ...inputValues
      }
    }
    // inputs.map((el, idx) => {
    //   payload[el.key] = el.value
    //   if (el.type === 'FilePicker' && el.mutation && el.mutation.key) {
    //     payload[el.key] = inputs[idx].value;
    //     payload[el.mutation.key] = el.value;
    //   }
    // })

    const schemaArr = formElements || [];
    payload.schemaArr = schemaArr;
    const { refetchHomeComponents, selectedModule, updateMutation, onClose, dispatch } = this.props
    const id = selectedModule.content_module.id;
    // console.log('values  ------->', {
    //   id,
    //   // published,
    //   // archived,
    //   ...payload,
    // })
    this.setState({
      isLoading: true
    });
    const mutation = updateMutation;
    client.mutate({
      mutation,
      variables: {
        id,
        // published,
        // archived,
        ...payload,
      }
    }).then(async (result) => {
      dispatch(
        showAlert({
          msg: 'Successfully updated data!',
          error: false,
        })
      );
      await refetchHomeComponents();

      onClose();
    }).catch((err) => {
      console.log('error', err)
    }).finally(() => {
      this.setState({
        isLoading: false
      });
    })
    this.props.onSave && this.props.onSave(this.state);
    // this.props.onClose && this.props.onClose();
  }

  resetFields = () => {
    const {
      inputs
    } = this.state
    let updatedInputs = inputs.map(el => {
      return ({
        ...el,
        value: ''
      })
    })

    this.setState({
      inputs: updatedInputs,
      formElements: [],
      inputValues: {}
    })
  }

  //

  renderFormOptionButtons = (item, index) => {
    const { theme } = this.props;
    const onClickUp = () => this.onMoveUp(item)
    const onClickDown = () => this.onMoveDowm(item)
    const onClickDelete = () => this.onDeleteItem(item)

    const renderUpButton = () => {
      return (
        <BtnImageSvg
          width="18"
          height="11"
          viewBox="0 0 18 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClickUp}
        >
          <path
            d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
            fill={'white'}
          />
        </BtnImageSvg >
      )
    }
    const renderDownButton = () => {
      return (
        <BtnImageSvg
          width="16"
          height="10"
          viewBox="0 0 16 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClickDown}
        >
          <path
            d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
            fill={'white'} // theme.colors.mono100 || 
          />
        </BtnImageSvg>
      )
    }
    const renderDeleteButton = () => {
      return (
        <BtnImageSvg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClickDelete}
        >
          <path
            d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
            fill={'white'}
          />
        </BtnImageSvg>
      );
    }
    return (
      <div className='form_item_option_container' style={{ height: '40px' }}>
        <FormActionButtonContainer>
          {renderUpButton()}
          {renderDownButton()}
          {renderDeleteButton()}
        </FormActionButtonContainer>
      </div>
    )
  }

  renderTitle = (title) => {
    const { theme } = this.props;
    return (
      <HeaderTitle theme={theme}>
        {title}
      </HeaderTitle>
    )
  }

  renderVideoImage = (item = {}, index = 0) => {
    const onDrop = (acceptedFiles, rejectedFiles) => {
      this.onImageDrop(acceptedFiles, item)
    }

    const onDelete = () => {
      this.onImageDelete(index, item)
    }
    // const coverUrl = getPreviewImage(item.value, item.value) || [];
    const { title, placeholder, type, key, typeToPick, mutation } = item;
    const {
      inputValues
    } = this.state;

    const previewUrls = mutation ? (inputValues[mutation.key] ? getFilesUrls(inputValues[mutation.key]) : getFilesUrls(inputValues[key])) : getFilesUrls(inputValues[key]);
    return (
      <ImageUploadCXL
        showHint={false}
        isMultiple={false}
        style={{ background: 'transparent' }}
        title={title}
        titleHelpText={placeholder}
        onDrop={onDrop}
        previewUrls={previewUrls}
        onImageDelete={onDelete}
      ></ImageUploadCXL>
    )
  }

  renderFormList = (item, index) => {
    console.log('renderFormList ----->', item)
    const { name, displayName, position, type, options } = item
    const onChange = (key, value) => {
      const options = [...this.state.formElements]
      const temp = options.map(i => {
        if (i.position == position) {
          return {
            ...i,
            [key]: value
          }
        }
        return i;
      })
      this.setState({ formElements: temp })
    }

    const onAddOption = (options) => {
      onChange('options', options)
    }

    return (
      <div id={'position_' + index} key={'position_' + index} className='form_list_container' style={{ marginTop: '1rem' }}>
        <div className='form_item_container'>
          {this.renderFormOptionButtons(item, index)}
          <div className='form_item_innner_container' style={{ background: 'white', padding: '1rem' }}>
            <div>
              {this.renderTitle('Form Label')}
              <CustomInput
                value={displayName}
                onChange={(e) => onChange('displayName', e.target.value)}
                placeholder={`Enter Form Label`}
                clearOnEscape
                maxLength={125}
              />
              {this.renderTitle('Form Field')}
              <CustomInput
                value={name}
                onChange={(e) => onChange('name', e.target.value)}
                placeholder={`Enter Form Field`}
                clearOnEscape
                maxLength={125}
              />
              {this.renderTitle('Field Type')}
              <Select
                options={formFieldType}
                labelKey="label"
                valueKey="id"
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: { style: { zIndex: 3 } },
                      },
                    },
                  },
                }}
                value={formFieldType.filter((el) => el.id == type)}
                onChange={({ value }) => {
                  if (value.length == 0) onChange('type', '');
                  else onChange('type', value[0].id);
                }}
              ></Select>
              {
                type === 'Select' && (
                  <TagsInput
                    tags={options}
                    title={'Dropdown Options'}
                    placeholder={'Enter a Option and hit Enter'}
                    inputTip={'Type name of your option and hit "Enter" to select it'}
                    onChangeOption={onAddOption}
                  />
                )
              }

              {/* <CustomInput
                value={field_type}
                onChange={(e) => onChange('field_type', e.target.value)}
                placeholder={`Enter Field Type`}
                clearOnEscape
                maxLength={125}
              /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAddFormButton = () => {
    const { css } = this.props;
    const btnOverrides = {
      BaseButton: {
        style: {
          color: '#4A4CA0',
          maxWidth: '356px',
          width: '100%',
          border: '3px solid #4D4B45',
          boxSizing: 'border-box',
          boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.17)',
          borderRadius: '20px',
          background: 'white',
          ':hover': {
            background: '#4A4CA0',
            color: 'white'
          }
        }
      }
    };

    const startEnhancer = () => (
      <img
        src={svgIcons.AddGradientIcon}
        style={{
          width: '20px',
          height: '20px',
          marginBottom: '0'
        }}
      />
    )
    return (
      <AddAdditionalContainer>
        <Button
          onClick={this.onAdd}
          overrides={btnOverrides}
          startEnhancer={startEnhancer}
        >
          Add additional form element
        </Button>
      </AddAdditionalContainer>
    )
  }

  renderItem(item) {
    const { key, type, title, value, options, placeholder, isVisible, visibilityParameter } = item;
    const onChange = (newKey, newValue) => {
      const options = [...this.state.inputs]
      const temp = options.map(item => {
        if (item.key == newKey) {
          return {
            ...item,
            value: newValue
          }
        }
        return item
      })
      let updatedInputVals = this.state.inputValues || {}
      updatedInputVals[newKey] = newValue;
      this.setState({
        inputs: temp,
        inputValues: updatedInputVals
      })
    }
    switch (type) {
      case 'Input':
        return (
          <CustomInput
            value={value}
            onChange={(e) => onChange(key, e.target.value)}
            placeholder={`Enter ${title || ''}`}
            clearOnEscape
            maxLength={125}
          />
        )
      case 'Select':
        return (
          <SelectCXL
            {...item}
            onChange={(value) => onChange(key, value)}
            value={value}
            overrides={{
              Popover: {
                props: {
                  overrides: {
                    Body: { style: { zIndex: 3 } },
                  },
                },
              },
            }}
          ></SelectCXL>
        )
      case 'FilePicker':
        return this.renderVideoImage(item)
      case 'Checkbox':
        return (
          <Checkbox
            checked={value}
            onChange={e => onChange(key, e.target.checked)}
          >
            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>{placeholder}</ParagraphSmall>
          </Checkbox>
        )
      default:
        break;

    }
  }

  renderInputFields = () => {
    return this.state.inputs.map(item => {
      const { title, isVisible, visibilityParameter } = item;
      if (isVisible) {
        const visibilityVal = this.state.inputValues[visibilityParameter]
        if (isVisible(visibilityVal) === false) {
          return null;
        }
      }
      return (
        <div>
          {title && this.renderTitle(title)}
          {this.renderItem(item)}
        </div>
      )

    })
  }


  render() {
    console.log('formElements', this.state.formElements)

    const {
      formElements,
      isLoading,
      inputValues
    } = this.state || {};
    const options = formElements || [];
    // console.log('inputValues', inputValues)
    return (
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3,
            }
          },
        }}
        isOpen={true}
        size={'full'}
        onClose={() => {
          this.props.onSave && this.props.onSave(this.state);
          this.props.onClose && this.props.onClose();
        }}
      >
        <ModalHeader>Form Information</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%', padding: '1rem', height: '80vh', overflowY: 'auto' }}>
              {this.renderInputFields()}
            </div>
            <div className='form_builder_container' style={{ width: '50%', background: 'black', padding: '1rem', maxHeight: '80vh', overflowY: 'auto' }}>
              {options && options.map(this.renderFormList)}
              {this.renderAddFormButton()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={this.onSave}
            isLoading={isLoading}
          >
            Save
          </ModalButton>
          <ModalButton
            onClick={() => {
              this.props.onClose && this.props.onClose();
            }}
          >
            Cancel
          </ModalButton>
        </ModalFooter>
      </Modal>

    );
  }
}

export default FormBuilderComponent;
