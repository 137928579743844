import React from 'react';
import { silentAuth } from './index';

const noAuhPaths = [/accept-invite/, /password-reset-success/, /password-reset/, /signup/, /ms-callback/, /preview/];

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    // debugger;
    console.log('CHECKING SESSION................');
    let currentPath = window.location.pathname;
    let isNoAuthRequired = noAuhPaths.filter(el => currentPath.match(el));
    console.log(isNoAuthRequired);
    if (isNoAuthRequired.length > 0) {
      this.handleCheckSession();
    } else silentAuth(this.handleCheckSession);
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    );
  }
}

export default SessionCheck;
