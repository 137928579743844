import auth0 from 'auth0-js';
import { navigate } from 'gatsby';
import axios from 'axios';
import store from '../redux/store';
import _ from 'lodash';
import { getServerUrl } from '../apollo/client';
import { client } from '../apollo/client';
import gql from 'graphql-tag';
import { parseJwt, setLastLoggedInOrgId, setAuth0Token, lastLoggedInOrgId, clearLastLoggedInOrgId, clearAuth0Token, getAuth0Token, getOrganizationIdFromPath, dropOldCookies } from './helpers';
import { setUser } from '../redux/actions/appBasicControls';
import { forgotPasswordMutation } from '../quries';

const dispatch = store.dispatch;
const isBrowser = typeof window !== 'undefined';

const auth0_module = auth0;

if (isBrowser) {
  dropOldCookies();
};

export const login = (organization, email, password) => {
  if (!isBrowser) {
    return;
  }
  let { auth0 } = organization
  let { domain, client_id, client_secret } = auth0;
  const bodyParams = {
    grant_type: 'password',
    username: email,
    password: password,
    audience: `https://${domain}/userinfo`,
    scope: 'openid profile email user_metadata',
    client_id,
    client_secret
  };
  return axios({
    url: `https://${domain}/oauth/token`,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: bodyParams
  })
    .then(responseJson => {
      let data = {};
      Object.keys(responseJson.data).forEach(key => {
        data[_.camelCase(key)] = responseJson.data[key];
      });
      client.cache.reset();
      setLastLoggedInOrgId(organization.id);
      setAuth0Token(data.idToken);
      setUserInfoToStateFromToken(data.idToken, organization.id, domain, () => {
        navigate(`/app/${organization.id}/groups/standard/channels`);
      });
      return true;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const switchToOrg = (organization_id) => {
  client.cache.reset();
  setLastLoggedInOrgId(organization_id);
  let profile = getProfile();
  dispatch(
    setUser({
      ...profile,
      organization_id
    })
  );
  let url = `${window.location.origin}/app/${organization_id}/groups/standard/channels`;
  window.location = url;
};

export const setUserInfoToStateFromToken = (token, organization_id, domain, callback = () => { }) => {
  let decoded = parseJwt(token);
  axios({
    url: `${getServerUrl()}/get_auth_user/${organization_id}/${domain}/${token}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(responseJson => {
      if (responseJson && responseJson.data) {
        dispatch(setUser({
          idToken: token,
          idTokenPayload: { ...decoded, ...responseJson.data },
          organization_id
        }));

      } else dispatch(setUser({
        idToken: token,
        idTokenPayload: decoded,
        organization_id
      }));
      // LETS TELL SMART LOOK ABOUT IDENTITY OF USER 
      try {
        if (typeof window != undefined && window.smartlook) {
          console.log("PUSHING CLIENT INFO TO SMART LOOK");
          let p = { ...decoded, ...responseJson.data };
          window.smartlook('identify', p.user_id, {
            user_id: p.user_metadata.admin_id,
            organization_id: organization_id,
            email: p.email,
            at: new Date().getTime(),
            origin: window.location.origin
          });
        }
      } catch (e) {
        console.log(e);
        console.log('error in smart look Identity');
      }
      callback();
    }).catch(e => {
      dispatch(setUser({
        idToken: token,
        idTokenPayload: decoded,
        organization_id
      }));
      callback();
    });
};

const clearDataAndFallBackToLogin = (callback = () => { }) => {
  console.log('clear data and back to login');
  clearLastLoggedInOrgId();
  clearAuth0Token();
  setTimeout(() => {
    navigate('/login');
    callback();
  }, 500);
}

export const silentAuth = callback => {
  let token = getAuth0Token();
  let orgId = getOrganizationIdFromPath();
  orgId = orgId == null ? lastLoggedInOrgId() : orgId;
  console.log(token);
  console.log(orgId);
  if (!orgId || !token) return clearDataAndFallBackToLogin(callback);
  // fetch domain from server
  let pathToRedirect = '';
  if (getOrganizationIdFromPath()) pathToRedirect = window.location.pathname;
  else pathToRedirect = `/app/${orgId}/groups/standard/channels`;
  client.query({
    query: gql`
      query organization{
        organization(id: "${orgId}") {
          auth0 {
            domain
          }
        }
      }
    `
  }).then((result) => {
    if (result && result.data && result.data.organization) {
      let { auth0 } = result.data.organization;
      let domain = auth0 ? auth0.domain : null;
      if (!domain) return clearDataAndFallBackToLogin(callback);
      return axios({
        url: `${getServerUrl()}/validate-token?token=${token}&domain=${domain}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(responseJson => {
          if (responseJson.data.success) {
            console.log('A valid token');
            console.log('redirecting to ' + pathToRedirect);
            setUserInfoToStateFromToken(token, orgId, domain, () => {
              navigate(pathToRedirect);
              callback();
            });
          } else clearDataAndFallBackToLogin(callback);
        })
        .catch((err) => {
          console.log(err);
          clearDataAndFallBackToLogin(callback);
        });
    };
    return clearDataAndFallBackToLogin(callback);
  })
    .catch((err) => {
      console.log(err);
      clearDataAndFallBackToLogin(callback)
    });
};

export const logout = async () => {
  clearDataAndFallBackToLogin();
};

export const getToken = () => {
  let user = store.getState().appBasicControls.user || {};
  return {
    ...user
  }
}

export const forgotPassword = (organization, email, domain = window.location.origin) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: forgotPasswordMutation,
      variables: {
        email,
        domain
      },
      context: {
        headers: {
          'organization-id': organization,
        }
      }
    }).then((result) => {
      let message = result.data.forgotPassword.data;
      console.log(result);
      resolve(message);
    }).catch(() => reject(null))
  })
}

export const getProfile = () => {
  return store.getState().appBasicControls.user;
};