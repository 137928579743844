import React, { useState } from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import ResponseTable from '../quiz-responses/index';
// import RightSidePageHeader from '../pageHeaders';
import Button from '../styledButton';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
// import moment from 'moment';
// import gql from 'graphql-tag';
import { getServerUrl } from '../../apollo/client';
import { gridPaddingMarginOverrides, gridPaddingOverrides } from '../overrides';
// import { client as reactClient } from '../../apollo/client';

const QuizMetrics = props => {
  const [showQuizResponseTable, setShowQuizResponseTable] = useState(true);
  const closeResponseTable = () => setShowQuizResponseTable(false);

  const onClickDownload = () => {
    window.open(`${getServerUrl()}/quiz_response_data/download/${props.organization_id}`, '_blank').focus();
  }


  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      <Cell span={12}>
        <StatefulButtonGroup
          mode={MODE.radio}
          initialState={{ selected: 0 }}
          style={{
            float: 'right',
            marginBottom: '10px'
          }}
          overrides={{
            Root: {
              style: {
                float: 'right',
                marginBottom: '10px'
              }
            }
          }}
        >
          <Button
            onClick={onClickDownload}
            isSelected
          >
            Download All Data
          </Button>
        </StatefulButtonGroup>
      </Cell>

      <Cell span={12}>
        <div style={{ marginBottom: '32px' }}>
          <ResponseTable organization_id={props.organization_id} close={closeResponseTable} />
        </div>
      </Cell>
    </Grid>
  )
};

export default QuizMetrics;