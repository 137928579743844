import React from 'react';
import { navigate } from 'gatsby';
// import { navigate } from "@reach/router";
import Login from './login';

export default function Home({}) {
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() =>{setIsReady(true)}, 500);
  }, []);
  if (!isReady) {
    return <></>;
  };
  return <Login></Login>
};
