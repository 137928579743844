const GenCSV = async (cardArray, graphCardArray, client, queryServerClient) => {
  let cardArrayData = cardArray.map(
    ({ title, query, dataProcessor, queryFromQueryServer, toCsvLines }) => {
      let _client = client;
      if (queryFromQueryServer) _client = queryServerClient;
      if (_client && query) {
        return _client
          .query({
            query,
          })
          .then(result => {
            let myData = { toCsvLines };
            myData.title = title;
            myData.data = dataProcessor(result);
            return myData;
          });
      };
      return false;
    }
  );

  let graphCardArrayData = graphCardArray.map(
    ({ title, query, dataProcessor, queryFromQueryServer, toCsvLines }) => {
      let _client = client;
      if (queryFromQueryServer) _client = queryServerClient;
      if (_client && query) {
        return _client
          .query({
            query,
          })
          .then(result => {
            let myObject = { toCsvLines };
            myObject.data = dataProcessor(result);
            myObject.title = title;
            return myObject;
          });
      };
      return false;
    }
  );

  let concatArrayData = [...cardArrayData, ...graphCardArrayData].filter(el => el);
  concatArrayData = await Promise.all(concatArrayData);
  let csvArray = [['', '', '']];
  concatArrayData.forEach((el) => {
    if (!el.toCsvLines) return;
    csvArray = el.toCsvLines(el.title, el.data, csvArray);
  });
  let csvContent = csvArray.map(e => e.join(",")).join("\n");

  var exportedFilenmae = 'export.csv';

  var blob = new Blob([csvContent]);
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return;
};
module.exports = GenCSV;
