import * as React from 'react';
import SearchableCheckList from "../../SearchableCheckList";
import { client } from '../../../apollo/client';
import { gql } from '@apollo/client';
import BaseClass from './base';

class TableColumnPicker extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            value: []
        };
    }
    render() {
        const google_sheet_integration_id = this.props.root.inputs[1].value;
        if (!google_sheet_integration_id) return <></>;
        let value = this.state.value || [];
        if (typeof value === 'string') {
            value = JSON.parse(value);
        }
        console.log(value);
        return <SearchableCheckList
            google_sheet_integration_id={google_sheet_integration_id}
            typeToPick={this.props.typeToPick}
            value={value}
            onChange={(value) => {
                this.setState({value: JSON.stringify(value)})
            }}
        ></SearchableCheckList>;
    }
}

export default TableColumnPicker