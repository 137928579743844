import * as React from 'react';
import { connect } from 'react-redux';
import Connect from './connect';

const ConnectWrapper = ({
  channels,
  organization_id,
  dispatch,
  channelGroups,
  defaultTheme
}) => {
  return (
    <Connect
      organization_id={organization_id}
      dispatch={dispatch}
      channels={channels}
      channelGroups={channelGroups}
      defaultTheme={defaultTheme}
    ></Connect>
  );
};

const mapStateToProps = props => {
  let { channels, groups, theme } = props.appBasicControls;
  return {
    channels,
    channelGroups: groups,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(ConnectWrapper);
