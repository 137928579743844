import * as React from 'react';
import Button from '../common/button';
import svgIcons from '../../svg'
import HelpQuestionMark from '../help/questionMarkHelp';
import exportToCsv from '../../util/exportCSV';
import { tableColumnKeys } from './userCard';
import { getServerUrl } from '../../apollo/client';

const AddUser = ({
    users,
    type,
    columns,
    organization_id,
    filters
}) => {
    // ----------------------------------> Event Function <----------------------------------------------- //
    const exportUsers = () => {
        // let cols = columns.filter(el => el.dataKey);
        // const HeaderArr = cols.map((el) => el.name);
        // const dataArr = users.map(user => {
        //     return cols.map((el) => `${user[el.dataKey] ? user[el.dataKey] : ''}`);
        // });
        // const exportData = [
        //     HeaderArr,
        //     ...dataArr
        // ];

        // exportToCsv('user_list.csv', exportData)
        let url = `${getServerUrl()}/${type == 'admin' ? 'admins_data' : 'app_users_data'}/download/${organization_id}`;
        let filter = filters[0];
        let query = filter ? filter.dataQuery : {};
        let __url = new URL(url);
        Object.keys(query).forEach(key => {
            __url.searchParams.append(key, query[key])
        });
        console.log(__url.href);
        window.open(
            __url.href,
            '_blank'
        );
    }
    return (
        <>
            <div span={3} style={{ float: 'right' }}>
                <Button
                    border_property={"true"}
                    onClick={() => exportUsers()}
                    text={`Export ${type}${type[type.length - 1] != 's' ? 's' : ''}`}
                    style={{ textTransform: 'capitalize', marginRight: '16px' }}
                    startEnhancer={() => (
                        <img
                            src={svgIcons.DownloadIcon}
                            style={{
                                margin: 0
                            }}
                        />
                    )}
                    endEnhancer={() => (
                        <HelpQuestionMark text={`Download ${type} list`} button />
                    )}
                />
            </div>
        </>
    );
};
export default AddUser;
