import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

const getSubForCategoryUpdate = id => {
  return gql`
    subscription OnCategoryUpdate {
      categoryUpdated(id:"${id}"){
        name
        archived
        published
        position
        public
        created_by_id
        deck_count
        id
        organization_id
        description
        created_at
        layout
        has_data_source_connected
        api_path_id
      }
    }
  `;
};
const getSubForCategoryArchive = id => {
  return gql`
    subscription OnCategoryArchive {
      categoryArchived(id:"${id}"){
        name
        archived
        published
        position
        public
        created_by_id
        deck_count
        id
        organization_id
        description
        created_at
        layout
        has_data_source_connected
        api_path_id
      }
    }
  `;
};

const getSubForCategoryCreate = orgId => {
  return gql`
    subscription OnCategoryCreate {
      categoryCreated(orgId:"${orgId}"){
        category {
          name
          archived
          published
          position
          public
          created_by_id
          deck_count
          id
          organization_id
          description
          created_at
          layout
          has_data_source_connected
          api_path_id
        }
        category_app_content_scope {
          id
          category_id
          position
          published
          app_content_scope_id
          isNew
        }
      }
    }
  `;
};

class SubscribeCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('[SUBSCRIBE] CATEGORY CREATE');
    this.createSub = this.props.subscribeToMore({
      document: getSubForCategoryCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
      updateQuery: (data1, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.categoryCreated
        ) {
          let c = JSON.parse(JSON.stringify(this.props.category || []));
          data1 = JSON.parse(JSON.stringify(data1));
          console.log('[SUBSCRIBE] CATEGORY CREATE NEW EVENT');
          data1.category_app_content_scopes.push({
            ...subscriptionData.data.categoryCreated.category_app_content_scope,
            category: subscriptionData.data.categoryCreated.category,
          });
          // data1.category_app_content_scopes = c;
          return data1;
        }
      },
    });
  }

  
  componentWillUnmount() {
    console.log('[SUBSCRIBE] UNMOUNTED CARD CREATE');
    this.createSub && this.createSub();
  }

  render() {
    // 2. category update
    (this.props.category || []).forEach(el => {
      this.props.subscribeToMore({
        document: getSubForCategoryUpdate(el.category_id),
        variables: { id: el.category_id },
        updateQuery: (data1, { subscriptionData }) => {
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.categoryUpdated
          ) {
            let { categoryUpdated } = subscriptionData.data;
            let c = JSON.parse(JSON.stringify(this.props.category || []));
            data1 = JSON.parse(JSON.stringify(data1));
            let index = c.findIndex(el => el.category_id == categoryUpdated.id);
            c[index] = {
              ...c[index],
              position: categoryUpdated.position,
              published: categoryUpdated.published,
              isNew: null,
              category: {
                ...c[index].category,
                ...categoryUpdated,
              },
            };
            data1.category_app_content_scopes = c;
            return data1;
          }
        },
      });
      this.props.subscribeToMore({
        document: getSubForCategoryArchive(this.props.orgId),
        variables: { orgId: this.props.orgId },
        updateQuery: (data1, { subscriptionData }) => {
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.categoryArchived
          ) {
            let { categoryArchived } = subscriptionData.data;
            let c = JSON.parse(JSON.stringify(this.props.category || []));
            data1 = JSON.parse(JSON.stringify(data1));
            let index = c.findIndex(el => el.category_id == categoryArchived.id);
            c[index] = {
              ...c[index],
              position: categoryArchived.position,
              published: categoryArchived.published,
              isNew: null,
              category: {
                ...c[index].category,
                ...categoryArchived,
                // archived: true
              },
            };
  
            data1.category_app_content_scopes = c;
            return data1;
          }
        },
      });
    });

    return <></>;
  }
}

const mapStateToProps = props => {
  const { category } = props.appBasicControls;
  return { category };
};

export default connect(mapStateToProps)(SubscribeCategory);
