import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { useMutation } from '@apollo/client';
import { Select } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { unArchiveSlideMutation } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';

export default ({ slide, refetchHomeComponents, onClose, dispatch }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  if (isOpen === false) onClose();

  const [
    unArchiveSlide,
    { loading: unArchiveSlideLoader, data: unArchivedSlide },
  ] = useMutation(unArchiveSlideMutation);

  const onSave = () => {
    setIsLoading(true);
    unArchiveSlide({
      variables: {
        id: slide.id,
      },
    });
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (unArchivedSlide) {
      refetchHomeComponents();
      dispatch(
        showAlert({
          msg: 'Successfully unarchived slide!',
          error: false,
        })
      );
      onClose();
    }
  }, [unArchivedSlide]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 100
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>
              Are you sure you want to unarchive this slide -{' '}
              <b style={{ color: 'rgb(118, 95, 237)' }}>{slide.title || ''}?</b>
            </H5>
            <H6>
              This process may take a few moments and all changes are final. If
              you need to move this back, you will be able to do so.
            </H6>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={unArchiveSlideLoader}
            onClick={() => {
              onSave();
            }}
          >
            UnArchive this anyway
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
