import * as React from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { gridPaddingOverrides } from '../overrides';
import { Grid, Cell } from 'baseui/layout-grid';
import RightSidePageHeader from '../pageHeaders';
import FeedbackCard from './feedbackCard';
import useFeedBack from './useFeedBack';

const Feedback = props => {
  const [feedBackPage, setFeedbackPage] = React.useState(1);

  const { feedBacks, loading, hasMore, error, archiveFeedback } = useFeedBack(props.organization_id, feedBackPage, null, setFeedbackPage);
  const observer = React.useRef();

  const lastFeedBackElementRef = React.useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setFeedbackPage(prevFeedBackPage => prevFeedBackPage + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore]);

  return (
    <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
      <RightSidePageHeader
        icon={null}
        title={'Feedback Content'}
        rightContent={null}
        headerDescription={'How people react on your cards?'}
      ></RightSidePageHeader>
      <Cell span={12}>
        <div style={{ marginBottom: '32px' }}>
          <FlexGrid
            flexGridColumnCount={[1, 1, 2, 4]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            {feedBacks && feedBacks.map((item, index) => {
              if (feedBacks.length === index + 1) {
                return <FlexGridItem key={index} >
                  <div ref={lastFeedBackElementRef}>
                    <FeedbackCard feedback={item} archiveFeedback={archiveFeedback}/>
                  </div>
                </FlexGridItem>
              } else {
                return <FlexGridItem key={index}>
                  <div>
                    <FeedbackCard feedback={item} archiveFeedback={archiveFeedback}/>
                  </div>
                </FlexGridItem>
              }

            })}
          </FlexGrid>
        </div>
      </Cell>
    </Grid>
  );
};

export default Feedback;
