import * as React from 'react';
import {
  Modal,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { useStyletron } from 'baseui';
import Delete from 'baseui/icon/delete';

export default ({ isOpen, onClose, children }) => {
  const styletron = useStyletron();
  const theme = styletron[1];
  return <Modal
    onClose={onClose}
    closeable={false}
    isOpen={isOpen}
    animate
    autoFocus
    size={SIZE.default}
    role={ROLE.dialog}
    overrides={{
      Dialog: {
        style: {
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          margin: '0px !important'
        },
      },

      Backdrop: {
        style: ({ $theme }) => ({
          backdropFilter: $theme.modalBackDropFilter,
        }),
      },
      Root: {
        style: 
          () => {
            return {
              width: '100vw',
              height: '100vh',
            }
          }
      },
    }}
  >
    <div style={{
      position: 'absolute',
      margin: '0px !important',
      width: '100vw',
      height: '100vh',
      overflow: 'scroll',
      background: theme.colors.backgroundSecondary
    }}>
      <>
        {/* <div
          style={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            right: '8px',
            top: '8px',
            boxShadow: theme.lighting.shadow400,
            zIndex: 100
          }}
          onClick={onClose}
        >
          <Delete size={36} color={theme.colors.primaryA} 
            style={{
              position: 'absolute',
              top: '50%', left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
          />
        </div> */}
        {children}
      </>
    </div>
  </Modal>
}