import * as React from 'react';
import { connect } from 'react-redux';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import CardList from './cardList';
import { DragDropContext } from 'react-beautiful-dnd';

import { arrayMove } from 'baseui/dnd-list';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import { useUrlParams } from '../help';
import {setCards, setQuestionCards, showAlert} from '../../redux/actions/appBasicControls';

const itemProps = {
  backgroundColor: 'mono300',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const gridPaddingOverrides = {
  Grid: {
    style: ({ $theme }) => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      marginBottom: $theme.sizing.scale550,
    }),
  },
};

const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    }),
  },
};

const App = ({
  deck,
  dispatch,
  categoryId,
  decks,
  orgId,
  cards,
  tabFilter,
  isQuizType,
  questionCards,
  defaultTheme,
  searchText,
  refreshQuizQuestions
}) => {
  const [updateCard] = useMutation(gql`
    mutation updateCard($id: ID!, $position: Int, $deck_id: ID) {
      updateCard(id: $id, position: $position, deck_id: $deck_id) {
        title
      }
    }
  `);

  const [updateQuizCard] = useMutation(gql`
    mutation updateQuizQuestion($id: ID!, $position: Int) {
      updateQuizQuestion(id: $id, position: $position) {
        id
      }
    }
  `);

  const [filtered, setFiltered] = React.useState([]);
  React.useEffect(() => {
    console.log('questionCardsquestionCardsquestionCards', questionCards);
    if (isQuizType) return setFiltered(
      questionCards
        .filter(el => el.quiz_deck_id == deck.deck_id)
        .filter(el => {
          if (!el) return false;
          if (searchText && searchText !== "" && el.quiz_question_data.title.indexOf(searchText) === -1) {
            return false;
          }
          if (tabFilter == 'archived') return el.quiz_question_data.archived == true;
          if (tabFilter == 'published')
            return el.published == true && el.quiz_question_data.archived != true;
          if (tabFilter == 'all') {
            return el.quiz_question_data.archived == false || el.quiz_question_data.archived === null; 
          }
          // return el.quiz_question_data.archived == false;
          return true;
        })
    );
    setFiltered(
      cards
        .filter(el => el.deck_id == deck.deck_id)
        .filter(el => {
          if (searchText && searchText !== "" && el.card.title.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
            return false;
          }
          if (tabFilter == 'archived') return el.card.archived === true;
          if (tabFilter == 'published')
            return el.published == true && el.card.archived !== true;
          if (tabFilter == 'all') return el.card.archived === false;
          return true;
        })
    );
  }, [cards, questionCards]);
  const [css, theme] = useStyletron();
  const params = useUrlParams();


  const updateSortedCardsOnServerAndRedux = (list) => {
    setFiltered(list);
    if (isQuizType) {
      let updatedReduxState = questionCards.map((card) => {
        card = JSON.parse(JSON.stringify(card));
        let id = card.id;
        let index = list.findIndex(el => el.id == id);
        if (index !== -1) {
          card.position = list[index].position;
        };
        return card;
      });
      dispatch(setQuestionCards(updatedReduxState));
    } else {
      let updatedReduxState = cards.map((card) => {
        card = JSON.parse(JSON.stringify(card));
        let id = card.card_id;
        let index = list.findIndex(el => el.card_id == id);
        if (index !== -1) {
          card.position = list[index].position;
        };
        return card;
      });
      dispatch(setCards(updatedReduxState));
    }
    list.forEach(el => {
      if (isQuizType)
        return updateQuizCard({
          variables: {
            id: el.quiz_question_id,
            position: el.position,
          }
        })
      updateCard({
        variables: {
          id: el.card.id,
          position: el.position,
          deck_id: deck.deck_id
        },
      });
    });
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} overrides={cellPaddingOverrides}>
        <div
          id="wrapper"
          // className="wrapper"
          style={{
            marginTop: '-1rem',
          }}
        >
          <FlexGrid
            // flexGridColumnCount={3}
            // flexGridColumnGap="scale800"
            // flexGridRowGap="scale800"
            marginTop={theme.sizing.scale600}
            marginBottom={theme.sizing.scale600}
          >
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                // // dropped outside the list
                if (!destination) {
                  return;
                }
                let newArr = arrayMove(
                  JSON.parse(JSON.stringify(filtered)),
                  source.index,
                  destination.index
                );
                newArr = newArr.map((el, i) => {
                  delete el.isNew;
                  el.position = i;
                  return el;
                });
                updateSortedCardsOnServerAndRedux(newArr);
                dispatch(
                  showAlert({
                    msg: `Successfully moved card!`,
                    error: false,
                  })
                );
              }}
            >
              <CardList
                cards={filtered}
                originalCards={cards}
                questionCards={questionCards}
                decks={decks}
                droppableId={'row1'}
                type={'cardList1'}
                categoryId={categoryId}
                orgId={orgId}
                deck={deck}
                dispatch={dispatch}
                groupId={params.groupId}
                channelId={params.channelId}
                tabFilter={tabFilter}
                isQuizType={isQuizType}
                defaultTheme={defaultTheme}
                refreshQuizQuestions={refreshQuizQuestions}
              />
              {/* <CardList cards={cards} droppableId={"row2"} type={"cardList2"}></CardList> */}
            </DragDropContext>
          </FlexGrid>
        </div>
      </Cell>
    </Grid>
  );
};

const mapStateToProps = props => {
  let { cards, decks, questionCards, theme } = props.appBasicControls;
  return {
    cards,
    decks,
    questionCards,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(App);
