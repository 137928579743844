

export default function prepareDataForEmailPreview(modules) {
    let data = [];
    modules.forEach((el) => {
        var content_module_type = el.__typename;
        switch (content_module_type) {
            case "TextCardModule": {
                data.push({
                    body: el.inputs[0].value,
                    type: el.__typename
                })
                break;
            }
            case "ButtonCardModule": {
                data.push({
                    title: el.inputs[0].value,
                    url: el.inputs[1].value,
                    type: el.__typename
                })
                break;
            }
            case "ImageCardModule": {
                data.push({
                    url: el.inputs[0].value ? URL.createObjectURL(el.inputs[0].value[0]) : null,
                    type: el.__typename
                })
                break;
            }
            case "HeaderImageCardModule": {
                data.push({
                    url: el.inputs[0].value ? URL.createObjectURL(el.inputs[0].value[0]) : null,
                    type: el.__typename
                })
                break;
            }
            case "YoutubeCardModule": {
                data.push({
                    type: el.inputs[0].value,
                    youtube_video_id: el.inputs[1].value,
                    cover_image: el.inputs[2].value ? URL.createObjectURL(el.inputs[2].value[0]) : null,
                    type: el.__typename
                })
                break;
            }
            case "AttachmentCardModule": {
                data.push({
                    attachments: el.inputs[0].value.map((el) => {
                        return { url: el.file ? URL.createObjectURL(el.file) : null }
                    }),
                    type: el.__typename
                })
                break;
            }
            case "ImageGalleryCardModule": {
                data.push({
                    gallery: el.inputs[0].value.map((el) => {
                        return { url: el.file ? URL.createObjectURL(el.file) : null }
                    }),
                    type: el.__typename
                })
                break;
            }
            default:
                return null;
        }
    });
    return data;
}