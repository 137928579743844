import React from 'react';
import styled from '@emotion/styled';
import { useStyletron } from 'baseui';
import PropTypes from 'prop-types';

const CardBackItemContainer = styled.div`
  margin-top: 4%;
  width: 100%;
`;

const ActionBtnItemContainer = styled.div`
  width: 100%;
  position: relative;
  height: 29px;
`;

const CardBackItemInnerContainer = styled.div`
  width: 100%;
  background: white;
  padding: 19px;
  border-radius: 3px;
`;

const ActionBtnImageSvg = styled.svg`
  position: relative;
  float: right;
  margin-right: 18px;
  cursor: pointer;
`;

const ActionButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-90%);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;



const CardBackFloatingListItem = React.memo((props) => {

  const {
    onMoveUpClick,
    onMoveDownClick,
    onDeleteClick,
    id,
    key
  } = props || {};

  const [css, theme] = useStyletron();

  const renderUpButton = () => {
    return (
      <ActionBtnImageSvg
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onMoveUpClick}
      >
        <path
          d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
          fill={theme.colors.mono100}
        />
      </ActionBtnImageSvg >
    )
  }

  const renderDowmButton = () => {
    return (
      <ActionBtnImageSvg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onMoveDownClick}
      >
        <path
          d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
          fill={theme.colors.mono100}
        />
      </ActionBtnImageSvg>
    )
  }

  const renderDeleteButton = () => {
    return (
      <ActionBtnImageSvg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onDeleteClick}
      >
        <path
          d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
          fill={theme.colors.mono100}
        />
      </ActionBtnImageSvg>
    );
  }
  return (
    <CardBackItemContainer
      id={id}
      key={key}
    >
      <ActionBtnItemContainer>
        <ActionButtonContainer>
          {renderDowmButton()}
          {renderUpButton()}
          {renderDeleteButton()}
        </ActionButtonContainer>
      </ActionBtnItemContainer>
      <CardBackItemInnerContainer>
        {props.children}
      </CardBackItemInnerContainer>
    </CardBackItemContainer>
  );
})

CardBackFloatingListItem.propTypes = {
  onMoveUpClick: PropTypes.func,
  onMoveDownClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  id: PropTypes.string,
  key: PropTypes.string,
}

CardBackFloatingListItem.defaultProps = {
  onMoveUpClick: () => {},
  onMoveDownClick: () => {},
  onDeleteClick: () => {},
  id: '',
  key: '',
}

export default CardBackFloatingListItem;