import * as React from 'react';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import ProgressBar  from './infiniteProgressBar';

const SearchUsers = ({ searchText, setSearchText, isDataLoading }) => {
  return (
    <div span={4} style={{ float: 'left', width: '35%', position: 'relative' }}>
      <Input
        value={searchText}
        onChange={e => setSearchText(e.target.value.toLowerCase())}
        placeholder="Search by name or email"
        clearOnEscape
        clearable
        endEnhancer={<Search size="25px" color='#9C9C9C' />}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              outline: `none`,
              borderRadius: "45px",
              maxWidth: '269px',
              background: 'rgb(234, 234, 234)',
            })
          },
          Input: {
            style: ({ $theme }) => ({
              fontSize: "14px",
              background: 'rgb(234, 234, 234)',
            })
          },
          EndEnhancer: {
            style: ({ $theme }) => ({
              background: 'rgb(234, 234, 234)',
            })
          },
        }}
      />
      {isDataLoading && <ProgressBar/>}
    </div>
  );
};

export default SearchUsers;
