import { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

export const useUrlParams = () => {
  const location = useLocation();
  const [orgId, setOrgId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [deckId, setDeckId] = useState(null);
  const [cardId, setCardId] = useState(null);

  const url = location.pathname;
  const orgRegExp = /app\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const groupRegExp = /groups\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const channelRegExp = /channels\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const deckRegExp = /deck\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const cardRegExp = /card\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

  useEffect(() => {
    const orgMatch = url.match(orgRegExp);
    if (orgMatch) {
      setOrgId(orgMatch[0].replace('app/', ''));
    } else {
      setOrgId(null);
    }
    const groupMatch = url.match(groupRegExp);
    if (groupMatch) {
      setGroupId(groupMatch[0].replace('groups/', ''));
    } else {
      setGroupId(null);
    }
    const channelMatch = url.match(channelRegExp);
    if (channelMatch) {
      setChannelId(channelMatch[0].replace('channels/', ''));
    } else {
      setChannelId(null);
    }
    const deckMatch = url.match(deckRegExp);
    if (deckMatch) {
      setDeckId(deckMatch[0].replace('deck/', ''));
    } else {
      setDeckId(null);
    }
    const cardMatch = url.match(cardRegExp);
    if (cardMatch) {
      setCardId(cardMatch[0].replace('card/', ''));
    } else {
      setCardId(null);
    }
  }, [url]);

  return { orgId, groupId, channelId, deckId, cardId };
};
