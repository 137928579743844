import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { addCategoryMutation, updateCategoryMutation, archiveCategoryMutation } from '../../quries';
import TextArea from '../channel/textarea';
import { Select } from 'baseui/select';
import { Notification, KIND } from "baseui/notification";
import ModalStateContainer from '../modalStateContainer';
import { apiQuery } from '../apiMappings/query';
import titlesLength from '../../../data/titlesLength.json';
import store from '../../redux/store';
import { setCreatedCategory } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';
import CategoryApiMapping from '../apiMappings/category-mapping';
import { FileUploader } from "baseui/file-uploader";
import { getServerUrl } from '../../apollo/client';
import axios from 'axios';

const dispatch = store.dispatch;

const deckStyleOptions = [
  { label: 'Legacy', id: 'legacy' },
  { label: 'V1', id: 'v1' },
]

export default ({
  category,
  position,
  app_content_scope_id,
  organization_id,
  onClose,
  refetch
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isDelete, setIsDelete] = React.useState(false)
  const [name, setName] = React.useState(category.name || '');
  const [description, setDescription] = React.useState(
    category.description || ''
  );
  const [publicS, setPublic] = React.useState(category.public || false);
  const [published, setPublished] = React.useState(category.published || false);
  const [layout, setLayout] = React.useState(category.layout || 'horizontal');
  const [deckStyle, setDeckStyle] = React.useState(category.deck_style || 'legacy');
  const [isLoading, setIsLoading] = React.useState(false);
  const [nameRequiredNotification, setNameRequiredNotification] = React.useState(false);
  const id = category.id;
  const categoryAlreadyArchived = category.archived;
  const [isMappingOpen, setIsMappingOpen] = React.useState(false);
  const [has_data_source_connected, set_has_data_source_connected] = React.useState(category.has_data_source_connected || false);
  const [api_path_id, set_api_path_id] = React.useState(category.api_path_id || null);
  const [paths, setPaths] = React.useState([]);
  const { is_api_settings_on } = store.getState().appBasicControls.organization_settings;

  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);

  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
    });
  }, []);


  const [
    updateCategory,
    { loading: onUpdateLoader, data: updatedChannel },
  ] = useMutation(updateCategoryMutation);
  const [
    archiveCategory,
    { loading: onArchiveLoader, data: archivedChannel },
  ] = useMutation(archiveCategoryMutation);
  const [
    addCategory,
    { loading: onCreateLoader, data: createdChannel },
  ] = useMutation(addCategoryMutation);

  React.useEffect(() => {
    setIsLoading(false);
    if (updatedChannel || createdChannel) {
      setIsOpen(false);
      setIsDelete(false);
      onClose();
    }
  }, [createdChannel, updatedChannel]);

  React.useEffect(() => {
    setIsLoading(false);
    if (archivedChannel) {
      setIsOpen(false);
      setIsDelete(false);
      onClose();
      refetch();
    }
  }, [archivedChannel]);

  const onDelete = () => {
    setIsDelete(true)
  }

  const onArchive = (status) => {
    // if status true means archived otherwise un archived
    if (id) {
      setIsLoading(true);
      archiveCategory({
        variables: {
          id,
          status
        },
      });
    }
  }

  const onSave = () => {
    if (name == '') {
      setNameRequiredNotification(true);
      return;
    };
    setIsLoading(true);
    if (id) {
      updateCategory({
        variables: {
          id,
          name,
          public: publicS,
          description,
          published,
          layout,
          has_data_source_connected, api_path_id,
          deck_style: deckStyle
        },
      });
    } else {
      addCategory({
        variables: {
          name,
          app_content_scope_id: app_content_scope_id,
          position: position,
          created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
          organization_id,
          public: true,
          description: description,
          archived: false,
          published: false,
          layout,
          deck_style: deckStyle
        },
      }).then(({ data }) => {
        if (data) {
          const channelData = { ...data.createCategory };
          dispatch(setCreatedCategory(channelData));
        }
      });
    }
  };

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
    <>
      <Modal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
        }}
      >
        <ModalHeader>{'Import from CSV'}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportFile(acceptedFiles[0])

            }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importFile}
            onClick={() => {
              setImportLoading(true);
              const formData = new FormData();
              formData.append('file', importFile);
              formData.append('organization_id', organization_id);
              formData.append('category_id', id);
              axios.post(`${getServerUrl()}/import_card_list_with_deck`, formData)
                .then((result) => {
                  console.log(result);
                  setImportLoading(false);
                  setIsImportModalOpen(false);
                  onClose();
                })
            }}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
      <ModalStateContainer>
        {({ isConfirmationOpen, toggleConfirm }) => (
          <React.Fragment><Modal
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              }
            }}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setIsOpen(false);
            }}
            autoFocus={false}
          >
            <ModalHeader>{'Category Information'}</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                  <Input
                    value={name}
                    onChange={e => {
                      if (e.target.value.length <= titlesLength.category)
                        setName(e.target.value);
                      nameRequiredNotification && setNameRequiredNotification(false);
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        onSave();
                      }
                      return true;
                    }}
                    placeholder="Your title"
                    clearOnEscape
                  />
                  <ParagraphSmall style={{ marginBottom: '0px' }}>{name.length}/{titlesLength.category}</ParagraphSmall>
                  {nameRequiredNotification && <Notification kind={KIND.negative} closeable overrides={{
                    Body: {
                      style: ({ $theme }) => ({
                        width: '100% !important',
                      }),
                    }
                  }}>{() => "Category name is required."}</Notification>}
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                  <div style={{ position: 'relative' }}>
                    <TextArea
                      value={description}
                      onChange={setDescription}
                      textAreaKey="editCategory"
                      placeholder="Your description"
                    ></TextArea>
                  </div>
                </Cell>
                {id && (
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Layout</LabelMedium>
                    <Select
                      options={[
                        { label: 'Vertical', id: 'vertical' },
                        { label: 'Horizontal', id: 'horizontal' },
                      ]}
                      value={[
                        { label: 'Vertical', id: 'vertical' },
                        { label: 'Horizontal', id: 'horizontal' },
                      ].filter(el => el.id == layout)}
                      labelKey="label"
                      valueKey="id"
                      placeholder=''
                      onChange={({ value }) => setLayout(value[0].id)}
                    />
                  </Cell>
                )}

                {id && (
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Deck Style</LabelMedium>
                    <Select
                      options={deckStyleOptions}
                      value={deckStyleOptions.filter(el => el.id == deckStyle)}
                      labelKey="label"
                      valueKey="id"
                      placeholder=''
                      onChange={({ value }) => setDeckStyle(value[0].id)}
                    />
                  </Cell>
                )}

                {id && <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      checked={publicS}
                      onChange={e => setPublic(!publicS)}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Public</ParagraphSmall>
                    </Checkbox>
                    <div style={{ marginLeft: '16px' }}>
                      <Checkbox
                        checked={published}
                        onChange={e => setPublished(!published)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Published</ParagraphSmall>
                      </Checkbox>
                    </div>
                    {is_api_settings_on && <div style={{ marginLeft: '16px' }}>
                      <Checkbox
                        checked={has_data_source_connected}
                        onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Has Data Source</ParagraphSmall>
                      </Checkbox>
                    </div>}
                  </div>
                </Cell>}


                {is_api_settings_on && has_data_source_connected && <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px', marginBottom: '8px', }}>Api Path</LabelMedium>
                  <Select
                    options={paths}
                    value={paths.filter(el => el.id == api_path_id)}
                    labelKey="name"
                    valueKey="id"
                    placeholder=''
                    onChange={({ option }) => {
                      if (option)
                        set_api_path_id(option.id);
                      else
                        set_api_path_id(null);
                    }}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />
                  <LabelMedium style={{ marginTop: '8px', cursor: 'pointer' }}>
                    <a onClick={() => {
                      setIsMappingOpen(true);
                    }}>Map Category data with Api &#8594;</a>
                  </LabelMedium>
                </Cell>}
              </Grid>
            </ModalBody>
            <ModalFooter>
              {id && <ModalButton
                onClick={() => setIsImportModalOpen(true)}
                kind="tertiary"
              >
                {'Import'}
              </ModalButton>}
              {id && <ModalButton
                onClick={() => toggleConfirm(true)}
              >
                {categoryAlreadyArchived ? 'Restore Category' : 'Archive Category'}
              </ModalButton>}
              <ModalButton
                onClick={() => {
                  onSave();
                }}
                isLoading={isLoading}
              >
                {id ? 'Update' : 'Add'} Category
              </ModalButton>
            </ModalFooter>
          </Modal>
            {!categoryAlreadyArchived && <Modal
              onClose={() => toggleConfirm(false)}
              isOpen={isConfirmationOpen}
            >
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody>Are you sure you want to archive this category? Don't worry, this can be undone at any time</ModalBody>
              <ModalFooter>
                <ModalButton
                  kind="tertiary"
                  onClick={() => toggleConfirm(false)}
                  disabled={isLoading}
                >
                  No
                </ModalButton>
                <ModalButton
                  onClick={() => {
                    onArchive(true);
                  }}
                  isLoading={isLoading}
                >
                  Yes
                </ModalButton>
              </ModalFooter>
            </Modal>}
            {categoryAlreadyArchived && <Modal
              onClose={() => toggleConfirm(false)}
              isOpen={isConfirmationOpen}
            >
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody>Are you sure you want to un archive this category?</ModalBody>
              <ModalFooter>
                <ModalButton
                  kind="tertiary"
                  onClick={() => toggleConfirm(false)}
                >
                  No
                </ModalButton>
                <ModalButton
                  onClick={() => {
                    onArchive(false);
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Yes
                </ModalButton>
              </ModalFooter>
            </Modal>}
          </React.Fragment>
        )}
      </ModalStateContainer>

      <Modal
        isOpen={id && isMappingOpen}
        onClose={() => {
          setIsMappingOpen(false);
        }}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Dialog: {
            style: {
              width: '80vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
            },
          }
        }}
      >
        <div style={{
          paddingTop: '32px',
          paddingBottom: '32px'
        }}>
          <CategoryApiMapping
            category_id={id}
            organization_id={organization_id}
            paths={paths}
            api_path_id={api_path_id}
          />
        </div>
      </Modal>
    </>
  );
};
