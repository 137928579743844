import * as React from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
// import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { Skeleton } from 'baseui/skeleton'
// import Button from '../styledButton';
import DataCard from './card';
import GraphCard from './graph-card';
// import GenCSV from '../../util/GenCSV';
// import { client as queryServerClient, getServerUrl } from '../../apollo/client';
// import RightSidePageHeader from '../pageHeaders';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides, gridPaddingMarginOverrides } from '../overrides';
// import useFeedBack from '../feedback/useFeedBack';
import moment from 'moment';
import gql from 'graphql-tag';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { client as reactClient } from '../../apollo/client';
import store from '../../redux/store';
import { groupDataByTP } from './utils';
import { quizzes_by_time_help, quizzes_help, top_performing_users_help, top_quiz_decks_help, users_count_quiz_decks_help } from '../help/helpTexts';

const cardArray = [
  {
    title: 'Quizzes',
    value: '0',
    helpText: quizzes_help,
    description: 'Total quiz taken - Until today {{cValue}} since previous month',
    dataProcessor: (result) => {
      result = result.data.count_by_table_name;
      let thisMonthUser = result.data;
      return {
        value: result.total,
        cValue: thisMonthUser
      };
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push([title, processedData.value]);
      return csvArray;
    },
    query: gql`
      query application_installed($testers: [String] $include_testers: Boolean) {
        count_by_table_name (
          table_name: "quizzes"
          has_date_range: true
          start_date: "${moment().startOf('month').toDate().toISOString()}"
          end_date: "${moment().toDate().toISOString()}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          total
          error
          status
        }
      }
    `,
  }
];

const graphCardArray = [
  {
    title: 'Top Performing Users',
    type: 'vertical-bar',
    helpText: top_performing_users_help,
    width: '100%',
    query: gql`
      query category_app_content_scopes_view($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "quizzes"
          group_by_col: "app_user_id"
          distinct_col: "app_user_id"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab, users) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        // // console.log('________________________________________________________________');
        // // console.log(d, users);
        d = d.map(el => {
          let u = users.find(u => u.id === el.app_user_id);
          if (!u) return false;
          return {
            y: 1 * el.count,
            _x: u.name,
            x: u.name
          }
        }).filter(el => el);
        data = d;
        data = data.sort((a, b) => b.y - a.y);
        // // console.log(data);
      } catch (e) {
        // // console.log(e);
      }
      return data.slice(0, 20);
    },
  },
  {
    title: 'Top Quiz Decks',
    type: 'vertical-bar',
    helpText: top_quiz_decks_help,
    width: '100%',
    query: gql`
      query category_app_content_scopes_view($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "quizzes"
          group_by_col: "quiz_deck_id"
          distinct_col: "quiz_deck_id"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      let decks = store.getState().appBasicControls.decks;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          let _deck = decks.find(el1 => el1.deck_id == el.quiz_deck_id);
          return {
            y: 1 * el.count,
            _x: _deck ? _deck.deck_category_deck.title : 'Archived Quiz Deck',
            x: _deck ? _deck.deck_category_deck.title : 'Archived Quiz Deck'
          }
        });
        data = d;
        // console.log(data);
        data = data.sort((a, b) => b.y - a.y).filter(el => el.x != 'NA');
      } catch (e) {
        // console.log(e);
      }
      data = data.slice(0, 20);
      return data;
    },
  },
  {
    title: 'Quizzes by Time',
    type: 'spline',
    helpText: quizzes_by_time_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => {
      return gql`
      query application_openeds($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "quizzes"
          group_by_col: "created_at"
          distinct_col: "id"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `;
    },
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    }
  },
  {
    title: 'Users count by Quiz Decks',
    type: 'spline',
    helpText: users_count_quiz_decks_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => {
      return gql`
      query application_openeds($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "quiz_question_responses"
          group_by_col: "quiz_deck_id"
          distinct_col: "app_user_id"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `;
    },
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      let decks = store.getState().appBasicControls.decks;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          let _deck = decks.find(el1 => el1.deck_id == el.quiz_deck_id);
          return {
            y: 1 * el.count,
            _x: _deck ? _deck.deck_category_deck.title : 'Archived Quiz Deck',
            x: _deck ? _deck.deck_category_deck.title : 'Archived Quiz Deck'
          }
        });
        data = d;
        // // console.log(data);
        // data = data.sort((a, b) => b.y - a.y).filter(el => el.x != 'NA');
      } catch (e) {
        // console.log(e);
      }
      return data;
    }
  }
];

const Dashboard = props => {
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [testers, setTesters] = React.useState([]);
  const [lastUpdate, setLastUpdate] = React.useState(new Date());
  const [users, setUsers] = React.useState([]);

  const LoadingBar = () => {
    return (
      <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {new Array(3).fill('').map(item => <Skeleton
            width="500px"
            height="145px"
            animation
            overrides={{
              Root: {
                style: {
                  marginRight: '60px'
                },
              },
            }}
          />)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '25px'
                  },
                },
              }}
            />)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '10px',
                    borderRadius: '48px'
                  },
                },
              }}
            />)}
          </div>
        </div>
        <div style={{ marginTop: '2rem' }}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
            />))}
        </div>
        <div style={{ float: 'right' }}>
          <Skeleton
            width="500px"
            height="40px"
            animation
          />
        </div>
      </div>
    )
  };

  React.useEffect(() => {
    reactClient.query({
      query: gql`
            query getLastUpdatedAt {
                getLastUpdatedAt
                fetch_user_names_by_ids {
                  id
                  name
                }
            }
      `
    }).then((result) => {
      setLastUpdate(result.data.getLastUpdatedAt);
      setUsers(result.data.fetch_user_names_by_ids);
    })
  }, []);

  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      {/* <RightSidePageHeader
        icon={null}
        title={'Learning Data'}
        rightContent={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 1 }}
            >
              <Button
                onClick={() => {
                  props.setActiveKey('0')
                }}
              >
                Application Metrics
              </Button>
              <Button
                onClick={() => {
                  props.setActiveKey('1')
                }}
              >
                Learning Metrics
              </Button>
              <Button
                onClick={() => {
                  props.setActiveKey('2')
                }}
              >
                Quiz Metrics
              </Button>
            </StatefulButtonGroup>
          </div>
        }
        headerDescription={`Overview of Cardware Learning Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`}
      /> */}
      {loading ? <LoadingBar /> : (
        <>
          <Cell span={12}>
            <FlexGrid
              flexGridColumnCount={[1, 1, 2, 4]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800"
            >
              {cardArray.map((item, index) => (
                <FlexGridItem key={index}>
                  <DataCard {...item} client={reactClient} include_testers={checked} testers={testers} />
                </FlexGridItem>
              ))}
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={[1, 1, 1, 1]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800"
              marginBottom="scale800"
              marginTop="scale800"
            >
              {graphCardArray.map((item, index) => (
                <FlexGridItem key={index} overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      width: item.width ? `${item.width} !important` : '100%',
                      ...item.width ? {
                        ... (index % 2 == 0) ? {
                          marginLeft: '1% !important'
                        } : {
                            marginRight: '1% !important'
                          }
                      } : {}
                    })
                  }
                }}>
                  <GraphCard {...item} client={reactClient} include_testers={checked} testers={testers} users={users}/>
                </FlexGridItem>
              ))}
            </FlexGrid>
          </Cell>
        </>
      )}

    </Grid>
  );
};

export default Dashboard;
