import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import RightSidePageHeader from '../pageHeaders';
import { sourceByType } from '../../util';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import UserComponent from '../admin/user';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";
import { forgotPassword } from '../../auth/index';
import { client } from '../../apollo/client';
import { Tabs, Tab } from "baseui/tabs";
import AdminComponent from '../admin/index'
import {
  app_users as app_users_query,
  app_users_archived,
  createUserWithChannels as createUserWithChannelsMutation,
  updateUserWithChannels as updateUserWithChannelsMutation,
  archiveAppUserFromOrganizationMutation,
  sendInviteMutation,
  getActivationLink,
  forgotPasswordMutation
} from '../../quries';
import { tableColumnKeys } from '../admin/userCard';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const TAB_STATUS = {
  ALL: 'all',
  LIVE: 'pending',
  ARCHIVE: 'archived',
};

const tableColumns = [
  {
    name: '',
    dataKey: '',
    key: tableColumnKeys.USER_PROFILE,
    span: 1
  },
  {
    name: 'Name',
    dataKey: 'name',
    key: tableColumnKeys.USER_NAME,
    span: 2
  },
  {
    name: 'Email',
    dataKey: 'email',
    key: tableColumnKeys.USER_EMAIL,
    span: 3
  },
  {
    name: 'Role',
    dataKey: 'is_converted_to_cardware',
    key: tableColumnKeys.USER_CARDWARE_CONVERTED,
    span: 2
  },
  {
    name: 'Active',
    dataKey: 'approved',
    key: tableColumnKeys.USER_ACTIVE,
    span: 2
  },
  {
    name: 'Settings',
    dataKey: '',
    key: tableColumnKeys.USER_SETTINGS,
    span: 1
  },
  {
    name: 'Actions',
    dataKey: '',
    key: tableColumnKeys.USER_ACTIONS,
    span: 1
  }
]
const UserManage = ({ organization_id, channels, defaultTheme }) => {
  const [tab, setTab] = React.useState(TAB_STATUS.ALL);
  const [activeKey, setActiveKey] = React.useState("0");
  const [listActionInProgress, setListActionInProgress] = React.useState(false);
  const [_t, setT] = React.useState(new Date().getTime());

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const refetchUsers = () => setT(new Date().getTime());

  const listMenuOptions = [
    {
      id: 'remind',
      display: 'Remind',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.is_connected_with_admin) {
          showToaster("This user is Admin user. Make updates from Admin page");
          return;
        };
        setListActionInProgress(true);
        showToaster("Sending Invite. Please Wait...");
        client.mutate({
          mutation: sendInviteMutation,
          variables: {
            organization_id,
            userId: user.id,
            domain: window.location.origin,
            isAdmin: false
          }
        }).then(() => {
          showToaster("Invite Sent");
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'archive',
      display: 'Archive',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.is_connected_with_admin) {
          showToaster("This user is Admin user. Make updates from Admin page");
          return;
        };
        setListActionInProgress(true);
        showToaster("Arching User. Please Wait...");
        client.mutate({
          mutation: archiveAppUserFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: true
          }
        }).then(() => {
          showToaster("User Archived");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'password-reset',
      display: 'Password Reset',
      onClick: async (user) => {
        let message;
        let method;
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.is_connected_with_admin) {
          showToaster("This user is Admin user. Make updates from Admin page");
          return;
        };
        setListActionInProgress(true);
        showToaster("Sending email to password reset");
        // try {
        //   message = await forgotPassword(organization_id, user.email);
        //   method = 'info';
        // } catch (err) { message = err; method = 'negative' }
        // showToaster(message, method);
        // setListActionInProgress(false);
        client.mutate({
          mutation: forgotPasswordMutation,
          variables: {
            email: user.email,
            domain: window.location.origin
          }
        })
          .then((response) => {
            let message = response.data.forgotPassword.data;
            console.log(response);
            setListActionInProgress(false);
            showToaster(message, method);
          });
      }
    },
    {
      id: 'copy-activation-link',
      display: 'Get Activation Link',
      onClick: async (user) => {
        let message;
        let method;
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.approved) {
          showToaster("User is already activated");
          return;
        };
        setListActionInProgress(true);
        showToaster("Getting activation link");
        // try {
        //   message = await forgotPassword(organization_id, user.email);
        //   method = 'info';
        // } catch (err) { message = err; method = 'negative' }
        // showToaster(message, method);
        // setListActionInProgress(false);
        client.query({
          query: getActivationLink,
          variables: {
            organization_id,
            user_id: user.id,
            isAdmin: false,
            domain: window.location.origin
          }
        })
          .then((response) => {
            let message = 'URL is copied to clipboard';
            console.log(response.data.getUserActivationUrl);
            try {
              navigator.clipboard.writeText(response.data.getUserActivationUrl);
            } catch (e) {
              console.log(e);
            }
            setListActionInProgress(false);
            showToaster(message);
          });
      }
    }
  ];

  const Divider = () => {
    return (
      <span style={{width: '100%', height: '2px', background: '#EAEAEA', marginBottom: '1rem'}}></span>
    )
  }
  const listMenuOptionsArchived = [
    {
      id: 'archive',
      display: 'Unarchive',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.is_connected_with_admin) {
          showToaster("This user is Admin user. Make updates from Admin page");
          return;
        };
        setListActionInProgress(true);
        showToaster("UnArching User. Please Wait...");
        client.mutate({
          mutation: archiveAppUserFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: false
          }
        }).then(() => {
          showToaster("User UnArchived");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'delete',
      display: 'Delete',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.is_connected_with_admin) {
          showToaster("This user is Admin user. Make updates from Admin page");
          return;
        };
        setListActionInProgress(true);
        showToaster("Deleting User. Please Wait...");
        client.mutate({
          mutation: archiveAppUserFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: false,
            isPermanent: true
          }
        }).then(() => {
          showToaster("User Deleted");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    }
  ]

  return (
    <Tabs
      onChange={({ activeKey }) => {
        setActiveKey(activeKey);
      }}
      activeKey={activeKey}
      activateOnFocus
      overrides={{
        TabContent: {
          style: ({ $theme }) => ({
            padding: 0,
            paddingTop: 0
          })
        },
        Root: {
          style: ({ $theme }) => ({
            padding: 0,
          })
        },
        Tab: {
          style: ({ $theme }) => ({
            fontSize: '16px',
            fontFamily: 'Lexend',
            fontWeight: 600,
          })
        },
      }}
    >
      <Tab title="Users">
        <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
          {/* <RightSidePageHeader
            icon={null}
            title={'Users'}
            headerDescription={'Manage App Users Here'}
            rightContent={
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                <Button onClick={() => setTab(TAB_STATUS.ALL)}>All</Button>
                <Button onClick={() => setTab(TAB_STATUS.LIVE)}>Pending</Button>
                <Button onClick={() => setTab(TAB_STATUS.ARCHIVE)}>
                  Archives
                </Button>
              </StatefulButtonGroup>
            }
          /> */}
          <Divider />
          <Cell span={12} overrides={cellPaddingOverrides}>
            <UserComponent
              columns={tableColumns}
              tabFilter={tab}
              setTab={setTab}
              showFilter={{
                cardware_converted: true
              }}
              channels={channels} s
              organization_id={organization_id}
              defaultTheme={defaultTheme}
              type={'app users'}
              listMenuOptions={tab === TAB_STATUS.ARCHIVE ? listMenuOptionsArchived : listMenuOptions}
              addUserMutation={createUserWithChannelsMutation}
              updateUserMutation={updateUserWithChannelsMutation}

              showToaster={showToaster}
              _t={_t}
            />
          </Cell>
        </Grid>
      </Tab>
      <Tab title="Admin">
        <Divider />
        <AdminComponent organization_id={organization_id} /></Tab>
    </Tabs>
  );
};

const mapStateToProps = props => {
  const { channels, theme } = props.appBasicControls;
  return {
    channels,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(UserManage);
