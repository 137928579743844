import React from 'react';
import { navigate } from 'gatsby';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H2 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { login } from '../auth/index';
import { organizations } from '../quries';
import { useQuery } from '@apollo/client';
import { Spinner } from 'baseui/spinner';
import { Select } from 'baseui/select';
import {
  LabelMedium,
  LabelSmall,
  HeadingXSmall,
  LabelXSmall,
  HeadingXLarge,
  ParagraphSmall,
} from 'baseui/typography';
import gql from 'graphql-tag';
import { Router } from '@reach/router';

export default function Home({ _subDomain }) {
  console.log(_subDomain);
  const [css, theme] = useStyletron();
  const [noOrg, setNoOrg] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const subDomain = window.location.host.includes('.')
    ? window.location.host.split('.')[0]
    : '';
  console.log(subDomain);
  const { data, loading } = useQuery(
    gql`
        query domain {
            organization_domain_by_name(domain: "${subDomain}"){
                organization {
                  name
                  id
                  auth0 {
                    domain
                    client_id
                  }
                }
            }
        }
    `,
    { fetchPolicy: 'network-only' }
  );
  console.log(data);
  const { data: domains, loading: domainsLoading } = useQuery(gql`
        query domains{
            organization_domains_by_email(email: "${email}"){
                domain
              }
        }
    `);
  console.log(domains);
  const loginWithAuth0 = () => {
    localStorage.setItem(
      `CARDWARE_ADMIN_${subDomain}`,
      JSON.stringify(data.organization_domain_by_name.organization)
    );
    login();
  };

  React.useEffect(() => {
    if (data && !data.organization_domain_by_name) {
      setNoOrg(true);
    }
  }, [data]);

  const style = {
    color: 'teal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    color: 'black',
  };
  const formStyle = {
    borderRadius: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height: '200px',
    width: '330px',
    background: theme.colors.primaryB,
  };
  console.log(subDomain);
  const [domain, setDomain] = React.useState('');
  const [emailInput, setEmailInput] = React.useState('');
  if (noOrg && subDomain) {
    return (
      <div
        className={css({
          position: 'absolute',
          left: '50%',
          top: '20%',
          transform: 'translate(-50%, -50%)',
        })}
      >
        <HeadingXLarge>No Organization Found!</HeadingXLarge>
      </div>
    );
  }

  if (noOrg) {
    return (
      <div
        className={css({
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        })}
      >
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Enter Domain of your Organization
          </LabelMedium>
          <Input
            value={domain}
            endEnhancer={window.location.host}
            onChange={e => setDomain(e.target.value)}
            clearOnEscape
          />
          <Button
            style={{ marginTop: '16px' }}
            onClick={() => {
              if (domain) {
                navigate(
                  `${window.location.protocol}//${domain}.${window.location.host}`
                );
              }
            }}
          >
            Lets Go
          </Button>
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Enter Email lets us fetch Organizations
          </LabelMedium>
          <Input
            value={emailInput}
            onChange={e => setEmailInput(e.target.value)}
            clearOnEscape
          />
          <Button
            isLoading={domainsLoading}
            style={{ marginTop: '16px' }}
            onClick={() => {
              setEmail(emailInput);
            }}
          >
            Find
          </Button>
          <br />
          {domains &&
            domains.organization_domains_by_email &&
            domains.organization_domains_by_email.map(el => {
              return (
                <Button
                  style={{ marginTop: '16px' }}
                  onClick={() => {
                    navigate(
                      `${window.location.protocol}//${el.domain}.${window.location.host}`
                    );
                  }}
                >
                  {' '}
                  Goto {el.domain}
                </Button>
              );
            })}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        className={css({
          position: 'absolute',
          width: theme.sizing.scale1200,
          height: theme.sizing.scale1200,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        })}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div style={style}>
      <div style={formStyle}>
        <H2 style={{ padding: '1rem', margin: 0 }}>Welcome back!</H2>
        <Grid
          overrides={{
            Grid: {
              style: () => ({
                padding: '15px !important',
              }),
            },
          }}
        >
          <div
            className={css({
              width: '100%',
            })}
          >
            <Cell
              align="center"
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                    marginTop: '1rem',
                  }),
                },
              }}
            >
              <div
                className={css({
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                })}
              >
                <Button onClick={() => loginWithAuth0()}>
                  Login with Auth0
                </Button>
              </div>
              {/* <Button style={{ float: 'right', marginRight: '16px'}} onClick={() => loginEmailPass("click")}>Login</Button> */}
            </Cell>
          </div>
        </Grid>
      </div>
    </div>
  );
}
