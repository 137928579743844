import * as React from 'react';
import { connect } from 'react-redux';
import {
  H3,
  H5,
  LabelMedium,
  LabelXSmall,
  ParagraphLarge,
} from 'baseui/typography';
import _ from 'lodash';
import Button from 'baseui/button';
import { useStyletron } from 'baseui';
import Gallery from 'react-grid-gallery';
import ReactHtmlParser from 'react-html-parser';
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';

import store from '../../redux/store';
import './preview.css';
import { sourceByType } from '../../util';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import ReactAudioPlayer from 'react-audio-player';
import {createEditorCardsFromCardModules} from  './builderUtils/helpers';

const getSrc = (data, type) => {
  try {
    if (!data) return data;
    return sourceByType(data, type);
  } catch (e) {
    return null;
  }
};

const getFileName = data => {
  try {
    if (!data) return '';
    return JSON.parse(data).filename || JSON.parse(data).full.metadata.filename;
  } catch (e) {
    return '';
  }
};

const GetModuleStructure = ({ cmodule, isLast }) => {
  const [css, theme] = useStyletron();
  const B = require('baseui/button').Button;
  let component = null;
  //
  //
  switch (cmodule.__typename) {
    case 'TextCardModule':
      //
      component = (
        <ParagraphLarge className={css({
          
        })}>
          {ReactHtmlParser(cmodule.inputs[0].value)}
        </ParagraphLarge>
      );
      break;
    case 'AudioCardModule':
      component = cmodule.inputs[0].value.map((el, i) => {
        return (
          <div key={`AudioCardModule${i}`}>
            <ReactAudioPlayer
              src={
                el.file_data
                  ? getSrc(el.file_data)
                  : URL.createObjectURL(el.file)
              }
              autoPlay={false}
              controls
            />
          </div>
        );
      });
      break;
    case 'AttachmentCardModule':
      component = cmodule.inputs[0].value.map((el, i) => {
        return (
          <div key={`AttachmentCardModule${i}`}>
            <B>
              <a
                href={
                  el.file_data
                    ? getSrc(el.file_data)
                    : URL.createObjectURL(el.file)
                }
                target="_blank"
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {el.file_data ? getFileName(el.file_data) : el.file.name}
              </a>
            </B>
          </div>
        );
      });
      break;
    case 'ButtonCardModule':
      component = (
        <B>
          <a
            href={cmodule.inputs[1].value}
            target="_blank"
            style={{
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {cmodule.inputs[0].value}
          </a>
        </B>
      );
      break;
    case 'ContactCardModule':
      break;
    case 'EmbedCodeCardModule':
      //
      component = (
        <div
          className={css({
            width: '100% !important',
          })}
          dangerouslySetInnerHTML={{
            __html: cmodule.inputs[0].value,
          }}
        ></div>
      );
      break;
    case 'ImageCardModule':
      let f = cmodule.inputs[0].value;
      let isFile = Array.isArray(f) && f[0].path;
      //
      component = (
        <Gallery
          images={[
            {
              caption: '',
              src: isFile ? URL.createObjectURL(f[0]) : getSrc(f, 'small'),
              thumbnail: isFile ? URL.createObjectURL(f[0]) : getSrc(f),
              thumbnailWidth: 200,
              thumbnailHeight: 160,
              // rowHeight: 160,
            },
          ]}
        />
      );
      break;
    case 'HeaderImageCardModule':
      let fh = cmodule.inputs[0].value;
      let isFile1 = Array.isArray(fh) && fh[0].path;
      //
      component = (
        <Gallery
          images={[
            {
              caption: '',
              src: isFile1 ? URL.createObjectURL(fh[0]) : getSrc(fh, 'small'),
              thumbnail: isFile1 ? URL.createObjectURL(fh[0]) : getSrc(fh),
              thumbnailWidth: 200,
              thumbnailHeight: 160,
              // rowHeight: 160,
            },
          ]}
        />
      );
      break;
    case 'ImageGalleryCardModule':
      //
      component = (
        <Gallery
          images={cmodule.inputs[0].value.map(el => {
            return {
              caption: el.caption,
              src: el.file_data
                ? getSrc(el.file_data)
                : URL.createObjectURL(el.file),
              thumbnail: el.file_data
                ? getSrc(el.file_data)
                : URL.createObjectURL(el.file),
              thumbnailWidth: 200,
              thumbnailHeight: 160,
              rowHeight: 160,
            };
          })}
          className="imageViewGallery"
        />
      );
      break;
    case 'VideoGalleryCardModule':
      //
      component = (
        <Gallery
          images={(cmodule.inputs[0].value || []).map(el => {
            return {
              title: el.title,
              src: el.cover_photo_data
                ? getSrc(el.cover_photo_data)
                : el.cover_photo
                ? URL.createObjectURL(el.cover_photo[0])
                : '',
              thumbnail: el.cover_photo_data
                ? getSrc(el.cover_photo_data)
                : el.cover_photo
                ? URL.createObjectURL(el.cover_photo[0])
                : '',
              thumbnailWidth: 200,
              thumbnailHeight: 160,
              rowHeight: 160,
            };
          })}
          className="imageViewGallery"
        />
      );
      break;
    case 'MeetingCardModule':
      break;
    case 'YoutubeCardModule':
      //
      let videoId = cmodule.inputs[1].value.split('/')[
        cmodule.inputs[1].value.split('/').length - 1
      ];
      if (cmodule.inputs[0].value == 'Vimeo') {
        component = (
          <Vimeo
            videoId={videoId}
            playButton={
              <B type="primary" className="vimeoPlayBtn">
                PLAY
              </B>
            }
          />
        );
      } else {
        component = (
          <YouTube
            className="youtube"
            containerClassName="youtube"
            opts={{
              width: '100%',
              height: '280px',
            }}
            videoId={videoId}
          />
        );
      }
      break;
  }
  return (
    <div
      className={css({
        position: 'relative',
        width: '100%',
        float: 'left',
        marginTop: theme.sizing.scale650,
      })}
    >
      {component}
    </div>
  );
};

const PreviewElements = ({ cardBackData, dataType }) => {
  if (dataType && dataType === 'originalDBData') {
    // convert card back data to editor format
    cardBackData = createEditorCardsFromCardModules(cardBackData, {options: editorBtnOptions}) || [];
    //debugger
    cardBackData = cardBackData.sort((a, b) => a.position - b.position);
  };
  return (
    <>
      {cardBackData.map((module, i) => {
        return (
          <div>
            <GetModuleStructure
              cmodule={module}
              isLast={i == cardBackData.length - 1}
            ></GetModuleStructure>
          </div>
        );
      })}
    </>
  );
};

export default PreviewElements;
