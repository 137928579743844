import { combineReducers } from 'redux';
import appBasicControls from './appBasicControls';

const appReducer = combineReducers({
  appBasicControls: appBasicControls,
});

export default function(state, action) {
  return appReducer(state, action);
}
