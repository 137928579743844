import * as React from 'react';
import { useStyletron } from 'baseui';
import { Avatar } from "baseui/avatar";
import { Link } from 'gatsby';
import { Button } from 'baseui/button';
import { connect } from 'react-redux';
import { DeleteAlt } from "baseui/icon";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
const itemProps = {
  // height: 'scale1000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const alignItemsLeft = {
   // height: 'scale1000',
   display: 'flex',
   alignItems: 'left',
   justifyContent: 'left',
}

const FeedbackCard = ({ feedback, categories, channels, organization, archiveFeedback }) => {
  const [css, theme] = useStyletron();
  const paddingLeftRightCss = {
    paddingLeft: theme.sizing.scale800,
    paddingRight: theme.sizing.scale800,
  };


  const getRedirectLink = (feedback) => {
    // /app/6a98c3a9-d26c-4c69-b139-84cae533e1cc/groups/3fc8156b-7261-4ae6-b853-a2ff215c13d2/channels/87d83ace-627a-4dbe-97d6-eaa0509e5bb6
    let categoryId = feedback.category.id;
    let category = categories.find((el) => el.category_id == categoryId);
    if (!category) return '#';
    let channelId = category.app_content_scope_id;
    let channel = channels.find((el) => el.id == channelId);
    if (!channel) return '#';
    let groupId = channel.app_content_scope_group_id;
    return `/app/${organization.id}/groups/${groupId}/channels/${channelId}`;
  };

  let { user } = feedback;
  if (!user) user = { name: 'Unknown User' };
  // console.log(user, feedback);
  return (
    <>
      <div className="channelsDropDown">
        <div
          className={css({
            position: 'relative',
            background: 'rgb(226, 226, 226)',
            border: '1px solid hsla(0, 0%, 0%, 0.2)',
            borderRadius: '5px',
            minHeight: '200px',
          })}
        >
          <div
            className={css({
              position: 'relative',
              width: '100%',
              padding: '16px'
            })}
          >
            <FlexGrid
              flexGridColumnCount={1}
              flexGridColumnGap="scale100"
              flexGridRowGap="scale100"
            >
              <FlexGridItem {...itemProps}><div style={{ marginTop: "0px", fontSize: '14px' }}><Avatar name={user.name || 'App User'} size="scale1200" src={user.image} /></div></FlexGridItem>
              <FlexGridItem {...itemProps}><div style={{ marginTop: "0px", fontSize: '14px' }}><b>{user.name || 'App User'}</b></div></FlexGridItem>
              <FlexGridItem {...itemProps}><div style={{ marginTop: "8px", fontSize: '14px' }}><i>"{feedback.value}"</i></div></FlexGridItem>
              <FlexGridItem {...itemProps}><div style={{ marginTop: "8px", fontSize: '12px' }}>
                <Link to={getRedirectLink(feedback)}>{feedback.deck.title}</Link> /
                <Link to={getRedirectLink(feedback)}>{feedback.card.title}</Link> /
                <Link to={getRedirectLink(feedback)}>{feedback.category.name}</Link>
              </div>
              </FlexGridItem>
              <FlexGridItem {...alignItemsLeft}>
                <div style={{ marginTop: "8px", fontSize: '14px', cursor: 'pointer', display: 'flex'}} onClick={() => archiveFeedback(feedback.id)}>
                  <DeleteAlt size={28} /> 
                  <span style={{marginTop: '1px'}}>Archive</span> 
                </div>
              </FlexGridItem>
            </FlexGrid>
          </div>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = props => {
  let { category, channels, organization } = props.appBasicControls;
  return { categories: category, channels, organization };
};

export default connect(mapStateToProps)(FeedbackCard);
