import React from 'react';
import BaseClass from './base';
import ColorPicker from '../../common/ColorPicker';


class ColorPickerComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render() {
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        <ColorPicker
          color={this.state.value}
          onChange={e => {
            this.setState({ value: e })
          }}
        />
      </>
    );
  }
}

export default ColorPickerComponent;
