import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { showAlert } from '../../redux/actions/appBasicControls';
import { FileUploader } from 'baseui/file-uploader';
import {
  LabelLarge,
} from 'baseui/typography';
import { organizations, updateOrganization as updateOrganizationMutation } from '../../quries';
import { getProfile } from '../../auth/index';

export default ({ organization, data, dispatch, ...props }) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [name, setName] = React.useState('');
  const [logo, setLogo] = React.useState(null);

  const [domain, setDomain] = React.useState('');

  const [auth0Domain, setAuth0Domain] = React.useState('');
  const [client_id, setClientId] = React.useState('');
  const [client_secret, setClientSecret] = React.useState('');

  const [preview_image, setPreview] = React.useState(null);
  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  React.useEffect(() => {
    if (data) {
        const {domain, organization,} = data;
        const {name, logo_data, id, auth0Domain, client_id, client_secret} = organization
        name && setName(name)
        logo_data && setPreview(JSON.parse(logo_data).metadata.full_url)
        domain && setDomain(domain)
        auth0Domain && setAuth0Domain(auth0Domain)
        client_id && setClientId(client_id)
        client_secret && setClientSecret(client_secret)
    }
  }, [data])

  const [updateOrganization, { loading, data: updatedOrg }] = useMutation(
    updateOrganizationMutation
  );

  React.useEffect(() => {
    setIsLoading(false);
    if (updatedOrg) {
        dispatch && dispatch(
        showAlert({
          msg: 'Successfully updated organization!',
          error: false,
        })
      );
      props.onClose();
    }
  }, [updatedOrg]);

  const [isCopyCurrentOrgAuth, setCopyCurrentOrgAuth] = React.useState(false);

  React.useEffect(() => {
    if (isCopyCurrentOrgAuth) {
      setAuth0Domain(organization.auth0.domain);
      setClientId(organization.auth0.client_id);
      setClientSecret(organization.auth0.client_secret);
    } else {
      setAuth0Domain('');
      setClientId('');
      setClientSecret('');
    }
  }, [isCopyCurrentOrgAuth]);

  const { idTokenPayload } = getProfile();
  const onSave = () => {
    setIsLoading(true);
    updateOrganization({
      variables: {
        id: data.organization.id,
        name,
        logo_data: logo,
        domain: domain,
        admin: {
          email: idTokenPayload.email,
          domain: window.location.origin
        },
        auth0: {
          domain: auth0Domain,
          client_id,
          client_secret,
        },
      },
    });
  };


  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={true}
        onClose={() => {
            props.onClose();
        }}
      >
        {/* <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey.toString());
            }}
            activateOnFocus
            fill={FILL.fixed}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  marginTop: '2rem'
                })
              }
            }}
          >
            <Tab title="General Information"> */}
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Name of Organization</H6>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name of Organization"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Domain of Organization</H6>
              <Input
                value={domain}
                onChange={e => setDomain(e.target.value)}
                placeholder="Domain of Organization"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Logo</H6>
              {preview_image && <img src={preview_image}></img>}
              <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setLogo(acceptedFiles);
                  previewImage(acceptedFiles[0]);
                }}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <Checkbox
                checked={isCopyCurrentOrgAuth}
                onChange={e => setCopyCurrentOrgAuth(e.target.checked)}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                Copy from current Organization {organization.name}
              </Checkbox>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Domain</H6>
              <Input
                value={auth0Domain}
                onChange={e => setAuth0Domain(e.target.value)}
                placeholder="Auth0 Domain"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Client Id</H6>
              <Input
                value={client_id}
                onChange={e => setClientId(e.target.value)}
                placeholder="Auth0 Client ID"
                type="password"
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Auth0 Client Secret</H6>
              <Input
                value={client_secret}
                onChange={e => setClientSecret(e.target.value)}
                placeholder="Auth0 Client Secret"
                type="password"
                clearOnEscape
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onSave();
            }}
            isLoading={isLoading}
          >
            Update Organization
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
