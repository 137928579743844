import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { useMutation } from '@apollo/client';
import { Select } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { moveCardStackCardMutation, movedSlideMutation } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';

export default ({ slide, component, onClose, refetchHomeComponents, componentType, dispatch }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  if (isOpen === false) onClose();

  const [
    moveSlide,
    { loading: onMoveSlideLoader, data: movedSlide },
  ] = useMutation(movedSlideMutation);

  const onSave = () => {
    if (!slide) return;
    setIsLoading(true);

    if (componentType === 'CardStackModule') {
      return client.mutate({
        mutation: moveCardStackCardMutation,
        variables: {
          id: slide.card_back_home_component_card.id,
          home_component_id: component.home_component.id
        }
      }).then(() => {
        refetchHomeComponents();
        dispatch(
          showAlert({
            msg: 'Successfully moved Card!',
            error: false,
          })
        );
        onClose();
      })
    }
    moveSlide({
      variables: {
        id: slide.id,
        home_component_id: component.home_component.id,
      },
    });
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (movedSlide) {
      refetchHomeComponents();
      dispatch(
        showAlert({
          msg: 'Successfully moved Card!',
          error: false,
        })
      );
      onClose();
    }
  }, [movedSlide]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const HeaderComponent = React.useMemo(() => {
    const h5Title1 = componentType === 'CardStackModule'?
    'Are you sure you want to move this Card -'
    :'Are you sure you want to move this Slide -';

    const title = componentType === 'CardStackModule'? slide.card_back_home_component_card.title: slide.title
    return (
      <>
        <H5>
          {h5Title1}{' '}
          <b style={{ color: 'rgb(118, 95, 237)' }}>{title}</b>{' '}
          to new Component - <b style={{ wordWrap: 'anywhere', color: 'rgb(118, 95, 237)' }}>{component.home_component.title}</b>?{' '}
        </H5>
        <H6>
          This process may take a few moments and all changes are final. If
          you need to move this back, you will be able to do so.
        </H6>
      </>
    )
  }, [slide]);

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 100
            }
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            {HeaderComponent}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={onMoveSlideLoader}
            onClick={() => {
              onSave();
            }}
            disabled={!slide}
          >
            Move this anyway
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
