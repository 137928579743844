import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { setCategory } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';

const getSubForCategoryUpdate = id => {
  return gql`
    subscription OnChannelUpdate {
        appContentScopeUpdated(id:"${id}"){
          id
          position
          name
          public
          published
          organization_id
          app_content_scope_group_id
          logo_data
          isNew
          pincode
          description
          content_controlled
          media_url
          isDeleted
          abbr
          sales_button_enabled
          sales_incentive_button_title
          sales_incentive_description
          sales_incentive_title
          sales_incentive_image
          archived
          is_requires_subscription
          subscription_into_headline
          subscription_description
          subscription_button_text
          subscription_image_data
          subscription_id
          subscription {
            name
            id
          }
          is_travel_pass_channel
          has_data_source_connected
        }
      }
  `;
};

const getSubForChannelCreate = orgId => {
  return gql`
    subscription OnChannelCreate {
        appContentScopeCreated(orgId:"${orgId}"){
          id
          position
          name
          public
          published
          organization_id
          app_content_scope_group_id
          logo_data
          isNew
          pincode
          description
          content_controlled
          media_url
          abbr
          sales_button_enabled
          sales_incentive_button_title
          sales_incentive_description
          sales_incentive_title
          sales_incentive_image
          archived
          is_requires_subscription
          subscription_into_headline
          subscription_description
          subscription_button_text
          subscription_image_data
          subscription_id
          subscription {
            name
            id
          }
          is_travel_pass_channel
          has_data_source_connected
      }
    }
  `;
};

class SubscribeCategory extends React.Component {
  constructor(props) {
    super(props);
    this.updateSubs = null;
  }

  componentDidMount() {
    const primaryDataByOrg = this.props.primaryDataByOrg;
    // Here we are going to subscribe for category, decks, cards

    // 1. category create
    console.log('[SUBSCRIBE] CHANNEL CREATE');
    this.createSub = this.props.subscribeToMore({
      document: getSubForChannelCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
      updateQuery: (data1, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.appContentScopeCreated
        ) {
          console.log('[SUBSCRIBE] CHANNEL CREATE NEW EVENT');
          let c = JSON.parse(JSON.stringify(this.props.channels || []));
          data1 = JSON.parse(JSON.stringify(data1));
          console.log(subscriptionData.data.appContentScopeCreated);
          c.push(subscriptionData.data.appContentScopeCreated);
          data1.app_content_scopes_org = c;
          return data1;
        }
      },
    });
  }

  componentWillUnmount() {
    console.log('[SUBSCRIBE] UNMOUNTED CARD CREATE');
    this.createSub && this.createSub();
  }

  render() {
    // 2. category update

    // unsubscribe
    this.updateSubs && this.updateSubs.map(el => el());
    // subscribe
    this.updateSubs = (this.props.channels || []).map((el, index) => {
      return this.props.subscribeToMore({
        document: getSubForCategoryUpdate(el.id),
        variables: { id: el.id },
        updateQuery: (data1, { subscriptionData }) => {
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.appContentScopeUpdated
          ) {
            let { appContentScopeUpdated } = subscriptionData.data;
            let c = JSON.parse(JSON.stringify(this.props.channels || []));
            data1 = JSON.parse(JSON.stringify(data1));
            if (appContentScopeUpdated.isDeleted) {
              c.splice(index, 1)
            } else {
              c[index] = appContentScopeUpdated;
            }
            data1.app_content_scopes_org = c;
            return data1;
          }
        },
      });
    });

    return <></>;
  }
}

const mapStateToProps = props => {
  const { channels } = props.appBasicControls;
  return { channels };
};

export default connect(mapStateToProps)(SubscribeCategory);
