import * as React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import StyledButton from '../styledButton';
import MasonryComponent from 'react-masonry-component';
import { useStyletron } from 'baseui';
import ReactPlayer from 'react-player';
import RightSidePageHeader from '../pageHeaders';
import HelpQuestionMark from '../help/questionMarkHelp';
import Button from '../common/button';
import { Plus } from 'baseui/icon';
import { libraries, getTags } from '../../quries';
import AddLibrary from './addLibrary'
import AddVideo from './addVideo'
import LibraryModal from './libraryModal'
import {Skeleton} from 'baseui/skeleton'
import { showAlert } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const TAB_STATUS = {
    VIDEO: 'video',
    IMAGE: 'image',
};
const buttonOverrides = {
BaseButton: {
    style: () => ({
        fontFamily: 'Roboto'
    })
}
}

const Library = ({
  defaultTheme,
  dispatch,
  organization,
  ...rest
}) => {
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [isAddVideoOpen, setAddVideoOpen] = React.useState(false);
  const [tab, setTab] = React.useState(TAB_STATUS.IMAGE);
  const [selectedItem, setSelectedItem] = React.useState(null)

  const [css, theme] = useStyletron();
  const [tags, setTags] = React.useState([])

  const { data: libraryData, loading: isLoading, refetch: refetchLibraries } = useQuery(
    libraries(),
    { fetchPolicy: 'network-only' }
  );

  const loadTags = () => {
    client.query({
      query: getTags(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
        const tags = [...data.tags_org].sort((a, b) => a.position - b.position)
        setTags(tags);
    })
  };

  React.useEffect(() => {
      console.log('Load tags:')
    loadTags()
  }, [])

  const onAdd = () => {
      setAddOpen(true);
  }
  const onVideoAdd = () => {
    setAddVideoOpen(true);
  }
  const onClose = () => {
      setAddOpen(false);
      setAddVideoOpen(false);
      setSelectedItem(null);
  }

  const onMessage = (msg) => {
    dispatch(
        showAlert({
          msg,
          error: false,
        })
      );
  }

  const childElements = libraryData && Array.isArray(libraryData.libraries) && libraryData.libraries.map(function(item) {
    const {type, library } = item;
    let url = null;
    try {
        url = library ? JSON.parse(library).metadata.full_url || JSON.parse(library).metadata.url : '';
    } catch(error) {
        // console.log(error)
    }
    
    if (! url) return;
    if (type == 'image' && tab == 'image' ) {
        if (url.indexOf('jpg') > -1 || url.indexOf('png') > -1 || url.indexOf('jpeg') > -1)
        return (
            <img 
                src={url}
                onClick={e => {
                    setSelectedItem(item)
                }}
                alt='library'
                style={{ 
                    objectFit: 'contain',
                    marginLeft: '10px',
                    padding: '5px',
                    // boxShadow: 'rgb(0 0 0 / 85%) 0px 1px 10px 0px',
                    cursor: 'pointer',
                    listStyleType: 'none',
                    display: 'block'
                }}
            />
        )
    }
    if ( type == 'video' && tab == 'video') {
        return (
            <ul style={{ marginLeft: '20px', padding: '5px',  cursor: 'pointer', listStyleType: 'none'}}>
                <ReactPlayer controls={true} url={url} width="200px" height="260px" />
            </ul>
         )
    }
    return null;
    });

  return (
    <div style={{ padding: '0.5rem'}}>
        <RightSidePageHeader
          icon={null}
          title={'Library'}
          rightContent={
            <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 0 }}
              
            >
              <StyledButton
                className={css({
                  fontSize: '14px !important'
                })}
                overrides={buttonOverrides}
                onClick={() => setTab(TAB_STATUS.IMAGE)}>Image</StyledButton>
              <StyledButton
                className={css({
                  fontSize: '14px !important'
                })}
                overrides={buttonOverrides}
                onClick={() => setTab(TAB_STATUS.VIDEO)}>Video</StyledButton>
            </StatefulButtonGroup>
          }
          headerDescription={'Manage App Library Here'}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{margin: 0, color: 'rgb(117, 117, 117)', marginLeft: '1rem'}}>Browse Libraries</p>
            {tab == 'image' && (
                <Button
                    border_property={"true"}
                    onClick={onAdd}
                    text='Add Image'
                    style={{ textTransform: 'capitalize' }}
                    startEnhancer={() => (
                        <Plus
                        size={16}
                        overrides={{
                            Svg: {
                            style: {
                                backgroundColor: 'white',
                                color: '#5D64AB',
                                borderRadius: '2px',
                            },
                            },
                        }}
                        />
                    )}
                    endEnhancer={() => (
                        <HelpQuestionMark text={`add new image to the system`} button />
                    )}
                />
            )}
        
            {tab == 'video' && (
                 <Button
                    border_property={"true"}
                    onClick={onVideoAdd}
                    text='Add Video'
                    style={{ textTransform: 'capitalize' }}
                    startEnhancer={() => (
                        <Plus
                        size={16}
                        overrides={{
                            Svg: {
                            style: {
                                backgroundColor: 'white',
                                color: '#5D64AB',
                                borderRadius: '2px',
                            },
                            },
                        }}
                        />
                    )}
                    endEnhancer={() => (
                        <HelpQuestionMark text={`add new video to the system`} button />
                    )}
                />
            )}
           
        </div>
        <div style={{marginTop: '1rem'}}>
            {isLoading ? (
                <div style={{ width: '80%', marginLeft: '1rem'}}>
                    <Skeleton
                        rows={4}
                        width="60%"
                        overrides={{
                            Row: {
                            style: {
                                height: '20px',
                                marginBottom: '15px',
                            },
                            },
                        }}
                    />
                    <Skeleton
                        rows={4}
                        width="60%"
                        overrides={{
                            Root: {
                                style: {
                                    marginTop: '2rem',
                                },
                            },
                            Row: {
                                style: {
                                    height: '20px',
                                    marginBottom: '15px',
                                },
                            },
                        }}
                    />
                    <Skeleton
                        rows={4}
                        width="60%"
                        overrides={{
                            Root: {
                                style: {
                                    marginTop: '2rem',
                                },
                            },
                            Row: {
                                style: {
                                    height: '20px',
                                    marginBottom: '15px',
                                },
                            },
                        }}
                    />
                </div>
            ) : (
                <div>
                    {libraryData && Array.isArray(libraryData.libraries) && tab == 'image' ? (
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{350: 2, 750: 4, 900: 6}}
                            >
                                <Masonry>{childElements}</Masonry>
                            </ResponsiveMasonry>
                        ) : (
                            <MasonryComponent>{childElements}</MasonryComponent>
                        )}
                    {(!isLoading && !libraryData || libraryData.libraries.length == 0) && (
                        <p style={{ textAlign: 'center' }}>No library data found</p>
                    )}
                </div>
            )}
            {
                isAddOpen && (
                    <AddLibrary onClose={onClose} tags={tags} refetchLibraries={refetchLibraries} />
                )
            }
            {
                isAddVideoOpen && (
                    <AddVideo onClose={onClose} tags={tags} refetchLibraries={refetchLibraries} />
                )
            }
            {selectedItem && <LibraryModal onClose={onClose} library={selectedItem} onMessage={onMessage} />}
        </div>
    </div>
  );
};

const mapStateToProps = props => {
  let {
    theme,
    organization
  } = props.appBasicControls;
  return {
    defaultTheme: theme,
    organization
  };
};
export default connect(mapStateToProps)(Library);
