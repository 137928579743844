import { Spinner } from 'baseui/spinner';
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { HeadingLarge } from 'baseui/typography';
import styled from '@emotion/styled';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
`

const HeadingText = styled(HeadingLarge)`
  text-align: center;
`

const MsCallback = (props) => {
  const fetchAccessToken = () => {
    const params = queryString.parse(props.location.search);
    const {
      code,
      state
    } = params || {};
    if (!code) {
      closeCallback({
        success: false,
        message: 'Auth Code is not available'
      })
      return;
    }

    const stateJson = JSON.parse(state);

    axios.get(`${stateJson.redirectUri}?code=${code}&state=${stateJson.id}&redirect_uri=${window.location.origin}/ms-callback`)
      .then((res) => {
        if (res.status === 200) {
          closeCallback(res.data);
        } else {
          closeCallback({ success: false })
        }

      }).catch((err) => {
        closeCallback({
          success: false,
          error: err
        });
      })

  };

  const closeCallback = (data) => {
    window.opener.callback && window.opener.callback(data);
    window.open(props.location, '_self').close({ fetchToken: true });
  }

  useEffect(() => {
    fetchAccessToken();
  }, [])

  return (
    <Container>
      <HeadingText>Please wait while we are connecting to microsoft server</HeadingText>
      <Spinner
        size={96}
      />
    </Container>
  )
}

export default MsCallback;