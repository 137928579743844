import React from 'react';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const SentryIntegration = ({ children }) => {
  var history = null;
  if (typeof window !== `undefined`) {
    const _H = require('history');
    const createBrowserHistory = _H.createBrowserHistory;
    history = createBrowserHistory();
  };

  React.useEffect(() => {
    if (typeof window !== `undefined` && window.location.origin.includes('localhost')) return;
    Sentry.init({
      dsn: "https://2f1e51003fb4495f91c58d5652cd2b93@o927630.ingest.sentry.io/5877078",
      integrations: [new Integrations.BrowserTracing(
        {
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }
      )],
      tracesSampleRate: 1.0,
    });

    let CURRENT_SERVER_URL = window.location.origin;
    let SERVER_NAME = CURRENT_SERVER_URL.replace('https://', '').replace('.herokuapp.com', '');
    var orgId = '';
    let path = window.location.pathname;
    let orgIdMatch = path.match(
      /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
    );
    if (orgIdMatch) {
      orgId = orgIdMatch[0];
    }
    Sentry.setContext("cardware_info", {
      _current_server_url: CURRENT_SERVER_URL,
      _current_server_name: SERVER_NAME,
      _current_server_type: 'cardware_admin',
      _current_server_organization_id: orgId,
    });
  }, []);

  if (typeof window !== `undefined` && window.location.origin.includes('localhost')) return <>{children}</>;

  return <Sentry.ErrorBoundary showDialog>
    {children}
  </Sentry.ErrorBoundary>;
};