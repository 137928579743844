import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { client } from '../../apollo/client';
import { apiQuery, getData, createCommon, updateCommon } from './query';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import Button from '../styledButton';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import store from '../../redux/store';
import { connect } from 'react-redux';
import Builder from '../builder/builder';
import RightSidePageHeader from '../builder/builderHeader';
import { createEditorCardsFromCardModules } from '../builder/builderUtils/helpers';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const buttonOverrides = {
  BaseButton: {
    style: () => ({
      fontFamily: 'Roboto'
    })
  }
}

const editorContainerStyle = () => { return { marginTop: '0px', overflow: 'auto' } }


const CategoryMappings = ({ selectedChannel, paths, organization_id, cardBackDataRedux, record_2 }) => {

  const [mappings, setMappings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [cardBackData, setCardBackDataState] = React.useState([]);

  const OnSaveOrUpdate = () => {
    return new Promise((resolve, reject) => {
      setCardBackDataState([...store.getState().appBasicControls.cardBackData] || []);
      resolve();
    })
  }

  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "card_back_api_mappings",
          query: `{\"where\": {\"app_content_scope_id\": \"${selectedChannel.id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        console.log(JSON.parse(response.data));
        setMappings(JSON.parse(response.data));
      }
    });
  }, [selectedChannel]);

  const onSave = () => {
    console.log(cardBackData);
    let modules = cardBackData.map((el) => {
      let op = {};
      el.inputs.forEach(el1 => {
        op[el1.key] = el1.is_connected_with_data ? `datasource__${el1.connected_data_point}` : el1.value;
      })
      return {
        __typename: el.__typename,
        id: el.dId,
        ...op
      }
    });
    console.log('modules', modules);
    if (mapping_id == null) {
      // create
      setIsLoading(true);
      client.mutate({
        mutation: createCommon,
        variables: {
          data: {
            model: "card_back_api_mappings",
            data: JSON.stringify({
              api_path_id,
              organization_id,
              app_content_scope_id: selectedChannel.id,
              content_modules: JSON.stringify(modules)
            })
          }
        }
      }).then(() => setIsLoading(false))
    } else {
      // update
      setIsLoading(true);
      client.mutate({
        mutation: updateCommon,
        variables: {
          data: {
            data: JSON.stringify({
              id: mapping_id,
              api_path_id,
              content_modules: JSON.stringify(modules)
            }),
            model: "card_back_api_mappings",
          }
        }
      }).then(() => setIsLoading(false))
    }
  }


  const [mapping_id, set_mapping_id] = React.useState(null);
  const [api_path_id, set_api_path_id] = React.useState(null);
  const [content_modules, set_content_modules] = React.useState([]);
  const [sample_data, set_sample_data] = React.useState({});


  React.useEffect(() => {
    if (mappings.length > 0) {
      let map = mappings[0];
      set_mapping_id(map.id);
      set_api_path_id(map.api_path_id);
      console.log('MAp', map);
      if (map.content_modules) {
        try {
          set_content_modules(JSON.parse(map.content_modules));
        } catch (e) {
          console.log('e', e);
        }
      }
    }
  }, [mappings]);


  React.useEffect(() => {
    if (api_path_id) {
      client.query({
        query: getData(api_path_id),
        variables: {
          parent_record: JSON.stringify(record_2)
        }
      })
        .then((result) => {
          let response = result.data.get_data_from_api_path;
          if (response.status) {
            set_sample_data(JSON.parse(response.data));
          }
        })
    }
  }, [api_path_id]);

  React.useEffect(() => {
    if (content_modules.length > 0) {
      let _m = createEditorCardsFromCardModules(content_modules.map(el => {
        return {
          __typename: el.__typename,
          content_module: {
            ...el
          }
        }
      }), {
        options: editorBtnOptions.filter(
          el => el.addInDataConnection
        )
      });
      _m = _m.map(el => {
        el.inputs = el.inputs.map(el1 => {
          if (el1.value && el1.value.includes('datasource__')) {
            let map = el1.value.replace('datasource__', '');
            el1.value = '';
            el1.is_connected_with_data = true;
            el1.connected_data_point = map;
          }
          return el1;
        });
        return el;
      })
      setCardBackDataState(_m);
    };
  }, [content_modules]);

  console.log(content_modules);

  return <Grid
    overrides={{
      Grid: {
        style: {
          padding: '10px 13px 0 7px !important'
        }
      }
    }} gridMaxWidth={'unset'}
  >
    <Cell
      span={8}
      overrides={{
        Cell: {
          style: () => ({
            padding: '0px !important',
            height: '100% '
          }),
        }
      }}
    >
      <Cell span={10}>
        <div style={{ marginBottom: '32px' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Api Path</LabelMedium>
          <Select
            options={paths}
            value={paths.filter(el => el.id == api_path_id)}
            labelKey="name"
            valueKey="id"
            placeholder=''
            onChange={({ value }) => set_api_path_id(value[0].id)}
          />
        </div>
      </Cell>

      <Cell span={10} >
        <div style={editorContainerStyle()}>
          <Builder
            noPreview={true}
            leftCellSpan={1.2}
            middleCellSpan={2}
            rightCellSpan={8}
            options={editorBtnOptions.filter(
              el => el.addInDataConnection
            )}
            isModal={true}
            dispatch={store.dispatch}
            options2={cardBackData}
            data={cardBackData}
            onSave={OnSaveOrUpdate}
            onUpdate={OnSaveOrUpdate}
            headerComponent={() => <RightSidePageHeader
              title={"Message Body"}
              headerDescription={"Drag content modules from the left into the blank space to create rich media for your messaging."}
            />}
            isShowHoverContent={false}
            cardBackData={cardBackDataRedux}
            isMainPageModal={true}
            isCommunication={true}
            hasDataSource={true}
            dataPoints={Object.keys(sample_data)}
          ></Builder>
        </div>
      </Cell>

      <Cell span={10}>
        <Button
          style={{
            fontSize: '14px !important'
          }}
          overrides={buttonOverrides}
          isSelected
          isLoading={isLoading}
          onClick={onSave}>Save</Button>
      </Cell>
    </Cell>

    <Cell
      span={4}
      overrides={{
        Cell: {
          style: () => ({
            padding: '0px !important',
            height: '100% '
          }),
        }
      }}
    >
      <p>This is one sample record so you can map title and description fields.</p>
      <JSONInput
        id='a_unique_id'
        placeholder={sample_data}
        theme="dark_vscode_tribute"
        colors={{
          string: "#DAA520" // overrides theme colors with whatever color value you want
        }}
        locale={locale}
        height='650px'
        width={'100%'}
      />
    </Cell>
  </Grid>
};

const mapStateToProps = props => {
  let { cardBackData } = props.appBasicControls;
  return {
    cardBackDataRedux: cardBackData
  };
};
export default connect(mapStateToProps)(CategoryMappings);