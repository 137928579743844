import React, { useState } from 'react';
import _ from 'lodash';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { arrayMove } from 'baseui/dnd-list';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { navigate } from 'gatsby-link';
import { FlexGrid } from 'baseui/flex-grid';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from '@emotion/styled';

import Portal from './portal';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { sourceByType } from '../../util';
import Card from '../cards/card';
import { useUrlParams } from '../help';
import AddCard from '../cards/addCardStackCard';
import ArchiveCard from '../cards/archiveCard';
import UnArchiveCard from '../cards/unArchiveCard';
import MoveCard from './moveSlide';
import { showAlert } from '../../redux/actions/appBasicControls';

const AddCardContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: calc(100% + 21px);
  top: 0;
  box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 25px;
  `;

const DroppableContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-row: 1;
  padding-top: 3px;
  margin-right: 200px;
  scrollbar-width: thin;
  scrollbarColor: ${props => props.scrollbarColor}
`;

const WrapperContainer = styled.div`
  padding-top: ${props => props.theme.sizing.scale600};
  min-height: 274px;
`


const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'small');
}

const CardStack = ({
  selectedComponent,
  appContentScopeId,
  organization_id,
  home_component_app_content_scopes,
  refetchHomeComponents,
  tabFilter,
  dispatch,
  defaultTheme,
  isCopyCard = false
}) => {
  const [css, theme] = useStyletron();
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedHomeComponent, setSelectedHomeComponent] = useState(null);
  const [archivedCardOpen, setArchiveCardOpen] = React.useState(false);
  const [unArchivedCardOpen, setUnArchiveCardOpen] = React.useState(false);
  const [moveCardOpen, setMoveCardOpen] = React.useState(false);

  const params = useUrlParams();

  const [updateCardStackCard, { data: updatedCardStackCard }] = useMutation(
    gql`
      mutation updateCardStackCard(
        $id: ID
        $position: Int
        $published: Boolean
        $input: updateCardStackCardInput
      ) {
        updateCardStackCard(
            id: $id
            position: $position
            published: $published
            input: $input) {
          card {
            id
            title
          }
        }
      }
    `
  )

  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(selectedComponent.home_component.card_stack_home_components));
    if (isCopyCard) {
      s = JSON.parse(JSON.stringify(selectedComponent.home_component.copy_card_home_components));
    }
    s = s.filter((el) => {
      if (tabFilter == 'archived') return el.card_back_home_component_card.archived === true;
      if (tabFilter === 'published') {
        return el.published == true && el.card_back_home_component_card.archived !== true;
      }
      if (tabFilter === 'all') {
        return el.card_back_home_component_card.archived !== true
      }
      return true;
    });
    setCards(s.sort((a, b) => a.position - b.position));
  }, [selectedComponent]);

  ///////////////////////////////////////////////////// Event Function ////////////////////////////////////////////////////

  const onDragEnd = ({ destination, source, ...rest }) => {
    if (!destination) {
      return;
    }
    console.log(destination.index);
    console.log(source.index);
    let newArr = arrayMove(
      JSON.parse(JSON.stringify(cards)),
      source.index,
      destination.index
    );
    newArr = newArr.map((el, i) => {
      el.position = i;
      delete el.isNew;
      return el;
    });

    newArr.forEach(el => {
      let old = cards.find(el1 => el.id == el1.id);
      if (old.position !== el.position) {
        updateCardStackCard({
          variables: {
            id: el.id,
            position: el.position,
            published: el.published,
            is_copy_card: isCopyCard
          }
        })
      }
    })
    setCards(newArr);
  }

  const onClick = data => {
    const url = `/app/${params.orgId}/groups/${params.groupId}/channels/${params.channelId}/card_stack/${data.cardStackId}/card/${data.card_id}`
    localStorage.setItem('router_history', url);
    localStorage.setItem('card_id', data.card_id);
    navigate(url);
  };

  const updatePublished = async (id, position, published) => {
    dispatch(showAlert({
      msg: 'Updating Home Component'
    }))
    await updateCardStackCard({
      variables: {
        id,
        position,
        published
      }
    })
    refetchHomeComponents()
    // .then(() => {
    dispatch(showAlert({
      msg: 'Home Component Updated'
    }))
    // })
    return;
  };

  const onArchiveCard = cardEl => {
    setSelectedCard({ ...cardEl });
    setArchiveCardOpen(true);
  };

  const onUnArchiveCard = cardEl => {
    setSelectedCard({ ...cardEl });
    setUnArchiveCardOpen(true);
  };

  const onCancel = () => {
    setArchiveCardOpen(false);
    setUnArchiveCardOpen(false);
    setMoveCardOpen(false);
    setSelectedCard(null);
    setSelectedHomeComponent(null);
  }

  ////////////////////////////////////////////////////// Render Function ////////////////////////////////////////////////////

  const renderPortableItem = ({
    item,
    dragProvided,
    snapshot,
    index,
  }) => {
    let title = item.card_back_home_component_card.title;
    let imageSrc = getSrc(item.card_back_home_component_card.file_data || item.card_back_home_component_card.front_image_data);
    let card_id = item.card_back_home_component_card.id;
    let full_bleed = item.card_back_home_component_card.full_bleed;

    const onToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      updatePublished(item.id, item.position, e.target.checked);
      let _c = JSON.parse(JSON.stringify(cards));
      let indexToUpdate = _c.findIndex(m0 => m0.id == item.id);
      _c[indexToUpdate].published = e.target.checked;
      setCards(_c);
      console.log('onToggleClick', _c[indexToUpdate])
    }

    const onSettingsClick = () => {
      onClick({
        card_id
      })
    }

    const onArchive = () => {
      onArchiveCard(item);
    }

    const onUnArchive = () => {
      onUnArchiveCard(item)
    }

    const onMoveSlide = (targetComponent) => {
      setSelectedCard(item);
      setSelectedHomeComponent(targetComponent);
      setMoveCardOpen(true);
    }

    const onClone = e => {

    }

    const onCardClick = () => {
      setSelectedCard(item);
      onClick({
        card_id,
        cardStackId: item.id
      });
      // setIsEditOpen(true);
    }

    return (
      <Portal
        snapshot={snapshot}
        child={
          <div
            {...dragProvided.dragHandleProps}
            {...dragProvided.draggableProps}
            ref={dragProvided.innerRef}
            onClick={onCardClick}
          >
            <Card
              key={index}
              title={title}
              imageSrc={imageSrc}
              toggle={item.published}
              isHomeComponent={true}
              componentType={selectedComponent.home_component.component_type}
              home_component_app_content_scopes={home_component_app_content_scopes}
              onToggleClick={onToggle}
              width={'148px'}
              isHomeComponent={true}
              home_component_app_content_scopes={home_component_app_content_scopes}
              currentSlideComponent={selectedComponent}
              onClone={onClone}
              onSettingsClick={onSettingsClick}
              onArchive={onArchive}
              onUnArchive={onUnArchive}
              onMoveSlide={onMoveSlide}
              tabFilter={tabFilter}
              showSettingsIcon={false}
              full_bleed={full_bleed}
              isCopyCard={isCopyCard}
            ></Card>
          </div>
        }
      />
    )
  }

  const renderDroppableContainer = (dropProvided) => {
    const {
      home_component
    } = selectedComponent || {};
    return (
      <DroppableContainer
        // className="scrolls"
        className="scrolls_v1"
        ref={dropProvided.innerRef}
        scrollbarColor={defaultTheme === "dark" ? 'black #282828' : "white black"}
      >
        {
          cards.map((el, index) => {
            return (
              <Draggable
                key={el.id}
                draggableId={el.id}
                index={index}
              >
                {(dragProvided, snapshot) => (
                  <>
                    {renderPortableItem({
                      item: el,
                      dragProvided,
                      snapshot,
                      index
                    })}
                  </>
                )}
              </Draggable>
            )
          })
        }
        {tabFilter !== "archived" && (
          <AddCardContainer>
            <AddCard
              defaultTheme={defaultTheme}
              cardStyle="Version1"
              cards={cards}
              home_component_id={home_component.id}
              orgId={params.orgId}
              refetchHomeComponents={refetchHomeComponents}
              // onCloseModal={onCloseAddCardModal}
              modalProps={{ returnFocus: false }}
              isCopyCard={isCopyCard}
            />
          </AddCardContainer>
        )}
      </DroppableContainer>
    )
  }
  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <WrapperContainer id={`wrapper_card_stack${selectedComponent.id}`} className="wrapper" theme={theme}>
            <FlexGrid>
              <DragDropContext
                onDragEnd={onDragEnd}
              >
                <Droppable
                  droppableId={selectedComponent.id}
                  type={'CARD'}
                  direction="horizontal"
                  isCombineEnabled={false}
                >
                  {renderDroppableContainer}
                </Droppable>
              </DragDropContext>
            </FlexGrid>
          </WrapperContainer>
        </Cell>
      </Grid>
      {archivedCardOpen && (
        <ArchiveCard
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
          refreshQuizQuestions={refetchHomeComponents}
        />
      )}
      {unArchivedCardOpen && (
        <UnArchiveCard
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
          refreshQuizQuestions={refetchHomeComponents}
        />
      )}
      {moveCardOpen && (
        <MoveCard
          onClose={onCancel}
          refetchHomeComponents={refetchHomeComponents}
          component={selectedHomeComponent}
          slide={selectedCard}
          componentType='CardStackModule'
          dispatch={dispatch}
        />
      )}
    </>
  )

}

export default CardStack;