import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      'color': 'rgb(0, 0, 0)',
      'fontSize': '16px',
      '::placeholder': {
        color: 'rgb(173,173,173)',
      },
    },
    invalid: {
      color: 'rgb(255,1,0)',
      iconColor: 'rgb(255,1,0)',
    },
  }
};

export default function CardInput() {
  return (
    <div style={{
      'backgroundColor': 'rgb(238, 238, 238)',
      padding: '12.5px',
      border: '2px solid rgb(238, 238, 238)'
    }}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}