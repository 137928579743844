import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../redux/store';

const getCardRelation = (card) => {
  let myCard = Object.assign({ selected: false }, card.card);
  let decks = store.getState().appBasicControls.decks || [];
  let categories = store.getState().appBasicControls.category || [];
  let channels = store.getState().appBasicControls.channels || [];
  let groups = store.getState().appBasicControls.groups || [];
  let deck, category, channel, group;
  deck = decks.find((deck) => deck.deck_id === card.deck_id);
  if (deck) {
    category = categories.find((category) => category.category_id === deck.category_id);
    if (category) {
      channel = channels.find((channel) => channel.id === category.app_content_scope_id);
      if (channel) {
        group = groups.find((group) => group.id === channel.app_content_scope_group_id);
      }
    }
  }
  if (deck && category && channel && group) {
    myCard.title = `${myCard.title} ( ${group && group.name ? group.name : "Undefined"} > ${channel && channel.name ? channel.name : "Undefined"} > ${category && category.category && category.category.name ? category.category.name : "Undefined"} > ${deck && deck.deck_category_deck && deck.deck_category_deck.title ? deck.deck_category_deck.title : "Undefined"} )`
    return myCard
  } else {
    return null
  }
};

export default ({
  selectedCardId,
  onCardSelect,
  title,
  theme,
  marginTop,
  placeholder
}) => {
  let options = store.getState().appBasicControls.cards || [];
  options = options.filter((el) => !el.card.archived && el.published).map((el) => getCardRelation(el)).filter((el) => el);
  let matchingOption = options.filter((el) => el.id == selectedCardId);
  return (
    <>
      {title ? <h5
        style={{
          color: theme.colors.primaryA,
          marginTop: marginTop || theme.sizing.scale1200,
        }}
      >
        {title}
      </h5> : null}
      <Select
        options={options}
        onChange={({ option }) => {
          if (option) onCardSelect(option.id)
          else onCardSelect(null)
        }}
        value={matchingOption}
        placeholder={selectedCardId ? '' : placeholder}
        multi={false}
        labelKey={'title'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </>
  )
}
