import React from 'react';
import { Input, Textarea } from 'baseui/textarea';
import BaseClass from './base';
import { Button } from 'baseui/button';

class InputComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  render() {
    let value = this.state.value || [];
    if (typeof value === 'string') {
      value = JSON.parse(value);
    };
    // console.log('value value value', value);
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        {value.map((el, i) => {
            return <>
            <h5
                style={{
                color: this.props.theme.colors.primaryA,
                marginTop: this.props.theme.sizing.scale1200,
                }}
            >
                {`Matching Pair #${i + 1}`}
             </h5>
                {el.map((el1, i1) => {
                return <>
                <h5
                style={{
                color: this.props.theme.colors.primaryA,
                marginTop: this.props.theme.sizing.scale1200,
                }}
                >
                    {`Matching Item`}
                </h5>
                <Textarea
                    value={el1}
                    onChange={e => {
                    // this.setState({ value: e.target.value })
                    let v1 = [...value];
                    v1[i][i1] = e.target.value;
                    this.setState({value: JSON.stringify(v1)})
                    }}
                    placeholder={this.props.placeholder}
                    clearOnEscape
                /></>
            })}
            </>
        })}
        <Button 
        style={{
          marginTop: '16px',
        }}
        onClick={() => {
          // debugger;
          let v1 = [...value];
          v1.push(["", ""]);
          this.setState({value: JSON.stringify(v1)})
        }}>Add New Matching Pair</Button>
      </>
    );
  }
}

export default InputComponent;
