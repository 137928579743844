import Cookies from 'universal-cookie';
import { navigate } from '@reach/router';

const cookies = new Cookies();
const LAST_CHANNEL_USER_COOKIE = 'cardware_last_channel';


export const setChannelToCookies = (id) => cookies.set(LAST_CHANNEL_USER_COOKIE, id, { path: '/' });

export const getChannelIdFromCookie = () => {
  return cookies.get(LAST_CHANNEL_USER_COOKIE);
};

export const validateChannelId = (channels, channelId, groups, groupId) => {
  let channelStatus = channels.filter(ch => !ch.archived).find(ch => ch.id === channelId);
  let groupStatus = groups.filter(g => !g.archived).find(g => channelStatus && g.id === channelStatus.app_content_scope_group_id);
  if (groupStatus) {
    groupStatus = groupStatus.id == groupId;
  };
  return channelStatus && groupStatus;
};

export const getFirstChannelId = (groups, channels) => {
  let groupId = 'standard';
  let channelId = 'no-channel';
  let fg = groups.filter(g => !g.archived).sort((a, b) => a.position - b.position);
  for (var i = 0; i < fg.length; i++) {
    let chs = channels.filter(ch => !ch.archived && ch.app_content_scope_group_id == fg[i].id).sort((a, b) => a.position - b.position);
    if (chs.length > 0) {
      groupId = fg[i].id
      channelId = chs[0].id;
      break;
    }
  };
  if (groupId == 'standard' && fg.length > 0) groupId = fg[0].id; 
  return {
    groupId,
    channelId
  }
};


export const _navigate = async (groups, channels, organization_id, channelId, groupId, next) => {

  let isRedirectionRequired = false;
  let lastSelectedChannelId = getChannelIdFromCookie();

  if (channelId && channelId === lastSelectedChannelId) {
    debugger
    return;
  }
  // if channelId is not available get last channel id from cookies
  if (!channelId) {
    isRedirectionRequired = true;
    channelId = getChannelIdFromCookie();
    let _c = channels.find(ch => ch.id === channelId);
    if (_c) groupId = _c.app_content_scope_group_id;
  };
  // check is this channel id is valid in current case
  let isValid = validateChannelId(channels, channelId, groups, groupId);
  // if not valid get first channelId
  if (!isValid) {
    let response = getFirstChannelId(groups, channels);
    channelId = response.channelId;
    groupId = response.groupId;
    isRedirectionRequired = true;
  };

  if (isRedirectionRequired) {
    console.log('VALIDATION FAILED REDIRECTING TO NEW CHANNEL');
    console.log('VALIDATION FAILED REDIRECTING TO NEW CHANNEL');
    console.log('VALIDATION FAILED REDIRECTING TO NEW CHANNEL');
    await navigate(
      `/app/${organization_id}/groups/${groupId}/channels/${channelId}`
    );
    debugger
    return setTimeout(() => {
      next(channels.find(el => el.id == channelId));
    }, 200);
  };
  next(channels.find(el => el.id == channelId));
};