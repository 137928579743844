import React from 'react';
import { DatePicker } from 'baseui/datepicker';
import BaseClass from './base';

class DateComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(),
    };
  }

  render() {
    let value = new Date(
      this.state.value ? this.state.value : new Date().toDateString()
    );
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        <DatePicker
          value={value}
          onChange={({ date }) => this.setState({ value: date })}
        />
      </>
    );
  }
}

export default DateComponent;
