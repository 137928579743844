import React from 'react';
import ReactHighcharts from 'react-highcharts';

export default function BarChart(props) {


  const handBarChartData = () => {

    const verticalData = props.data.map(el => {
      return {
        name: el.x,
        y: el.y,
        drilldown: el.x,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgb(52, 69, 236)'],
            [1, 'rgb(93, 100, 171)']
          ]
        }
      }
    })

    // [
    //   {
    //     name: "Cardware",
    //     y: 10,
    //     drilldown: "Cardware",
    //     color: {
    //       linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    //       stops: [
    //         [0, 'rgb(52, 69, 236)'],
    //         [1, 'rgb(93, 100, 171)']
    //       ]
    //     }
    //   }
    // ];
    return verticalData;
  }

  const handBarChartType = () => {

    switch (props.chartType) {
      case "horizontal":
        return "bar";
      case "vertical":
        return "column";
    }
  }



  return (
    <ReactHighcharts config={{
      chart: {
        type: handBarChartType(),
        backgroundColor: props.backgroundColor,
        style: {
          "border-radius": "5px",
        }
      },
      tooltip: {
        formatter: function() {
          console.log(this);
          return `
            <div style="background: ${this.color}; color: white">
              <b>${this.key}</b> <br/>
              <span style="color:#4A4CA0; font-weight:bold; font-size: 14px;">${this.point.y}</span>
            </div>
          `;
        }
      },
      credits: {
        enabled: false
      },
      title: false,
      subtitle: false,
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        allowDecimals: false,
        type: 'category'
      },
      yAxis: {
        allowDecimals: false,
        title: false,
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        [handBarChartType()]: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            // format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          colors: [
            'rgba(118, 95, 237, 1)',
            'rgba(16, 156, 241, 1)',
            'rgba(255, 230, 0, 1)',
            'rgba(102, 209, 158, 1)',
            'rgba(255, 138, 0, 1)',
            'rgba(255, 0, 0, 1)'
          ]
        }
      },
      series: [
        {
          name: props.title,
          colorByPoint: true,
          data: handBarChartData(),
          // pointWidth: 70,
        },
      ],

    }}></ReactHighcharts>
  );
}