import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Tabs, Tab, FILL } from 'baseui/tabs-motion';
import {
  ParagraphSmall,
} from 'baseui/typography';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import ProfileEditor from './editor';
import RightSidePageHeader from '../pageHeaders';
import { gridPaddingOverrides } from '../overrides';

const Profile = ({ organization_id, organization }) => {
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState(0);
  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>
          <RightSidePageHeader
            icon={null}
            title={'My Profile'}
            headerDescription={"Edit your profile here"}
          >
          </RightSidePageHeader>
        </Cell>
        <Cell span={12}>
          <ProfileEditor organization_id={organization_id} organization={organization}></ProfileEditor>
        </Cell>
      </Grid>
    </>
  );
};

export default Profile;
