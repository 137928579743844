import { SET_CARDS, SET_CARD_BACK_DATA, UPDATE_CARD_BACK_DATA, SET_DECKS, SET_CREATED_CATEGORY, SET_CREATED_COMPONENT, SET_TOUR_POP_STATUS } from '../actions/types';
import { toaster } from 'baseui/toast';
import { cloneDeep } from 'lodash'
const initialState = {
  searchText: '',
  searchPreviewData: [],
  isTourPopEnabled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CARDS:
      return { ...state, cards: action.cards };
    case 'UPDATE_CARDS':
      return { ...state, cards: action.cards };
    case 'SET_QUESTION_CARDS':
      return { ...state, questionCards: action.data };
    case SET_CARD_BACK_DATA: {
      return { ...state, cardBackData: [...cloneDeep(action.data)] };
    }
    case UPDATE_CARD_BACK_DATA:
      return { ...state, cardBackData: [...action.data] };
    case SET_DECKS:
      return { ...state, decks: action.data };
    case 'UPDATE_DECK':
      return {
        ...state, decks: state.decks.map(item => {
          if (item.deck_id === action.data.deck_id) {
            return action.data
          }
          return item;
        })
      };
    case 'SET_PRIMARY_DATA_BY_ORG':
      return { ...state, primaryDataByOrg: action.data };
    case 'SET_CAT':
      return { ...state, category: action.data };
    case 'SET_GROUPS':
      return { ...state, groups: action.data };
    case 'SET_CHANNELS':
      return { ...state, channels: action.data };
    case 'SET_ORG':
      return { ...state, organization: action.data };
    case 'SET_ORG_SETTINGS':
      return { ...state, organization_settings: action.data };
    case 'SET_SELECTED_CHANNEL':
      return { ...state, selectedChannel: action.data };
    case 'SET_SELECTED_GROUP':
      return { ...state, selectedGroup: action.data };
    case 'SET_BREADCRUMB':
      return { ...state, breadCrumb: action.data };
    case 'SET_LOADING':
      return { ...state, isLoading: action.data };
    case 'SET_USER':
      return { ...state, user: action.data };
    case 'SET_SEARCH_TEXT':
      return { ...state, searchText: action.data };
    case 'SET_SEARCH_PREVIEW':
      return { ...state, searchPreviewData: action.data };
    case 'SET_JWPLAYER_DATA':
      return { ...state, jwPlayerData: action.data };
    case 'SET_LIBRARY_DATA':
      return { ...state, libraries: action.data };
    case 'SHOW_ALERT':
      let d1 = action.data;
      d1.pushed_at = new Date().getTime();
      return { ...state, showAlert: d1 };
    case 'TOGGLE_THEME':
      localStorage.setItem(
        'cardware_admin_theme',
        state.theme === 'dark' ? 'light' : 'dark'
      );
      return { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' };
    case SET_CREATED_CATEGORY:
      return { ...state, createdCategory: action.data };
    case SET_CREATED_COMPONENT:
      return { ...state, createdComponent: action.data };
    case SET_TOUR_POP_STATUS: 
      return { ...state, isTourPopEnabled: action.data };
    default:
      return state;
  }
}
