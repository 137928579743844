import React, { Component, Node } from 'react';
import { useStyletron } from 'baseui';

const EditorMainOptionsButton = ({ data, selectedMainOptionId }) => {
  const [css, theme] = useStyletron();
  const isSelected = selectedMainOptionId == data.id
  return (
    <div
      className={css({
        width: '100%',
        height: '82px',
        backgroundColor:
        isSelected ? 'rgb(20 20 20)' : 'transparent', // #EFF3FE
        paddingTop: '16px',
        cursor: 'pointer',
      })}
    >
      <div
        className={css({
          position: 'relative',
          float: 'left',
          width: '100%',
          textAlign: 'center',
        })}
      >
        {data.icon}
      </div>
      <div
        className={css({
          position: 'relative',
          float: 'left',
          width: '100%',
          textAlign: 'center',
          color: 'white', // theme.colors.primaryA,
          // fontFamily: 'IBM Plex Sans',
          fontWeight: 500,
          fontSize: '14px'
        })}
      >
        {data.display}
      </div>
    </div>
  );
};

export default EditorMainOptionsButton;
