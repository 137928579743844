import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import _ from 'lodash';
import { getTags } from '../../quries';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';

export default ({ user, organization_id,  userTags, setUserTags }) => {

    const [css, theme] = useStyletron();
    const [tags, setTags] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');

    const loadTags = () => {
        client.query({
            query: getTags(organization_id),
            fetchPolicy: 'network-only'
        }).then(({ data }) => {
            setTags(data.tags_org)
        })
    };

    

    React.useEffect(() => {
        loadTags()
    }, []);

    React.useEffect(() => {
        if (searchText == '') {
            setFiltered(tags)
        } else {
            setFiltered(tags.filter(e => e.name.toLowerCase().startsWith(searchText)))
        }
    }, [searchText, tags]);

    const getCheckedStatus = (valueId) => {
        let isExists = userTags.find(el => {
            return (
                el.tag_id == valueId
            );
        });
        if (!isExists) return false;
        if (isExists.checked === undefined) return true;
        return isExists.checked;
    };

    const markChecked = (tagId, checked) => {
        let tag = userTags.findIndex(el => {
            return (
                el.tag_id == tagId
            );
        });
        let userTagCopy = JSON.parse(JSON.stringify(userTags));
        if (tag == -1) {
            userTagCopy.push({
                tag_id: tagId,
            });
            tag = userTagCopy.length - 1;
        }
        userTagCopy[tag].checked = checked;
        setUserTags(userTagCopy);
    };


    return (
        <>
            <Grid overrides={gridPaddingOverrides}>
                <Cell overrides={cellPaddingOverrides} span={12}>
                    <div
                        className={css({
                            padding: '24px',
                            marginBottom: '24px',
                        })}
                    >
                        {tags.length <= 0 ? (
                            <div
                                className={css({
                                    position: 'absolute',
                                    width: theme.sizing.scale1200,
                                    height: theme.sizing.scale1200,
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                })}
                            >
                                <Spinner />
                            </div>
                        ) : (
                                <Grid overrides={gridPaddingOverrides}>
                                    <div
                                        style={{
                                            float: 'left',
                                            width: '100%',
                                            marginRight: '48px',
                                            paddingTop: '16px',
                                            paddingBottom: '16px',

                                        }}>
                                        <Input
                                            placeholder="Search by name"
                                            clearOnEscape
                                            clearable
                                            onChange={search => setSearchText(search.target.value.toLowerCase())}
                                            endEnhancer={<Search size="18px" />}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            float: 'left',
                                            width: '100%',
                                            backgroundColor: theme.colors.primaryB,
                                            marginRight: '48px',
                                            paddingTop: '16px',
                                            paddingBottom: '16px',
                                            borderRadius: '4px',
                                            marginBottom: '48px',
                                        }}
                                    >
                                        {filtered.map((el, i2) => {
                                            return (
                                                <div
                                                    key={i2}

                                                    className={css({
                                                        paddingTop: theme.sizing.scale300,
                                                        paddingLeft: '24px',
                                                        paddingRight: '24px',
                                                        minWidth: '300px',
                                                        float: 'left',
                                                        display: 'block'
                                                    })}
                                                >
                                                    <Checkbox
                                                        checked={getCheckedStatus(el.id)}
                                                        onChange={e =>
                                                            markChecked(el.id, e.target.checked)
                                                        }
                                                        labelPlacement={LABEL_PLACEMENT.right}
                                                    >
                                                        {`${el.name}`}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </Grid>
                            )}
                    </div>
                </Cell>
            </Grid>
        </>
    );
};
