import VimeoIcon from './vimeo_logo_blue_on_white.svg';
import YoutubeIcon from './yt_logo_rgb_light.svg';
import CloudUploadIcon from './cloud_upload.svg';
import AddGradientIcon from './add_gradient_icon.svg';
import JWPlyaerIcon from './jwplayer_icon.svg';

const svgIcons = {
    VimeoIcon,
    YoutubeIcon,
    CloudUploadIcon,
    AddGradientIcon,
    JWPlyaerIcon
};

export default svgIcons;