import * as React from 'react';
import { Card, StyledBody, StyledAction, StyledThumbnail } from 'baseui/card';
import { Button } from 'baseui/button';
import {
  LabelMedium,
  LabelSmall,
  HeadingSmall,
  ParagraphMedium,
  HeadingXSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './channelCard.css';
import BaseImageUrl from './images/Image.png';
import EditChannel from './addEditChannel';
import {
  setSelectedChannel,
  setChannels,
} from '../../redux/actions/appBasicControls';
import { navigate } from '@reach/router';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import ReactHtmlParser from 'react-html-parser';

export default ({
  card,
  usePortal,
  title,
  count,
  toggle,
  imageSrc,
  onToggleClick,
  organization_id,
  app_content_scope_group_id,
  channel,
  dispatch,
  isCollapsed,
  onCollapseButtonClick,
}) => {
  const [css, theme] = useStyletron();
  const [onHover, setOnHover] = React.useState(false);
  const [isEditOpen, setIsEditOpen] = React.useState(false);

  const onSettingsClick = d => {
    setIsEditOpen(true);
  };

  const onClose = () => {
    setIsEditOpen(false);
  };
  return (
    <>
      {isCollapsed ? (
        <div
          className={css({
            position: 'relative',
            width: '519px',
            height: '34px',
            lineHeight: '34px',
            background: usePortal
              ? theme.colors.primaryC
              : theme.colors.channelCardBackgroundColor,
            marginBottom: '16px',
            display: 'flex',
            padding: '8px',
            color: usePortal ? 'white' : 'black',
          })}
        >
          <StatefulTooltip
            content={() => <div padding={'20px'}>Reorder</div>}
            placement={PLACEMENT.bottom}
            returnFocus
            autoFocus
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                fill={usePortal ? 'white' : 'black'}
              />
            </svg>
          </StatefulTooltip>

          <LabelSmall
            className={css({
              marginLeft: '16px',
              color: theme.colors.primaryB,
            })}
          >
            {card.name}
          </LabelSmall>
        </div>
      ) : (
        <div
          onClick={() => {
            dispatch(setSelectedChannel(channel));
            navigate(
              `/app/${organization_id}/groups/${app_content_scope_group_id}/channels/${channel.id}`
            );
          }}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className={css({
            position: 'relative',
            height: 'auto',
            background:
              usePortal || onHover
                ? theme.colors.primaryC
                : theme.colors.channelCardBackgroundColor,
            marginTop: '16px',
            marginBottom: '16px',
            display: 'flex',
            padding: '16px',
            color: usePortal || onHover ? 'white' : 'black',
            borderRadius: '5px',
            transition: '0.8s',
          })}
        >
          <div>
            {(
              <StatefulTooltip
                content={() => <div padding={'20px'}>Reorder</div>}
                placement={PLACEMENT.bottom}
                returnFocus
                autoFocus
              >
                <div
                  style={{display: 'inline'}}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCollapseButtonClick();
                  }}
                >
                  <svg
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: 'pointer' }}
                  >
                    <path
                      d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                      fill={usePortal || onHover ? 'white' : 'black'}
                    />
                  </svg>
                </div>
              </StatefulTooltip>
            )}

            <StatefulTooltip
              content={() => (
                <div padding={'20px'}>{toggle ? 'Toggle off to Publish' : 'Toggle on to Publish'}</div>
              )}
              returnFocus
              autoFocus
              placement={PLACEMENT.bottom}
            >
              <div
                className={css({
                  position: 'absolute',
                  width: theme.sizing.scale1200,
                  height: theme.sizing.scale1200,
                  right: '16px',
                  top: '16px',
                })}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Toggle
                  id="cheese-status"
                  defaultChecked={toggle}
                  icons={false}
                  checked={toggle}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    // if (tabFilter === "archived") {
                    //   return dispatch(
                    //     showAlert({
                    //       msg: 'Archived category can\'t be published!',
                    //       error: true,
                    //     })
                    //   );
                    // }
                    onToggleClick(e);
                  }}
                />
              </div>
            </StatefulTooltip>

            <div
              className={css({
                position: 'absolute',
                width: theme.sizing.scale1200,
                height: theme.sizing.scale1200,
                right: '16px',
                bottom: '16px',
                width: '22px',
                height: '22px',
                cursor: 'pointer',
              })}
              onClick={event => {
                event.stopPropagation();
                onSettingsClick();
              }}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
                  fill={usePortal || onHover ? 'white' : 'black'}
                />
              </svg>
            </div>
            <div
              className={css({
                position: 'relative',
                marginTop: '16px',
              })}
            >
              <div
                className={css({
                  background: imageSrc ? imageSrc : "#000000",
                  backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.44) 0%, rgba(0, 0, 0, 0.88) 100%)",
                  width: '92px',
                  height: '92px',
                  backgroundSize: 'contain !important',
                  position: 'cover',
                  float: 'left',
                  marginTop: '16px',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: "4%"
                })}
              ></div>
              <div
                className={css({
                  position: 'relative',
                  paddingLeft: theme.sizing.scale800,
                  float: 'left',
                })}
              >
                <HeadingXSmall
                  className={css({
                    color: onHover ? 'white !important' : 'black !important',
                    marginBottom: '5px',
                  })}
                >
                  {title}
                </HeadingXSmall>
              </div>
              <div
                className={css({
                  position: 'relative',
                  paddingLeft: theme.sizing.scale800,
                  float: 'left',
                  width: '89%',
                  height: 'auto',
                  whiteSpace: 'initial',
                })}
              >
                {ReactHtmlParser(channel.description)}
              </div>
            </div>
          </div>
        </div>
      )}
      {isEditOpen && (
        <EditChannel
          organization_id={organization_id}
          dispatch={dispatch}
          app_content_scope_group_id={app_content_scope_group_id}
          count={count}
          onClose={onClose}
          name={channel.name}
          public={channel.public}
          logo_data={channel.logo_data}
          id={channel.id}
          description={channel.description}
          media_url={channel.media_url}
          content_controlled={channel.content_controlled}
          pincode={channel.pincode}
          abbr={channel.abbr}
        ></EditChannel>
      )}
    </>
  );
};
