import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import store from './src/redux/store';
import { client } from './src/apollo/client';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { lightTheme, darkTheme } from './theme';
import { connect } from 'react-redux';
import SessionCheck from './src/auth/sessionCheck';
import {SentryIntegration} from './sentry';

const mapStateToProps = props => {
  let { theme } = props.appBasicControls;
  return {
    theme,
  };
};

const ThemeProvider = connect(mapStateToProps)(({ children, theme }) => {
  return (
    <BaseProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <ZoomContainer>
        {children}
      </ZoomContainer>
    </BaseProvider>
  );
});

// const ZOOM = 0.7;
// const WIDTH = 100 / ZOOM;
// const HEIGHT = 100 / ZOOM;
const ZoomContainer = ({ children }) => {
  window.addEventListener('resize', function () {
    console.log('resize');
  });
  return <div style={{
    // zoom: `${ZOOM}`,
    // position: 'fixed',
    // width: `${WIDTH}%`,
    // height: `${HEIGHT}%`
  }}>
    {children}
  </div>
}

export default ({ element }) => {
  const styletron = require('styletron-engine-atomic');
  const clientEngine = new styletron.Server();
  return (
    <SessionCheck>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <StyletronProvider value={clientEngine}>
            <ThemeProvider>
              <SentryIntegration>
                {element}
              </SentryIntegration>
            </ThemeProvider>
          </StyletronProvider>
        </ApolloProvider>
      </Provider>
    </SessionCheck>
  );
};
