import React from 'react';
import { Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { Button, KIND as BUTTON_KIND, SHAPE } from "baseui/button";
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Router } from '@reach/router';
import { Spinner } from 'baseui/spinner';
import { navigate } from '@reach/router';
import { login, forgotPassword } from '../auth/index';
import { saveModalOnEnterPress } from '../util';
import { Search } from 'baseui/icon';
import {
  LabelSmall,
  LabelLarge
} from 'baseui/typography';
import { Notification, KIND } from "baseui/notification";
import ArrowLeft from 'baseui/icon/arrow-left'
import LoginContainer from '../components/loginContainer';
import './login.css';
import { adminSliders } from '../helpers/slidersData';
import { removeEmailCookie, getEmailFromCookie, setEmailToCookie } from '../auth/helpers';
import { getAdminPageSliders } from '../quries';
import styled from '@emotion/styled';

const ForgotButton = styled(Button)`
  color: ${props => props.color}; 
  margin-top: 8px; 
  cursor: pointer; 
  text-decoration: underline; 
  text-align: right; 
  float: right;
`;

const organizationBtnStyle = {
  width: '100%',
  marginTop: '16px'
}

const sliders = adminSliders;

const LoginWithAuth0Page = ({ subDomain, email }) => {
  const { data, loading } = useQuery(
    gql`
        query domain {
            organization_domain_by_name(domain: "${subDomain}"){
                organization {
                  name
                  id
                  auth0 {
                    domain
                    client_id
                    client_secret
                  }
                }
            }
        }
  `,
    { fetchPolicy: 'network-only' }
  );
  const [css, theme] = useStyletron();
  const [password, setPassword] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [isForgetLoading, setForgetLoading] = React.useState(false);
  if (!subDomain) {
    navigate('/login');
    return <></>;
  };
  if (loading) {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            color: theme.colors.primaryA,
          }}
        >
          Setting Up login from Auth0
        </div>
        <div
          className={css({
            position: 'absolute',
            width: theme.sizing.scale1200,
            height: theme.sizing.scale1200,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          })}
        >
          <Spinner />
        </div>
      </>
    );
  };
  if (!data || !data.organization_domain_by_name) {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            color: theme.colors.primaryA,
          }}
        >
          No organization found on this domain {subDomain}
        </div>
        <Button
          overrides={{
            BaseButton: { style: { width: '100%', marginTop: '16px' } },
          }}
          onClick={() => navigate('/login')}
        >
          Go Back
        </Button>
      </>
    );
  }
  if (!email) {
    navigate('/login');
    return <></>;
  };
  const loginInternal = (organization) => {
    setLoading(true);
    setError('');
    login(organization, email, password).catch(err => {
      setLoading(false);
      setError('Invalid Email or Password');
    })
  };

  const resetPassword = () => {
    setForgetLoading(true)
    setError('');
    setInfo('');
    forgotPassword(data.organization_domain_by_name.organization.id, email, window.location.origin).then((res) => setInfo(res)).catch((err) => setError(err))
      .finally(() => setForgetLoading(false));
  };


  return (
    <>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          color: theme.colors.primaryA,
        }}
      >
        Login into{' '}
        <b>{data.organization_domain_by_name.organization.name}</b>
      </div>
      <Cell
        span={12}
        overrides={{
          Cell: {
            style: () => ({
              marginTop: '32px',
            }),
          },
        }}
      >
        {(error || info) && <>
          <Notification
            kind={error ? KIND.negative : KIND.positive}
            overrides={{
              Body: { style: { width: 'auto' } },
            }}
            onClose={() => {
              setInfo('');
              setError('');
            }}
            closeable
          >
            {() => error || info}
          </Notification>
          <hr style={{ height: '2px', margin: 0, background: '#E2E2E2', marginBottom: '8px' }} />
        </>}
        <Input
          value={email}
          placeholder="Email"
          clearOnEscape
          disabled
          overrides={{
            Input: {
              style: {
                color: `${theme.colors.primaryA} !important`,
                ...theme.typography.ParagraphLarge
              }
            }
          }}
        />
        <div style={{ width: '100%', marginTop: '16px' }}></div>
        <Input
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          clearOnEscape
          type={"password"}
        />
        <Button
          key={'1'}
          isLoading={isLoading}
          overrides={{
            BaseButton: { style: { width: '100%', marginTop: '16px' } },
          }}
          onClick={() => {
            setError('');
            setInfo('');
            password && loginInternal(data.organization_domain_by_name.organization);
          }}
        >
          Login
        </Button>
        <ForgotButton onClick={resetPassword}
          kind={isForgetLoading ? BUTTON_KIND.secondary : BUTTON_KIND.tertiary}
          isLoading={isForgetLoading}
          shape={isForgetLoading ? SHAPE.circle : SHAPE.default}
          color={theme.colors.primary}
        >
          Forgot Password?
        </ForgotButton>
        {/* <LabelSmall onClick={() => resetPassword(email)}  
          style={{ color: theme.colors.primary, marginTop: '8px', cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }}
        >
          Forgot Password?
        </LabelSmall> */}
      </Cell>
    </>
  );
};

const OrganizationList = ({ email }) => {
  const { data, loading } = useQuery(gql`
      query domains{
          organization_domains_by_email(email: "${email}"){
              domain
              organization {
                name
                id
              }
            }
      }
  `);
  const [css, theme] = useStyletron();
  const [selectedOrg, setSelectedOrg] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const list = data ? data.organization_domains_by_email || [] : [];
  React.useEffect(() => {
    if (searchText === '') setSelectedOrg(list);
    else {
      let _searchText = searchText;
      _searchText = _searchText.trim().toLowerCase();
      setSelectedOrg(list.filter((el) => {
        let name = el.organization.name;
        name = name.trim().toLowerCase();
        return name.includes(_searchText)
      }));
    };
  }, [searchText, list]);

  if (!email) {
    navigate('/login');
    return <></>;
  }
  if (loading) {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            color: theme.colors.primaryA,
          }}
        >
          Loading organizations for email {email}
        </div>
        <div
          className={css({
            position: 'absolute',
            width: theme.sizing.scale1200,
            height: theme.sizing.scale1200,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          })}
        >
          <Spinner />
        </div>
      </>
    );
  }


  if (list.length === 0) {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            color: theme.colors.primaryA,
          }}
        >
          <Notification
            kind={KIND.positive}
            overrides={{
              Body: { style: { width: 'auto' } },
            }}
          >
            {() => 'No organization matches your email address. Please try again'}
          </Notification>
        </div>
        <Button
          overrides={{
            BaseButton: { style: { width: '100%', marginTop: '8px' } },
          }}
          onClick={() => navigate('/login')}
          startEnhancer={() => <ArrowLeft size={24} />}
        >
          Go Back
        </Button>
      </>
    );
  }
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          color: theme.colors.primaryA,
        }}
      >
        Select one of your organizations below to access your Cardware Administration Panel.
      </div>
      <Cell
        span={12}
        overrides={{
          Cell: {
            style: () => ({
              marginTop: '32px',
            }),
          },
        }}
      >
        <Input
          endEnhancer={<Search size="18px" />}
          placeholder="Search Organization"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <div style={{
          position: 'relative',
          width: '100%',
          maxHeight: '39vh',
          overflowY: 'auto'
        }}>
          {selectedOrg.map((el, index) => {
            return (
              <Button
                key={index}
                overrides={{
                  BaseButton: { style: organizationBtnStyle },
                }}
                onClick={() => navigate(`/login/organizations/${el.domain}`)}
              >
                Go to {el.organization.name}
              </Button>
            );
          })}
        </div>
      </Cell>
    </>
  );
};

const EmailInput = ({ email, setEmail }) => {
  const [css, theme] = useStyletron();
  if (css) console.log('Ok Warning');
  const onClick = () => {
    removeEmailCookie();
    setEmailToCookie(email);
    navigate('/login/organizations');
  };
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          color: theme.colors.primaryA,
        }}
      >
        Enter your details below here
      </div>
      <Cell
        span={12}
        overrides={{
          Cell: {
            style: () => ({
              marginTop: '32px',
            }),
          },
        }}
      >
        <Input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email Address"
          clearOnEscape
          onKeyDown={e => {
            saveModalOnEnterPress({ e, onEnterPress: onClick });
          }}
        />
        <Button
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                marginTop: '16px'
              }
            },
          }}
          onClick={() => onClick()}
        >
          Find Organizations
        </Button>
      </Cell>
    </>
  );
};

export default function Login() {
  const { loading, error, data: sliderData } = useQuery(getAdminPageSliders);
  const [email, setEmail] = React.useState('');
  const [sliders, setSliders] = React.useState([]);
  const emailFromCookie = getEmailFromCookie();

  React.useEffect(() => {
    if (emailFromCookie) setEmail(emailFromCookie);
  }, [emailFromCookie])

  React.useEffect(() => {
    if (sliderData && sliderData.adminIndexPageSliders && sliderData.adminIndexPageSliders.length > 0) {
      setSliders(sliderData.adminIndexPageSliders);
    }
  }, [sliderData]);

  return (
    <LoginContainer sliders={sliders || []}>
      <Router>
        <EmailInput
          email={email}
          setEmail={setEmail}
          path="/login"
        ></EmailInput>
        <OrganizationList
          email={email}
          path="/login/organizations"
        ></OrganizationList>
        <LoginWithAuth0Page email={email} path="/login/organizations/:subDomain"></LoginWithAuth0Page>
      </Router>
    </LoginContainer>
  );
}
