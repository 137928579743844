import * as React from 'react';
import { Grid } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { showAlert } from '../../redux/actions/appBasicControls';
import { archiveCardMutation, archiveCardStackCardMutation, archiveQuizCardMutation } from '../../quries';
import { client } from '../../apollo/client';

export default ({ card, onCancel, dispatch, refreshQuizQuestions }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  if (isOpen === false) onCancel();

  const [
    archiveCard,
    { loading: archiveCardLoading, data: archivedCard },
  ] = useMutation(archiveCardMutation);
  React.useEffect(() => {
    setIsLoading(false);
    if (archivedCard) {
      dispatch(
        showAlert({
          msg: 'Successfully archived card!',
          error: false,
        })
      );
      onCancel();
    }
  }, [archivedCard]);

  const onArchive = () => {
    setIsLoading(true);
    dispatch(
      showAlert({
        msg: 'Archiving card!',
        error: false,
      })
    );
    if (card.quiz_question_id) {
      return client.mutate({
        mutation: archiveQuizCardMutation,
        variables: {
          id: card.quiz_question_id,
          quiz_deck_id: card.quiz_deck_id
        }
      }).then(() => {
        refreshQuizQuestions();
        setIsLoading(false);
        dispatch(
          showAlert({
            msg: 'Successfully archived card!',
            error: false,
          })
        );
        onCancel();
      })
    } else if (card.card_back_home_component_card) {
      return client.mutate({
        mutation: archiveCardStackCardMutation,
        variables: {
          id: card.card_back_home_component_card.id,
          card_stack_id: card.id
        }
      }).then(() => {
        refreshQuizQuestions();
        setIsLoading(false);
        dispatch(
          showAlert({
            msg: 'Successfully archived card!',
            error: false,
          })
        );
        onCancel();
      })
    }

    archiveCard({
      variables: {
        id: card.card_id,
        deck_id: card.deck_id,
      },
    });
  };

  console.log(card);

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3
            }
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          onCancel();
        }}
      >
        <ModalHeader>{ }</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <H5>
              Are you sure you want to archive this card - {' '}
              <b style={{ color: 'rgb(118, 95, 237)' }}>
                {card.card ? card.card.title : card.card_back_home_component_card ? card.card_back_home_component_card.title :'Quiz'}
              </b>{' '}
              ?{' '}
            </H5>
            <H6>
              This process may take a few moments and all changes are final. If
              you need to move this back, you will be able to do so.
            </H6>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={archiveCardLoading}
            onClick={() => {
              onArchive();
            }}
          >
            Archive
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
