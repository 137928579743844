import React from 'react';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import BaseClass from './base';
import styled from '@emotion/styled';

const HeaderTitle = styled.h5`
  color: ${props => props.theme.colors.primaryA};
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

class CheckboxComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
  }

  render() {
    return (
      <>
        <HeaderTitle
          theme={this.props.theme}
        >
          {this.props.title || ''}
        </HeaderTitle>
        <Checkbox
          checked={this.state.value}
          onChange={e =>  this.setState({ value: e.target.checked })}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          {this.props.placeholder}
    </Checkbox>
      </>
    );
  }
}

export default CheckboxComponent;
