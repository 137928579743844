import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Card, StyledBody, StyledAction } from 'baseui/card';
import { HeadingMedium, ParagraphSmall } from 'baseui/typography';
import PhoneDesign from '../builder/phone-design';
import PreviewElements from '../builder/previewElementsV1';

const style = {
  paddingLeft: '20px',
  paddingRight: '20px',
  marginBottom: '20px'
}

const ArticlePreview = ({ feed }) => {
  let modules = feed.communication.card_content_modules || [];
  var front_image = feed.front_image;
  return <Grid overlay={gridPaddingOverrides}>
    <Cell overrides={cellPaddingOverrides} span={6}>
      <PhoneDesign title="Article Front" helpText="Front Side of a communication tile">
        <div style={{
          width: '100%',
          background: 'rgba(255, 255, 255, 0.1)',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
        }}>
          <Card
            overrides={{ Root: { style: { width: '100%', background: '#2E4C58', border: 'none' } }, StyledBody: { style: {marginTop: '0px'}} }}
            headerImage={front_image}
          >
            <StyledBody>
              <HeadingMedium style={{ color: 'white', paddingBottom: '0px', marginBottom: '16px' }}>{feed.communication.title}</HeadingMedium>
            </StyledBody>
            <StyledAction>
              <ParagraphSmall style={{ paddingBottom: '32px', textAlign: 'left', color: '#869599', fontWeight: 'bold' }}>{feed.communication.tags.toString()}</ParagraphSmall>
            </StyledAction>
          </Card>
        </div>
      </PhoneDesign>
    </Cell>
    <Cell overrides={cellPaddingOverrides} span={6}>
      <PhoneDesign title="Article Back" helpText="Back Side of a communication tile" width={450} height={880}>
        <PreviewElements
          title={feed.communication.title}
          cardBackData={modules}
        ></PreviewElements>
      </PhoneDesign>
    </Cell>
  </Grid>
};

export default ArticlePreview;