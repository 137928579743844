import slider1 from '../../static/images/slider-1.png';
import slider2 from '../../static/images/slider-2.png';
import slider3 from '../../static/images/slider-3.png';
import slider4 from '../../static/images/slider-4.png';
import { deck_help, card_help } from '../components/help/helpTexts';
import appImage from '../../static/images/app_image.png';
import webOs from '../../static/images/webos.png';

export const adminSliders = [
    {
        title: 'Design Decks for Channel',
        description: deck_help,
        icon: slider1,
        background: 'rgba(118, 95, 237, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
    {
        title: 'Design Cards for Deck',
        description: card_help,
        icon: slider2,
        background: 'rgba(16, 156, 241, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
    {
        title: 'Manage Admins',
        description: deck_help,
        icon: slider3,
        background: 'rgba(255, 230, 0, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
    {
        title: 'Analyze by Dashboard',
        description: card_help,
        icon: slider4,
        background: 'rgba(102, 209, 158, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
    {
        title: 'Design Decks for Channel',
        description: deck_help,
        icon: slider1,
        background: 'rgba(255, 138, 0, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
    {
        title: 'Design Cards for Deck',
        description: card_help,
        icon: slider2,
        background: 'rgba(255, 0, 0, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    },
];

export const userSliders = [
    {
        title: '',
        description: 'Get access to catalystxl app',
        icon: appImage,
        background: 'rgba(118, 95, 237, 1)',
    },
    {
        title: '',
        description: 'Get access to WebOs app',
        icon: webOs,
        background: 'rgba(16, 156, 241, 1)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 4px 16px 16px'
    }
];