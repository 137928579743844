import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { addChannel, updateChannel, removeChannel } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';
import { sourceByType } from '../../util';
import TextArea from './textarea';
import { cellPaddingOverrides } from '../overrides';
import ModalStateContainer from '../modalStateContainer';
import ImageUploadCXL from '../imageUploaderCXL';
import { channelImageGuideline, channelIconGuideline } from '../help/imageSizeGuidelines.json';
import { Tabs, Tab } from "baseui/tabs-motion";
import titlesLength from '../../../data/titlesLength.json';
import store from '../../redux/store';
import { Select } from 'baseui/select';
import ChannelMappings from '../apiMappings/channel-mappings';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};
const isValidHttpUrl = (string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(string);
};
const getSrc = data => sourceByType(data, 'full');

export default ({
  app_content_scope_group_id,
  totalCount,
  organization_id,
  onClose,
  logo_data,
  dispatch,
  ...rest
}) => {
  const ORGANIZATION = store.getState().appBasicControls.organization || {};
  console.log(rest, ORGANIZATION);
  const HAS_SUBSCRIPTION = ORGANIZATION.subscriptions ? ORGANIZATION.subscriptions.length > 0 ? true : false : false;
  const [activeKey, setActiveKey] = React.useState("0");
  const [isOpen, setIsOpen] = React.useState(true);
  // console.log('HAS_SUBSCRIPTION', HAS_SUBSCRIPTION, isOpen);
  const [name, setName] = React.useState(rest.name || '');
  const [logo, SetLogo] = React.useState(null);
  const [logoData, setLogoData] = React.useState(logo_data);
  const [publicS, setPublic] = React.useState(rest.public || false);
  const [preview_image, setPreview] = React.useState(null);

  const [icon, setIcon] = React.useState(null);
  const [iconData, setIconData] = React.useState(rest.icon_data);
  const [icon_preview, setIconPreview] = React.useState(null);

  const [description, setDescription] = React.useState(rest.description || '');
  const [mediaUrl, setMediaUrl] = React.useState(rest.media_url || '');
  const [contentControl, setContentControl] = React.useState(
    rest.content_controlled || false
  );
  const [pinCode, setPinCode] = React.useState(rest.pincode || '');
  const [abbr, setAbbr] = React.useState(rest.abbr || '');
  const [sales_button_enabled, setSalesButtonEnabled] = React.useState(rest.sales_button_enabled || false);
  const [sales_incentive_button_title, set_sales_incentive_button_title] = React.useState(rest.sales_incentive_button_title || '');
  const [sales_incentive_description, set_sales_incentive_description] = React.useState(rest.sales_incentive_description || '');
  const [sales_incentive_title, set_sales_incentive_title] = React.useState(rest.sales_incentive_title || '');
  const [sales_incentive_image, set_sales_incentive_image] = React.useState(rest.sales_incentive_image || '');
  const [sales_incentive_image_preview, set_sales_incentive_image_preview] = React.useState(null);
  const [sales_incentive_image_upload, set_sales_incentive_image_upload] = React.useState(null);


  const id = rest.id;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const archived = rest.archived || false;


  const [is_requires_subscription, set_is_requires_subscription] = React.useState(rest.is_requires_subscription || false);
  const [subscription_id, set_subscription_id] = React.useState(rest.subscription_id || null);
  const [subscription_into_headline, set_subscription_into_headline] = React.useState(rest.subscription_into_headline || '');
  const [subscription_button_text, set_subscription_button_text] = React.useState(rest.subscription_button_text || '');
  const [subscription_description, set_subscription_description] = React.useState(rest.subscription_description || '');
  const [subscription_image, set_subscription_image] = React.useState(rest.subscription_image || null);


  const [is_travel_pass_channel, set_is_travel_pass_channel] = React.useState(rest.is_travel_pass_channel || false);
  const [has_data_source_connected, set_has_data_source_connected] = React.useState(rest.has_data_source_connected || false);

  const {is_api_settings_on, is_rewards_nav_on, is_channel_advanced_on, is_sales_button_on, is_subscription_on} = store.getState().appBasicControls.organization_settings;
  
  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const [updateAppContentScope] = useMutation(updateChannel);
  const [createAppContentScope] = useMutation(addChannel);

  const Success = () => {
    setIsLoading(false);
    setIsRemoveLoading(false);
    setIsOpen(false);
    onClose();
  };

  const Error = () => {
    setIsLoading(false);
    setIsRemoveLoading(false);
    setError(true);
  }

  const onRemove = () => {
    if (!id) {
      return;
    }
    setIsRemoveLoading(true);
    updateAppContentScope({
      variables: {
        id,
        archived: !rest.archived
      },
    }).then(() => Success()).catch(() => Error());
  }

  const onSave = () => {
    if (mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl)) {
      return;
    }
    if (id) {
      if (publicS && archived && dispatch) {
        return dispatch(
          showAlert({
            msg: 'Archived channel can\'t be published!',
            error: true,
          })
        );
      }
      setIsLoading(true);
      updateAppContentScope({
        variables: {
          id,
          name,
          // public: publicS,
          logo,
          icon,
          logo_data: logoData,
          icon_data: iconData,
          description,
          media_url: mediaUrl,
          content_controlled: contentControl,
          pincode: pinCode,
          abbr,
          sales_button_enabled,
          sales_incentive_button_title,
          sales_incentive_description,
          sales_incentive_title,
          sales_incentive_image,
          sales_incentive_image_upload,
          is_requires_subscription,
          is_travel_pass_channel,
          has_data_source_connected,
          subscription_id,
          subscription_into_headline,
          subscription_button_text,
          subscription_description,
          subscription_image
        },
      }).then(() => Success()).catch(() => Error());
    } else {
      if (!name) return null;
      setIsLoading(true);
      createAppContentScope({
        variables: {
          name,
          public: publicS,
          logo,
          icon,
          position: totalCount,
          app_content_scope_group_id,
          position: totalCount,
          organization_id,
          description,
          media_url: mediaUrl,
          content_controlled: contentControl,
          pincode: pinCode,
          abbr,
          sales_button_enabled,
          sales_incentive_button_title,
          sales_incentive_description,
          sales_incentive_title,
          sales_incentive_image,
          sales_incentive_image_upload,
          is_requires_subscription,
          is_travel_pass_channel,
          has_data_source_connected,
          subscription_id,
          subscription_into_headline,
          subscription_button_text,
          subscription_description,
          subscription_image
        },
      }).then(() => Success()).catch(() => Error());
    }
  };

  return (
    <ModalStateContainer>
      {({ isConfirmationOpen, toggleConfirm }) => (
        <React.Fragment>
          <Modal
            overrides={{
              Dialog: {
                style: {
                  width: '70vw',
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3
                }
              }
            }}
            isOpen={isOpen}
            onClose={() => {
              // console.log('MODAL CLOSED');
              onClose();
              setIsOpen(false);
            }}
          >
            <ModalHeader>{id ? 'Update' : 'Add'} Channel</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                  setActiveKey(activeKey);
                }}
                activateOnFocus
              >
                <Tab title="Basic">
                  <Grid
                    gridGaps={[12, 12, 12]}
                    overrides={{
                      Grid: {
                        style: ({ $theme }) => outerGridOverride,
                      },
                    }}
                  >
                    <Cell span={6} overrides={cellPaddingOverrides}>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                        <Input
                          value={name}
                          onChange={e => {
                            e.target.value.length <= titlesLength.channel && setName(e.target.value)
                          }}
                          placeholder="Your title"
                          positive={name && name.length > 0 && name.length < titlesLength.channel}
                          clearOnEscape
                        />
                        <ParagraphSmall style={{ marginBottom: '0px' }}>{name.length}/{titlesLength.channel}</ParagraphSmall>
                        {name.length > titlesLength.channel && <p style={{ color: 'red', marginTop: '1rem' }}>Max {titlesLength.channel} characters allowed</p>}
                      </Cell>
                      
                    </Cell>
                    <Cell span={6} overrides={cellPaddingOverrides}>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                        <TextArea
                          value={description}
                          onChange={setDescription}
                        ></TextArea>
                      </Cell>
                    </Cell>
                    <Cell span={6} overrides={cellPaddingOverrides}>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <ImageUploadCXL
                          title={'Channel Image'}
                          titleHelpText={'Select the channel image'}
                          previewUrls={preview_image || getSrc(logoData, 'small')}
                          isMultiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            SetLogo(acceptedFiles);
                            previewImage(acceptedFiles[0]);
                          }}
                          onImageDelete={() => {
                            SetLogo(null);
                            setPreview(null);
                            setLogoData(null);
                          }}
                          showFullBleedSettings={false}
                          imageSize={channelImageGuideline.size}
                          imageSizeGuidelineURL={channelImageGuideline.url}
                        />
                      </Cell>
                    </Cell>
                    <Cell span={6} overrides={cellPaddingOverrides}>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <ImageUploadCXL
                          title={'Channel Icon'}
                          titleHelpText={'Select the channel icon which will be used in navigation nar of mobile app'}
                          previewUrls={icon_preview || getSrc(iconData, 'full')}
                          isMultiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            setIcon(acceptedFiles);
                            setIconPreview(URL.createObjectURL(acceptedFiles[0]));
                          }}
                          onImageDelete={() => {
                            setIconData(null);
                            setIconPreview(null);
                            setIcon(null);
                          }}
                          showFullBleedSettings={false}
                          imageSize={channelIconGuideline.size}
                          imageSizeGuidelineURL={channelIconGuideline.url}
                        />
                      </Cell>
                    </Cell>
                  </Grid>
                </Tab>
                {is_channel_advanced_on && <Tab title="Advanced">
                  <Grid
                    gridGaps={[12, 12, 12]}
                    overrides={{
                      Grid: {
                        style: ({ $theme }) => outerGridOverride,
                      },
                    }}
                  >
                    <Cell span={12} overrides={cellPaddingOverrides}>
                      <Cell
                        span={12}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                        <div style={{ display: 'flex', width: '100%' }}>
                          {/* <Checkbox
                            checked={publicS}
                            onChange={e => setPublic(!publicS)}
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Public</ParagraphSmall>
                          </Checkbox> */}
                          <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={contentControl}
                              onChange={e => setContentControl(!contentControl)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Content Control</ParagraphSmall>
                            </Checkbox>
                          </div>
                          {is_sales_button_on && <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={sales_button_enabled}
                              onChange={e => setSalesButtonEnabled(!sales_button_enabled)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Active Sales Button</ParagraphSmall>
                            </Checkbox>
                          </div>}
                          {is_rewards_nav_on && <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={is_travel_pass_channel}
                              onChange={e => set_is_travel_pass_channel(!is_travel_pass_channel)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Travel Pass Channel</ParagraphSmall>
                            </Checkbox>
                          </div>}
                          {/* <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={has_data_source_connected}
                              onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Has Data Source Connection</ParagraphSmall>
                            </Checkbox>
                          </div> */}

                          {is_api_settings_on && id && <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={has_data_source_connected}
                              onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Make Channel as external API Channel</ParagraphSmall>
                            </Checkbox>
                          </div>}
                        </div>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Pincode</LabelMedium>
                        <Input
                          value={pinCode}
                          onChange={e => setPinCode(e.target.value)}
                          placeholder="Your Pincode"
                          clearOnEscape
                        />
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Media Url</LabelMedium>
                        <Input
                          value={mediaUrl}
                          onChange={e => setMediaUrl(e.target.value)}
                          placeholder="Your media url"
                          positive={mediaUrl && !(mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl))}
                          clearOnEscape
                        />
                        {(mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl)) && <p style={{ color: 'red', marginTop: '1rem' }}>Invalid media URL</p>}
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Abbreviation</LabelMedium>
                        <Input
                          value={abbr}
                          onChange={e => setAbbr(e.target.value)}
                          placeholder="Your abbreviation"
                          clearOnEscape
                        />
                      </Cell>
                    </Cell>
                    <Cell span={6} overrides={cellPaddingOverrides}>
                      {sales_button_enabled &&
                        <>
                          <Cell
                            span={[1, 12]}
                            overrides={{
                              Cell: {
                                style: () => ({
                                  paddingLeft: '0px !important',
                                }),
                              },
                            }}
                          >
                            <LabelMedium style={{ marginBottom: '16px' }}>Sales form title</LabelMedium>
                            <Input
                              value={sales_incentive_title}
                              onChange={e => set_sales_incentive_title(e.target.value)}
                              placeholder="Sales form title"
                              clearOnEscape
                            />
                          </Cell>
                          <Cell
                            span={[1, 12]}
                            overrides={{
                              Cell: {
                                style: () => ({
                                  paddingLeft: '0px !important',
                                }),
                              },
                            }}
                          >
                            <LabelMedium style={{ marginBottom: '16px' }}>Sales form description</LabelMedium>
                            <TextArea
                              value={sales_incentive_description}
                              onChange={set_sales_incentive_description}
                            ></TextArea>
                          </Cell>
                          <Cell
                            span={[1, 12]}
                            overrides={{
                              Cell: {
                                style: () => ({
                                  paddingLeft: '0px !important',
                                }),
                              },
                            }}
                          >
                            <LabelMedium style={{ marginBottom: '16px' }}>Sales form button title</LabelMedium>
                            <Input
                              value={sales_incentive_button_title}
                              onChange={e => set_sales_incentive_button_title(e.target.value)}
                              placeholder="Sales form button title"
                              clearOnEscape
                            />
                          </Cell>
                          <Cell
                            span={[1, 12]}
                            overrides={{
                              Cell: {
                                style: () => ({
                                  paddingLeft: '0px !important',
                                }),
                              },
                            }}
                          >
                            <ImageUploadCXL
                              title={'Sales form image'}
                              titleHelpText={'Select the sales form image'}
                              previewUrls={sales_incentive_image_preview || getSrc(sales_incentive_image, 'small')}
                              isMultiple={false}
                              onDrop={(acceptedFiles, rejectedFiles) => {
                                set_sales_incentive_image_upload(acceptedFiles);
                                set_sales_incentive_image_preview(URL.createObjectURL(acceptedFiles[0]));
                              }}
                              onImageDelete={() => {
                                set_sales_incentive_image_upload(null);
                                set_sales_incentive_image_preview(null);
                                set_sales_incentive_image(null);
                              }}
                              showFullBleedSettings={false}
                              imageSize={channelImageGuideline.size}
                              imageSizeGuidelineURL={channelImageGuideline.url}
                            />
                          </Cell>
                        </>
                      }
                    </Cell>
                  </Grid>
                </Tab>}
                {HAS_SUBSCRIPTION && is_subscription_on && <Tab title="Subscription">
                  <Grid
                    gridGaps={[12, 12, 12]}
                    overrides={{
                      Grid: {
                        style: ({ $theme }) => outerGridOverride,
                      },
                    }}
                  >
                    {/* <Cell span={12} overrides={cellPaddingOverrides}> */}
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important'
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Requires Subscription</LabelMedium>
                      <div style={{ display: 'flex', width: '400px' }}>
                        <Checkbox
                          checked={is_requires_subscription}
                          onChange={e => set_is_requires_subscription(!is_requires_subscription)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Yes / No</ParagraphSmall>
                        </Checkbox>
                      </div>
                    </Cell>
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Subscription</LabelMedium>
                      <Select
                        options={ORGANIZATION.subscriptions}
                        value={ORGANIZATION.subscriptions.filter(el => el.id == subscription_id)}
                        labelKey="name"
                        valueKey="id"
                        placeholder=''
                        onChange={({ value }) => set_subscription_id(value[0].id)}
                        overrides={{
                          Popover: {
                            props: {
                              overrides: {
                                Body: { style: { zIndex: 3 } },
                              },
                            },
                          },
                        }}
                      />
                    </Cell>
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important'
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Intro Headline</LabelMedium>
                      <div style={{ display: 'flex', width: '400px' }}>
                        <Input
                          value={subscription_into_headline}
                          onChange={e => {
                            set_subscription_into_headline(e.target.value)
                          }}
                          placeholder="Headline intro of subscription"
                          clearOnEscape
                        />
                      </div>
                    </Cell>
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important'
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Button Text</LabelMedium>
                      <div style={{ display: 'flex', width: '400px' }}>
                        <Input
                          value={subscription_button_text}
                          onChange={e => {
                            set_subscription_button_text(e.target.value)
                          }}
                          placeholder="Headline intro of subscription"
                          clearOnEscape
                        />
                      </div>
                    </Cell>
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                      <TextArea
                        value={subscription_description}
                        onChange={set_subscription_description}
                      ></TextArea>
                    </Cell>
                    <Cell
                      span={[1, 6]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <ImageUploadCXL
                        title={'Intro Image'}
                        titleHelpText={'Select the intro image'}
                        previewUrls={(subscription_image && URL.createObjectURL(subscription_image[0])) || getSrc(rest.subscription_image_data, 'small')}
                        isMultiple={false}
                        onDrop={(acceptedFiles, rejectedFiles) => {
                          set_subscription_image(acceptedFiles);
                        }}
                        onImageDelete={() => {
                          set_subscription_image(null);
                        }}
                        showFullBleedSettings={false}
                        imageSize={channelImageGuideline.size}
                        imageSizeGuidelineURL={channelImageGuideline.url}
                      />
                    </Cell>
                    {/* </Cell> */}
                  </Grid>
                </Tab>}
                {is_api_settings_on && has_data_source_connected && id && <Tab title="ApI Setup">
                  <Grid
                    gridGaps={[12, 12, 12]}
                    overrides={{
                      Grid: {
                        style: ({ $theme }) => outerGridOverride,
                      },
                    }}
                  >
                    {/* <Cell span={12} overrides={cellPaddingOverrides}> */}
                    <Cell
                      span={[1, 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important'
                          }),
                        },
                      }}
                    >
                      <ParagraphSmall style={{ marginBottom: '0px' }}>
                        In api setup select your pre defined api paths. If response of api is a single object then it will generate only one channel otherwise if it is an array it will generate multiple channels.
                        As this channel is marked as api channel so in apps you will not be able to see this channel this is going to be used as template for data coming from api. 
                      </ParagraphSmall>
                    </Cell>
                    <Cell
                      span={[1, 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important'
                          }),
                        },
                      }}
                    >
                      <ChannelMappings 
                        organization_id={organization_id}
                        selectedChannel={
                          {id: id}
                        }
                      />
                    </Cell>
                  </Grid>
                </Tab>
                }
              </Tabs>
            </ModalBody>
            <ModalFooter>
              {!!id && (
                <ModalButton
                  onClick={() => {
                    toggleConfirm(true);
                  }}
                  disabled={isLoading}
                  isLoading={isRemoveLoading}
                >
                  {archived ? 'Unarchive' : 'Archive'} Channel
                </ModalButton>
              )}
              <ModalButton
                onClick={() => {
                  onSave();
                }}
                disabled={!name || name === "" || name.length > titlesLength.channel || isRemoveLoading}
                isLoading={isLoading}
              >
                {id ? 'Update' : 'Add'} Channel
            </ModalButton>

            </ModalFooter>
          </Modal>
          <Modal
            onClose={() => toggleConfirm(false)}
            isOpen={isConfirmationOpen}
            overrides={{
              Root: {
                style: {
                  zIndex: 4,
                }
              }
            }}
          >
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to {archived ? 'unarchive' : 'archive'} this channel?</ModalBody>
            <ModalFooter>
              <ModalButton
                kind="tertiary"
                onClick={() => toggleConfirm(false)}
              >
                No
                </ModalButton>
              <ModalButton
                onClick={() => {
                  onRemove();
                }}
                isLoading={isRemoveLoading}
                disabled={isLoading}
              >
                Yes
            </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </ModalStateContainer>
  );
};
