import React from 'react';
import { Input, Textarea } from 'baseui/textarea';
import BaseClass from './base';
import { Button } from 'baseui/button';

class InputComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  render() {
    let value = this.state.value || [];
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>

        {value && Array.isArray(value) && value.map((el, i) => {
          return <>
          <h5
            style={{
              color: this.props.theme.colors.primaryA,
              marginTop: this.props.theme.sizing.scale1200,
            }}
          >
            {`Correct Text #${i + 1} ${i > 0 ? '(optional)' : ''}`}
          </h5>
          <Textarea
          value={el}
          onChange={e => {
            // this.setState({ value: e.target.value })
            let v1 = [...value];
            v1[i] = e.target.value;
            this.setState({value: JSON.stringify(v1)})
          }}
          placeholder={this.props.placeholder}
          clearOnEscape
        /></>
        })}
        <Button 
        style={{
          marginTop: '16px',
        }}
        onClick={() => {
          // debugger;
          let v1 = [...value];
          v1.push('');
          this.setState({value: JSON.stringify(v1)})
        }}>Add New Answer</Button>
      </>
    );
  }
}

export default InputComponent;
