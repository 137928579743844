import * as React from 'react';
import Organizations from '../components/organization/index';
import { useStyletron } from 'baseui';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

const Outer = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        background: theme.colors.primary50,
        // padding: theme.sizing.scale700,
        height: '100vh',
        overflow: 'auto',
      })}
    >
      {children}
    </div>
  );
};

const OrganizationsPage = ({ organization }) => {
  if (!organization) {
    navigate('/');
    return <></>;
  }

  return (
    <Outer>
      <Organizations organization={organization}></Organizations>
    </Outer>
  );
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};
export default connect(mapStateToProps)(OrganizationsPage);
// export default OrganizationsPage;
