import * as React from 'react';
import { useStyletron } from 'baseui';
import {
    Modal,
    ModalBody,
} from 'baseui/modal';
import { HeadingXLarge } from 'baseui/typography';
import Button from '../common/button';

const ReorderInModal = ({ children, isCollapsed, onClose, title, save }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const B = require('baseui/button').Button;

    const [css, theme] = useStyletron();

    const close = (status) => {
        setIsOpen(false);
        onClose(status);
    };

    React.useEffect(() => {
        setIsOpen(isCollapsed);
    }, [isCollapsed]);

    return (
        <>
            <React.Fragment>
                <Modal
                    onClose={() => close(false)}
                    closeable
                    isOpen={isOpen}
                    animate
                    overrides={{
                        Dialog: {
                            style: {
                                width: '70vw',
                                height: '96vh',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                                paddingBottom: '1rem'
                            },
                            Body: {
                                style: ({ $theme }) => ({
                                    overflow: 'hidden',
                                }),
                            },
                        },
                        Root: {
                            style: {
                                zIndex: 100
                            }
                        }
                    }}
                >
                    <ModalBody>
                
                        <div
                            className={css({
                                position: 'absolute',
                                width: '90%',
                                height: '95%',
                                overflow: 'hidden',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                paddingBottom: '1rem'
                            })}
                        >
                            <div
                                className={css({
                                    position: 'absolute',
                                    width: '100%',
                                    height: '70px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingRight: theme.sizing.scale800,
                                    marginRight: theme.sizing.scale800,
                                })}
                            >
                                <div
                                    className={css({
                                    position: 'relative',
                                    // float: 'left',
                                    // marginRight: theme.sizing.scale400,
                                    paddingRight: theme.sizing.scale800,
                                    marginTop: '10px'
                                    })}
                                >
                                    <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <circle cx="16" cy="16" r="16" fill="#192F57" />
                                    <path
                                        d="M13.4 14.5L18.17 6.24C17.47 6.09 16.75 6 16 6C13.6 6 11.4 6.85 9.68 8.25L13.34 14.6L13.4 14.5ZM25.54 13C24.62 10.08 22.39 7.74 19.54 6.66L15.88 13H25.54ZM25.8 14H18.31L18.6 14.5L23.36 22.75C25 20.97 26 18.61 26 16C26 15.31 25.93 14.65 25.8 14ZM12.54 16L8.64 9.25C7.01 11.03 6 13.39 6 16C6 16.69 6.07 17.35 6.2 18H13.69L12.54 16ZM6.46 19C7.38 21.92 9.61 24.26 12.46 25.34L16.12 19H6.46ZM17.73 19L13.83 25.76C14.53 25.91 15.25 26 16 26C18.4 26 20.6 25.15 22.32 23.75L18.66 17.4L17.73 19Z"
                                        fill="#00A3FF"
                                    />
                                    </svg>
                                </div>
                                <HeadingXLarge style={{ color: theme.colors.primaryB === '#141414' ? 'white' : '#0D0E13' }}>{title || 'Reorder Elements'}</HeadingXLarge>
                            </div>
                            <div
                                className={css({
                                    position: 'absolute',
                                    width: '100%',
                                    top: '70px',
                                    bottom: '0px',
                                    overflow: 'auto',
                                    '::-webkit-scrollbar': {
                                        // backgroundColor: theme.colors.primaryC
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        minHeight: '266px',
                                        backgroundColor: theme.colors.primaryC,
                                    },
                                })}
                            >
                                {children}
                            </div>
                            <div
                                className={css({
                                    position: 'absolute',
                                    // width: '80px',
                                    right: '0',
                                    top: '16px',
                                    zIndex: 100,
                                })}
                            >
                                <div style={{ marginLeft: '12px' }}>
                                    <Button text={'Save'} border_property={"true"} onClick={() => close(save)}></Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        </>
    );
};

export default ReorderInModal;
