import * as React from 'react';
import _ from 'lodash';
import './preview.css';
import PreviewElements from './previewElements';
class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: null,
    };
    this.onSave = this.onSave.bind(this);
  }

  render() {
    let cardBackData = this.state.cardBackData;
    if (!cardBackData) return null;
    return (
      <>
        <PreviewElements cardBackData={cardBackData}></PreviewElements>
      </>
    );
  }
}
export default Preview;
