export const tableColumnKeys = {
    USER_PROFILE: 'user_profile',
    USER_NAME: 'user_name',
    USER_EMAIL: 'user_email',
    USER_CARDWARE_CONVERTED: 'user_cardware_converted',
    USER_ACTIVE: 'user_active',
    USER_SETTINGS: 'user_settings',
    USER_ACTIONS: 'user_actions'
}

export const USER_CONVERTED_OPTION = [
    {
        name: 'Converted',
        key: 'user_converted'
    },
    {
        name: 'Not Converted',
        key: 'user_not_converted'
    },
    {
        name: 'Both',
        key: 'both'
    }
]

export const adminUsertableColumns = [
    {
        name: '',
        dataKey: 'image',
        key: tableColumnKeys.USER_PROFILE,
        span: 1
      },
      {
        name: 'Name',
        dataKey: 'name',
        key: tableColumnKeys.USER_NAME,
        span: 2
      },
      {
        name: 'Email',
        dataKey: 'email',
        key: tableColumnKeys.USER_EMAIL,
        span: 3
      },
      {
        name: 'Active',
        dataKey: 'approved',
        key: tableColumnKeys.USER_ACTIVE,
        span: 2
      },
      {
        name: 'Settings',
        dataKey: '',
        key: tableColumnKeys.USER_SETTINGS,
        span: 2
      },
      {
        name: 'Actions',
        dataKey: '',
        key: tableColumnKeys.USER_ACTIONS,
        span: 2
      }
  ]