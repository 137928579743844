import * as React from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import DataCard from './card';
import GraphCard from './graph-card';
import { cardArray, graphCardArray } from './api-data';
import GenCSV from '../../util/GenCSV';
import { client as queryServerClient, getServerUrl } from '../../apollo/client';
import RightSidePageHeader from '../pageHeaders';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingMarginOverrides } from '../overrides';
import FeedbackCard from '../feedback/feedbackCard';
import { navigate } from 'gatsby';
import IntakeTable from '../intake/index';
import useFeedBack from '../feedback/useFeedBack';
import ResponseTable from '../quiz-responses/index';
import moment from 'moment';
import gql from 'graphql-tag';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { client as reactClient } from '../../apollo/client';

const Dashboard = props => {
  const [loading, setLoading] = React.useState(false);
  const { feedBacks, hasMore, archiveFeedback } = useFeedBack(props.organization_id, 1, true);
  const [checked, setChecked] = React.useState(false);

  const [showIntakeTable, setShowIntakeTable] = React.useState(true);
  const closeIntakeTable = () => setShowIntakeTable(false);

  const [showQuizResponseTable, setShowQuizResponseTable] = React.useState(true);
  const closeResponseTable = () => setShowQuizResponseTable(false);

  const [lastUpdate, setLastUpdate] = React.useState(new Date());

  const [testers, setTesters] = React.useState([]);

  const LoadingBar = () => {
    return (
      <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {new Array(3).fill('').map(item => <Skeleton
            width="500px"
            height="145px"
            animation
            overrides={{
              Root: {
                style: {
                  marginRight: '60px'
                },
              },
            }}
          />)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '25px'
                  },
                },
              }}
            />)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '10px',
                    borderRadius: '48px'
                  },
                },
              }}
            />)}
          </div>
        </div>
        <div style={{ marginTop: '2rem' }}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
            />))}
        </div>
        <div style={{ float: 'right' }}>
          <Skeleton
            width="500px"
            height="40px"
            animation
          />
        </div>
      </div>
    )
  };

  const fetch = () => {
    const query = () => gql`
    query organization_testers {
        organization_testers_by_org(organization_id: "${props.organization_id}") {
            id
            email
            name
            device_id
          }
    }
    `;
    setLoading(true);
    reactClient.query({
      query: query(),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setTesters(data.organization_testers_by_org);
      setLoading(false);
    })
  };

  React.useEffect(() => {
    props.client && props.client.query({
      query: gql`
       query getLastUpdatedAt {
        getLastUpdatedAt
       }
      `
    }).then((result) => {
      setLastUpdate(result.data.getLastUpdatedAt);
    })
  }, [props.client]);

  React.useEffect(() => {
    fetch();
  }, [checked]);

  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      {/* <RightSidePageHeader
        icon={null}
        title={'Application Data'}
        rightContent={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
            {/* <div style={{ marginRight: '16px', marginTop: '14px' }}>
              <Checkbox
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                Include Test Users Data
              </Checkbox>
            </div> */}
            {/* <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 0 }}
            > */}
              {/* <Button
                onClick={async () => {
                  setLoading(true);
                  await GenCSV(
                    cardArray,
                    graphCardArray,
                    props.client,
                    queryServerClient
                  )
                  setLoading(false);
                }
                }
                isLoading={loading}
              >Download Data</Button> */}
              {/* <Button
                onClick={() => {
                  props.setActiveKey('0')
                }}
              >
                Application Metrics
              </Button>
              <Button
                onClick={() => {
                  props.setActiveKey('1')
                }}
              >
                Learning Metrics
              </Button>
              <Button
                onClick={() => {
                  props.setActiveKey('2')
                }}
              >
                Quiz Metrics
              </Button>
            </  >
          </div>
        }
        headerDescription={`Overview of Cardware App Analytics \n Last updated at ${moment(lastUpdate).format('lll')}`}
      /> */}
      {loading ? <LoadingBar /> : (
        <>
          <Cell span={12}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '16px', justifyContent: 'right', marginTop: '-32px'}}>
              <div style={{ marginRight: '16px', marginTop: '14px' }}>
                <Checkbox
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  Include Test Users Data
              </Checkbox>
              </div>
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                <Button
                  onClick={async () => {
                    setLoading(true);
                    await GenCSV(
                      cardArray,
                      graphCardArray,
                      props.client,
                      queryServerClient
                    )
                    setLoading(false);
                  }
                  }
                  isLoading={loading}
                >Download Data</Button>
              </StatefulButtonGroup>
            </div>
          </Cell>
          <Cell span={12}>
            <FlexGrid
              flexGridColumnCount={[1, 1, 2, 4]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800"
            >
              {cardArray.map((item, index) => (
                <FlexGridItem key={index}>
                  <DataCard {...item} client={props.client} include_testers={checked} testers={testers} />
                </FlexGridItem>
              ))}
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={[1, 1, 1, 1]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800"
              marginBottom="scale800"
              marginTop="scale800"
            >
              {graphCardArray.map((item, index) => (
                <FlexGridItem key={index} overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      width: item.width ? `${item.width} !important` : '100%',
                      ...item.width ? {
                        ... (index % 2 == 0) ? {
                          marginLeft: '1% !important'
                        } : {
                            marginRight: '1% !important'
                          }
                      } : {}
                    })
                  }
                }}>
                  <GraphCard {...item} client={props.client} include_testers={checked} testers={testers} />
                </FlexGridItem>
              ))}
            </FlexGrid>
          </Cell>
          {/* { feedBacks && feedBacks.length > 0 && <>
            <RightSidePageHeader
              icon={null}
              title={'Feedback Content'}
              rightContent={
                <StatefulButtonGroup
                  mode={MODE.radio}
                  initialState={{ selected: 0 }}
                >
                  {hasMore && <Button
                    onClick={() => { navigate(`/app/${props.organization_id}/feedback`) }}
                  >
                    All Feedbacks
                </Button>}
                </StatefulButtonGroup>
              }
              headerDescription={'How people react on your cards?'}
            ></RightSidePageHeader>
            <Cell span={12}>
              <div style={{ marginBottom: '32px' }}>
                <FlexGrid
                  flexGridColumnCount={[1, 1, 2, 4]}
                  flexGridColumnGap="scale800"
                  flexGridRowGap="scale800"
                >
                  {feedBacks && feedBacks.map((item, index) => {
                    return <FlexGridItem key={index}>
                      <div>
                        <FeedbackCard feedback={item} archiveFeedback={archiveFeedback} />
                      </div>
                    </FlexGridItem>
                  })}
                </FlexGrid>
              </div>
            </Cell>
          </>} */}
          {
            showIntakeTable && <><RightSidePageHeader
              icon={null}
              title={'Intake form Data'}
              rightContent={
                <StatefulButtonGroup
                  mode={MODE.radio}
                  initialState={{ selected: 0 }}
                >
                  <Button
                    onClick={() => {
                      window.open(`${getServerUrl()}/intake_forms_data/download/${props.organization_id}`, '_blank').focus();
                    }}
                  >
                    Download All Data
                </Button>
                </StatefulButtonGroup>
              }
              headerDescription={'Data from sales incentive'}
            ></RightSidePageHeader>
              <Cell span={12}>
                <div style={{ marginBottom: '32px' }}>
                  <IntakeTable organization_id={props.organization_id} close={closeIntakeTable}></IntakeTable>
                </div>
              </Cell>
            </>
          }
          {/* {showQuizResponseTable && <><RightSidePageHeader
            icon={null}
            title={'Quiz Response Data'}
            rightContent={
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                <Button
                  onClick={() => {
                    window.open(`${getServerUrl()}/quiz_response_data/download/${props.organization_id}`, '_blank').focus();
                  }}
                >
                  Download All Data
                </Button>
              </StatefulButtonGroup>
            }
            headerDescription={'Data from quizzes taken by app users'}
          ></RightSidePageHeader>
            <Cell span={12}>
              <div style={{ marginBottom: '32px' }}>
                <ResponseTable organization_id={props.organization_id} close={closeResponseTable}></ResponseTable>
              </div>
            </Cell>
          </>} */}
        </>
      )}

    </Grid>
  );
};

export default Dashboard;
