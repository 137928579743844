import React, { Component } from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { FlexGrid } from 'baseui/flex-grid';
import { sourceByType } from '../../util';
import { connect } from 'react-redux';
import Card from './card';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { StatefulMenu } from 'baseui/menu';

const gridPaddingOverrides = {
  Grid: {
    style: () => ({
      padding: '0px !important',
    }),
  },
};

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const ArchivedCards = ({ cards, organization_id }) => {
  const [css, theme] = useStyletron();
  const [showMenu, setShowMenu] = React.useState(null);
  const [filtered, setFiltered] = React.useState([]);
  const onClick = data => {
    navigate(
      `/app/${organization_id}/channels/deck/${data.deck_id}/card/${data.card_id}`
    );
  };
  const onSettingsClick = card => {
    if (showMenu) {
      if (card.card_id != showMenu) setShowMenu(card.card_id);
      else setShowMenu(null);
    } else {
      setShowMenu(card.card_id);
    }
  };
  React.useEffect(() => {
    setFiltered(
      cards.filter(el => {
        return el.card.archived == true;
      })
    );
  }, [cards]);

  const [updateCard, { loading: updateCardLoading }] = useMutation(gql`
    mutation updateCard($id: ID!, $published: Boolean, $archived: Boolean) {
      updateCard(id: $id, published: $published, archived: $archived) {
        title
      }
    }
  `);

  const onToggleClick = (value, card_id) => {
    let updated = JSON.parse(JSON.stringify(filtered)).map(el => {
      if (el.card_id === card_id) {
        el.published = value;
      }
      return el;
    });
    setFiltered(updated);
    updateCard({
      variables: {
        id: card_id,
        published: value,
      },
    });
  };

  const unarchiveCard = (value, card_id) => {
    let updated = JSON.parse(JSON.stringify(filtered)).map(el => {
      if (el.card_id === card_id) {
        el.archived = value;
      }
      return el;
    });
    setFiltered(updated);
    updateCard({
      variables: {
        id: card_id,
        archived: value,
      },
    });
  };

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>
          <div
            id="wrapper"
            className="wrapper"
            style={{
              paddingTop: theme.sizing.scale600,
            }}
          >
            <FlexGrid>
              <div className="scrolls" style={{ display: 'flex' }}>
                {filtered.map((card, index) => {
                  return (
                    <>
                      <Card
                        key={index}
                        title={card.card.title}
                        imageSrc={getSrc(card.card.file_data)}
                        toggle={card.published}
                        showSettingsIcon={true}
                        onCardClick={() => onClick(card)}
                        onSettingsClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSettingsClick(card);
                        }}
                        onToggleClick={v =>
                          onToggleClick(v.target.checked, card.card_id)
                        }
                      ></Card>
                      {showMenu == card.card_id && (
                        <div
                          className={css({
                            marginRight: '16px',
                            height: '100%',
                          })}
                        >
                          <StatefulMenu
                            items={[{ label: 'Unarchive' }]}
                            onItemSelect={() => {
                              unarchiveCard(false, card.card_id);
                              setShowMenu(null);
                            }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </FlexGrid>
          </div>
        </Cell>
      </Grid>
    </>
  );
};

const mapStateToProps = props => {
  let { cards } = props.appBasicControls;
  return {
    cards,
  };
};
export default connect(mapStateToProps)(ArchivedCards);
