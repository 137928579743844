import * as React from 'react';
import { useStyletron } from 'baseui';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { HeadingXLarge } from 'baseui/typography';
import { KIND } from 'baseui/button';
import Button from './common/button';

const ReorderInModal = ({ children, isCollapsed, onClose, title }) => {
  const [isOpen, setIsOpen] = React.useState(isCollapsed);
  const B = require('baseui/button').Button;
  const [css, theme] = useStyletron();
  const close = (status) => {
    // debugger;
    setIsOpen(false);
    onClose({
      isSavedFromReorder: status
    });
  };

  React.useEffect(() => {
    setIsOpen(isCollapsed);
  }, [isCollapsed]);
  return (
    <>
      {isOpen ? (
        <React.Fragment>
          <Modal
            onClose={() => close(false)}
            closeable
            isOpen={isOpen}
            animate
            overrides={{
              Dialog: {
                style: {
                  width: '99vw',
                  height: '96vh',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                },
                Body: {
                  style: ({ $theme }) => ({
                    overflow: 'hidden',
                  }),
                },
              },
              Root: {
                style: {
                  zIndex: 100
                }
              }
            }}
          >
            {/* <ModalHeader>{title || 'Reorder Elements'}</ModalHeader> */}
            <ModalBody>
              <div
                className={css({
                  position: 'absolute',
                  width: '80px',
                  right: '16px',
                  top: '16px',
                  zIndex: 100,
                  backgroundColor: theme.colors.primaryB,
                })}
              >
                <div style={{ marginLeft: '12px' }}>
                  <Button text={'Save'} border_property={"true"} onClick={() => close(true)}></Button>
                </div>
                <B kind={KIND.minimal} onClick={() => close(false)}>
                  cancel
                </B>
              </div>
              <div
                className={css({
                  position: 'absolute',
                  width: '600px',
                  height: '100%',
                  overflow: 'hidden',
                  left: '50%',
                  transform: 'translateX(-50%)',
                })}
              >
                <div
                  className={css({
                    position: 'absolute',
                    width: '100%',
                    height: '70px',
                    textAlign: 'center',
                  })}
                >
                  <HeadingXLarge style={{color: theme.colors.primaryB === '#141414' ? 'white' : '#0D0E13'}}>{title || 'Reorder Elements'}</HeadingXLarge>
                </div>
                <div
                  className={css({
                    position: 'absolute',
                    width: '100%',
                    top: '70px',
                    bottom: '0px',
                    overflow: 'auto',
                    '::-webkit-scrollbar': {
                      // backgroundColor: theme.colors.primaryC
                    },
                    '::-webkit-scrollbar-thumb': {
                      minHeight: '266px',
                      backgroundColor: theme.colors.primaryC,
                    },
                  })}
                >
                  {children}
                </div>
              </div>
            </ModalBody>
            {/* <ModalFooter>
          <ModalButton onClick={() => close()}>Okay</ModalButton>
        </ModalFooter> */}
          </Modal>
        </React.Fragment>
      ) : (
        <div style={{paddingBottom: '12rem', width: '100%', height: '100%'}}>{children}</div>
      )}
    </>
  );
};

export default ReorderInModal;
