import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import { active_sessions_chart_help, active_users_chart_help, active_users_help, app_installations_graph_help, app_installations_help, app_sessions_graph_help, app_updated_graph_help, app_updated_help, card_back_view_graph_help, card_front_view_graph_help, channel_selected_graph_help, platform_analysis_graph_help, screens_graph_help, top_cards_graph_help, top_categories_graph_help, top_channels_graph_help, top_users_graph_help, total_sessions_help, total_users_help, version_analysis_graph_help } from '../help/helpTexts';
import { getTimeRange, groupDataByTP } from './utils';

export const cardArray = [
  {
    title: 'App Installations',
    value: '10,125',
    description: `Lifetime \n This Month: {{cValue}}`,
    percentage: '1,450',
    helpText: app_installations_help,
    query: gql`
      query application_installed($testers: [String] $include_testers: Boolean) {
        count_by_table_name (
          table_name: "application_installed"
          has_date_range: true
          start_date: "${moment().startOf('month').toDate().toISOString()}"
          end_date: "${moment().toDate().toISOString()}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          total
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.count_by_table_name;
      let thisMonthUser = result.data;
      return {
        value: result.total,
        cValue: thisMonthUser
      };
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push([title, processedData.value]);
      return csvArray;
    }
  },
  {
    title: 'App Updated',
    value: '10,125',
    description: 'Total updated \n This Month: {{cValue}}',
    helpText: app_updated_help,
    percentage: '1,450',
    query: gql`
      query application_updated($testers: [String] $include_testers: Boolean) {
        count_by_table_name (
          table_name: "application_updated"
          has_date_range: true
          start_date: "${moment().startOf('month').toDate().toISOString()}"
          end_date: "${moment().toDate().toISOString()}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          total
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.count_by_table_name;
      let thisMonthUser = result.data;
      return {
        value: result.total,
        cValue: thisMonthUser
      };
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push([title, processedData.value]);
      return csvArray;
    }
  },
  {
    title: 'Total Users',
    value: '10,125',
    description: 'Total users \n This Month: {{cValue}}',
    helpText: total_users_help,
    percentage: '1,450',
    query: gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        count_by_table_name (
          table_name: "application_opened"
          has_date_range: true
          start_date: "${moment().startOf('month').toDate().toISOString()}"
          end_date: "${moment().toDate().toISOString()}"
          is_distinct: true
          distinct_col: "context_device_id"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          total
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.count_by_table_name;
      let thisMonthUser = result.data;
      return {
        value: result.total,
        cValue: thisMonthUser
      };
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push([title, processedData.value]);
      return csvArray;
    }
  },
  {
    title: 'App Sessions',
    value: '10,125',
    description: 'Total sessions \n This Month: {{cValue}}',
    helpText: total_sessions_help,
    percentage: '1,450',
    query: gql`
      query application_opened($testers: [String] $include_testers: Boolean) {
        count_by_table_name (
          table_name: "application_opened"
          has_date_range: true
          start_date: "${moment().startOf('month').toDate().toISOString()}"
          end_date: "${moment().toDate().toISOString()}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          total
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.count_by_table_name;
      let thisMonthUser = result.data;
      return {
        value: result.total,
        cValue: thisMonthUser
      };
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push([title, processedData.value]);
      return csvArray;
    }
  },
];

export const graphCardArray = [
  {
    title: 'Active Users',
    helpText: active_users_chart_help,
    type: 'spline',
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => {
      return gql`
      query application_openeds($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_opened"
          group_by_col: "original_timestamp"
          is_distinct: true
          distinct_col: "context_device_id"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `;
    },
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Active Sessions',
    type: 'spline',
    helpText: active_sessions_chart_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => {
      return gql`
      query application_openeds($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_opened"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `;
    },
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'App Installations',
    type: 'spline',
    helpText: app_installations_graph_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => gql`
      query application_installed($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_installed"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'App Lifetime',
    type: 'spline',
    helpText: app_sessions_graph_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => gql`
      query application_openeds($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_opened"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Application Updated',
    type: 'spline',
    helpText: app_updated_graph_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => gql`
      query application_updated($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_updated"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Channel Views',
    type: 'spline',
    helpText: channel_selected_graph_help,
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "channel_selected"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  // {
  //   title: 'Card Front View',
  //   type: 'spline',
  //   helpText: card_front_view_graph_help,
  //   has_time_period_intervals: true,
  //   getTpQuery: ({ startDate, endDate }) => gql`
  //     query card_flip_front($testers: [String] $include_testers: Boolean) {
  //       group_by_table_name (
  //         table_name: "card_flip_front"
  //         group_by_col: "original_timestamp"
  //         has_date_range: true
  //         start_date: "${startDate}"
  //         end_date: "${endDate}"
  //         include_testers: $include_testers
  //         testers: $testers
  //       ){
  //         data
  //         error
  //         status
  //       }
  //     }
  //   `,
  //   dataProcessor: (result, tab) => {
  //     result = result.data.group_by_table_name;
  //     // console.log(result);
  //     let data = [];
  //     if (!result.status) return data;
  //     try {
  //       let d = result.data;
  //       d = JSON.parse(d);
  //       d = groupDataByTP(d, tab);
  //       data = d;
  //     } catch (e) {
  //       // console.log(e);
  //     }
  //     return (data);
  //   },
  //   toCsvLines: (title, processedData, csvArray) => {
  //     csvArray.push(['', '', '']);
  //     csvArray.push(['', '', '']);
  //     csvArray.push([title]);
  //     csvArray.push(['Date', 'Count']);
  //     processedData.forEach((el) => {
  //       csvArray.push([el._x, el.y])
  //     });
  //     return csvArray;
  //   }
  // },
  {
    title: 'Card View',
    helpText: card_back_view_graph_help,
    type: 'spline',
    has_time_period_intervals: true,
    getTpQuery: ({ startDate, endDate }) => gql`
      query card_flip_back($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "card_flip_back"
          group_by_col: "original_timestamp"
          has_date_range: true
          start_date: "${startDate}"
          end_date: "${endDate}"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result, tab) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = groupDataByTP(d, tab);
        data = d;
      } catch (e) {
        // console.log(e);
      }
      return (data);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Date', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el._x, el.y])
      });
      return csvArray;
    }
  },
  // {
  //   title: 'Screens',
  //   helpText: screens_graph_help,
  //   type: 'spline',
  //   has_time_period_intervals: true,
  //   hide_tp_filters: true,
  //   getTpQuery: ({ startDate, endDate }) => gql`
  //     query screens($testers: [String] $include_testers: Boolean) {
  //       group_by_table_name (
  //         table_name: "screens"
  //         group_by_col: "name"
  //         has_date_range: true
  //         start_date: "${startDate}"
  //         end_date: "${endDate}"
  //         include_testers: $include_testers
  //         testers: $testers
  //       ){
  //         data
  //         error
  //         status
  //       }
  //     }
  //   `,
  //   dataProcessor: (result) => {
  //     result = result.data.group_by_table_name;
  //     // console.log(result);
  //     let data = [];
  //     if (!result.status) return data;
  //     try {
  //       let d = result.data;
  //       d = JSON.parse(d);
  //       d = d.map(el => {
  //         return {
  //           y: el.count,
  //           _x: el.name,
  //           x: el.name
  //         }
  //       });
  //       data = d;
  //       // data = data.sort((a, b) => b.y - a.y);
  //     } catch (e) {
  //       // console.log(e);
  //     }
  //     return (data);
  //   },
  //   toCsvLines: (title, processedData, csvArray) => {
  //     csvArray.push(['', '', '']);
  //     csvArray.push(['', '', '']);
  //     csvArray.push([title]);
  //     csvArray.push(['Name', 'Count']);
  //     processedData.forEach((el) => {
  //       csvArray.push([el.x, el.y])
  //     });
  //     return csvArray;
  //   }
  // },
  {
    title: 'Top Channels',
    helpText: top_channels_graph_help,
    type: 'vertical-bar',
    width: '49%',
    query: gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "channel_selected"
          group_by_col: "channel_title"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          return {
            y: el.count,
            _x: el.channel_title,
            x: el.channel_title
          }
        });
        data = d;
        data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data.slice(0, 10);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Top Cards',
    helpText: top_cards_graph_help,
    type: 'vertical-bar',
    width: '49%',
    query: gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "card_flip_back"
          group_by_col: "title"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          return {
            y: el.count,
            _x: el.title,
            x: el.title
          }
        });
        data = d;
        data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data.slice(0, 10);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Version Analysis',
    helpText: version_analysis_graph_help,
    type: 'radial',
    width: '49%',
    query: gql`
      query channel_selected ($testers: [String] $include_testers: Boolean){
        group_by_table_name (
          table_name: "application_opened"
          group_by_col: "context_app_version"
          is_distinct: true
          distinct_col: "context_device_id"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          return {
            angle: el.count,
            label: `${el.count}%`,
            subLabel: el.context_app_version,
            name: el.context_app_version,
            y: el.count,
            _x: el.context_app_version,
            x: el.context_app_version
          }
        });
        data = d;
        // data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data;
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Platform Analysis',
    helpText: platform_analysis_graph_help,
    type: 'radial',
    width: '49%',
    query: gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "application_opened"
          group_by_col: "context_os_name"
          is_distinct: true
          distinct_col: "context_device_id"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          return {
            angle: el.count,
            label: `${el.count}%`,
            subLabel: el.context_os_name,
            name: el.context_os_name,
            y: el.count,
            _x: el.context_os_name,
            x: el.context_os_name
          }
        });
        data = d;
        // data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data;
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Top Users',
    helpText: top_users_graph_help,
    type: 'vertical-bar',
    width: '49%',
    query: gql`
      query channel_selected($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "screens"
          group_by_col: "email"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.filter(el => {
          return el.email && el.email != 'anonymous' && el.email != '0' && el.email != 'Not logged in'
        }).map(el => {
          return {
            y: el.count,
            _x: el.email,
            x: el.email
          }
        });
        data = d;
        data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data.slice(0, 20);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  {
    title: 'Top Categories',
    helpText: top_categories_graph_help,
    type: 'vertical-bar',
    width: '49%',
    query: gql`
      query category_app_content_scopes_view($testers: [String] $include_testers: Boolean) {
        group_by_table_name (
          table_name: "category_app_content_scopes_view"
          group_by_col: "name"
          include_testers: $include_testers
          testers: $testers
        ){
          data
          error
          status
        }
      }
    `,
    dataProcessor: (result) => {
      result = result.data.group_by_table_name;
      // console.log(result);
      let data = [];
      if (!result.status) return data;
      try {
        let d = result.data;
        d = JSON.parse(d);
        d = d.map(el => {
          return {
            y: el.count,
            _x: el.name,
            x: el.name
          }
        });
        data = d;
        data = data.sort((a, b) => b.y - a.y);
      } catch (e) {
        // console.log(e);
      }
      return data.slice(0, 20);
    },
    toCsvLines: (title, processedData, csvArray) => {
      csvArray.push(['', '', '']);
      csvArray.push(['', '', '']);
      csvArray.push([title]);
      csvArray.push(['Name', 'Count']);
      processedData.forEach((el) => {
        csvArray.push([el.x, el.y])
      });
      return csvArray;
    }
  },
  // {
  //   title: 'Platform usage',
  //   type: 'radial',
  //   query: gql`
  //     query application_installeds {
  //       application_installeds{
  //         context_os_name
  //       }
  //     }
  //   `,
  //   dataProcessor: (result) => {
  //     let { application_installeds } = result.data;
  //     let grouped = _.groupBy(application_installeds, x => x.context_os_name);
  //     let data = [];
  //     let total_count = application_installeds.length;
  //     Object.keys(grouped).forEach((key) => {
  //       data.push({
  //         angle: grouped[key].length,
  //         label: `${((grouped[key].length / total_count) * 100).toFixed(2)}%`,
  //         subLabel: key,
  //         name: key,
  //         y: grouped[key].length
  //       })
  //     });
  //     return data;
  //   },
  //   toCsvLines: (title, processedData, csvArray) => {
  //     csvArray.push(['', '', '']);
  //     csvArray.push(['', '', '']);
  //     csvArray.push([title]);
  //     csvArray.push(['Platform', 'No of Usages']);
  //     processedData.forEach((el) => {
  //       csvArray.push([el.subLabel, el.angle])
  //     });
  //     return csvArray;
  //   }
  // },
  // {
  //   title: 'Most used search terms',
  //   type: 'horizontal-bar',
  // },
  // {
  //   title: 'Top Filters Used',
  //   type: 'vertical-bar',
  // },
];