import * as React from 'react';
import { LabelMedium, HeadingXSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { Grid, Cell } from 'baseui/layout-grid';
import { arrayMove } from 'baseui/dnd-list';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { navigate } from '@reach/router';
import {
  LabelSmall,
} from 'baseui/typography';
import { useMutation } from '@apollo/client';

import Portal from '../home-components/portal';
import { setSelectedChannel, setSelectedGroup, setGroups, setChannels } from '../../redux/actions/appBasicControls';
import { updateGroup, updateChannel } from '../../quries';
import AddEditChannel from './addEditChannel';
import AddEditChannelGroup from '../groups/addEditGroup';
import GroupReOrder from '../groups/GroupReOrder';
import { sourceByType } from '../../util';
import { Link } from 'gatsby';

class DragItem extends React.Component {
  render() {
    const {
      dragProvided,
      title,
      index,
      css,
      snapshot,
      setIsCollapsed,
      isCollapsed,
    } = this.props;
    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        <div
          key={index}
          className={css({
            position: 'relative',
            width: '100%',
            height: '50px',
            lineHeight: '50px',
            background: snapshot.isDragging ? '#5D64AB' : '#0D0E13',
            margin: '0',
            display: 'flex',
            padding: '16px',
            color: 'white',
            // background: '#0D0E13',
            marginBottom: '8px',
            borderBottom: '1px solid white',
            cursor: 'move'
          })}
        >
          <StatefulTooltip
            content={() => <div padding={'20px'}>Reorder</div>}
            placement={PLACEMENT.bottom}
            returnFocus
            autoFocus
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                cursor: 'move',
              })}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <path
                d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                fill={snapshot.isDragging ? 'white' : 'white'}
              />
            </svg>
          </StatefulTooltip>
          <LabelSmall
            className={css({
              marginLeft: '16px',
              color: 'white !important'
            })}
          >
            {title}
          </LabelSmall>
        </div>
      </div>
    );
    return <Portal snapshot={snapshot} child={child}></Portal>
  }
};

export default ({
  channels,
  channelGroups,
  selectedChannel,
  dispatch,
  organization_id,
  defaultTheme,
  close,
  showChannelsManger = true,
  showAddButtons = true,
  onChannelClick,
  showAllGroupsButton = false,
  onAllGroupsButtonClick,
  tab
}) => {
  const [
    updateAppContentScopeGroup,
  ] = useMutation(updateGroup);

  const [
    updateAppContentScope
  ] = useMutation(updateChannel);

  const [css, theme] = useStyletron();

  const [activeKey, setActiveKey] = React.useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [orderGroups, setOrderedGroups] = React.useState([]);
  const [orderChannels, setOrderedChannels] = React.useState([]);
  const [onHover, setOnHover] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState([
    false,
    false,
  ]);
  const [groupId, setGroupId] = React.useState(false);
  const [groupPosition, setGroupPosition] = React.useState(null);
  const [currentChannel, setCurrentChannel] = React.useState(null);
  const [group, setGroup] = React.useState('');
  const getChannelsOfGroup = groupId => {
    return filtered
      .filter(el => el.app_content_scope_group_id == groupId)
      .sort((a, b) => a.position - b.position);
  };

  const paddingLeftRightCss = {
    paddingLeft: theme.sizing.scale800,
    paddingRight: theme.sizing.scale800,
  };

  const [currentChannelInfo, setCurrentChannelInfo] = React.useState({});
  const [isAddChannelOpen, setIsAddChannel] = React.useState(false);
  const [isAddChannelGroupOpen, setIsAddGroupChannel] = React.useState(false);

  // Group reorder modal
  const [isGroupOrderOpen, reOrderGroups] = React.useState(false);
  const [isChannelOrderOpen, reOrderChannels] = React.useState(false);
  console.log(orderGroups);
  const onClose = (save) => {
    setIsAddChannel(false);
    setIsAddGroupChannel(false);
    reOrderGroups(false);
    reOrderChannels(false);
    setGroup(null);
    setGroupId(false);
    setCurrentChannelInfo(null);
    setCurrentChannel(null);

    if (save && save === 'group') {
      dispatch(setGroups(orderGroups));
      orderGroups.map(async group => {
        await updateAppContentScopeGroup({
          variables: {
            id: group.id,
            name: group.name,
            position: group.position
          },
        });
      });
    } else if (save && save === 'channel') {
      const ids = orderChannels.map(item => item.id);
      const newChannels = channels.map(channel => {
        if (ids.includes(channel.id)) {
          return {
            ...channel,
            position: orderChannels.filter(item => item.id === channel.id)[0]['position']
          }
        } else {
          return channel;
        }
      })
      dispatch(setChannels(newChannels));
      orderChannels.map(async channel => {
        await updateAppContentScope({
          variables: {
            id: channel.id,
            position: channel.position
          },
        });
      });
    }
  };

  React.useEffect(() => {
    setFiltered(channels);
  }, [channels]);

  React.useEffect(() => {
    setOrderedGroups([...channelGroups].filter(o => !o.archived).sort((a, b) => (a.position - b.position)));
  }, []);

  const getLogoAbbreviation = name => {
    let newTitle = '';
    if (name.indexOf(' ') > -1) {
      const newName = name.split(' ')
      newTitle = newName[0].split('')[0] + newName[1].split('')[0]
    } else {
      newTitle = name.split('')[0] + name.split('')[1]
    }
    return newTitle.length > 2 ? newTitle.substring(0, 2) : newTitle.toUpperCase();
  }

  const isValidImageFormat = url => {
    var pattern = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    return !!pattern.test(url);
  }

  const TAB_STATUS = {
    ALL: 'all',
    LIVE: 'published',
    ARCHIVE: 'archived'
  };

  const redirectChannel = () => {

    let groups = [...channelGroups].sort((a, b) => (a.position - b.position)).filter(el => el.archived != true);

    if (groups.length > 0) {
      for (let i = 0; i < groups.length; i++) {
        console.log("010101", groups[i])
        console.log("0909", groups[i].id);
        let channels = getChannelsOfGroup(groups[i].id);
        console.log("looochannels", channels)
        if (channels.length > 0) {
          navigate(
            `/app/${organization_id}/groups/${groups[0].id}/channels/${channels[0].id}`
          );
          break;
        }
        continue;
      }
      navigate(`/app/${organization_id}/groups/${groups[0].id}`);
    }
    navigate(`/app/${organization_id}`);
  }


  return (<>
    <div className="channelsDropDown">
      {/* groups */}
      <div
        className={css({
          position: 'fixed',
          zIndex: 44,
          background: defaultTheme === "dark" ? '#1e1f2c' : theme.colors.primaryB,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          height: 'calc(100vh - 52px)',
          maxHeight: 'calc(100vh - 52px)',
          overflowY: 'auto',
        })}
      >
        <div
          className={css({
            position: 'relative',
            background: defaultTheme === "dark" ? '#1e1f2c' : theme.colors.primaryB,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            height: 'calc(100vh - 52px)',
            maxHeight: 'calc(100vh - 52px)',
            overflowY: 'auto',
          })}
        >
          <div
            className={css({
              marginTop: '16px',
              padding: '16px',
            })}
          >
            {showChannelsManger && <HeadingXSmall
              className={css({
                padding: '0 !important',
                margin: '0 !important',
                paddingLeft: '5px !important'
              })}
            >
              {/* <Link to={`/app/${organization_id}/groups`} style={{ color: defaultTheme === "dark" ? 'white' : 'black', textDecoration: 'none' }}>
                Channel Manager
            </Link> */}
            </HeadingXSmall>}
            <Tabs
              activeKey={activeKey}
              onChange={({ activeKey }) => {
                setActiveKey(activeKey.toString());
              }}
              activateOnFocus
              fill={FILL.fixed}
            >
              <Tab title="All"></Tab>
              <Tab title="Archived"></Tab>
            </Tabs>
            <Input
              endEnhancer={<Search size="18px" />}
              placeholder="Search channels"
              overrides={{
                Root: {
                  style: {
                    // marginTop: '0.5rem'
                  }
                }
              }}
              clearOnEscape
              onChange={e => {
                let v = e.target.value;
                if (v == '') setFiltered(channels);
                else
                  setFiltered(
                    channels.filter(el =>
                      el.name.toLowerCase().includes(v.toLowerCase())
                    )
                  );
              }}
            />
            {showAllGroupsButton && <LabelMedium
              style={{
                marginLeft: '16px',
                marginTop: '16px'
              }}
            ><a href="#all" onClick={onAllGroupsButtonClick} style={{ color: selectedChannel.id == '*' ? 'rgb(98, 70, 238)' : 'black' }}>Show all Channels</a></LabelMedium>}
          </div>
          {showAddButtons && <HeadingXSmall
            onClick={() => {
              setIsAddGroupChannel(true);
            }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            className={css({
              ...paddingLeftRightCss,
              color: '#5D64AB',
              margin: 0,
              cursor: 'pointer',
              textDecoration: onHover ? 'underline' : 'none',
              marginLeft: '1rem'
            })}
          >
            + Groups
          </HeadingXSmall>}
          {[...channelGroups].sort((a, b) => (a.position - b.position)).filter((el) => {
            if (activeKey === "0" || activeKey === 0) {
              return el.archived !== true
            }
            return el.archived === true
          }).map((el, i) => {
            return (

              <div
                className={css({
                  paddingTop: theme.sizing.scale800,
                  paddingBottom: theme.sizing.scale800,
                  // width: '335px'
                  width: '100%'
                })}
                key={i}
              >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div style={{ display: 'flex' }}>
                    <div
                      className={css({
                        position: 'relative',
                        paddingLeft: theme.sizing.scale800,
                        paddingRight: theme.sizing.scale800,
                      })}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16" cy="16" r="16" fill="#192F57" />
                        <path
                          d="M13.4 14.5L18.17 6.24C17.47 6.09 16.75 6 16 6C13.6 6 11.4 6.85 9.68 8.25L13.34 14.6L13.4 14.5ZM25.54 13C24.62 10.08 22.39 7.74 19.54 6.66L15.88 13H25.54ZM25.8 14H18.31L18.6 14.5L23.36 22.75C25 20.97 26 18.61 26 16C26 15.31 25.93 14.65 25.8 14ZM12.54 16L8.64 9.25C7.01 11.03 6 13.39 6 16C6 16.69 6.07 17.35 6.2 18H13.69L12.54 16ZM6.46 19C7.38 21.92 9.61 24.26 12.46 25.34L16.12 19H6.46ZM17.73 19L13.83 25.76C14.53 25.91 15.25 26 16 26C18.4 26 20.6 25.15 22.32 23.75L18.66 17.4L17.73 19Z"
                          fill="#00A3FF"
                        />
                      </svg>
                    </div>
                    <HeadingXSmall
                      className={css({
                        ...paddingLeftRightCss,
                        marginBottom: '16px !important',
                        paddingRight: 0,
                        paddingLeft: 0
                      })}
                    >
                      {el.name.length > 20 ? el.archived ? el.name.substring(0, 19) + " Archive" : el.name.substring(0, 19) : el.archived ? el.name + " Archive" : el.name}
                    </HeadingXSmall>
                  </div>
                  <div style={{ display: 'flex', paddingTop: '4px' }}>

                    <StatefulTooltip
                      content={() => <div padding={'20px'}>Reorder</div>}
                      placement={PLACEMENT.bottom}
                      returnFocus
                      autoFocus
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={css({
                          cursor: 'pointer',
                          marginTop: '3px'
                        })}
                        onClick={(event) => {
                          event.stopPropagation();
                          reOrderGroups(!isGroupOrderOpen);
                        }}
                      >
                        <path
                          d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                          fill={defaultTheme === 'light' ? 'black' : 'white'}
                        />
                      </svg>
                    </StatefulTooltip>
                    <div style={{ marginLeft: '0.1rem' }}>
                      <Checkbox
                        checked={el.public}
                        overrides={{
                          ToggleTrack: {
                            style: {
                              ...(el.public ? { background: 'green !important' } : {})
                            }
                          },
                        }}
                        onChange={e => {
                          e.stopPropagation()
                          updateAppContentScopeGroup({
                            variables: {
                              id: el.id,
                              name: el.name,
                              public: !el.public
                            },
                          });
                        }}
                        checkmarkType={STYLE_TYPE.toggle_round}
                      />
                    </div>
                    <div
                      className={css({
                        width: theme.sizing.scale1200,
                        height: theme.sizing.scale1200,
                        width: '22px',
                        height: '22px',
                        cursor: 'pointer',
                        marginLeft: '0.25rem'
                      })}
                      onClick={event => {
                        event.stopPropagation();
                        setGroupId(el.id);
                        setGroupPosition(el.position)
                        setGroup(el)
                        setIsAddGroupChannel(true)
                      }}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
                          fill={defaultTheme === 'light' ? 'black' : 'white'}
                        />
                      </svg>
                    </div>

                  </div>
                </div>

                {/* LISTS */}
                <div>
                  {getChannelsOfGroup(el.id).map((el1, i1) => {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '0.5rem', marginLeft: '1rem' }} className={css({
                        height: '48px',
                        cursor: 'pointer',
                        ...(el1.id == selectedChannel.id
                          ? {
                            background:
                              'linear-gradient(180deg, #6246EE 0%, #7C66ED 100%)',
                          }
                          : {}),
                      })}>
                        <div
                          key={i1}
                          onClick={() => {
                            if (onChannelClick) {
                              return onChannelClick(el1);
                            };
                            dispatch(setSelectedChannel(el1));
                            localStorage.setItem('selectedChannel', JSON.stringify(el1))
                            dispatch(setSelectedGroup(el));
                            localStorage.setItem('selectedGroup', JSON.stringify(el))
                            navigate(
                              `/app/${organization_id}/groups/${el.id}/channels/${el1.id}`
                            );
                            close();
                          }}
                        >
                          <div className={css({
                            paddingLeft: '60px',
                            ...(el1.id == selectedChannel.id
                              ? { color: theme.colors.primaryB }
                              : {}),
                            display: 'flex',
                            alignItems: 'center'
                          })}>
                            {sourceByType(el1.logo_data) && isValidImageFormat(sourceByType(el1.logo_data)) && (
                              <img src={sourceByType(el1.logo_data)} className={css({
                                width: '30px',
                                height: '30px',
                                margin: 0,
                                borderRadius: '50%',
                                marginRight: '10px'
                              })} alt="channel_logo" />
                            )}
                            {(!isValidImageFormat(sourceByType(el1.logo_data)) || !sourceByType(el1.logo_data)) && (
                              <span className={css({
                                width: '30px',
                                height: '30px',
                                margin: 0,
                                background: 'linear-gradient(160deg, rgb(98, 70, 238), rgb(238 155 70 / 57%))',
                                borderRadius: '50%',
                                marginRight: '10px',
                                fontSize: '16px',
                                textAlign: 'center',
                                color: 'white',
                                fontWeight: 'bold',
                                paddingTop: '2px'
                              })}>{getLogoAbbreviation(el1.name)}</span>
                            )}

                            <LabelMedium
                              className={css({
                                // lineHeight: '48px',
                              })}
                            >
                              {el1.name}
                            </LabelMedium>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>

                          <StatefulTooltip
                            content={() => <div padding={'20px'}>Reorder</div>}
                            placement={PLACEMENT.bottom}
                            returnFocus
                            autoFocus
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={css({
                                cursor: 'pointer',
                              })}
                              onClick={(event) => {
                                event.stopPropagation();
                                setOrderedChannels(getChannelsOfGroup(el.id));
                                reOrderChannels(!isChannelOrderOpen);
                              }}
                            >
                              <path
                                d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                                fill={defaultTheme === 'light' ? 'black' : 'white'}
                              />
                            </svg>
                          </StatefulTooltip>
                          <div>
                            <Checkbox
                              checked={el1.public}
                              overrides={{
                                ToggleTrack: {
                                  style: {
                                    ...(el1.public ? { background: 'green !important' } : {})
                                  }
                                },
                              }}
                              onChange={e => {
                                e.stopPropagation()
                                updateAppContentScope({
                                  variables: {
                                    id: el1.id,
                                    public: !el1.public
                                  },
                                });
                              }}
                              checkmarkType={STYLE_TYPE.toggle_round}
                            />
                          </div>
                          <div
                            className={css({
                              width: theme.sizing.scale1200,
                              height: theme.sizing.scale1200,
                              width: '22px',
                              height: '22px',
                              cursor: 'pointer',
                              marginLeft: '0.25rem'
                            })}
                            onClick={event => {
                              event.stopPropagation();
                              // setGroupId(el.id);
                              // setGroupPosition(el.position)
                              // setGroupTitle(el.name)
                              // setIsAddGroupChannel(true)
                              setCurrentChannelInfo({
                                app_content_scope_group_id: el.id,
                                count: getChannelsOfGroup(el.id).length,
                              });
                              setCurrentChannel(el1);
                              setIsAddChannel(true);
                            }}
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
                                fill={defaultTheme === 'light' ? 'black' : 'white'}
                              />
                            </svg>
                          </div>
                        </div>

                      </div>

                    );
                  })}
                  {showAddButtons && <div
                    className={css({
                      height: '48px',
                      cursor: 'pointer',
                    })}
                  >
                    <LabelMedium
                      className={css({
                        lineHeight: '48px',
                        paddingLeft: '80px',
                        color: '#5D64AB',
                      })}
                      onClick={() => {
                        setCurrentChannelInfo({
                          app_content_scope_group_id: el.id,
                          count: getChannelsOfGroup(el.id).length,
                        });
                        setIsAddChannel(!isAddChannelOpen);
                      }}
                    >
                      + Add Channel
                    </LabelMedium>
                  </div>}
                </div>
              </div>
            );
          })}

        </div>
      </div>

      {isAddChannelOpen && (
        <AddEditChannel
          onClose={onClose}
          {...currentChannel}
          totalCount={currentChannelInfo.count}
          app_content_scope_group_id={
            currentChannelInfo.app_content_scope_group_id
          }
          dispatch={dispatch}
          organization_id={organization_id}
        ></AddEditChannel>
      )}
      {isAddChannelGroupOpen && (
        <AddEditChannelGroup
          onClose={onClose}
          totalCount={channelGroups.length}
          organization_id={organization_id}
          id={groupId}
          position={groupPosition}
          {...group}
          redirectChannel={redirectChannel}

        ></AddEditChannelGroup>
      )}
      {isGroupOrderOpen && (
        <GroupReOrder
          isCollapsed={true}
          onClose={onClose}
          title={'Reorder Groups'}
          save='group'
        >
          <DragDropContext
            onDragEnd={({ destination, source, ...rest }) => {
              if (!destination) {
                return;
              }
              let newArr = arrayMove(
                [...orderGroups],
                source.index,
                destination.index
              );
              console.log(newArr);
              let newGroups = newArr.map((item, i) => {
                return {
                  ...item,
                  isNew: false,
                  position: i
                };
              });
              setOrderedGroups(newGroups);
            }}
          >
            <Droppable
              droppableId={'row1'}
              type={'group'}
              direction={'vertical'}
              isCombineEnabled={false}
            >
              {dropProvided => (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                  {orderGroups.filter(o => !o.archived).map((el, index) => {
                    return (
                      <Grid
                        overrides={{
                          Grid: {
                            style: {
                              padding: '0 !important',
                              margin: 0,
                            }
                          }
                        }}
                        key={index}
                        gridMaxWidth={'unset'}
                      >
                        <Cell span={12} overrides={{
                          Cell: {
                            style: {
                              padding: '0 !important',
                              margin: 0,
                            }
                          }
                        }}>
                          <Draggable
                            key={el.id}
                            draggableId={el.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <DragItem
                                dragProvided={dragProvided}
                                title={el.name}
                                css={css}
                                theme={theme}
                                snapshot={snapshot}
                                index={index}
                                setIsCollapsed={reOrderGroups}
                                isCollapsed={isGroupOrderOpen}
                              />
                            )}
                          </Draggable>
                        </Cell>
                      </Grid>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </GroupReOrder>
      )}
      {isChannelOrderOpen && (
        <GroupReOrder
          isCollapsed={true}
          onClose={onClose}
          title={'Reorder Channels'}
          save='channel'
        >
          <DragDropContext
            onDragEnd={({ destination, source, ...rest }) => {
              if (!destination) {
                return;
              }
              let newArr = arrayMove(
                [...orderChannels],
                source.index,
                destination.index
              );
              let newChannels = newArr.map((item, i) => {
                return {
                  ...item,
                  isNew: false,
                  position: i
                };
              });
              setOrderedChannels(newChannels);
            }}
          >
            <Droppable
              droppableId={'row2'}
              type={'channel'}
              direction={'vertical'}
              isCombineEnabled={false}
            >
              {dropProvided => (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                  {orderChannels.map((el, index) => {
                    return (
                      <Grid
                        overrides={{
                          Grid: {
                            style: {
                              padding: '0 !important',
                              margin: 0,
                            }
                          }
                        }}
                        key={index}
                        gridMaxWidth={'unset'}
                      >
                        <Cell span={12} overrides={{
                          Cell: {
                            style: {
                              padding: '0 !important',
                              margin: 0,
                            }
                          }
                        }}>
                          <Draggable
                            key={el.id}
                            draggableId={el.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <DragItem
                                dragProvided={dragProvided}
                                title={el.name}
                                css={css}
                                theme={theme}
                                snapshot={snapshot}
                                index={index}
                                setIsCollapsed={reOrderChannels}
                                isCollapsed={isChannelOrderOpen}
                              />
                            )}
                          </Draggable>
                        </Cell>
                      </Grid>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </GroupReOrder>
      )}
    </div>
  </>
  );
};
