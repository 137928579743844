import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import BaseClass from './base';

class SelectOnCheckBoxComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      checked: false,
    };
  }

  render() {
    const value = this.state.value;

    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {''}
        </h5>
        <Checkbox
          checked={this.state.checked}
          onChange={e => this.setState({ checked: e.target.checked })}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          Add Contact Method
        </Checkbox>
        {this.state.checked && (
          <>
            {' '}
            <h5 style={{ marginTop: this.props.theme.sizing.scale600 }}>
              {this.props.title || ''}
            </h5>
            <Select
              options={this.props.options}
              onChange={({ value }) => this.setState({ value })}
              value={value}
              placeholder={this.props.placeholder}
              multi={this.props.multi || false}
              labelKey="label"
              valueKey="id"
              maxDropdownHeight="300px"
              type={TYPE.search}
            />
          </>
        )}
      </>
    );
  }
}

export default SelectOnCheckBoxComponent;
