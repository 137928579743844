import gql from 'graphql-tag';

var cardModuleCreateSub = [];
var cardModuleUpdateSub = [];

export const initSubscription = (
  subscribeToMore,
  options,
  data,
  cardId,
  onSaveLoading
) => {
  // CRATE
  console.log('SETTING UP SUBS');
  cardModuleCreateSub && cardModuleCreateSub.forEach(el => el());
  cardModuleCreateSub = options.map(el => {
    let keysToGet = el.inputs.map(el1 => {
      return `${el1.key} ${
        el1.subQueryKeys
          ? `{
            ${el1.subQueryKeys.map(el2 => el2 + '\n')}
          }`
          : ''
      }`;
    });
    return subscribeToMore({
      document: gql`
          subscription on${el.__typename} {
            ${el.__typename}Created(card_id: "${cardId}") {
              data {
                id
                ${keysToGet.join('\n')}
              }
              module {
                content_module_type
                position
                id
              }
            }
          }
          `,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data[`${el.__typename}Created`] ||
          onSaveLoading
        ) {
          console.log('UPDATE IGNORED SAVE IN PROGRESS');
          return prev;
        }
        let { data, module } = subscriptionData.data[`${el.__typename}Created`];
        let x = JSON.parse(JSON.stringify(prev));
        x.card.card_content_modules.push({
          id: module.id,
          position: module.position,
          content_module: {
            __typename: module.content_module_type,
            ...data,
          },
        });
        return x;
      },
      onError: err => {
        console.log(err);
        console.log(el);
      },
    });
  });

  // UPDATE
  cardModuleUpdateSub.forEach(el => el());
  cardModuleUpdateSub = data
    ? data.card.card_content_modules.map((el, i) => {
        let moduleType = el.content_module.__typename;
        let keysToGet = options
          .find(el1 => el1.__typename == moduleType)
          .inputs.map(el1 => {
            return `${el1.key} ${
              el1.subQueryKeys
                ? `{
        ${el1.subQueryKeys.map(el2 => el2 + '\n')}
      }`
                : ''
            }`;
          });
        return subscribeToMore({
          document: gql`
        subscription on${moduleType} {
          ${moduleType}Updated(id: "${el.content_module.id}"){
            data {
              id
              ${keysToGet.join('\n')}
            }
            module {
              content_module_type
              position
              id
            }
          }
        }
      `,
          updateQuery: (prev, { subscriptionData }) => {
            if (
              !subscriptionData.data[`${moduleType}Updated`] ||
              onSaveLoading
            ) {
              console.log('UPDATE IGNORED SAVE IN PROGRESS');
              return prev;
            }
            let { data, module } = subscriptionData.data[
              `${moduleType}Updated`
            ];
            let x = JSON.parse(JSON.stringify(prev));
            x.card.card_content_modules[i].position = module.position;
            keysToGet.forEach(key => {
              x.card.card_content_modules[i].content_module[key] = data[key];
            });
            return x;
          },
          onError: err => {
            console.log(err);
          },
        });
      })
    : [];
};

export const dropSubscriptions = () => {
  cardModuleCreateSub && cardModuleCreateSub.forEach(el => el());
  cardModuleUpdateSub.forEach(el => el());
};
