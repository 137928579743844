import React from 'react';
import { TimePicker } from 'baseui/timepicker';
import BaseClass from './base';

class TimeComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(),
    };
  }

  render() {
    let value = new Date(this.state.value);
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        <TimePicker
          value={value}
          onChange={date => this.setState({ value: date })}
        />
      </>
    );
  }
}

export default TimeComponent;
