import * as React from 'react';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import 'react-toggle/style.css';
import { Textarea } from 'baseui/textarea';
import { saveModalOnEnterPress } from '../../util';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE,
} from 'baseui/modal';
import {
    Checkbox,
    LABEL_PLACEMENT
} from "baseui/checkbox";
import ModalStateContainer from '../modalStateContainer';
import { client } from '../../apollo/client';

var toggleConfirmFunc;

export default ({
    deck_id,
    position,
    organization_id,
    refreshQuizQuestions,
    cards,
    defaultTheme,
    groupId,
    channelId,
    onCloseModal = () => { },
    modalProps = {}
}) => {
    const [css, theme] = useStyletron();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';
    const addCard = () => {
        setIsOpen(true);
    };

    // const [addMatchingQuestion, { loading, data }] = useMutation(gql`
    //     mutation createQuizMatchingQuestion(
    //         $created_by_id: ID,
    //         $quiz_deck_id: ID,
    //         $organization_id: ID
    //         $archived: Boolean
    //         $question_text: String
    //         $published: Boolean
    //         $position: Int
    //       ) {
    //         createQuizMatchingQuestion(
    //             created_by_id: $created_by_id,
    //             quiz_deck_id: $quiz_deck_id,
    //             organization_id: $organization_id
    //             archived: $archived
    //             question_text: $question_text
    //             published: $published
    //             position: $position
    //         ) {
    //             id
    //         }
    //       }
    // `);

    // const [addDefaultQuestion, { loading: loading1, data: data1 }] = useMutation();

    // const [addOptionQuestion, { loading: loading2, data: data2 }] = useMutation(gql`
    //     mutation createQuizOptionQuestion(
    //         $created_by_id: ID,
    //         $quiz_deck_id: ID,
    //         $organization_id: ID
    //         $archived: Boolean
    //         $question_text: String
    //         $published: Boolean
    //         $position: Int
    //       ) {
    //         createQuizOptionQuestion(
    //             created_by_id: $created_by_id,
    //             quiz_deck_id: $quiz_deck_id,
    //             organization_id: $organization_id
    //             archived: $archived
    //             question_text: $question_text
    //             published: $published
    //             position: $position
    //         ) {
    //             id
    //         }
    //       }
    // `);

    const onSaveInterNal = () => {
        console.log(selectedCardType);
        let dataToSave = {
            variables: {
                created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
                organization_id,
                position,
                quiz_deck_id: deck_id,
                archived: false,
                published: false,
                question_text: formValue
            }
        };
        if (formValue) {
            // debugger;
            setIsLoading(true);
            client.mutate({
                mutation: gql`
                mutation createDefaultQuizQuestion(
                    $created_by_id: ID,
                    $quiz_deck_id: ID,
                    $organization_id: ID
                    $archived: Boolean
                    $question_text: String
                    $published: Boolean
                    $position: Int
                  ) {
                    createDefaultQuizQuestion(
                        created_by_id: $created_by_id,
                        quiz_deck_id: $quiz_deck_id,
                        organization_id: $organization_id
                        archived: $archived
                        question_text: $question_text
                        published: $published
                        position: $position
                    ) {
                        id
                        quiz_question_id
                    }
                  }
            `,
                variables: dataToSave.variables
            }).then((result) => {
                console.log(result);
                refreshQuizQuestions();
                setTimeout(() => {
                    navigate(
                        `/app/${organization_id}/groups/${groupId}/channels/${channelId}/deck/${deck_id}/card/${result.data.createDefaultQuizQuestion.quiz_question_id}/quiz`
                    );
                }, 1000)
               
            })
        }
    };

    const [selectedCardType, setSelectedCardType] = React.useState(null);
    const [formValue, setFormValue] = React.useState('');
    // const CARD_TYPES = [
    //     {
    //         id: 'text',
    //         display: 'Text',
    //         mutations: {
    //             create: '',
    //         },
    //         modalHeader: 'New Text Question',
    //         title: 'Text Question Name',
    //         placeholder: 'Your Text Question Name'
    //     },
    //     {
    //         id: 'matching',
    //         display: 'Matching',
    //         mutations: {
    //             requiredFields: []
    //         },
    //         modalHeader: 'New Matching Question',
    //         title: 'Matching Question Name',
    //         placeholder: 'Your Matching Question Name'
    //     },
    //     {
    //         id: 'multiple',
    //         display: 'Multiple Choice',
    //         mutations: {
    //             create: '',
    //         },
    //         modalHeader: 'New Option Question',
    //         title: 'Option Question Name',
    //         placeholder: 'Your Option Question Name'
    //     }
    // ];

    // React.useEffect(() => {
    //     if ((data1) && !(loading1)) {
    //         refreshQuizQuestions();
    //     };
    // }, [data1]);

    React.useEffect(() => {
        setIsLoading(false);
        setSelectedCardType(null);
        setFormValue('');
        // toggleConfirmFunc(false, () => setIsOpen(false));
    }, [cards]);

    // console.log(data);

    const Version1CardDesign = (
        <div
            className={css({
                position: 'absolute',
                width: '100%',
                top: '50%',
                transform: 'translateY(-50%)',
            })}
        >
            <svg
                width="15"
                height="15.75"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={css({
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    cursor: 'pointer',
                })}
                onClick={addCard}
            >
                <path
                    d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
                    fill={bgColor}
                />
            </svg>
            <LabelLarge
                className={`add-new-card-label ${css({
                    color: bgColor
                })}`}
            // className={css({
            //     // textAlign: 'center',
            //     // // marginTop: '72px',
            //     color: bgColor,
            //     // textTransform: 'uppercase',
            // })}
            >
                Add New Card
            </LabelLarge>
        </div>
    );

    return (
        <>
            <div
                // className={css({
                //     width: '180px !important',
                //     minWidth: '180px !important',
                //     height: '250px',
                //     marginBottom: '5px',
                //     marginRight: 0,
                //     boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                //     position: 'relative',
                //     float: 'left',
                //     border: '2px solid #5D64AB',
                //     borderRadius: '5px',
                //     // background: defaultTheme === 'white' ? '#ECEBEB' : 'rgba(74, 76, 160, 0.2)',
                //     // border: '2px solid rgba(74, 76, 160, 0.2)',
                //     background: '#FAFAFA',
                //     border: '3px solid #4A4CA0',
                //     cursor: 'pointer',
                // })}
                className='add-new-card-container-style'
                onClick={addCard}
            >
                {Version1CardDesign}
            </div>
            {/* <ModalStateContainer>
                {({ isConfirmationOpen, toggleConfirm }) => (
                    <React.Fragment>
                        {toggleConfirmFunc = toggleConfirm} */}
            {/* <Modal
                            overrides={{
                                Backdrop: {
                                    style: ({ $theme }) => ({
                                        backdropFilter: $theme.modalBackDropFilter,
                                    }),
                                },
                                Root: {
                                    style: {
                                        zIndex: 3
                                    }
                                }
                            }}
                            onClose={() => {
                                setIsOpen(false);
                                onCloseModal();
                            }}
                            isOpen={isOpen}
                            {...modalProps}
                        >
                            <ModalHeader>Select Quiz Card Type</ModalHeader>
                            <ModalBody>
                                {CARD_TYPES.map(cardType => {
                                    return (
                                        <Checkbox
                                            checked={selectedCardType && selectedCardType.id === cardType.id ? true : false}
                                            onChange={e => setSelectedCardType(cardType)}
                                            labelPlacement={LABEL_PLACEMENT.right}
                                        >
                                            {cardType.display}
                                        </Checkbox>
                                    )
                                })}
                            </ModalBody>
                            <ModalFooter>
                                <ModalButton
                                    kind="tertiary"
                                    onClick={() => {
                                        setIsOpen(false)
                                        onCloseModal();
                                    }}
                                >
                                    Cancel
                                </ModalButton>
                                <ModalButton
                                    onClick={() => {
                                        selectedCardType && toggleConfirm(true)

                                    }}>
                                    Okay
                                </ModalButton>
                            </ModalFooter>
                        </Modal> */}
            <Modal
                onClose={() => {
                    setIsOpen(false);
                    onCloseModal();
                }}
                isOpen={isOpen}
                overrides={{
                    Backdrop: {
                        style: ({ $theme }) => ({
                            backdropFilter: $theme.modalBackDropFilter,
                        }),
                    },
                    Root: {
                        style: {
                            zIndex: 3
                        }
                    }
                }}
            >
                <ModalHeader>Add Quiz Card</ModalHeader>
                <ModalBody>
                    <LabelMedium
                        className={css({
                            marginBottom: '16px',
                        })}
                    >
                        {"Enter title"}
                    </LabelMedium>
                    <Textarea
                        value={formValue}
                        onChange={e => setFormValue(e.target.value.replace('\n', ''))}
                        placeholder={"Enter your question text"}
                        clearOnEscape
                        onKeyDown={e =>
                            saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <ModalButton
                        onClick={() => {
                            onSaveInterNal(() => {
                                setIsOpen(false);
                            });
                            onCloseModal();
                        }}
                        isLoading={isLoading}
                    >
                        Save
                                </ModalButton>
                </ModalFooter>
            </Modal>
            {/* </React.Fragment>
                )}
            </ModalStateContainer> */}
        </>
    );
};
