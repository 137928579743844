import * as React from 'react';
import * as FontAwesome from 'react-icons/fa';
import { trim, startCase, replace } from 'lodash';
import styled from '@emotion/styled';
import { useStyletron } from 'baseui';

export default ({iconName, searchIcon, style}) => {
    const [css, theme] = useStyletron();
    const trimmedName = `Fa${trim(startCase(iconName))}`;
    const convertedName = iconName.includes('Fa') ? iconName : replace(trimmedName, /\s/g, '');
    // console.log(convertedName);
    const OrgIcon = styled(FontAwesome[convertedName])`
      color: ${theme.colors.primaryA};
      font-size: ${searchIcon ? '36px' : '96px'};
      font-weight: lighter;
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
    return <div style={style}><OrgIcon /></div>;
};