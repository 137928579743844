import * as React from 'react';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import 'react-toggle/style.css';
import { useMutation } from '@apollo/client';
import { Textarea } from 'baseui/textarea';
import { addCardMutation, addCardStackCard } from '../../quries';
import { saveModalOnEnterPress } from '../../util';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { card_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import titlesLength from '../../../data/titlesLength.json';
import ImportCardModal from './ImportCardModal';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

export default ({
  cardStyle = 'basis',
  orgId,
  cards,
  home_component_id,
  defaultTheme,
  onCloseModal = () => { },
  modalProps = {},
  refetchHomeComponents,
  isCopyCard
}) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [subTitle, setSubTitle] = React.useState('');

  const [addCard, { loading: addCardLoading, data: createdCard }] = useMutation(
    addCardStackCard
  );

  React.useEffect(() => {
    setIsModalOpen(false);
  }, [createdCard]);

  const bgColor = '#5D64AB';

  const addCardOpen = () => {
    setIsModalOpen(true);
  };

  ////////////////////////////////////////////// Event Functions //////////////////////////////////////////////
  const onCloseImportModal = () => {
    setIsImportModalOpen(false)
  }

  const onSuccessfullyImport = () => {
    setIsImportModalOpen(false);
    setIsModalOpen(false);
    refetchHomeComponents();
  }

  const onSaveInterNal = async () => {
    if (value != '') {
      let d = {
        title: value,
        sub_title: subTitle || '',
        card_type: 'modular',
        front_image_data: null,
        link: null,
        back_image_data: null,
        back_description: null,
        youtube_video_id: null,
        file_data: null,
        archived: false,
        full_bleed: false,
        organization_id: orgId,
        created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
        has_feedback: false,
        card_favorite_count: 0,
        card_feedback_count: 0,
        created_by_name: '',
        position: cards.length,
        published: false,
        home_component_id,
        is_copy_card: isCopyCard
      };
      await addCard({
        variables: {
          input: d
        },
      });

      refetchHomeComponents();
    }
  };

  const onClickSave = () => {
    onSaveInterNal();
    setTimeout(() => {
      onCloseModal();
    }, 1000);
  }

  const onClickImport = () => {
    setIsImportModalOpen(true)
  }

  //////////////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////////

  // BASIC CARD DESIGN
  const BasicCardDesign = (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={css({
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: theme.sizing.scale950,
        })}
      >
        <path
          d="M2.55 17.54L3.96 18.95L5.75 17.15L4.34 15.74L2.55 17.54ZM10 21.45H12V18.5H10V21.45ZM3 9.5H0V11.5H3V9.5ZM14 5.31V0.5H8V5.31C6.21 6.35 5 8.28 5 10.5C5 13.81 7.69 16.5 11 16.5C14.31 16.5 17 13.81 17 10.5C17 8.28 15.79 6.35 14 5.31ZM19 9.5V11.5H22V9.5H19ZM16.24 17.16L18.03 18.96L19.44 17.55L17.64 15.76L16.24 17.16Z"
          fill={bgColor}
        />
      </svg>
      <LabelLarge
        className={css({
          textAlign: 'center',
          marginTop: '72px',
          color: bgColor
        })}
      >
        {isCopyCard? 'Add Copy Card' : 'Add new card'}
      </LabelLarge>
      <div className="addCard">
        {(
          <Textarea
            value={value}
            onChange={e => setValue(e.target.value.replace('\n', ''))}
            placeholder="Enter card title here"
            clearOnEscape
            className={css({
              position: 'absolute',
              width: 'auto',
              left: '14px',
              height: '92px',
              bottom: '16px',
              right: '14px',
            })}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                onSaveInterNal(value);
              }
            }}
          />
        )}
      </div>
    </>
  );

  const Version1CardDesign = (
    <div
      className={css({
        position: 'absolute',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
      })}
    >
      <svg
        width="15"
        height="15.75"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={css({
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          cursor: 'pointer',
        })}
      // onClick={addCardOpen}
      >
        <path
          d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
          fill={bgColor}
        />
      </svg>
      <LabelLarge

        className={`add-new-card-label ${css({
          color: bgColor
        })}`}
      >
        {isCopyCard? 'Add Copy Card' : 'Add Card'}
      </LabelLarge>
    </div>
  );

  return (
    <>
      <div
        className='add-new-card-container-style'
        onClick={addCardOpen}
      >
        {cardStyle == 'Version1' ? Version1CardDesign : BasicCardDesign}
      </div>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={() => {
          setIsModalOpen(false);
          setValue('');
          setSubTitle('');
          onCloseModal();
        }}
        closeable
        isOpen={isModalOpen}
        animate
        autoFocus
        {...modalProps}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
            Add {isCopyCard? 'Copy' : 'New'} Card
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={value}
                onChange={e => {
                  e.target.value.length <= titlesLength.card && setValue(e.target.value.replace('\n', ''))
                }}
                placeholder="Enter card title here"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
                }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{value.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Subtitle</LabelMedium>
              <Textarea
                value={subTitle}
                onChange={e => setSubTitle(e.target.value.replace('\n', ''))}
                placeholder="Enter card sub title here"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
                }
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          {
            !isCopyCard && (
              <ModalButton
                isLoading={addCardLoading}
                onClick={onClickImport}
              >
                Import
              </ModalButton>
            )
          }

          <ModalButton
            isLoading={addCardLoading}
            onClick={onClickSave}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
      <ImportCardModal
        isModalOpen={isImportModalOpen}
        cards={cards}
        onSuccessfullyImport={onSuccessfullyImport}
        onCloseModal={onCloseImportModal}
        home_component_id={home_component_id}
      />
    </>
  );
};
