import * as React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { useStyletron } from 'baseui';

import { Input } from 'baseui/input';
import { Search, Plus } from 'baseui/icon';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';
  
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { LabelLarge, LabelSmall } from "baseui/typography";
import { Avatar } from "baseui/avatar";
import { navigate } from '@reach/router';
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMove } from 'baseui/dnd-list';
import { useMutation } from '@apollo/client';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { bounce, slideInLeft, slideInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { MdGroupOff, MdGroup, MdGroupAdd } from "react-icons/md";

import Button from '../common/button';
import { setSelectedChannel, setSelectedGroup, setGroups, setChannels, showAlert } from '../../redux/actions/appBasicControls';
import { sourceByType } from '../../util';
import PortalAwareItem from '../home-components/portal';
import ReorderInModal from '../reorderInModal';
import { cellPaddingOverrides, gridPaddingOverrides } from '../overrides';
import AddEditChannel from './addEditChannel';
import AddEditChannelGroup from '../groups/addEditGroup';
import { updateGroup, updateChannel } from '../../quries';


const GroupContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
  &:hover .groupReorder {
    fill: ${props => props.theme.colors.primaryA} !important;
  }
  &:hover .groupSettings {
    fill: ${props => props.theme.colors.primaryA} !important;
  }
`;


const MainDiv = styled.div`
  position: absolute;
  width: 350px;
  background: ${({ theme }) => theme.colors.channelManagerBackgroundColor};
  box-shadow: ${({ theme }) => theme.lighting.shadowCardware};
  height: calc(100vh - 52px);
  max-height: calc(100vh - 52px);
  overflow: auto;
  scroll-behavior: smooth; 
`;

const ChannelContainer = styled.div``;

const SearchContainer = styled.div`
  padding: ${props => props.theme.sizing.scale600};
`;

const ChannelDiv = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background: ${props => props.selected ? props.theme.colors.primaryC : 'none'};
  padding: ${props => props.theme.sizing.scale400};
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-duration: 200ms;
  padding-left: ${props => props.theme.sizing.scale600};
  padding-right: ${props => props.theme.sizing.scale600};
  &:hover {
    background: ${props => props.theme.colors.primaryC};
  };
  &:hover .channelControls {
    display: block;
  };
  &:hover .channelReorder {
    display: block !important;
  };
  &:hover .channelName {
    color: ${props => props.theme.colors.primaryB} !important;
  }
`;

const ChannelDivButton = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background: ${props => props.selected ? props.theme.colors.primaryC : 'none'};
  padding: ${props => props.theme.sizing.scale400};
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-duration: 200ms;
  padding-left: ${props => props.theme.sizing.scale600};
  padding-right: ${props => props.theme.sizing.scale600};
`;

const RelativeDiv = styled.div`
  position: relative;
  height: 100%;
  float: ${props => props.float || 'left'};
`;

const RelativeControlsDiv = styled.div`
  position: relative;
  height: 100%;
  float: ${props => props.float || 'left'};
  display: ${props => props.isControlActive ? 'block' : 'none'};
`;

const Reorder = ({ css, isActive, color, style = {}, className }) => {
  return <svg width="21" height="18" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg"
    style={{
      cursor: 'pointer',
      ...style,
      fill: isActive ? color : '#AFAFAF',
    }}
    className={className}
  >
    <path d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z" />
  </svg>;
};

const Settings = ({ color, isActive = true, className }) => {
  return <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      cursor: 'pointer',
      fill: isActive ? color : '#AFAFAF',
    }}
    className={className}
  >
    <path d="M16.4731 10.536C16.5091 10.236 16.5331 9.924 16.5331 9.6C16.5331 9.276 16.5091 8.964 16.4611 8.664L18.4891 7.08C18.6691 6.936 18.7171 6.672 18.6091 6.468L16.6891 3.144C16.5691 2.928 16.3171 2.856 16.1011 2.928L13.7131 3.888C13.2091 3.504 12.6811 3.192 12.0931 2.952L11.7331 0.408C11.6971 0.168 11.4931 0 11.2531 0H7.41311C7.17311 0 6.98112 0.168 6.94512 0.408L6.58511 2.952C5.99711 3.192 5.45712 3.516 4.96512 3.888L2.57711 2.928C2.36111 2.844 2.10911 2.928 1.98911 3.144L0.0691144 6.468C-0.0508856 6.684 -0.00288541 6.936 0.189115 7.08L2.21711 8.664C2.16911 8.964 2.13311 9.288 2.13311 9.6C2.13311 9.912 2.15711 10.236 2.20511 10.536L0.177114 12.12C-0.0028857 12.264 -0.0508856 12.528 0.0571144 12.732L1.97711 16.056C2.09711 16.272 2.34911 16.344 2.56511 16.272L4.95311 15.312C5.45711 15.696 5.98511 16.008 6.57311 16.248L6.93312 18.792C6.98111 19.032 7.17311 19.2 7.41311 19.2H11.2531C11.4931 19.2 11.6971 19.032 11.7211 18.792L12.0811 16.248C12.6691 16.008 13.2091 15.684 13.7011 15.312L16.0891 16.272C16.3051 16.356 16.5571 16.272 16.6771 16.056L18.5971 12.732C18.7171 12.516 18.6691 12.264 18.4771 12.12L16.4731 10.536ZM9.33311 13.2C7.35312 13.2 5.73311 11.58 5.73311 9.6C5.73311 7.62 7.35312 6 9.33311 6C11.3131 6 12.9331 7.62 12.9331 9.6C12.9331 11.58 11.3131 13.2 9.33311 13.2Z" />
  </svg>
};

const AddWhite = () => {
  return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8333 0.625H2.16667C1.24167 0.625 0.5 1.4125 0.5 2.375V14.625C0.5 15.5875 1.24167 16.375 2.16667 16.375H13.8333C14.75 16.375 15.5 15.5875 15.5 14.625V2.375C15.5 1.4125 14.75 0.625 13.8333 0.625ZM12.1667 9.375H8.83333V12.875H7.16667V9.375H3.83333V7.625H7.16667V4.125H8.83333V7.625H12.1667V9.375Z" fill="white" />
  </svg>
};

const AddSecondary = () => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.8333 0.625H2.16667C1.24167 0.625 0.5 1.4125 0.5 2.375V14.625C0.5 15.5875 1.24167 16.375 2.16667 16.375H13.8333C14.75 16.375 15.5 15.5875 15.5 14.625V2.375C15.5 1.4125 14.75 0.625 13.8333 0.625ZM12.1667 9.375H8.83333V12.875H7.16667V9.375H3.83333V7.625H7.16667V4.125H8.83333V7.625H12.1667V9.375Z" fill="#4A4CA0" />
</svg>;



const TabRootOverride = {
  TabHighlight: {
    style: ({ $theme }) => ({
      background: $theme.colors.primaryC
    })
  },
  Tab: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor
    }),
  },
  TabList: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor
    }),
  }
};

const TabOverride = {
  Tab: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor,
    })
  },
  TabPanel: {
    style: {
      padding: '0px !important'
    }
  }
};


const ChannelAvatar = ({ channel, channelColors, index }) => {
  // const [src, setSrc] = React.useState(null);
  const src = sourceByType(channel.logo_data, 'small', 'small');
  // React.useEffect(() => {
  //   if (!url) return;
  //   const imageLoader = new Image();
  //   imageLoader.src = url;
  //   imageLoader.onload = () => {
  //     setTimeout(() => {
  //       setSrc(url);
  //     }, 200);
  //   };
  // }, [url]);
  return src ?
    <div style={{
      width: '40px', height: '40px', borderRadius: '50%'
    }}>
      <img src={src} style={{
        width: '40px', position: 'absolute', top: '50%', transform: 'translateY(-50%)'
      }} />
    </div>
    : <Avatar
      name={channel.name} size={"scale1000"}
      overrides={{
        Root: {
          style: {
            backgroundColor: channelColors[index]
          }
        }
      }}
    ></Avatar>;
}

const SearchInput = ({ searchText, setSearchText, theme }) => {
  return (<Input
    value={searchText}
    onChange={event => setSearchText(event.currentTarget.value.trim())}
    clearable
    placeholder={'Search for channels'}
    startEnhancer={<Search size="18px" />}
    overrides={{
      Root: {
        style: {
          background: theme.colors.borderOpaque
        }
      },
      Input: {
        style: {
          background: theme.colors.borderOpaque,
          ':focus': {
            background: `none !important`,
          }
        }
      },
      StartEnhancer: {
        style: {
          background: theme.colors.borderOpaque
        }
      },
      EndEnhancer: {
        style: {
          background: theme.colors.borderOpaque
        }
      },
      ClearIcon: {
        style: {
          background: theme.colors.borderOpaque
        }
      },
      ClearIconContainer: {
        style: {
          background: theme.colors.borderOpaque
        }
      }
    }}
  />)
};


const ReorderModal = ({ elements, onDone, onCancel, css, title }) => {
  const [state, setState] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(true);
  React.useEffect(() => { setState(elements) }, [elements]);
  return <ReorderInModal
    title={title}
    isCollapsed={isOpen}
    onClose={(req) => {
      if (req.isSavedFromReorder) {
        onDone(state);
        setIsOpen(false);
      } else { onCancel(); setIsOpen(false); };
    }}
  >
    <DragDropContext
      onDragEnd={({ destination, source, ...rest }) => {
        if (!destination) {
          return;
        }
        let newArr = arrayMove(
          [...state],
          source.index,
          destination.index
        );
        newArr = newArr.map((item, i) => {
          return {
            ...item,
            position: i
          };
        });
        setState(newArr);
      }}
    >
      <Droppable
        droppableId={'row1'}
        type={'group'}
        direction={'vertical'}
        isCombineEnabled={false}
      >
        {dropProvided => (
          <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
            {state.map((el, index) => {
              const title = el.name;
              return (
                <Grid
                  overrides={gridPaddingOverrides}
                  key={index}
                  gridMaxWidth={'unset'}
                >
                  <Cell span={12} overrides={cellPaddingOverrides}>
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={index}
                    >
                      {(dragProvided, snapshot) => (
                        <PortalAwareItem
                          snapshot={snapshot}
                          child={
                            <div
                              {...dragProvided.dragHandleProps}
                              {...dragProvided.draggableProps}
                              ref={dragProvided.innerRef}
                            >
                              <div
                                key={index}
                                className={css({
                                  position: 'relative',
                                  width: '600px',
                                  height: '50px',
                                  lineHeight: '50px',
                                  background: snapshot.isDragging ? '#5D64AB' : 'white',
                                  margin: '0',
                                  display: 'flex',
                                  padding: '16px',
                                  color: 'white',
                                  background: '#0D0E13',
                                  marginBottom: '8px'
                                })}
                              >
                                <StatefulTooltip
                                  content={() => <div padding={'20px'}>Reorder</div>}
                                  placement={PLACEMENT.bottom}
                                  returnFocus
                                  autoFocus
                                >
                                  <svg
                                    width="21"
                                    height="18"
                                    viewBox="0 0 21 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={css({
                                      cursor: 'pointer',
                                    })}
                                  >
                                    <path
                                      d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                                      fill={snapshot.isDragging ? 'white' : 'white'}
                                    />
                                  </svg>
                                </StatefulTooltip>
                                <LabelSmall
                                  className={css({
                                    marginLeft: '16px',
                                    color: 'white !important'
                                  })}
                                >
                                  {title}
                                </LabelSmall>
                              </div>
                            </div>
                          }
                        />
                      )}
                    </Draggable>
                  </Cell>
                </Grid>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </ReorderInModal>
}

const ChannelManager = ({
  channels,
  groups,
  decks,
  category,
  organization,
  organization_id,
  selectedChannel = {},
  isOpen,
  onClose,
  ...props
}) => {
  const [css, theme] = useStyletron();
  /** TABS STATUS */
  const [tabStatus, setTabStatus] = React.useState(0);
  /** END TABS STATUS */

  channels.map((c) => {
    console.log('channel', c.name, c.archived);
  })

  /** SEARCH AND FILTER */
  const [searchText, setSearchText] = React.useState('');
  const [filteredChannels, setFilteredChannels] = React.useState([]);
  const [filteredGroups, setFilteredGroups] = React.useState([]);
  const [channelColors, setChannelColors] = React.useState([]);
  React.useEffect(() => {
    // SET CHANNEL COLORS
    if (channelColors.length == 0 && channelColors.length !== channels.length) {
      setChannelColors(channels.map(el => getAvatarRandomBackground()));
    }
    // SET CHANNELS
    const filterChannels = channels.filter((c) => {
      // MATCH TAB CONDITION
      let status = true;
      const isChannelArchive = isChannelArchived(c)
      if (parseInt(tabStatus) == 0) {
        status = !c.archived
      };

      if (parseInt(tabStatus) == 1) {
        status = c.archived
        // const groupOfChannel = groups.filter(g => g.id == c.app_content_scope_group_id)
        // status = false;
        // if (groupOfChannel.length > 0 && groupOfChannel[0].archived) {
        //   status = true;
        // }
      }

      // MATCH SEARCH TEXT
      if (searchText && !c.name.toLowerCase().includes(searchText.toLowerCase())) {
        status = false;
      };
      return status;
    }).sort((a, b) => a.position - b.position);
    setFilteredChannels(filterChannels);

    // SET GROUPS
    const filterGroups = groups.filter((c) => {
      // MATCH TAB CONDITION
      let status = true;
      if (tabStatus == 0) {
        status = !c.archived
      };
      if (tabStatus == 1) {
        // first group could be archived
        let s1 = c.archived;
        // second group can have archived channel
        let s2 = channels.filter(c1 => c1.app_content_scope_group_id == c.id).filter(x => x.archived || isChannelArchived(x));
        s2 = s2.length > 0 ? true : false;
        status = s1 || s2;
      };
      return status;
    }).sort((a, b) => a.position - b.position);
    setFilteredGroups(filterGroups);
  }, [channels, groups, searchText, tabStatus, decks]);
  /** END SEARCH AND FILTER */

  /** [METHODS] */
  const getChannelsOfGroup = (groupId) => {
    return filteredChannels.filter(c => c.app_content_scope_group_id == groupId).sort((a, b) => a.position - b.position);
  };

  const isChannelArchived = (channel) => {
    return;
    // if (!channel) return;
    // let categoryIds = category
    // .filter(el => el.app_content_scope_id == channel.id)
    // .map(el => el.category_id);

    // let decksIds = decks
    //   .filter(el => categoryIds.includes(el.category_id))
    //   .filter(el => el.deck_category_deck.archived == true)
    //   .map(el => el.deck_id);
    // const deckLength = decksIds.length;
    // return deckLength > 0
  }

  const getAvatarRandomBackground = () => {
    let color_palette = organization ? organization.color_palette : [];
    color_palette = color_palette || [];
    if (color_palette.length == 0) color_palette = [theme.colors.primaryA];
    return color_palette[Math.floor(Math.random() * color_palette.length)];
  };
  const _setSelectedChannel = (group, channel) => {
    props.dispatch(setSelectedChannel(channel));
    props.dispatch(setSelectedGroup(group));
    handleOverLayClick();
    // do not navigate for connect page
    if (props.parent && props.parent == 'connect') return;
    navigate(
      `/app/${organization_id}/groups/${group.id}/channels/${channel.id}`
    );
  };
  /** END [METHODS] */

  const [updateAppContentScopeGroup] = useMutation(updateGroup);
  const [updateAppContentScope] = useMutation(updateChannel);

  React.useEffect(() => {
    const overlay = document.querySelector('#app-dropdown-overlay');
    // setTimeout(() => {
    //   console.log('scrolling');
    //   document.getElementById(selectedChannel.id).scrollIntoView();
    // }, 100);
    if (overlay) {
      // document.querySelector('.side-bar-container').style['z-index'] = 4;
      document.querySelector('#app-dropdown-overlay').addEventListener('click', handleOverLayClick);
      return () => {
        // document.querySelector('.side-bar-container').style['z-index'] = 0;
        document.querySelector('#app-dropdown-overlay').removeEventListener('click', () => handleOverLayClick);
      };
    };
  }, []);

  const handleOverLayClick = () => {
    onClose();
    // document.querySelector('.side-bar-container').style['z-index'] = 0;
    document.querySelector('#app-dropdown-overlay').style.display = 'none'
    document.querySelector('#app-container').style.overflow = 'auto';
  };

  React.useEffect(() => {
    if (isOpen) {
      // do not show overlay for connect page
      if (props.parent && props.parent == 'connect') return;
      document.querySelector('#app-dropdown-overlay').style.display = 'block';
      document.querySelector('#app-container').style.overflow = 'hidden';
    }
  }, [isOpen]);


  // Group Reorder
  const [isGroupReorderOn, setIsGroupReorderOn] = React.useState(false);
  const groupReorderSubmit = (sorted) => {
    props.dispatch(setGroups(groups.map(g => {
      let f = sorted.find(el => el.id == g.id);
      if (f) {
        return f;
      };
      return g;
    })));
    Promise.all(sorted.map(g => {
      return updateAppContentScopeGroup({
        variables: {
          id: g.id, position: g.position, name: g.name
        }
      })
    }));
  };
  const [isAddEditGroupOpen, setIsAddEditGroupOpen] = React.useState(false);
  const [currentGroupInfo, setCurrentGroupInfo] = React.useState({});
  const openGroupAdd = () => {
    setCurrentGroupInfo({
      totalCount: groups.length,
      group: {}
    });
    setIsAddEditGroupOpen(true);
  };
  const openGroupUpdate = (group) => {
    setCurrentGroupInfo({
      totalCount: groups.length,
      group
    });
    setIsAddEditGroupOpen(true);
  };
  const onCloseGroupAddEdit = () => {
    setIsAddEditGroupOpen(false);
    setCurrentGroupInfo({});
  }
  // END Group Reorder

  // Channel Reorder
  const [isChannelReorderOn, setIsChannelReorderOn] = React.useState(false);
  const [groupIdForChannelReorder, setGroupIdForChannelReorder] = React.useState(null);

  const channelReorderSubmit = (sorted, publish = false, publicOption=false) => {
    if (publish) {
      const channel = sorted[0];
      const { id, position, } = channel;
      props.dispatch(
        showAlert({
          msg: channel.published ? 'Publishing channel...' : 'Unpublishing channel...',
          error: false,
        })
      );
      return updateAppContentScope({
        variables: {
          id, position,
          published: channel.published
        }
      })
    }
    if (publicOption) {
      const channel = sorted[0];
      const { id, position, } = channel;
      props.dispatch(
        showAlert({
          msg: channel.public ? 'Making public channel...' : 'Making private channel...',
          error: false,
        })
      );
      return updateAppContentScope({
        variables: {
          id, position, public: channel.public,
        }
      })
    }

    props.dispatch(setChannels(channels.map(c => {
      let f = sorted.find(el => el.id == c.id);
      if (f) {
        return f;
      };
      return c;
    })));
    Promise.all(sorted.map(c => {
      return updateAppContentScope({
        variables: {
          id: c.id, position: c.position, public: c.public, published: c.published
        }
      })
    }));
  };
  const [isAddEditChannelOpen, setIsAddEditChannelOpen] = React.useState(false);
  const [currentChannelInfo, setCurrentChannelInfo] = React.useState({});
  const openChannelAdd = (groupId) => {
    setCurrentChannelInfo({
      app_content_scope_group_id: groupId,
      totalCount: getChannelsOfGroup(groupId).length,
      channel: {}
    });
    setIsAddEditChannelOpen(true);
  };
  const openChannelUpdate = (groupId, channel) => {
    setCurrentChannelInfo({
      app_content_scope_group_id: groupId,
      totalCount: getChannelsOfGroup(groupId).length,
      channel
    });
    setIsAddEditChannelOpen(true);
  };
  const onCloseChannelAddEdit = () => {
    setIsAddEditChannelOpen(false);
    setCurrentChannelInfo({});
  }
  // End Channel Reorder


  const ChannelList = () => {
    return <>
      {filteredGroups.map((group, index) => {
        return <GroupContainer theme={theme} key={`group-${index}`}>
          <div style={{ padding: theme.sizing.scale600, height: '48px' }}>
            <LabelLarge style={{ position: 'relative', float: 'left' }}>{group.name}</LabelLarge>

            <RelativeDiv style={{ height: '10px', top: '50%', transform: 'translateY(-50%)', marginLeft: theme.sizing.scale500, float: 'right' }} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openGroupUpdate(group);
            }}>
              <Settings color={theme.colors.primaryA} isActive={false} className="groupSettings"></Settings>
            </RelativeDiv>
            <div onClick={() => setIsGroupReorderOn(true)} style={{ position: 'relative', float: 'right' }}><Reorder css={css} isActive={false} color={theme.colors.primaryA} style={{ marginTop: theme.sizing.scale100 }} className="groupReorder"></Reorder></div>
          </div>
          <ChannelContainer>

            {getChannelsOfGroup(group.id).map((channel, index) => {
              const isControlActive = selectedChannel.id == channel.id;
              return  <ChannelDiv
                  theme={theme} selected={isControlActive} key={`channel-${index}`}
                  // onMouseEnter={() => setHoveredChannel(channel.id)}
                  // onMouseLeave={() => setHoveredChannel(null)}
                  onClick={(e) => {
                    console.log('Parent clicking')
                    _setSelectedChannel(group, channel)
                  }}
                  id={channel.id}
                // className={"channelDiv"}
                >

                  <div style={{ position: 'relative', float: 'left', width: '24px', height: '100%' }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setGroupIdForChannelReorder(group.id);
                    setIsChannelReorderOn(true);
                  }}>
                    <Reorder css={css} isActive={true} color={theme.colors.primaryB} style={{
                      position: 'absolute', top: '50%', transform: 'translateY(-50%)', display: isControlActive ? 'block' : 'none'
                    }} className="channelReorder"></Reorder>
                  </div>

                  <RelativeDiv style={{ marginLeft: theme.sizing.scale200 }}>
                    <ChannelAvatar
                      channel={channel}
                      channelColors={channelColors}
                      index={index}
                    />
                  </RelativeDiv>

                  <RelativeDiv style={{ marginLeft: theme.sizing.scale200, display: 'flex', alignItems: 'center', maxWidth: '43%' }}>
                    <LabelSmall className="channelName" style={{
                      color: theme.colors.primaryB,
                      maxHeight: '16px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: isControlActive ? theme.colors.primaryB : theme.colors.primaryA,
                    }}
                    >{channel.name}
                    </LabelSmall>
                  </RelativeDiv>

                  <RelativeControlsDiv float="right" className="channelControls" isControlActive={isControlActive}>
                  <StatefulPopover
                      content={() => (
                        <div
                        className={css({
                          fontSize: '14px',
                          padding: '16px',
                        })}
                      >
                        {channel.published ? 'Unpublish channel' : 'Publish channel'}
                      </div>
                      )}
                      triggerType={TRIGGER_TYPE.hover}
                      accessibilityType={'tooltip'}
                      showArrow
                      placement={PLACEMENT.topRight}
                      overrides={{
                        Body: {
                          style: {
                            zIndex: 100,
                          }
                        }
                      }}
                    >
                    <RelativeDiv style={{ height: '24px', top: '50%', transform: 'translateY(-50%)', marginRight: '0.5rem', cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
                      <Toggle
                        id="cheese-status"
                        defaultChecked={channel.published}
                        icons={false}

                        onChange={(e) => {
                          console.log('onClick Event')
                          e.preventDefault();
                          e.stopPropagation();
                          if (tabStatus !== 0 && tabStatus !== '0') {
                            return props.dispatch(showAlert(
                              {
                                msg: 'Archived channel can\'t be published',
                                error: true
                              }
                            ))
                          }
                          let dataToUpdate = [{ ...channel }];
                          dataToUpdate[0].published = !dataToUpdate[0].published;
                          channelReorderSubmit(dataToUpdate, true);
                        }}
                      ></Toggle>
                    </RelativeDiv>
                    </StatefulPopover>
                    <RelativeDiv style={{ height: '20px',  marginLeft: theme.sizing.scale200, marginTop: '10px', float: 'right' }} onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openChannelUpdate(group.id, channel);
                    }}>
                      <Settings color={theme.colors.primaryB}></Settings>
                    </RelativeDiv>
                   <StatefulPopover
                      content={() => (
                        <div
                        className={css({
                          fontSize: '14px',
                          padding: '1rem'
                        })}
                      >
                        {channel.public ? 'Make Private' : 'Make Public'}
                      </div>
                      )}
                      triggerType={TRIGGER_TYPE.hover}
                      accessibilityType={'tooltip'}
                      showArrow
                      placement={PLACEMENT.topRight}
                      overrides={{
                        Body: {
                          style: {
                            zIndex: 100,
                          }
                        }
                      }}
                    >
                      <span>
                      {channel.public ? (
                      <MdGroup
                        color={'#0adb0a'}
                        size={25}
                        style={{cursor: 'pointer', margin: '7px 5px 0 5px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let dataToUpdate = [{ ...channel }];
                          dataToUpdate[0].public = !dataToUpdate[0].public;
                          channelReorderSubmit(dataToUpdate, false, true);
                        }}
                      />) : (
                        <MdGroupOff 
                    color={'#EA4576'}
                    size={25}
                    style={{cursor: 'pointer', margin: '7px 5px 0 5px'}}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (tabStatus !== 0 && tabStatus !== '0') {
                        return props.dispatch(showAlert(
                          {
                            msg: 'Archived channel can\'t be published',
                            error: true
                          }
                        ))
                      }
                      let dataToUpdate = [{ ...channel }];
                      dataToUpdate[0].public = !dataToUpdate[0].public;
                      channelReorderSubmit(dataToUpdate, false, true);
                    }}/>
                    
                  )}
                  </span>
                  </StatefulPopover>
                  </RelativeControlsDiv>
                </ChannelDiv>
            })}

            {tabStatus == 0 && <ChannelDivButton theme={theme} >
              <div style={{ position: 'relative', float: 'left', width: '24px', height: '100%' }}>
              </div>
              <Button
                text={"Channel"}
                border_property
                startEnhancer={<AddSecondary />}
                backgroundColor={theme.colors.primaryB}
                style={{
                  color: theme.colors.primaryC,
                  border: `2px solid ${theme.colors.primaryC}`,
                  boxSizing: 'border-box',
                }}
                disableHover={true}
                onClick={() => openChannelAdd(group.id)}
              />
            </ChannelDivButton>}
          </ChannelContainer>
          <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: theme.colors.borderOpaque }} />
        </GroupContainer>
      })
      }

      {
        tabStatus == 0 && <RelativeDiv style={{ padding: theme.sizing.scale400 }}>
          <Button
            text={"Group"}
            border_property={"true"}
            startEnhancer={<AddWhite />}
            style={{
              border: `2px solid ${theme.colors.primaryC}`,
              boxSizing: 'border-box',
            }}
            disableHover={true}
            onClick={() => openGroupAdd()}
          />
        </RelativeDiv>}
    </>
  };

  return (
    <MainDiv theme={theme}>

      {/* GROUP REORDER */}
      {isGroupReorderOn && <ReorderModal elements={filteredGroups} css={css} onCancel={() => {
        setIsGroupReorderOn(false);
      }} onDone={(d) => {
        setIsGroupReorderOn(false);
        groupReorderSubmit(d);
      }}></ReorderModal>}
      {/* END GROUP REORDER */}

      {/* CHANNEL REORDER */}
      {isChannelReorderOn && <ReorderModal elements={getChannelsOfGroup(groupIdForChannelReorder)} css={css} onCancel={() => {
        setIsChannelReorderOn(false);
      }} onDone={(d) => {
        setIsChannelReorderOn(false);
        channelReorderSubmit(d);
      }}></ReorderModal>}
      {/* END CHANNEL REORDER */}

      {/* CHANNEL ADD & UPDATE */}
      {isAddEditChannelOpen && <AddEditChannel
        onClose={onCloseChannelAddEdit}
        {...currentChannelInfo.channel}
        totalCount={currentChannelInfo.totalCount}
        app_content_scope_group_id={currentChannelInfo.app_content_scope_group_id}
        organization_id={organization_id}
        dispatch={props.dispatch}
      ></AddEditChannel>}
      {/* END CHANNEL ADD & UPDATE */}

      {/* GROUP ADD AND UPDATE */}
      {isAddEditGroupOpen && <AddEditChannelGroup
        onClose={onCloseGroupAddEdit}
        totalCount={currentGroupInfo.totalCount}
        organization_id={organization_id}
        {...currentGroupInfo.group}
      ></AddEditChannelGroup>}
      {/* END GROUP ADD AND UPDATE */}


      {/* SEARCH */}
      <SearchContainer theme={theme}>
        <SearchInput searchText={searchText} setSearchText={setSearchText} theme={theme}></SearchInput>
      </SearchContainer>
      {/* END SEARCH */}

      {/* TABS */}
      <Tabs
        activeKey={tabStatus}
        onChange={({ activeKey }) => {
          setTabStatus(activeKey);
        }}
        activateOnFocus
        fill={FILL.fixed}
        overrides={TabRootOverride}
      >
        <Tab title="Live" overrides={TabOverride}>
          <ChannelList />
        </Tab>
        <Tab title="Archived" overrides={TabOverride}>
          <ChannelList />
        </Tab>
      </Tabs>
    </MainDiv>
  )
}


const mapStateToProps = props => {
  let { channels, groups, decks, organization, selectedChannel, category } = props.appBasicControls;
  return {
    channels, groups, organization, selectedChannel, decks, category
  };
};

const styles = {
  bounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  slideInLeft: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
  },
  slideInRight: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')
  }
}

const ChannelManagerContainer = (props) => {
  if (!props.isOpen) {
    return <></>;
  };

  return <div className="channelContainer">
    <StyleRoot>
      <div className="channelContainer" style={styles.slideInLeft}>
        <ChannelManager {...props} />
      </div>
    </StyleRoot>
  </div>;
}

export default connect(mapStateToProps)(ChannelManagerContainer);