import { BaseProvider } from 'baseui';
import * as React from 'react';
import { Router } from '@reach/router';
import Index from './index-old';
import Callback from './callback';
import App from './app';

const Test = ({}) => {
  const [options, setOptions] = React.useState({
    engine: null,
  });
  var engine = options.engine;
  React.useEffect(() => {
    import('styletron-engine-atomic').then(styletron => {
      const clientEngine = new styletron.Client();
      setOptions({
        ...options,
        engine: clientEngine,
      });
    });
  }, []);
  if (!engine) return null;
  return (
    <div>
      <Router>
        <Index path="/home"></Index>
        <Index path="/home/:sub_domain_id"></Index>
        <Callback path="/home/:sub_domain_id/callback"></Callback>
      </Router>
    </div>
  );
};

export default Test;
