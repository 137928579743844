import React from 'react';
import { Spinner } from 'baseui/spinner';
import axios from 'axios';
import { useStyletron } from 'baseui';
import { getServerUrl } from '../apollo/client';
import { Button } from 'baseui/button';
import LoginContainer from '../components/loginContainer';
import { Cell } from 'baseui/layout-grid';
import jwt_decode from "jwt-decode";

import {adminSliders, userSliders} from '../helpers/slidersData';

const AcceptInvite = () => {
  const [css, theme] = useStyletron();
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get('name');
  const email = urlParams.get('email');
  const token = urlParams.get('token');
  const decoded = jwt_decode(token);
  const type = decoded.isAdmin? 'admin': 'user';
  const [isLoading, setIsLoading] = React.useState(true);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    axios({
      url: `${getServerUrl()}/validate-invite?token=${token}&domain=${window.location.origin}`,
      method: 'GET',
    }).then((responseJson) => {
      if (responseJson.data.already_accepted) {
        // setIsLoading(false);
        // setError('Invite already accepted');
        window.location.href = responseJson.data.result_url;
        return;
      }
      if (responseJson.data.error) {
        setIsLoading(false);
        setError(responseJson.data.error);
        return;
      };
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    })
  }, []);


  const onsubmit = () => {
    setIsButtonLoading(true);
    axios({
      url: `${getServerUrl()}/accept-invite?token=${token}&domain=${window.location.origin}`,
      method: 'GET',
    }).then((responseJson) => {
      if (responseJson.data.success) {
        // debugger;
        // setIsButtonLoading(false);
        window.location.href = responseJson.data.resetUrlCXL;
        return;
      };
      setError(responseJson.data.message);
      setIsLoading(false);
    }).catch((err) => {
      setIsButtonLoading(false);
      setError(err.message);
    })
  };

  const sliders = type == 'admin' ? adminSliders : userSliders;

  return (
    <>
      <LoginContainer hasSliders={false} sliders={sliders} sloganSub={`${name}`} boxShadowSlider={false}>
        {isLoading ? <>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              color: theme.colors.primaryA,
            }}
          >
            Setting up Invitation
        </div>
          <div
            className={css({
              position: 'absolute',
              width: theme.sizing.scale1200,
              height: theme.sizing.scale1200,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            <Spinner />
          </div>
        </> :
          <>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                color: theme.colors.primaryA,
              }}
            >
              {error ? error : "Please accept the invite by clicking button below. After that you will be redirected to create new password via Auth0"}
            </div>
            <Cell
              span={12}
              overrides={{
                Cell: {
                  style: () => ({
                    marginTop: '32px',
                  }),
                },
              }}
            >
              <Button
                overrides={{
                  BaseButton: { style: { width: '100%', marginTop: '16px' } },
                }}
                isLoading={isButtonLoading}
                onClick={() => onsubmit()}
                disabled={error ? true : false}
              >
                Accept Invite
              </Button>
            </Cell>
          </>}
      </LoginContainer>
    </>
  );
};

export default AcceptInvite;
