import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
  HeadingXSmall,
  LabelSmall,
} from 'baseui/typography';
import Delete from 'baseui/icon/delete';
import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import { FileUploader } from 'baseui/file-uploader';
import { updateProfileMutation } from '../../quries';
import { getProfile, logout } from '../../auth/index';
import PasswordStrengthBar from 'react-password-strength-bar';
import { client } from '../../apollo/client';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import {setUserInfoToStateFromToken} from '../../auth/index';

const ProfileEditor = ({ organization_id, organization, dispatch }) => {
  const profile = getProfile();
  const { given_name, picture, sub, ...rest } = profile.idTokenPayload
    ? profile.idTokenPayload
    : {};

  const [css, theme] = useStyletron();
  const [isHover, setOnHover] = React.useState(false);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const [logo_data, set_logo_data] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setFirstName(
      profile.idTokenPayload ? profile.idTokenPayload.given_name : ''
    );
    setLastName(
      profile.idTokenPayload ? profile.idTokenPayload.family_name : ''
    );
    setEmail(profile.idTokenPayload ? profile.idTokenPayload.email : '');
    setPreview(profile.idTokenPayload ? profile.idTokenPayload.picture : '');
  }, [organization_id]);

  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const onSave = () => {
    let userData = {
      name: `${firstName} ${lastName}`,
      given_name: firstName,
      family_name: lastName,
      email: email,
      user_id: sub,
      organization_id,
      isAdmin: true,
    };
    if (logo_data) {
      userData['picture'] = logo_data;
    }

    if (email == '') delete userData.email;

    if (password && confirmPassword && password === confirmPassword) {
      userData['password'] = password;
    };
    setIsLoading(true);
    client.mutate({
      mutation: updateProfileMutation,
      variables: {
        userProfileInput: userData
      }
    }).then(async () => {
      setIsLoading(false);
      if (userData.password) logout(organization_id);
      else setUserInfoToStateFromToken(profile.idToken, organization.id, organization.auth0.domain, () => {});
    })
  };

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} align={'right'}>
        <div
          className={css({
            position: 'relative',
            float: 'right',
            marginBottom: '16px',
          })}
        >
          <Button isLoading={isLoading} onClick={onSave}>
            Save
          </Button>
        </div>
      </Cell>
      <Cell span={6}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            First Name
          </LabelMedium>
          <Input
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            placeholder="First Name"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Last Name
          </LabelMedium>
          <Input
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            placeholder="Last Name"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Email
          </LabelMedium>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={email ? !email : false}
            placeholder="Email"
            clearOnEscape
          // disabled
          />
          {profile.idTokenPayload.email != email && (
            <LabelSmall
              style={{ color: theme.colors.warning, marginTop: '4px' }}
            >
              After updating the email you will be logged out. You need to login
              again.
            </LabelSmall>
          )}
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <HeadingXSmall
            style={{
              marginBottom: '16px',
            }}
          >
            Reset Password
          </HeadingXSmall>
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Password
          </LabelMedium>
          <Input
            value={password}
            type="password"
            overrides={{
              MaskToggleButton: () => null,
            }}
            error={password !== confirmPassword}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            clearOnEscape
          />
          <PasswordStrengthBar password={password} />
          {password && (
            <LabelSmall style={{ color: theme.colors.warning }}>
              After updating the password you will be logged out. You need to
              login again.
            </LabelSmall>
          )}
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Confirm Password
          </LabelMedium>
          <Input
            value={confirmPassword}
            type="password"
            overrides={{
              MaskToggleButton: () => null,
            }}
            error={password !== confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
            clearOnEscape
          />
        </div>
      </Cell>
      <Cell span={6}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Profile Picture
          </LabelMedium>
          <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
          >
            <img src={preivew_image} style={{ height: '350px', ...(isHover && logo_data) ? {opacity: 0.3} : {} }}></img>
            {isHover && logo_data && (
              <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    set_logo_data(null);
                    setPreview(profile.idTokenPayload ? profile.idTokenPayload.picture : '');
                  }}
                >
                  Remove Upload File<Delete size={32} />
                </div>
              </div>
            )}
          </div>
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              set_logo_data(acceptedFiles[0]);
              previewImage(acceptedFiles[0]);
            }}
            accept={"image/*"}
          ></FileUploader>
        </div>
      </Cell>
    </Grid>
  );
};

export default ProfileEditor;
