/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/sss
 */
// You can delete this file if you're not using it

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./node_modules/react-image-gallery/styles/css/image-gallery.css";
import './static/redactor/redactor.css';
import './src/styles/global.css';
import wrapWithProvider from './wrap-root-element';
export const wrapRootElement = wrapWithProvider;
