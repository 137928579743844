import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Grid, Cell } from 'baseui/layout-grid';
import Overflow from 'baseui/icon/overflow';
import {
  HeadingSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import './index.css';
import App from '../cards/index';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { FlexGrid } from 'baseui/flex-grid';
import { StatefulMenu } from "baseui/menu";
import styled from '@emotion/styled';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Card from '../cards/card';
import EditDeck from './editDeck';
import CloneDeck from './cloneDeck';
import MoveDeck from './moveDeck';
import { sourceByType } from '../../util';
import CardDrawer from '../cards/cardDrawer';
import AddDeck from './addDeck';
import ArchiveDeck from './archiveDeck';
import UnArchiveDeck from './unArchiveDeck';
import store from '../../redux/store';
import { showAlert, setIsLoading } from '../../redux/actions/appBasicControls';
import { toaster } from 'baseui/toast';

let portal;

if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
}

const gridPaddingOverrides = {
  Grid: {
    style: ({ $theme }) => ({
      padding: '0px !important',
      margin: 0,
    }),
  },
};

const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    }),
  },
};

class PortalAwareItem extends React.PureComponent {
  componentDidMount() {
    if (!this.props.searchText && this.props.deckIdFromPath === this.props.deck.deck_category_deck.id) {
      let element = document.getElementById(this.props.deckIdFromPath);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView();
          element.click();
        }, 500);
      }
    }
  };
  render() {
    const {
      dragProvided,
      deck,
      index,
      channels,
      onClone,
      onArchive,
      onUnArchive,
      organization_id,
      snapshot,
      onToggleClick,
      onSettingsClick,
      onMoveDeck,
      css,
      categoryIndex,
      theme,
      publishAllCards,
      showToast,
      cardLength,
      cards,
      tabFilter,
      isDeckOpen,
      isQuizType,
      defaultTheme,
      searchText,
      refreshQuizQuestions,
      setisDragDisabled,
      quiz_question_quiz_decks_org
    } = this.props;
    const usePortal = snapshot.isDragging;
    var closePopover;

    const setDisableStatus = (status) => {
      setisDragDisabled(status)
    }
    let cardMenus = [
      { label: "Settings" },
      { label: deck.published ? "Unpublish deck" : "Publish deck" },
    ]

    if (cardLength.length > 0) {
      cardMenus.push({ label: "Publish all cards" })
    }

    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        <CardDrawer
          defaultTheme={defaultTheme}
          organization_id={organization_id}
          setisDragDisabled={setDisableStatus}
          publishStatus={deck.published}
          tabFilter={tabFilter}
          deck={deck}
          isDeckOpen={isDeckOpen}
          cards={cards}
          searchText={this.props.searchText}
          content={({ close }) => {
            closePopover = close;
            return (
              <>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <HeadingSmall
                    className={css({
                      position: 'relative',
                      float: 'left',
                      marginRight: theme.sizing.scale550,
                      color: theme.colors.primaryA,
                      marginBottom: theme.sizing.scale0,
                      whiteSpace: 'pre-wrap',
                      maxWidth: '50%'
                    })}
                  >
                    {deck.deck_category_deck.title.length > 20 ? deck.deck_category_deck.title.substring(0, 20) + '...' : deck.deck_category_deck.title}
                  </HeadingSmall>
                  <div
                    className={css({
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center'
                    })}
                  >
                    <StatefulPopover
                      initialState={{ isOpen: false }}
                      dismissOnEsc={true}
                      dismissOnClickOutside={true}
                      overrides={{
                        Body: {
                          style: {
                            zIndex: 3,
                          }
                        }
                      }}
                      content={() => (
                        <StatefulMenu
                          items={cardMenus}
                          onItemSelect={({ item, event }) => {
                            if (item.label === 'Publish all cards') {
                              showToast();
                              publishAllCards(deck);
                            }
                            if (item.label === 'Settings') {
                              onSettingsClick(deck);
                            }
                            if (item.label === 'Publish deck') {
                              onToggleClick(true, deck.deck_id, isQuizType);
                            }
                            if (item.label === 'Unpublish deck') {
                              onToggleClick(false, deck.deck_id, isQuizType);
                            }
                          }}
                        />
                      )}
                      placement={PLACEMENT.right}
                    >
                      <div style={{ position: 'relative', height: '48px' }}>
                        <Overflow
                          className={css({
                            color: defaultTheme === "dark" ? 'white !important' : "black !important",
                            cursor: 'pointer',
                            marginLeft: '10px',
                          })}
                          size={48}
                        />
                      </div>
                    </StatefulPopover>
                  </div>
                </div>
                <div
                  className={css({
                    position: 'relative',
                    float: 'left',
                    width: '100%',
                  })}
                >
                  <div id={`wrapper_deck${categoryIndex}`} className="wrapper">
                    <App
                      deck={deck}
                      searchText={searchText}
                      categoryId={deck.category_id}
                      orgId={organization_id}
                      tabFilter={tabFilter}
                      isQuizType={deck.deck_type == 'QuizDeck' ? true : false}
                      refreshQuizQuestions={refreshQuizQuestions}
                    ></App>
                  </div>
                </div>
              </>
            );
          }}
          className="___pop"
          index={index}
          key={index}
        >
          {(isOpen) => (
            <div id={deck.deck_category_deck.id}>
              <Card
                key={index}
                isOpen={isOpen}
                title={deck.deck_category_deck.title}
                count={cardLength || 0}
                imageSrc={deck.deck_category_deck.cover_type == 'photo' ? getSrc(deck.deck_category_deck.cover_image_data) : null}
                cover_type={deck.deck_category_deck.cover_type || 'icon'}
                icon_name={deck.deck_category_deck.icon_name}
                toggle={deck.published}
                channels={channels}
                onToggleClick={v => {
                  v.preventDefault();
                  v.stopPropagation();
                  onToggleClick(v.target.checked, deck.deck_id, isQuizType);
                }}
                onMoveDeck={selectedChannel => {
                  onMoveDeck(deck, selectedChannel);
                }}
                onArchive={selectedChannel => {
                  onArchive(deck, selectedChannel);
                }}
                onUnArchive={selectedChannel => {
                  onUnArchive(deck, selectedChannel);
                }}
                onSettingsClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSettingsClick(deck);
                }}
                onClone={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClone(deck);
                }}
                isDeck={true}
                isQuizType={deck.deck_type == 'QuizDeck' ? true : false}
                showSettingsIcon={false}
                tabFilter={tabFilter}
                organization_color_palette_index={
                  deck.deck_category_deck.organization_color_palette_index
                }
                full_bleed={deck.deck_category_deck.full_bleed}
              />
            </div>
          )}
        </CardDrawer>
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'cover_small_url');
};

const Deck = ({
  filteredDecks,
  organization_id,
  oDecks,
  cards,
  categories,
  channels,
  dispatch,
  categoryIndex,
  color_palette,
  categoryId,
  tabFilter,
  defaultTheme,
  searchText,
  isDeckOpen,
  quiz_question_quiz_decks_org,
  refreshQuizQuestions,
  deckIdFromPath,
  isLoading,
}) => {
  document.getElementById('overlay').style.display = 'none';
  const [updateDeck, { data: updatedDeck, loading: isUpdateUserWithChannelLoading, error: errorInUserUpdate }] = useMutation(gql`
    mutation updateDeck($id: ID!, $position: Int, $published: Boolean) {
      updateDeck(position: $position, id: $id, published: $published) {
        title
      }
    }
  `);

  const [updateQuizDeck, { data: updatedQuizDeck, }] = useMutation(gql`
    mutation updateQuizDeck($id: ID!, $position: Int, $published: Boolean) {
      updateQuizDeck(position: $position, id: $id, published: $published) {
        title
      }
    }
  `);

  const [publishMultipleCards] = useMutation(gql`
    mutation publishMultipleCards($ids: [ID!]) {
      publishMultipleCards(ids: $ids) {
        title
      }
    }
  `);

  const [css, theme] = useStyletron();
  const [toggleStatus, setToggleStatus] = React.useState(null);
  const [isDeckEditOpen, setIsDeckEditOpen] = React.useState(false);
  const [isDeckMoveOpen, setIsDeckMoveOpen] = React.useState(false);
  const [isDeckCloneOpen, setIsDeckCloneOpen] = React.useState(false);
  const [isDeckArchiveOpen, setIsDeckArchiveOpen] = React.useState(false);
  const [isDeckUnArchiveOpen, setIsDeckUnArchiveOpen] = React.useState(false);
  const [selectedDeck, setSelectedDeck] = React.useState(null);
  const [moveChannel, setMoveChannel] = React.useState(null);

  const onClose = () => {
    setSelectedDeck(null);
    setIsDeckEditOpen(false);
    setIsDeckMoveOpen(false);
    setIsDeckCloneOpen(false);
    setIsDeckArchiveOpen(false);
    setIsDeckUnArchiveOpen(false);
  };

  const onPublishedToggle = (value, deck_id, isQuizType) => {
    setToggleStatus(value)
    let dataToSave = {
      variables: {
        id: deck_id,
        published: value,
      },
    };
    let m = isQuizType ? updateQuizDeck : updateDeck;
    // if (isQuizType) return updateQuizDeck(dataToSave);
    // updateDeck(dataToSave);
    dispatch(showAlert({
      msg: `${value ? 'Publishing' : 'Unpublishing'} Deck`,
      error: false,
    }))
    return m(dataToSave).then((result) => {
      dispatch(showAlert({
        msg: `Deck ${value ? 'Published' : 'Unpublished'}`,
        error: false,
      }))
    })
  };

  // React.useEffect(() => {
  //   if (updatedDeck) {
  //     dispatch(
  //       showAlert({
  //         msg: `Successfully ${toggleStatus ? '' : 'un'}publishded deck!`,
  //         error: false,
  //       })
  //     );
  //   }
  // }, [updatedDeck])

  // React.useEffect(() => {
  //   if (updatedQuizDeck) {
  //     dispatch(
  //       showAlert({
  //         msg: `Successfully ${toggleStatus ? '' : 'un'}publishded quiz deck!`,
  //         error: false,
  //       })
  //     );
  //   }
  // }, [updatedQuizDeck])

  const onSettingsClick = deck => {
    setSelectedDeck(deck);
    setIsDeckEditOpen(true);
  };

  const onClone = (deck) => {
    setSelectedDeck(deck);
    setIsDeckCloneOpen(true);
  };

  const onArchive = deck => {
    setSelectedDeck(deck.deck_category_deck);
    setIsDeckArchiveOpen(true);
  };

  const onUnArchive = deck => {
    setSelectedDeck(deck.deck_category_deck);
    setIsDeckUnArchiveOpen(true);
  };

  const onMoveDeck = (deck, selectedChannel) => {
    setSelectedDeck(deck);
    setMoveChannel(selectedChannel);
    setIsDeckMoveOpen(true);
  };

  const publishAllCards = deck => {
    let cardIds = store
      .getState()
      .appBasicControls.cards.filter(
        el => el.deck_id == deck.deck_id && el.published === false
      )
      .map(el => el.card_id);
    publishMultipleCards({
      variables: { ids: cardIds },
    });
    setTimeout(() => {
      closeToast();
    }, 1000);
  };

  const [toastKey, setToastKey] = React.useState(null);
  const [isDragDisabled, setisDragDisabled] = React.useState(false);
  const showToast = () =>
    setToastKey(toaster.info('Cards are being published'));
  const closeToast = () => {
    toaster.clear(toastKey);
    setToastKey(null);
  };

  const setStatus = (status) => {
    setisDragDisabled(status)
  }

  const AddDeckContainer = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% + 9px);
    top: 0;
    box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
    background: ${theme.colors.primary50};
    padding-left: 25px;
  `

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <div id={`wrapper_deck${categoryId}`} style={{
            paddingTop: theme.sizing.scale600,
            position: 'relative',
            minHeight: '287px',
            marginBottom: '7px'
          }}>
            <FlexGrid>
              <Droppable
                droppableId={categoryId}
                type={'CARD'}
                direction="horizontal"
                isCombineEnabled={false}
              >
                {(dropProvided, snapshot) => (
                  <div
                    ref={dropProvided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver ? '#494d5b3b' : 'transparent',
                      display: 'flex',
                      overflow: 'auto',
                      flexGrow: 1,
                      paddingTop: '3px',
                      paddingBottom: '15px',
                      marginRight: tabFilter !== "archived" ? '200px' : '0px',
                      scrollbarWidth: 'thin',
                      scrollbarColor: defaultTheme === "dark" ? 'black #282828' : "white black"
                    }}
                  >
                    {filteredDecks.map((el, index) => {
                      let deck_type = el.deck_type;
                      const cardLength = (deck_type != 'QuizDeck' ? cards : quiz_question_quiz_decks_org).filter(item => (deck_type == 'QuizDeck' ? item.quiz_deck_id : item.deck_id) === el.deck_id)
                        .filter(el => {
                          if (tabFilter == 'archived') return (el.card || el.quiz_question_data).archived === true;
                          if (tabFilter == 'published')
                            return el.published == true && (el.card || el.quiz_question_data).archived !== true;
                          if (tabFilter == 'all') return (el.card || el.quiz_question_data).archived !== true;
                          return true;
                        }).length;
                      return (
                        <Draggable
                          key={el.deck_id}
                          draggableId={el.deck_id}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(dragProvided, snapshot) => (
                            <>
                              <PortalAwareItem
                                dragProvided={dragProvided}
                                deck={el}
                                index={index}
                                cardLength={cardLength}
                                organization_id={organization_id}
                                isLoading={isLoading}
                                snapshot={snapshot}
                                onToggleClick={onPublishedToggle}
                                onSettingsClick={onSettingsClick}
                                onArchive={onArchive}
                                onUnArchive={onUnArchive}
                                onMoveDeck={onMoveDeck}
                                onClone={onClone}
                                css={css}
                                setisDragDisabled={setStatus}
                                cards={cards}
                                categoryIndex={categoryIndex}
                                theme={theme}
                                defaultTheme={defaultTheme}
                                publishAllCards={publishAllCards}
                                setToastKey={setToastKey}
                                showToast={showToast}
                                closeToast={closeToast}
                                tabFilter={tabFilter}
                                channels={channels}
                                searchText={searchText}
                                isQuizType={el.deck_type == 'QuizDeck'}
                                refreshQuizQuestions={refreshQuizQuestions}
                                quiz_question_quiz_decks_org={quiz_question_quiz_decks_org}
                                isDeckOpen={isDeckOpen}
                                dispatch={dispatch}
                                deckIdFromPath={deckIdFromPath}
                              />
                            </>
                          )}
                        </Draggable>
                      );
                    })}
                    {tabFilter !== "archived" && (
                      <AddDeckContainer>
                        <AddDeck
                          position={filteredDecks.length}
                          organization_id={organization_id}
                          onClose={onClose}
                          color_palette={color_palette}
                          category_id={categoryId}
                          defaultTheme={defaultTheme}
                        />
                      </AddDeckContainer>
                    )}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </FlexGrid>
          </div>
        </Cell>
        {isDeckEditOpen && (
          <EditDeck
            deck={selectedDeck.deck_category_deck}
            position={filteredDecks.length}
            organization_id={organization_id}
            onClose={onClose}
            color_palette={color_palette}
            isQuizType={selectedDeck.deck_type == 'QuizDeck' ? true : false}
          />
        )}
        {isDeckCloneOpen && (
          <CloneDeck
            deck={selectedDeck.deck_category_deck}
            published={selectedDeck.published}
            category_id={categoryId}
            dispatch={dispatch}
            position={filteredDecks.length}
            organization_id={organization_id}
            onClose={onClose}
            color_palette={color_palette}
            isQuizType={selectedDeck.deck_type == 'QuizDeck' ? true : false}
          />
        )}
        {isDeckArchiveOpen && (
          <ArchiveDeck
            deck={selectedDeck}
            category_id={categoryId}
            dispatch={dispatch}
            position={filteredDecks.length}
            organization_id={organization_id}
            onClose={onClose}
            color_palette={color_palette}
          />
        )}
        {isDeckUnArchiveOpen && (
          <UnArchiveDeck
            deck={selectedDeck}
            category_id={categoryId}
            dispatch={dispatch}
            position={filteredDecks.length}
            organization_id={organization_id}
            onClose={onClose}
            color_palette={color_palette}
          />
        )}
        {isDeckMoveOpen && (
          <MoveDeck
            deck={selectedDeck.deck_category_deck}
            dispatch={dispatch}
            categories={categories}
            channel={moveChannel}
            position={filteredDecks.length}
            organization_id={organization_id}
            onClose={onClose}
            color_palette={color_palette}
          />
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = props => {
  const { questionCards, cards, isLoading } = props.appBasicControls;
  return {
    quiz_question_quiz_decks_org: (questionCards || []),
    cards,
    isLoading
  };
};
export default connect(mapStateToProps)(Deck);
