import store from '../../../redux/store';
import { client } from '../../../apollo/client';
import gql from 'graphql-tag';
import { setCardBackData } from '../../../redux/actions/appBasicControls';

const validateTypeOfMutation = (type, data) => {
  let status = false;
  switch (type) {
    case 'Upload':
      status = Array.isArray(data) && data[0].path && data[0].type;
      break;
    default:
      status = true;
      break;
  }
  return status;
};

const getMutations = options => {
  var mutations = {};
  for (var i = 0; i < options.length; i++) {
    let option = options[i];
    var temp = gql`
          mutation create${option.__typename}(
            $card_id: ID!
            $organization_id: ID
            $position: Int
            ${option.inputs.map(el =>
      el.mutation
        ? `$${el.mutation.key}: ${el.mutation.dataType} \n`
        : `$${el.key}: ${el.dataType} \n`
    )}
          ) {
            create${option.__typename}(
              card_id: $card_id
              organization_id: $organization_id
              position: $position
              ${option.inputs.map(el =>
      el.mutation
        ? `${el.mutation.key}: $${el.mutation.key} \n`
        : `${el.key}: $${el.key} \n`
    )}
            ) {
              id
              ${option.inputs[0].key} ${option.inputs[0].subQueryKeys
        ? `{
                    ${option.inputs[0].subQueryKeys.map(el2 => el2 + '\n')}
                  }`
        : ''
      }
            }
          }
        `;
    var temp1 = gql`
          mutation update${option.__typename}(
            $id: ID!
            $position: Int
            ${option.inputs.map(el =>
      el.mutation
        ? `$${el.mutation.key}: ${el.mutation.dataType} \n`
        : `$${el.key}: ${el.dataType} \n`
    )}
          ) {
            update${option.__typename}(
              id: $id
              position: $position
              ${option.inputs.map(el =>
      el.mutation
        ? `${el.mutation.key}: $${el.mutation.key} \n`
        : `${el.key}: $${el.key} \n`
    )}
            ) {
              ${option.inputs[0].key} ${option.inputs[0].subQueryKeys
        ? `{
                  ${option.inputs[0].subQueryKeys.map(el2 => el2 + '\n')}
                }`
        : ''
      }
            }
          }
        `;
    mutations[`add${option.__typename}`] = temp;
    mutations[`update${option.__typename}`] = temp1;
  }

  mutations.updateCard = gql`
    mutation updateCard($id: ID!, $published: Boolean) {
      updateCard(id: $id, published: $published) {
        title
        updated_at
      }
    }
  `;

  mutations.updateQuizQuestion = gql`
    mutation updateQuizQuestion($id: ID!, $published: Boolean) {
      updateQuizQuestion(id: $id, published: $published) {
        id
      }
    }
  `;

  mutations.deleteCardContentModule = gql`
    mutation deleteCardContentModule($id: ID! $type: String) {
      deleteCardContentModule(id: $id type: $type)
    }
  `;

  mutations.updateQuizTextQuestion = gql`
    mutation updateQuizQuestion($id: ID!, $question_text: String, $valid_text_responses: String) {
      updateQuizQuestion(id: $id, question_text: $question_text, valid_text_responses: $valid_text_responses) {
        id
      }
    }
  `;

  mutations.updateQuizMatchingQuestion = gql`
    mutation updateQuizQuestion($id: ID!, $question_text: String, $pairs: String) {
      updateQuizQuestion(id: $id, question_text: $question_text, pairs: $pairs) {
        id
      }
    }
  `;

  mutations.updateQuizOptionQuestion = gql`
    mutation updateQuizQuestion($id: ID!, $question_text: String, $options: String, $has_multiple_answers: Boolean, $no_of_correct_answers: String) {
      updateQuizQuestion(id: $id, question_text: $question_text, options: $options, has_multiple_answers: $has_multiple_answers, no_of_correct_answers: $no_of_correct_answers) {
        id
      }
    }
  `;




  mutations.addQuizTextQuestion = gql`
    mutation createQuizQuestion($card_id: ID!, $question_text: String, $valid_text_responses: String) {
      createQuizQuestion(card_id: $card_id, question_text: $question_text, valid_text_responses: $valid_text_responses, type: "QuizTextQuestion") {
        id
        quiz_question_id
      }
    }
  `;

  mutations.addQuizMatchingQuestion = gql`
    mutation createQuizQuestion($card_id: ID!, $question_text: String, $pairs: String) {
      createQuizQuestion(card_id: $card_id, question_text: $question_text, pairs: $pairs, type: "QuizMatchingQuestion") {
        id
        quiz_question_id
      }
    }
  `;

  mutations.addQuizOptionQuestion = gql`
    mutation createQuizQuestion($card_id: ID!, $question_text: String, $options: String) {
      createQuizQuestion(card_id: $card_id, question_text: $question_text, options: $options, type: "QuizOptionQuestion") {
        id
        quiz_question_id
      }
    }
  `;

  mutations.updateCardStackCard = gql`
    mutation updateCardStackCard(
      $id: ID
      $position: Int
      $published: Boolean
      $input: updateCardStackCardInput
    ) {
      updateCardStackCard(
          id: $id
          position: $position
          published: $published
          input: $input) {
        card {
          id
          title
        }
      }
    }
  `;
  return mutations;
};

export const saveCardBackData = async (
  organization_id,
  cardId,
  options,
  data,
  dataToSave,
) => {
  let cardBackData = dataToSave;
  if (cardBackData.length == 0) return [];
  let deletedModules = cardBackData.filter(el => {
    return el.isDeleted && !el.dId.includes('manual');
  });
  cardBackData = dataToSave || cardBackData;
  var mutations = getMutations(options);
  cardBackData = cardBackData
    .filter(el => {
      if (el.dId.includes('manual') && el.isDeleted) return false;
      if (el.isDeleted) return false;
      return true;
    })
  // .sort((a, b) => a.position - b.position)
  // .map((el, i) => {
  //   el.position = i;
  //   return el;
  // });

  // Drop ALL deleted modules from card
  await Promise.all(
    deletedModules.map(el => {
      return client.mutate({
        mutation: mutations.deleteCardContentModule,
        variables: { id: el.dId, type: el.__typename },
      });
    })
  );

  let result = await Promise.all(
    cardBackData.map(card => {
      let variables = {
        card_id: cardId,
        organization_id: organization_id,
        position: card.position,
        ...card.inputs.reduce((a, b) => {
          let key = b.key;
          if (b.mutation) {
            key = b.mutation.key;
          }
          a[key] = b.value;
          return a;
        }, {}),
      };
      if (card.dId.includes('manual')) {
        return client.mutate({
          mutation: mutations[`add${card.__typename}`],
          variables,
        });
      };
      return client.mutate({
        mutation: mutations[`update${card.__typename}`],
        variables: {
          id: card.dId,
          position: card.position,
          ...card.inputs.reduce((a, b) => {
            // if this feild has defined muation key
            // override default key eioth mutation
            // let key = b.key;
            // if (
            //   b.mutation &&
            //   validateTypeOfMutation(b.mutation.dataType, b.value)
            // ) {
            //   key = b.mutation.key;
            // }
            // a[key] = b.value;
            // if (a.gallery_videos) {
            //   a.gallery_videos =
            //     a.gallery_videos.map(item => {
            //       return {
            //         cover_photo_data: item.cover_photo_data,
            //         cover_photo: item.cover_photo,
            //         loop_times: item.loop_times,
            //         does_loop: item.does_loop,
            //         position: item.position,
            //         title: item.title,
            //         video_type: item.video_type,
            //         video_url: item.video_url
            //       }
            //     })
            // }
            let key = b.key;
            if (b.mutation && validateTypeOfMutation(b.mutation.dataType, b.value)) {
              key = b.mutation.key;
            }
            a[key] = b.value;
            return a;
          }, {}),
        },
      });
    })
  );
  // ALL CARDS MODULE HAS BEEN SAVED BOW UPDATE CARD
  data.card && await client.mutate({
    mutation: mutations.updateCard,
    variables: { id: cardId },
  });
  // assign id to all new created modules
  // debugger;
  if (cardBackData.filter((card) => card.dId.includes('manual')).length == 0) return result;
  var cardBackDataCurrent = store.getState().appBasicControls.cardBackData;
  cardBackData.forEach((card, i) => {
    if (card.dId.includes('manual')) {
      let index = cardBackDataCurrent.findIndex((el) => el.dId == card.dId);
      if (result[i].data[`createQuizQuestion`]) {
        cardBackDataCurrent[index].dId = (result[i].data[`createQuizQuestion`]).quiz_question_id;
      } else {
        cardBackDataCurrent[index].dId = (result[i].data[`create${card.__typename}`]).id;
      }
    }
  });
  setCardBackData(cardBackDataCurrent);
  return result;
};

export const updateCardBackData = async (
  organization_id,
  cardId,
  options,
  data,
  dataToSave,
) => {
  let oldModules = data.card ? data.card.card_content_modules : [];
  var cardBackData = store.getState().appBasicControls.cardBackData;
  // let deletedModules = oldModules.filter(el => {
  //   let idToFind = el.content_module.id;
  //   return cardBackData.find(el1 => el1.isDeleted && el1.dId === idToFind) ? true : false;
  // });
  cardBackData = dataToSave || cardBackData;
  var mutations = getMutations(options);
  cardBackData = cardBackData
    .filter(el => {
      if (el.dId.includes('manual') && el.isDeleted) return false;
      if (el.isDeleted) return false;
      return true;
    })
    .sort((a, b) => a.position - b.position)
    .map((el, i) => {
      el.position = i;
      return el;
    });
  let result = await Promise.all(
    cardBackData.map(card => {
      let variables = {
        card_id: cardId,
        organization_id: organization_id,
        position: card.position,
        ...card.inputs.reduce((a, b) => {
          let key = b.key;
          if (b.mutation) {
            key = b.mutation.key;
          }
          a[key] = b.value;
          return a;
        }, {}),
      };
      if (card.dId.includes('manual')) {
        //   return client.mutate({
        //     mutation: mutations[`add${card.__typename}`],
        //     variables,
        //   });
        return Promise.resolve(true);
      }


      console.log('Card________________________', card)

      return client.mutate({
        mutation: mutations[`update${card.__typename}`],
        variables: {
          id: card.dId,
          position: card.position,
          ...card.inputs.reduce((a, b) => {
            // if this feild has defined muation key
            // override default key eioth mutation
            let key = b.key;
            if (
              b.mutation &&
              validateTypeOfMutation(b.mutation.dataType, b.value)
            ) {
              key = b.mutation.key;
            }
            a[key] = b.value;
            if (a.gallery_videos) {
              a.gallery_videos =
                a.gallery_videos.map(item => {
                  return {
                    cover_photo_data: item.cover_photo_data,
                    cover_photo: item.cover_photo,
                    loop_times: item.loop_times,
                    does_loop: item.does_loop,
                    position: item.position,
                    title: item.title,
                    video_type: item.video_type,
                    video_url: item.video_url
                  }
                })
            }
            return a;
          }, {}),
        },
      });
    })
  );
  // // Drop ALL deleted modules from card
  // await Promise.all(
  //   deletedModules.map(el => {
  //     return client.mutate({
  //       mutation: mutations.deleteCardContentModule,
  //       variables: { id: el.id },
  //     });
  //   })
  // );
  // ALL CARDS MODULE HAS BEEN SAVED BOW UPDATE CARD
  data.card && await client.mutate({
    mutation: mutations.updateCard,
    variables: { id: cardId },
  });
  return result;
};


const getMutationsForCommunications = (options) => {
  var mutations = {};
  for (var i = 0; i < options.length; i++) {
    let option = options[i];
    var temp = gql`
      mutation create${option.__typename}(
        $communication_id: ID!
        $organization_id: ID
        $position: Int
        ${option.inputs.map(el =>
      el.mutation
        ? `$${el.mutation.key}: ${el.mutation.dataType} \n`
        : `$${el.key}: ${el.dataType} \n`
    )}
      ) {
        create${option.__typename}(
          communication_id: $communication_id
          organization_id: $organization_id
          position: $position
          ${option.inputs.map(el =>
      el.mutation
        ? `${el.mutation.key}: $${el.mutation.key} \n`
        : `${el.key}: $${el.key} \n`
    )}
        ) {
          ${option.inputs[0].key} ${option.inputs[0].subQueryKeys
        ? `{
                ${option.inputs[0].subQueryKeys.map(el2 => el2 + '\n')}
              }`
        : ''
      }
        }
      }
    `;
    mutations[`add${option.__typename}`] = temp;
  };
  return mutations;
};

export const getCreateMutationsForHomeComponent = (option) => {
  var temp = gql`
      mutation create${option.__typename}(
        $home_component_id: ID!
        $organization_id: ID
        $position: Int
        ${option.inputs.map(el =>
    el.mutation
      ? `$${el.mutation.key}: ${el.mutation.dataType} \n`
      : `$${el.key}: ${el.dataType} \n`
  )}
      ) {
        create${option.__typename}(
          home_component_id: $home_component_id
          organization_id: $organization_id
          position: $position
          ${option.inputs.map(el =>
    el.mutation
      ? `${el.mutation.key}: $${el.mutation.key} \n`
      : `${el.key}: $${el.key} \n`
  )}
        ) {
          ${option.inputs[0].key} ${option.inputs[0].subQueryKeys
      ? `{
                ${option.inputs[0].subQueryKeys.map(el2 => el2 + '\n')}
              }`
      : ''
    }
        }
      }
    `;
  return temp;
};

export const getUpdateMutationsForHomeComponent = (option) => {
  var temp1 = gql`
          mutation update${option.__typename}(
            $id: ID!
            $position: Int
            $published: Boolean
            $archived: Boolean
            ${option.inputs.map(el =>
    el.mutation
      ? `$${el.mutation.key}: ${el.mutation.dataType} \n`
      : `$${el.key}: ${el.dataType} \n`
  )}
          ) {
            update${option.__typename}(
              id: $id
              position: $position
              published: $published
              archived: $archived
              ${option.inputs.map(el =>
    el.mutation
      ? `${el.mutation.key}: $${el.mutation.key} \n`
      : `${el.key}: $${el.key} \n`
  )}
            ) {
              ${option.inputs[0].key} ${option.inputs[0].subQueryKeys
      ? `{
                  ${option.inputs[0].subQueryKeys.map(el2 => el2 + '\n')}
                }`
      : ''
    }
            }
          }
        `;
  return temp1;
};


export const saveCommunicationBackData = async (options, communicationId, organization_id, cardBackData = []) => {
  // debugger;
  if (cardBackData == null) cardBackData = [];
  var mutations = getMutationsForCommunications(options);
  let result = await Promise.all(cardBackData.map((card) => {
    let variables = {
      communication_id: communicationId,
      organization_id,
      position: card.position,
      ...card.inputs.reduce((a, b) => {
        let key = b.key;
        if (b.mutation) {
          key = b.mutation.key;
        }
        a[key] = b.value;
        return a;
      }, {}),
    };
    if (card.dId.includes('manual')) {
      return client.mutate({
        mutation: mutations[`add${card.__typename}`],
        variables,
      });
    }
  }));
  return result;
}


export const publishCard = async (cardId) => {
  let mutations = getMutations([]);
  await client.mutate({
    mutation: mutations.updateCard,
    variables: { id: cardId, published: true },
  });
  return true;
}

export const publishCardStackCard = async (cardStackId, status) => {
  let mutations = getMutations([]);
  await client.mutate({
    mutation: mutations.updateCardStackCard,
    variables: { id: cardStackId, published: status },
  });
  return true;
}

export const updateQuizCard= async (cardId, publish) => {
  let mutations = getMutations([]);
  await client.mutate({
    mutation: mutations.updateQuizQuestion,
    variables: { id: cardId, published: publish },
  });
  return true;
}

export const draftCard = async (cardId) => {
  let mutations = getMutations([]);
  await client.mutate({
    mutation: mutations.updateCard,
    variables: { id: cardId, published: false },
  });
  return true;
};