import * as React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`

`;


const DisabledDiv = styled.div`
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
    transition: opacity 0.3s;
`;

export default ({ isDisabled, children }) => {
    if (isDisabled) {
        return <DisabledDiv onClick={(e) => {
            e.stopPropagation();
        }}>{children}</DisabledDiv>
    };
    return <Container>
        {children}
    </Container>
};