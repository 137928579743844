import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select'
import { useMutation } from '@apollo/client';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { deck_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import gql from 'graphql-tag';
import { FileUploader } from 'baseui/file-uploader';
import CardPicker from '../../components/cardPickerCXL'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { saveModalOnEnterPress } from '../../util';
import { sourceByType } from '../../util';
import ModalStateContainer from '../modalStateContainer';
import { slideImageGuideline } from '../help/imageSizeGuidelines.json';
import ImageUploadCXL from '../imageUploaderCXL';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'small');
};

export default ({ defaultTheme, refetchLibraries, library, tags, onClose }) => {
  console.log('Tags: ', tags)
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const [title, setTitle] = React.useState(library ? library.type : '');
  const [image, setImage] = React.useState(getSrc(library ? library.library : null));
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [preview_image, setPreview] = React.useState(null);
  const id = library ? library.id : null;
  const [tag_id, setTagId] = React.useState(null)

  const [createLibrary, { loading: onAddLoader, data: addedImage }] = useMutation(
    gql`
        mutation createLibrary(
            $title: String
            $image: Upload
            $tag_id: ID
        ) {
            createLibrary(title: $title, image: $image, tag_id: $tag_id) {
                title
                library
                tag_id
            }
        }
    `
  );

  const onSave = (req = {}) => {
    if (!title || !uploadedFile) {
        return;
    }
    setIsLoading(true);
      createLibrary({
        variables: {
            title,
            image: uploadedFile,
            tag_id
        }
      });
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (addedImage) {
      refetchLibraries && refetchLibraries();
      setIsOpen(false);
      onClose();
    }
  }, [addedImage]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const onOptionChange = e => {
    setTagId(e.option.id)
  }

  const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';

  return (
        <React.Fragment>
          <Modal
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3,
                }
              },
            }}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          >
            <ModalHeader>{}</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <div
                  className={css({
                    display: 'flex',
                  })}
                >
                  <LabelLarge style={{ marginBottom: '16px' }}>Add Library</LabelLarge>
                </div>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    placeholder="Library Title"
                    clearOnEscape
                    onKeyDown={e =>
                      saveModalOnEnterPress({ e, onEnterPress: onSave })
                    }
                  />
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Tag</LabelMedium>
                  <Select
                    options={tags}
                    onChange={onOptionChange}
                    value={tags.find(i => i.id == tag_id)}
                    placeholder={tag_id ? '' : 'Select tag'}
                    multi={false}
                    labelKey={'name'}
                    valueKey={'id'}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />
                </Cell>

                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploadCXL
                    title={'Image library'}
                    titleHelpText={'Select the image for Library'}
                    previewUrls={preview_image || image}
                    isMultiple={false}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      setUploadedFile(acceptedFiles);
                      setPreview(URL.createObjectURL(acceptedFiles[0]));
                    }}
                    isMultiple
                    onImageDelete={() => {
                      setUploadedFile(null);
                      setPreview(null);
                      setImage(null)
                    }}
                    showFullBleedSettings={false}
                    imageSize={slideImageGuideline.size}
                    imageSizeGuidelineURL={slideImageGuideline.url}
                  ></ImageUploadCXL>
                </Cell>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  onClose();
                }}
              >
               Cancel
              </ModalButton>
              <ModalButton
                isLoading={isLoading}
                onClick={() => {
                  onSave();
                }}
              >
                Add Library
          </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
  );
};


