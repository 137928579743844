import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../redux/store';
import { gql } from '@apollo/client';
import { client } from '../apollo/client';

export default ({
    selectCommunicationId,
    onCommunicationSelect,
    placeholder,
    disabled
}) => {

    const [options, setOptions] = React.useState([]);
    let organization_id = store.getState().appBasicControls.organization.id;

    React.useEffect(() => {
        client.query({
            query: gql`
            query {
                communications_by_orgs(organization_id: "${organization_id}") {
                  title
                  id
                  type_news
                }
              }
          `,
            fetchPolicy: 'network-only'
        }).then(({ data }) => {
           setOptions(data.communications_by_orgs.filter((el) => el.type_news))
        })
    }, []);

    let matchingOption
    if(options) {
        matchingOption = options.filter((el) => el.id == selectCommunicationId);
    }
    return (
        <>
            <Select
                options={options}
                onChange={({ option }) => {
                    if (option) onCommunicationSelect(option.id)
                    else onCommunicationSelect(null)
                }}
                disabled = {disabled}
                value={matchingOption}
                placeholder={selectCommunicationId ? '' : placeholder}
                multi={false}
                labelKey={'title'}
                valueKey={'id'}
                maxDropdownHeight="300px"
                type={TYPE.search}
                overrides={{
                    Popover: {
                        props: {
                            overrides: {
                                Body: { style: { zIndex: 3 } },
                            },
                        },
                    },
                }}
            />
        </>
    )
}
