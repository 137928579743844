import React from 'react';
import { useStyletron } from 'baseui';
import { Select, SIZE } from "baseui/select";
import store from '../../../redux/store';
import { setCardBackData } from '../../../redux/actions/appBasicControls';
import {
  ParagraphXSmall
} from 'baseui/typography';
const svgIconsCss = {
  position: 'relative',
  float: 'right',
  marginRight: '18px',
  cursor: 'pointer',
};

const disabled = {
  pointerEvents: 'none',
  opacity: 0.4
}

const ConnectToData = (props) => {
  const [css, theme] = useStyletron();
  const { dataPoints, position } = props;
  const current = props.root.inputs[position];
  const is_connected = current.is_connected_with_data;
  const connected_to = current.connected_data_point;


  const setDataPointToCardNack = (option) => {
    const cardBackData = store.getState().appBasicControls.cardBackData;
    let findById = props.root.dId;
    let indexInState = cardBackData.findIndex(el => el.dId == findById);
    const indexOfInput = props.position;
    if (option) {
      cardBackData[indexInState].inputs[indexOfInput].is_connected_with_data = true;
      cardBackData[indexInState].inputs[indexOfInput].connected_data_point = option.id;
    } else {
      cardBackData[indexInState].inputs[indexOfInput].is_connected_with_data = false;
      cardBackData[indexInState].inputs[indexOfInput].connected_data_point = null;
    };
    props.dispatch(setCardBackData(cardBackData));
  }

  const options = dataPoints.map(el => {
    return { label: el, id: el };
  });

  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        ...is_connected ? disabled : {}
      }}>
        {props.children}
      </div>
      <div style={{ marginTop: '16px' }}>
        <ParagraphXSmall style={{ marginBottom: '8px' }}>Select data point for this input type or you can fill your own fixed value also</ParagraphXSmall>
        <Select
          size={SIZE.mini}
          options={options}
          value={options.filter(k => k.id == connected_to)}
          placeholder="Select data point"
          onChange={params => {
            setDataPointToCardNack(params.option);
          }}
        />
      </div>
    </>
  )
}

export default ConnectToData;