import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { updateDeck } from '../../redux/actions/appBasicControls';

const getSubForDeckUpdate = id => {
  return gql`
    subscription OnDeckUpdate {
      deckUpdated(id:"${id}"){
        deck {
          title
          created_by_id
          cover_type
          cover_image_data
          icon_name
          id
          archived
          organization_id
          description
          card_count
          full_bleed
          home_featured
          organization_color_palette_index
          show_answer_validation
          has_data_source_connected
          api_path_id
          card_style
        }
        deck_category {
          category_id
          deck_id
          deck_type
          position
          published
          isNew
          id
        }
      }
    }
  `;
};

const getSubForDeckCreate = orgId => {
  return gql`
    subscription OnDeckCreate {
      deckCreated(orgId:"${orgId}"){
        deck_category {
          category_id
          deck_id
          deck_type
          position
          published
          isNew
          id
        }
        deck {
          title
          created_by_id
          cover_type
          cover_image_data
          icon_name
          id
          archived
          organization_id
          description
          card_count
          full_bleed
          home_featured
          organization_color_palette_index
          has_data_source_connected
          show_answer_validation
          api_path_id
          card_style
        }
      }
    }
  `;
};

class SubscribeDeck extends React.Component {
  constructor(props) {
    super(props);
    this.listenForUpdate = this.listenForUpdate.bind(this);
    this.deckCreate = null;
    this.deckUpdates = null;
  }

  componentDidMount() {
    // 1. category create
    console.log('deck create sub activated');
    this.deckCreate = this.props.subscribeToMore({
      document: getSubForDeckCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.deckCreated
        ) {
          console.log('deck create sub received');
          prev = JSON.parse(JSON.stringify(prev));
          let data1 = prev.deck_categories;
          let { deckCreated } = subscriptionData.data;
          let isExists = data1.find(el => {
            return el.deck_id == deckCreated.deck_category.deck_id
          });
          if (isExists) {
            return prev;
          };
          data1.push({
            ...deckCreated.deck_category,
            deck_category_deck: deckCreated.deck,
          });
          prev.deck_categories = data1;
          return prev;
        }
      },
    });

    this.listenForUpdate();
  }

  listenForUpdate() {
    // 2. deck update
    this.deckUpdates = (this.props.decks || []).map(el => {
      return this.props.subscribeToMore({
        document: getSubForDeckUpdate(el.deck_id),
        variables: { id: el.deck_id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.deckUpdated
          ) {
            let { deckUpdated } = subscriptionData.data;
            let c = JSON.parse(JSON.stringify(this.props.decks || []));
            prev = JSON.parse(JSON.stringify(prev));
            let index = c.findIndex(el => el.deck_id == deckUpdated.deck.id);
            const updatedItem = {
              ...c[index],
              position: deckUpdated.deck_category.position,
              published: deckUpdated.deck_category.published,
              deck_category_deck: {
                ...c[index].deck_category_deck,
                ...deckUpdated.deck,
              },
            }
            c[index] = updatedItem;

            prev.deck_categories = c;
            return prev;
          }
        },
      });
    });
  }

  componentWillMount() {
    // unsubscribe
    console.log('deck create sub deactivated');
    this.deckCreate && this.deckCreate();
    this.deckUpdates && this.deckUpdates.map(el => el());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.decks.length != this.props.decks.length) {
      this.deckUpdates && this.deckUpdates.map(el => el());
      this.listenForUpdate();
    }
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = props => {
  const { decks } = props.appBasicControls;
  return { decks };
};

export default connect(mapStateToProps)(SubscribeDeck);
