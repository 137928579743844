import React, { useMemo, useState } from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import RightSidePageHeader from '../pageHeaders';
import Button from '../styledButton';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { gridPaddingOverrides } from '../overrides';
import useFeedBack from '../feedback/useFeedBack';
import FeedbackCard from '../feedback/feedbackCard';
import { Skeleton } from 'baseui/skeleton';
import HelpQuestionMark from '../help/questionMarkHelp';


const FeedbackMetrics = props => {
  const { feedBacks, hasMore, loading, archiveFeedback } = useFeedBack(props.organization_id, 1, true);
  const LoadingBar = () => {
    return (
      <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {new Array(3).fill('').map(item => <Skeleton
            width="500px"
            height="145px"
            animation
            overrides={{
              Root: {
                style: {
                  marginRight: '60px'
                },
              },
            }}
          />)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '25px'
                  },
                },
              }}
            />)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginRight: '10px',
                    borderRadius: '48px'
                  },
                },
              }}
            />)}
          </div>
        </div>
        <div style={{ marginTop: '2rem' }}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
            />))}
        </div>
        <div style={{ float: 'right' }}>
          <Skeleton
            width="500px"
            height="40px"
            animation
          />
        </div>
      </div>
    )
  };

  const Feedback = useMemo(() => {
    return feedBacks && feedBacks.length > 0 && <>
      {/* <RightSidePageHeader
        icon={null}
        title={'Feedback Content'}
        rightContent={
          <StatefulButtonGroup
            mode={MODE.radio}
            initialState={{ selected: 0 }}
          >
            {hasMore && <Button
              onClick={() => { navigate(`/app/${props.organization_id}/feedback`) }}
            >
              All Feedbacks
            </Button>}
          </StatefulButtonGroup>
        }
        headerDescription={'How people react on your cards?'}
      ></RightSidePageHeader> */}
      
      <Cell span={12}>
        <div style={{ marginBottom: '32px' }}>
          <FlexGrid
            flexGridColumnCount={[1, 1, 2, 4]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            {feedBacks && feedBacks.map((item, index) => {
              return <FlexGridItem key={index}>
                <div>
                  <FeedbackCard feedback={item} archiveFeedback={archiveFeedback} />
                </div>
              </FlexGridItem>
            })}
          </FlexGrid>
        </div>
      </Cell>
    </>
  }, [feedBacks, props.organization_id])
  return (
    <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
      {loading === true &&
        <LoadingBar /> ||
        Feedback
      }

    </Grid>
  )
};

export default FeedbackMetrics;