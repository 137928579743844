import React from 'react';

import { Hint, Sunburst } from 'react-vis';

import { EXTENDED_DISCRETE_COLOR_RANGE as COLORS } from 'react-vis/es/theme';

const DATA = {
  children: [
    {
      children: [
        { bigness: 1, children: [], clr: COLORS[1], name: 'excellent' },
        { bigness: 1, children: [], clr: COLORS[2], name: 'chart' },
      ],
      clr: COLORS[3],
    },
    {
      bigness: 1,
      children: [],
      clr: COLORS[4],
      name: 'cool',
      labelStyle: {
        fontSize: 15,
        fontWeight: 'bold',
      },
    },
    { bigness: 1, children: [], clr: COLORS[5], name: 'dogs' },
    { bigness: 1, children: [], clr: COLORS[6], name: 'sunglasses' },
    {
      children: [
        { bigness: 1, children: [], clr: COLORS[7], name: 'great' },
        { bigness: 1, children: [], clr: COLORS[8], name: 'label' },
      ],
      clr: COLORS[9],
    },
  ],
};

const tipStyle = {
  display: 'flex',
  color: '#fff',
  background: '#000',
  alignItems: 'center',
  padding: '5px',
};
const boxStyle = { height: '10px', width: '10px' };

function buildValue(hoveredCell) {
  const { radius, angle, angle0 } = hoveredCell;
  const truedAngle = (angle + angle0) / 2;
  return {
    x: radius * Math.cos(truedAngle),
    y: radius * Math.sin(truedAngle),
  };
}
{
  /* <RadialChart
data={myData}
width={300}
height={300} /> */
}

export default class SunburstWithTooltips extends React.Component {
  state = {
    hoveredCell: false,
  };
  render() {
    const { hoveredCell } = this.state;
    return (
      <Sunburst
        data={DATA}
        style={{ stroke: '#fff' }}
        onValueMouseOver={v =>
          this.setState({ hoveredCell: v.x && v.y ? v : false })
        }
        onValueMouseOut={v => this.setState({ hoveredCell: false })}
        height={300}
        margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
        getLabel={d => d.name}
        getSize={d => d.bigness}
        getColor={d => d.clr}
        width={this.props.width || 300}
        padAngle={() => 0.02}
      >
        {hoveredCell ? (
          <Hint value={buildValue(hoveredCell)}>
            <div style={tipStyle}>
              <div style={{ ...boxStyle, background: hoveredCell.clr }} />
              {hoveredCell.clr}
            </div>
          </Hint>
        ) : null}
      </Sunburst>
    );
  }
}
