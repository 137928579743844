
import * as React from 'react';
import styled from '@emotion/styled';

const CardBackHeaderTitle = styled.h5`
color: ${props => props.color};
margin-top: 16px;
margin-bottom: 11px;
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
`
export default CardBackHeaderTitle;