import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../redux/store';
import { gql } from '@apollo/client';
import { client } from '../apollo/client';

export default ({
  selectCustomFieldId,
  onFieldSelect,
  placeholder,
  disabled
}) => {
  console.log(selectCustomFieldId);
  const [options, setOptions] = React.useState([]);
  let organization_id = store.getState().appBasicControls.organization.id;

  React.useEffect(() => {
    client.query({
      query: gql`
        query custom_fields {
          custom_field_by_org (
            organization_id: "${organization_id}"
            integration_type: "Salesforce"
          ) {
            id
            integration_type
            field_name
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setOptions(data.custom_field_by_org)
    })
  }, []);

  let matchingOption
  if (options) {
    matchingOption = options.filter((el) => el.id == selectCustomFieldId);
  };
  console.log(options);
  console.log(matchingOption)
  return (
    <>
      <Select
        options={options}
        onChange={({ option }) => {
          if (option) onFieldSelect(option.id)
          else onFieldSelect(null)
        }}
        disabled={disabled}
        value={matchingOption}
        placeholder={selectCustomFieldId ? '' : placeholder}
        multi={false}
        labelKey={'field_name'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </>
  )
}
