import * as React from 'react';
import { useStyletron } from 'baseui';
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledCell,
} from 'baseui/table';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import {
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized/dist/commonjs/CellMeasurer';

const cache = new CellMeasurerCache({
  defaultHeight: 36,
  fixedWidth: false,
  // fixedHeight: true
});

export default function Table({ data, count, columns, loadMoreData, height, width }) {
  const [css] = useStyletron();

  const loadMore = (e) => {
    let endIndex = e.stopIndex;
    if (endIndex <= count && data.length <= endIndex) {
      console.log("Loading more data");
      console.log(endIndex);
      console.log(data.length);
      console.log(count);
      return loadMoreData();
    };
    return Promise.resolve();
  };

  const COLUMNS = columns.map((el) => el.display);
  const DATA = data.map((el) => {
    return columns.map((col) => {
      let value = el[col.key];
      if (col.key == '__root') value = el;
      return col.formatter ? col.formatter(value) : value;
    });
  });

  return (
    <div className={css({ height: height || '900px', width: width || 'unset'})}>
      <StyledTable
        role="grid"
        aria-colcount={COLUMNS.length}
        aria-rowcount={DATA.length}
      >
        <StyledHead role="row">
          {COLUMNS.map((column, index) => (
            <StyledHeadCell role="columnheader" key={index}>
              {column}
            </StyledHeadCell>
          ))}
        </StyledHead>
        <div className={css({ height: '100%' })}>
          <InfiniteLoader
            isRowLoaded={({ index }) => !!data[index]}
            loadMoreRows={loadMore}
            rowCount={count}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    height={height}
                    width={width}
                    rowCount={DATA.length}
                    rowHeight={cache.rowHeight}
                    deferredMeasurementCache={cache}
                    rowRenderer={({ index, key, parent, style }) => (
                      <CellMeasurer
                        cache={cache}
                        columnIndex={0}
                        key={key}
                        parent={parent}
                        rowIndex={index}
                      >
                        <StyledRow role="row" key={key} style={style}>
                          {DATA[index].map((cell, index) => {
                            // console.log(cell);
                            return (
                              <StyledCell role="gridcell" key={index}>
                                {/* {cell instanceof Object ? cell.result : cell} */}
                                {cell}
                              </StyledCell>
                            )
                          })}
                        </StyledRow>
                      </CellMeasurer>
                    )}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      </StyledTable>
    </div>
  );
}