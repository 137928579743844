import store from '../redux/store';

const GenCardUrl = async (cardId) => {

  let organization = store.getState().appBasicControls.organization || '';
  let cards = store.getState().appBasicControls.cards || [];
  let decks = store.getState().appBasicControls.decks || [];
  let categories = store.getState().appBasicControls.category || [];
  let channels = store.getState().appBasicControls.channels || [];
  let groups = store.getState().appBasicControls.groups || [];

  let card, deck, category, channel, group;
  card = cards.find((card) => card.card_id === cardId);
  if (card) {
    deck = decks.find((deck) => deck.deck_id === card.deck_id);
    if (deck) {
      category = categories.find((category) => category.category_id === deck.category_id);
      if (category) {
        channel = channels.find((channel) => channel.id === category.app_content_scope_id);
        if (channel) {
          group = groups.find((group) => group.id === channel.app_content_scope_group_id);
        }
      }
    }
  }

  if (card && deck && category && channel && group) {
    let cardUrl = `/app/${organization.id}/groups/${group.id}/channels/${channel.id}/deck/${deck.deck_id}/card/${card.card_id}`;
    return cardUrl;
  } else {
    return null
  }
}

export default GenCardUrl;
