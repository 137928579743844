import React from 'react';
import { Spinner } from 'baseui/spinner';
import axios from 'axios';
import { useStyletron } from 'baseui';
import { getServerUrl } from '../apollo/client';
import { Button } from 'baseui/button';
import LoginContainer from '../components/loginContainer';
import { Cell } from 'baseui/layout-grid';
import jwt_decode from "jwt-decode";
import {
  LabelMedium
} from 'baseui/typography';
import { Input } from 'baseui/input';
import PasswordStrengthBar from 'react-password-strength-bar';

import { adminSliders, userSliders } from '../helpers/slidersData';

const AcceptInvite = () => {
  const [css, theme] = useStyletron();
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get('name');
  const token = urlParams.get('token');
  const isInvitation = urlParams.get('isInvitation');
  const decoded = jwt_decode(token);
  const type = decoded.isAdmin ? 'admin' : 'user';
  const [isLoading, setIsLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const onsubmit = () => {
    if (!password) return;
    if (password !== confirmPassword) return;
    setIsButtonLoading(true);
    let fromInvitation = isInvitation && isInvitation || true;
    axios({
      url: `${getServerUrl()}/reset-password?token=${token}&domain=${window.location.origin}`,
      method: 'POST',
      data: {
        password,
        fromInvitation
      }
    }).then((responseJson) => {
      if (responseJson.data.success) {
        // debugger;
        // setIsButtonLoading(false);
        window.location.href = responseJson.data.result_url;
        return;
      };
      setError(responseJson.data.message);
      setIsLoading(false);
      setIsButtonLoading(false);
    }).catch((err) => {
      setIsButtonLoading(false);
      setError(err.message);
    })
  };

  React.useEffect(() => {
    if (password === '' || confirmPassword === '') return;
    if (password !== confirmPassword) {
      setError('Confirm Password and Password should be same');
    };
    if (password === confirmPassword) {
      setError(null);
    }
  }, [password, confirmPassword])

  const sliders = type === 'admin' ? adminSliders : userSliders;

  return (
    <>
      <LoginContainer hasSliders={false} sliders={sliders} sloganSub={`${name}`} boxShadowSlider={false}>
        {isLoading ? <>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              color: theme.colors.primaryA,
            }}
          >
            Setting up Invitation
        </div>
          <div
            className={css({
              position: 'absolute',
              width: theme.sizing.scale1200,
              height: theme.sizing.scale1200,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            <Spinner />
          </div>
        </> :
          <>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                color: theme.colors.primaryA,
              }}
            >
              Set the new password
            </div>
            <Cell
              span={12}
              overrides={{
                Cell: {
                  style: () => ({
                    marginTop: '32px',
                  }),
                },
              }}
            >
              <Input
                value={password}
                placeholder="Password"
                onChange={e => setPassword(e.target.value)}
                type={"password"}
                clearOnEscape
              />
              <PasswordStrengthBar password={password} />
              <Input
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={e => setConfirmPassword(e.target.value)}
                type={"password"}
                clearOnEscape
              />
              <Button
                overrides={{
                  BaseButton: { style: { width: '100%', marginTop: '16px' } },
                }}
                isLoading={isButtonLoading}
                onClick={() => onsubmit()}
              >
                Set New Password
              </Button>
              {error && <LabelMedium
                style={{ color: theme.colors.warning, marginTop: '8px' }}
              >
                {error}
              </LabelMedium>}
            </Cell>
          </>}
      </LoginContainer>
    </>
  );
};

export default AcceptInvite;
