import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { HeadingMedium, ParagraphSmall } from 'baseui/typography';
import { connect } from 'react-redux';
import { Button } from "baseui/button";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { toaster } from "baseui/toast";
import { DragDropContext } from 'react-beautiful-dnd';
import Plus from 'baseui/icon/plus';
import _ from 'lodash';

import CardBuilderEditor from '../builder/cardBuilderEditor';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import { getModuleMovementMethods } from '../builder/builderUtils/helpers';
import Builder from '../builder/builder';
import { cellPaddingOverrides } from '../overrides';
import store from '../../redux/store';
import RightSidePageHeader from '../builder/builderHeader';


const addNewContentButtonContainerStyle = () => { return { position: 'relative', display: 'flex', alignItems: 'center', marginTop: '32px', boxSizing: 'border-box', paddingLeft: '20px' } };
const editorContainerStyle = () => { return { marginTop: '0px', overflow: 'auto' } }


const Step4 = ({ cardBackData = [], setCardBackDataState, cardBackDataRedux}) => {
  const OnSaveOrUpdate = () => {
    return new Promise((resolve, reject) => {
      setCardBackDataState([...store.getState().appBasicControls.cardBackData] || []);
      resolve();
    })
  }
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingBottom: '32px',
          width: '100%',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }),
      },
    }} gridMaxWidth={'unset'}>
      <Cell span={12} overrides={cellPaddingOverrides}>
        <div style={editorContainerStyle()}>
          <Builder
            noPreview={true}
            leftCellSpan={1.2}
            middleCellSpan={2}
            rightCellSpan={8}
            options={editorBtnOptions.filter(
              el => el.addInNewCommunication
            )}
            isModal={true}
            dispatch={store.dispatch}
            options2={cardBackData}
            data={cardBackData}
            onSave={OnSaveOrUpdate}
            onUpdate={OnSaveOrUpdate}
            headerComponent={() => <RightSidePageHeader
              title={"Message Body"}
              headerDescription={"Drag content modules from the left into the blank space to create rich media for your messaging."}
            />}
            isShowHoverContent={false}
            cardBackData={cardBackDataRedux}
            isMainPageModal={true}
            isCommunication={true}
          ></Builder>
        </div>
      </Cell>
    </Grid>
  )
}


const mapStateToProps = props => {
  let { cardBackData } = props.appBasicControls;
  return {
    cardBackDataRedux: cardBackData
  };
};
export default connect(mapStateToProps)(Step4);