import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';

const CardListContainer = styled.div`
      position: absolute;
      top: ${props => props.position === "bottom" ? "290px" : "-351px"};
      animation: .2s ${props => !props.secondRender ? (props.position === "bottom" ? fadeUpAnimation : fadeInAnimation) : ''};
      left: -36px;
      height: 351px;
      width: calc(100% + 61px);
      background: ${props => props.defaultTheme === "dark" ? '#0D0E13' : 'white'};
      z-index: 3;
      padding-left: 2rem;
      padding-top: 10px;
      padding-right: 25px;
      &:before {
        width: 47px;
        height: 50px;
        background: ${props => props.defaultTheme === "dark" ? '#0D0E13' : 'white'};
        left: calc(${props => props.leftWidth}px - 9.5%);
        top: ${props => props.position === "bottom" ? "-5px" : "304px"};
        content: '';
        position: absolute;
        transform: rotate(45deg);
      }
      @media (max-width: 600px) {
    
        &:before {
          left: ${props => props.leftWidth + 60}px;
        }
      } 
    `;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`;
const fadeUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`;


class CardDrawer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      leftWidth: 100,
      position: 'bottom',
      secondRender: false
    }
  }

  close = () => {
    this.setState({
      isOpen: false
    });
    const {
      id,
      app_content_scope_group_id
    } = this.props.selectedChannel || {};
    let channelId = id && id  || '';
    let groupId = app_content_scope_group_id && app_content_scope_group_id || '';
     // navigate(
    //   `/app/${this.props.organization_id}/groups/${groupId}/channels/${channelId}`
    // )
    window.history.pushState('','', `/app/${this.props.organization_id}/groups/${groupId}/channels/${channelId}`);
  };

  handleOverLayClick = () => {
    const {
      id,
      app_content_scope_group_id
    } = this.props.selectedChannel || {};

    const overLay = document.querySelector('#app-overlay')
    overLay.style.display = 'none'
    this.props.setisDragDisabled(false);
    let channelId = id && id  || '';
    let groupId = app_content_scope_group_id && app_content_scope_group_id || '';
    // navigate(
    //   `/app/${this.props.organization_id}/groups/${groupId}/channels/${channelId}`
    // )
    window.history.pushState('','', `/app/${this.props.organization_id}/groups/${groupId}/channels/${channelId}`);
    this.setState({
      isOpen: false,
      secondRender: false
    })
  }

  handleOnClick = event => {
    const {
      organization_id,
      selectedChannel,
      deck
    } = this.props;
    const {
      id,
      app_content_scope_group_id
    } = selectedChannel || {};
    let channelId = id && id  || '';
    let groupId = app_content_scope_group_id && app_content_scope_group_id || '';
    const clientRect = event.currentTarget.getBoundingClientRect();
    if (!this.state.isOpen) {
      const overLay = document.querySelector('#app-overlay')
      overLay.style.display = 'block'
      this.props.setisDragDisabled(true)
      this.setState({
        isOpen: true,
        secondRender: false,
        leftWidth: clientRect.left || 100,
        position: clientRect.y > 400 ? 'top' : 'bottom'
      }, () => {
        const selectedDeckId = localStorage.getItem('deck_id');
        // const deckId = deck && selectedDeckId && deck.deck_id === selectedDeckId && deck.deck_id || '';
        const deckId = deck && deck?.deck_id || '';
        navigate(
          `/app/${organization_id}/groups/${groupId}/channels/${channelId}/deck/${deckId}`
        )
        let container = document.querySelector('#card-list-container')
        const appContainer = document.querySelector('#app-container');
        if (container && appContainer) {
          container = container.getBoundingClientRect();
          const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
          const space = container.top + container.height - 52 - viewHeight;
          const isNotVisible = (container.bottom < 0 || space >= 0);
          if (isNotVisible) {
            appContainer.scrollTo({
              top: appContainer.scrollTop + space,
              behavior: 'auto'
            })
          }
        }
      })
    }
  }

  componentDidMount() {
    const { isDeckOpen, deck, cards, tabFilter, searchText } = this.props;
    if (tabFilter === 'all') {
      const selectedDeckId = localStorage.getItem('deck_id');
      if (isDeckOpen && deck && selectedDeckId && deck.deck_id === selectedDeckId) {
        const id = "deck-container-" + deck.deck_id;
        document.querySelector('#' + id).click();
      }
    }
    if (searchText) {
      const filteredCards = cards.filter(item => item.deck_id === deck.deck_id)
      const isIncludeSearchCard = filteredCards.find(item => item.card.title === searchText)
      if (isIncludeSearchCard) {
        const id = "deck-container-" + deck.deck_id;
        document.querySelector('#' + id).click();
      }
    }

    const overLay = document.querySelector('#app-overlay')
    if (overLay) {
      overLay.addEventListener('click', this.handleOverLayClick);
    }
  }

  componentDidUpdate(prevProps, newState) {
    if (newState.isOpen) {
      if (!this.state.secondRender)
        this.setState({
          secondRender: true
        })
    }
  }

  componentWillUnmount() {
    const overLay = document.querySelector('#app-overlay');
    if (overLay) {
      overLay.addEventListener('click', this.handleOverLayClick);
    }
  }

  renderCardList = () => {
    const { content, defaultTheme } = this.props;
    const { position, secondRender, leftWidth, isOpen } = this.state;
    if (isOpen) {
      return (<CardListContainer id="card-list-container" leftWidth={leftWidth} defaultTheme={defaultTheme} position={position} secondRender={secondRender}>
        <div className='demo-card-list-container'>

        </div>
        {content({ close: this.close })}
      </CardListContainer>
      )
    }
  }

  renderChildren = () => {
    const { children } = this.props;
    const { isOpen } = this.state;
    return children(isOpen)
  }

  render() {
    const { index, deck } = this.props;

    return (
      <React.Fragment key={index}>
        <div
          id={"deck-container-" + deck.deck_id}
          onClick={this.handleOnClick}
        >
          {this.renderChildren()}
        </div>
        {this.renderCardList()}
      </React.Fragment>
    );
  }
};

const mapStateToProps = (props) => {
  const { selectedChannel } = props.appBasicControls;
  return {
    selectedChannel
  };
}

export default connect(mapStateToProps)(CardDrawer);
