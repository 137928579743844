import * as React from 'react';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { useStyletron } from 'baseui';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import moment from 'moment';
import { Skeleton } from 'baseui/skeleton'
import { ProgressBar } from 'baseui/progress-bar';
import { Spinner } from 'baseui/spinner';
import { KIND, toaster } from 'baseui/toast';
import { navigate } from '@reach/router';

import Button from '../styledButton';
import { sourceByType } from '../../util';
import { getCardByIdWithModules, getJWPlayerData } from './builderUtils/query';
import { libraries } from '../../quries';
import { saveCardBackData, updateCardBackData } from './builderUtils/save';
import {
  setIsLoading,
} from '../../redux/actions/appBasicControls';
import {
  //initSubscription,
  dropSubscriptions,
} from './builderUtils/subscription';
import RightSidePageHeader from './builderHeader';
import TabStyleBuilder from './tabStyleBuilder';
import WizardStyleBuilder from './wizardStyleBuilder';
import store from '../../redux/store';
import MainPageModal from './mainPageModal';
import CustomModal from '../common/modal';
import { useSelector } from 'react-redux';
import { Notification } from 'baseui/notification';
import _ from 'lodash';

const dispatch = store.dispatch;
const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};
const TAB_STATUS = {
  BUILD: 'Build',
  COVER: 'Cover',
  // STATS: 'Stats',
  SPECIFICATION: 'Filters',
};

const notificationStyle = {
  position: 'absolute',
  right: '20px',
  top: '100px',
  zIndex: 1,
  width: 'auto'
}

const mainPageStyle = 'modal'; // page
const builderStyle = 'wizard'; //tab;
const BuilderComponent = builderStyle == 'wizard' ? WizardStyleBuilder : TabStyleBuilder;

const Loader = () => {
  return (
    <div style={{ width: '100%', }}>
      <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
        <Skeleton
          width="99%"
          height="72px"
          animation
          overrides={{
            Root: {
              style: {
              },
            },
          }}
        />
        {new Array(5).fill('').map(item => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
              {new Array(2).fill('').map(item => <Skeleton
                width="47%"
                height="145px"
                animation
                overrides={{
                  Root: {
                    style: {
                      marginRight: '60px'
                    },
                  },
                }}
              />)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const BuilderP = ({ cardId, organization_id, cardStackId, options, isLoading, questionCards, cards, isCardStack, ...rest }) => {
  // OVERLAY HANDLING
  document.getElementById('overlay') &&
    (document.getElementById('overlay').style.display = 'none');
  // TAB
  const [tab, setTab] = React.useState(TAB_STATUS.BUILD);
  // THEME
  const [css, theme] = useStyletron();
  // TAB KEY
  const [closeToast, setCloseToast] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const [jwPlayerMediaData, setJwPlayerData] = React.useState(null);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isMsgModalOpen, setIsMsgModalOpen] = React.useState(false);
  const appBasicControls = useSelector(state => state.appBasicControls);

  const onModalClose = () => {
    if (appBasicControls.isLoading === true) {
      setIsMsgModalOpen(true)
      setTimeout(() => {
        setIsMsgModalOpen(false);
      }, 1500);
      return;
    }
    setIsModalOpen(false);
    if(isCardStack) {
      navigate(
        `/app/${organization_id}/groups/${rest.groupId}/channels/${rest.channelId}`
      )
      return;
    }
    navigate(
      `/app/${organization_id}/groups/${rest.groupId}/channels/${rest.channelId}/deck/${rest.deckId}`
    )
  };

  React.useEffect(() => {
    setIsModalOpen(true);
    return () => {
      setIsModalOpen(false);
    }
  }, []);

  // PREVIEW CONTAINER
  const [
    previewContainerVisibility,
    setPreviewContainerVisibility,
  ] = React.useState(true);

  // QUERY CARD DATA WITH MODULES
  const { data, error, loading: isLoadingCardModule, subscribeToMore, refetch } = useQuery(
    getCardByIdWithModules(cardId, options),
    { fetchPolicy: 'no-cache' }
  );

  // Query JW Player data
  const { data: jwPlayerData, jwError, loading: isLoadingJWPlayer } = useQuery(getJWPlayerData(organization_id), { fetchPolicy: 'no-cache' })
  // Query libraries
  const { data: libraryData, libraryErr, loading: isLoadingLibraries } = useQuery(libraries(), { fetchPolicy: 'no-cache'});

  const onSave = async (dataToSave, autoMode = false) => {
    console.log('SAVING CARD BACK DATA >>>>>>>>>>>>>>>>');
    // if (isLoading || isLoadingCardModule) return;
    dispatch(setIsLoading(true));
    dropSubscriptions();
    let toastKey;
    if (!closeToast && !toastKey) {
      const msg = 'Saving the card module data...';
      setCloseToast(true)
      toastKey = toaster.info(<>
        <ProgressBar
          value={value}
          getProgressLabel={value => msg}
          showLabel={false}
          infinite
          overrides={{
            BarProgress: {
              style: ({ $theme }) => ({
                backgroundColor: 'white'
              })
            }
          }}
        />
        <p style={{ textAlign: 'center', margin: '0 !important' }}>
          {msg}
        </p>
      </>, {
        onClose: () => setCloseToast(false),
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
      // setTimeout(() => {
      //   toaster.clear(toastKey);
      // }, 2000);
    }


    return saveCardBackData(organization_id, cardId, options, data, dataToSave, autoMode).then(result => {
      // console.log('builder autosave res', result);
      let errorList = [];
      result.map(ele => {
        if (ele.errors) {
          errorList.push(ele.errors);
        }
      })
      if (errorList.length > 0) {
        throw new Error(errorList);
      }

      if (closeToast || toastKey) {
        toaster.update(toastKey, {
          children: <>Card data saved.</>,
        });
        setTimeout(() => {
          toaster.clear(toastKey);
          setCloseToast(false)
        }, 1000);
      }
      refetch();
      dispatch(setIsLoading(false));
    }).catch(err => {
      onSaveErrorHandler(err);
      refetch();
      dispatch(setIsLoading(false));
      setTimeout(() => {
        toaster.clear(toastKey);
        setCloseToast(false)
      }, 1000);
    });
  };

  const onSaveErrorHandler = (err) => {
    let errorToaster = toaster.negative(<></>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    if (_.isArray(err)) {
      if (err.length > 1) {
        toaster.update(errorToaster, {
          children: <>{`${err.length} errors occured `}</>,
        });
      } else if (err.length === 1 && err[0].message) {
        toaster.update(errorToaster, {
          children: <>{err[0].message}</>,
        });
      } else {
        toaster.update(errorToaster, {
          children: <>{'An error has been occured'}</>,
        });
      }
    } else if (err && err.message) {
      toaster.update(errorToaster, {
        children: <>{err.message}</>,
      });
    } else {
      toaster.update(errorToaster, {
        children: <>{'An error has been occured'}</>,
      });
    }
    setTimeout(() => {
      toaster.clear(errorToaster);
    }, 1000);
    console.log('builder autosave error', JSON.stringify(err));
  }

  const onUpdate = async (dataToSave) => {
    console.log('UPDATING CARD BACK DATA >>>>>>>>>>>>>>>>');
    // dropSubscriptions();
    let toastKey;
    const msg = 'Saving the card module data...';
    dispatch(setIsLoading(true));
    if (!closeToast && !toastKey) {
      setCloseToast(true)
      toastKey = toaster.info(<>
        <ProgressBar
          value={value}
          getProgressLabel={value => msg}
          showLabel={false}
          infinite
          overrides={{
            BarProgress: {
              style: ({ $theme }) => ({
                backgroundColor: 'white'
              })
            }
          }}
        />
        <p style={{ textAlign: 'center', margin: '0 !important' }}>
          {msg}
        </p>
      </>, {
        onClose: () => setCloseToast(false),
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
      setTimeout(() => {
        toaster.clear(toastKey);
      }, 2000);
    }
    updateCardBackData(organization_id, cardId, options, data, dataToSave).then(result => {
      // refetch();
      dispatch(setIsLoading(false));
      if (closeToast || toastKey) {
        toaster.update(toastKey, {
          children: <>Card data saved.</>,
        });
        setTimeout(() => {
          toaster.clear(toastKey);
          setCloseToast(false)
        }, 1000);
      }
    }).catch(err => {
      dispatch(setIsLoading(false));
      setTimeout(() => {
        toaster.clear(toastKey);
        setCloseToast(false)
      }, 1000);
    });
  };

  const onCloseMsgModal = () => {
    setIsMsgModalOpen(false)
  }

  // React.useEffect(() => {
  //   initSubscription(subscribeToMore, options, data, cardId);
  // }, [data]);

  const getIconForCard = logo_data => {
    if (!logo_data) return null;
    let url = getSrc(logo_data);
    return url;
  };
  const getActiveTab = () => {
    switch (tab) {
      case "Build":
        return 0;
      case "Cover":
        return 1;
      case "Data":
        return 2;
      default:
        return 0;
    }
  }
  const BuilderHeadComponent = (props) => {
    const { flag, noTitles } = props;
    const icon = getIconForCard(data.card.file_data);
    return (
      <RightSidePageHeader
        icon={noTitles ? null : icon}
        title={noTitles ? '' : data.card.title}
        previewIcon={noTitles ? false : true}
        flag={flag}
        onPreviewIconClick={() =>
          setPreviewContainerVisibility(!previewContainerVisibility)
        }
        rightContent={builderStyle == 'wizard' ? null :
          <StatefulButtonGroup
            mode={MODE.radio}
            initialState={{ selected: getActiveTab() }}
          >
            {Object.keys(TAB_STATUS).map(el => {
              return (
                <Button onClick={() => setTab(TAB_STATUS[el])}>
                  {TAB_STATUS[el]}
                </Button>
              );
            })}
            {/* <BaseButton onClick={onSave} style={{marginRight: '16px'}}>Save</BaseButton> */}
          </StatefulButtonGroup>
        }
        headerDescription={noTitles ? '' : `Last updated at ${moment(
          data.card.updated_at
        ).format('h:mm a on MMMM Do YYYY')}`}
      />
    )
  };

  const Blank = ({ children }) => {
    return <>{children}</>;
  }
  const MainPageComponentByStyle = React.useMemo(() => mainPageStyle == 'modal' ? MainPageModal : Blank, [mainPageStyle])


  return (
    <MainPageComponentByStyle
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      {isMsgModalOpen === true && appBasicControls.isLoading === true && (
        <Notification
          kind={KIND.negative}
          autoHideDuration={1500}
          overrides={{
            Body: { style: notificationStyle },
          }}
        >
          Please wait. Saving is not complete
        </Notification>
      )}
      {!data || !jwPlayerData || !libraryData ? (
        <Loader />
      )
        : <BuilderComponent
          tab={tab}
          BuilderHeadComponent={BuilderHeadComponent}
          options={options}
          data={data}
          isCardStack={isCardStack}
          jwPlayerData={jwPlayerData}
          libraries={libraryData}
          onSave={onSave}
          onUpdate={onUpdate}
          refetch={refetch}
          previewContainerVisibility={previewContainerVisibility}
          organization_id={organization_id}
          TAB_STATUS={TAB_STATUS}
          refetch={refetch}
          rest={rest}
          isModal={mainPageStyle == 'modal'}
          onModalClose={onModalClose}
          questionCards={questionCards}
          cards={cards}
          cardStackId={cardStackId}
          dispatch={dispatch}
        ></BuilderComponent>
      }
    </MainPageComponentByStyle>
  );
};
const mapStateToProps = props => {
  let { channels, cards, questionCards, theme } = props.appBasicControls;
  return {
    questionCards,
    cards,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(BuilderP);