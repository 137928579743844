import * as React from 'react';
import Builder from './builder';
import CardFront from './cardFront';
import CardSpecifications from './cardSpecifications';
import StepsWizard from '../connect/steps-wizard';
import { Grid, Cell } from 'baseui/layout-grid';
import { cellPaddingOverrides } from '../overrides';
import {
  HeadingMedium,
  ParagraphSmall
} from 'baseui/typography';
import { sourceByType } from '../../util';
import CardFrontPreview from './cardFrontPreview';
import PhoneDesign from './phone-design';
import PreviewElements from './previewElementsV1';
import store from '../../redux/store';
import CardVisibilityIntegration from './cardVisibilityIntegration';
import { publishCard, draftCard, updateQuizCard, publishCardStackCard } from './builderUtils/save';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import { Button as BaseButton } from 'baseui/button';
import FilterTags from './filterTags';
import moment from 'moment';
import { useSelector } from 'react-redux';
import card from '../cards/card';
import { showAlert, updateCards, setQuestionCards } from '../../redux/actions/appBasicControls';
import PreviewFromPwa from './previewFromPwa';
import { checkIsValidToPublish } from '../dashboard/utils';

const NextButton = styled(BaseButton)`
  background-color: ${p => (p.isSelected ? '#5D64AB' : '#131313')};
  color: 'white';
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 3px;
  border-radius: 2px;
  float: right;
`;

const getSrc = (data, type) => {
  let path = sourceByType(data, type);
  return path;
};

const WizardStyleBuilder = ({
  BuilderHeadComponent,
  options,
  quizOptions,
  data,
  jwPlayerData,
  libraries,
  onSave,
  onUpdate,
  previewContainerVisibility,
  organization_id,
  refetch,
  rest,
  isModal,
  onModalClose,
  isQuizType,
  isCardStack,
  dispatch,
  questionCards,
  cards,
  cardStackId,
  ...remaining
}) => {
  const [filteredOptionMenu, setFilteredOptionMenu] = React.useState([]);
  React.useEffect(() => {
    if (isQuizType) return setFilteredOptionMenu(
      questionCards
        .filter(el => el.quiz_deck_id == rest.deckId && el.quiz_question_id != data.card.id)
        .map(el => {
          const label = el.default_title && el.default_title.substring(0, 40) || '';
          return {
            label,
            id: el.quiz_question_id
          }
        })
    );
    else if (isCardStack) return setFilteredOptionMenu([]);
    setFilteredOptionMenu(
      cards.filter(el => el.deck_id == rest.deckId && el.card_id != data.card.id)
        .map(el => {
          const label = el.card.title && el.card.title.substring(0, 100) || '';
          return {
            label,
            id: el.card_id
          }
        })
    );
  }, [cards, questionCards])
  const Deck = !isCardStack ? data.card.card_decks.find(el => el.deck_id == rest.deckId) : data.card.card_stack;
  const [current, setCurrent] = React.useState(0);
  const { is_card_back_settings_on } = store.getState().appBasicControls.organization_settings;
  const [currentPublishStatus, setCurrentPublishStatus] = React.useState(Deck.published);
  const deckId = !isCardStack? data.card.card_decks[0].deck_id : ''
  const onStepClick = (index) => {
    if (STEPS[index].disabled) return;
    setCurrent(index);
  };
  let STEPS;
  const [isLoading, setIsLoading] = React.useState(false);
  if (isQuizType) {
    STEPS = [
      {
        buttonTitle: 'Quiz',
        svgName: 'cover',
        component: () => <Builder
          key="cover"
          noPreview={true}
          options={quizOptions}
          headerComponent={BuilderHeadComponent}
          cardInfo={{
            title: data ? data.card.title : '',
            sub_title: data ? data.card.sub_title : '',
          }}
          onSave={onSave}
          onUpdate={onUpdate}
          data={data}
          isQuizType={isQuizType}
          isQuizOptionsTab={true}
          isModal={true}
          card_content_modules={data ? data.card.quiz_question_modules : []}
          previewContainerVisibility={previewContainerVisibility}
          toaster={rest.toaster}
          isMainPageModal={isModal}
          hideLeftPanel={false}
        />
      },
      {
        buttonTitle: 'Content',
        svgName: 'builder',
        component: () => <Builder
          key="builder"
          noPreview={true}
          options={options}
          headerComponent={BuilderHeadComponent}
          cardInfo={{
            title: data ? data.card.title : '',
            sub_title: data ? data.card.sub_title : '',
          }}
          onSave={onSave}
          onUpdate={onUpdate}
          data={data}
          isQuizType={isQuizType}
          isModal={true}
          card_content_modules={data ? data.card.card_content_modules : []}
          previewContainerVisibility={previewContainerVisibility}
          toaster={rest.toaster}
          isMainPageModal={isModal}
        />
      },
      // {
      //   buttonTitle: 'Filters',
      //   svgName: 'filters',
      //   component: () => <Grid overrides={{
      //     Grid: {
      //       style: () => ({
      //         paddingBottom: '64px',
      //         width: '100%',
      //         paddingTop: '29px',
      //         paddingLeft: '0px !important',
      //         paddingRight: '0px !important',
      //       }),
      //     },
      //   }} gridMaxWidth={'unset'}>
      //     <Cell span={12} overrides={cellPaddingOverrides}>
      //       <FilterTags card={data.card} organization_id={organization_id} toaster={rest.toaster} />
      //     </Cell>
      //   </Grid>,
      //   isReady: true
      // },
    ]
  }
  else {
    STEPS = [
      {
        buttonTitle: 'Design',
        svgName: 'cover',
        component: () => <Grid overrides={{
          Grid: {
            style: () => ({
              paddingBottom: '64px',
              width: '100%',
              paddingTop: '24px',
              paddingLeft: '24px !important',
              paddingBottom: '0px !important',
            }),
          },
        }} gridMaxWidth={'unset'}>
          {/* <Cell span={12} overrides={cellPaddingOverrides}>
            <HeadingMedium style={{ marginBottom: '4px' }}>
              Card Cover
            </HeadingMedium>
            <ParagraphSmall>
              Design overview of card
            </ParagraphSmall>
          </Cell> */}
          <Cell span={12} overrides={cellPaddingOverrides}>
            <CardFront card={data.card} deck_id={isCardStack? '' : data.card.card_decks[0].deck_id} isModal={true} refetch={refetch} toaster={rest.toaster} isMainPageModal={isModal} organization_id={organization_id} data={data} rest={rest}></CardFront>
          </Cell>
        </Grid>,
        isReady: true
      },
      {
        buttonTitle: 'Content',
        svgName: 'builder',
        component: () => <Builder
          key="builder"
          jwPlayerData={jwPlayerData}
          libraries={libraries}
          noPreview={true}
          options={options}
          headerComponent={BuilderHeadComponent}
          cardInfo={{
            title: data ? data.card.title : '',
            sub_title: data ? data.card.sub_title : '',
          }}
          onSave={onSave}
          onUpdate={onUpdate}
          data={data}
          isModal={true}
          card_content_modules={data ? data.card.card_content_modules : []}
          previewContainerVisibility={previewContainerVisibility}
          toaster={rest.toaster}
          isMainPageModal={isModal}
        />
      },
      {
        buttonTitle: 'Filters',
        svgName: 'filters',
        component: () => <Grid overrides={{
          Grid: {
            style: () => ({
              paddingBottom: '64px',
              width: '100%',
              paddingTop: '29px',
              paddingLeft: '0px !important',
              paddingRight: '0px !important',
            }),
          },
        }} gridMaxWidth={'unset'}>
          {/* <Cell span={12}>
            <HeadingMedium style={{ marginBottom: '4px' }}>
              Card Filters
            </HeadingMedium>
            <ParagraphSmall>
              Add filter or specifications to the card
            </ParagraphSmall>
          </Cell> */}
          <Cell span={12} overrides={cellPaddingOverrides}>
            <FilterTags card={data.card} organization_id={organization_id} toaster={rest.toaster} />
          </Cell>
        </Grid>,
        isReady: true
      },
      ...is_card_back_settings_on ? [{
        buttonTitle: 'Settings',
        svgName: 'timing',
        component: () => <CardVisibilityIntegration
          card={data.card} isModal={true} refetch={refetch} organization_id={organization_id} toaster={rest.toaster}>
        </CardVisibilityIntegration>
      }] : [],
      {
        buttonTitle: 'Preview',
        svgName: 'preview',
        component: () => <Grid overrides={{
          Grid: {
            style: () => ({
              paddingBottom: '64px',
              width: '100%',
              paddingTop: '29px',
              paddingLeft: '0px !important',
              paddingRight: '0px !important',
            }),
          },
        }} gridMaxWidth={'unset'}>
          {/* <Cell span={12}>
            <HeadingMedium style={{ marginBottom: '4px' }}>
              Card Preview
            </HeadingMedium>
            <ParagraphSmall>
              Preview the front and back side of the card
            </ParagraphSmall>
          </Cell> */}
          <Cell span={12} overrides={cellPaddingOverrides}>
            {/* <div style={{ display: 'flex' }}>
              <Cell span={12}>
                <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 224px)', padding: '24px' }}>
                  <PreviewFromPwa />
                </div>
              </Cell>
            </div> */}
            <PreviewFromPwa
              organization_id={organization_id}
              data={data}
              title={data.card.title}
              subtitle={`Auto saved at ${moment(data.card.updated_at).format('MMMM Do YYYY, h:mm a')}`}
              card_id={data.card.id}
              deck_id={deckId}
              channel_id={rest.channelId}
              add_open_in_new_tab={true}
            />
          </Cell>
        </Grid>,
        isReady: true
      }
    ];
  }

  const appBasicControls = useSelector(state => state.appBasicControls);
  // const STEPS = [
  //   {
  //     buttonTitle: 'Design',
  //     svgName: 'cover',
  //     component: () => <Grid overrides={{
  //       Grid: {
  //         style: () => ({
  //           paddingBottom: '64px',
  //           width: '100%',
  //           paddingTop: '24px',
  //           paddingLeft: '24px !important',
  //           paddingBottom: '0px !important',
  //         }),
  //       },
  //     }} gridMaxWidth={'unset'}>
  //       {/* <Cell span={12} overrides={cellPaddingOverrides}>
  //         <HeadingMedium style={{ marginBottom: '4px' }}>
  //           Card Cover
  //         </HeadingMedium>
  //         <ParagraphSmall>
  //           Design overview of card
  //         </ParagraphSmall>
  //       </Cell> */}
  //       <Cell span={12} overrides={cellPaddingOverrides}>
  //         <CardFront card={data.card} isModal={true} refetch={refetch} toaster={rest.toaster} isMainPageModal={isModal}></CardFront>
  //       </Cell>
  //     </Grid>,
  //     isReady: true
  //   },
  //   {
  //     buttonTitle: 'Content',
  //     svgName: 'builder',
  //     component: () => <Builder
  //       key="builder"
  //       noPreview={true}
  //       options={options}
  //       headerComponent={BuilderHeadComponent}
  //       cardInfo={{
  //         title: data ? data.card.title : '',
  //         sub_title: data ? data.card.sub_title : '',
  //       }}
  //       onSave={onSave}
  //       onUpdate={onUpdate}
  //       data={data}
  //       isModal={true}
  //       card_content_modules={data ? data.card.card_content_modules : []}
  //       previewContainerVisibility={previewContainerVisibility}
  //       toaster={rest.toaster}
  //       isMainPageModal={isModal}
  //     />
  //   },
  //   {
  //     buttonTitle: 'Filters',
  //     svgName: 'filters',
  //     component: () => <Grid overrides={{
  //       Grid: {
  //         style: () => ({
  //           paddingBottom: '64px',
  //           width: '100%',
  //           paddingTop: '29px',
  //           paddingLeft: '0px !important',
  //           paddingRight: '0px !important',
  //         }),
  //       },
  //     }} gridMaxWidth={'unset'}>
  //       {/* <Cell span={12}>
  //         <HeadingMedium style={{ marginBottom: '4px' }}>
  //           Card Filters
  //         </HeadingMedium>
  //         <ParagraphSmall>
  //           Add filter or specifications to the card
  //         </ParagraphSmall>
  //       </Cell> */}
  //       <Cell span={12} overrides={cellPaddingOverrides}>
  //         <FilterTags card={data.card} organization_id={organization_id} toaster={rest.toaster} />
  //       </Cell>
  //     </Grid>,
  //     isReady: true
  //   },
  //   {
  //     buttonTitle: 'Settings',
  //     svgName: 'timing',
  //     component: () => <CardVisibilityIntegration
  //       card={data.card} isModal={true} refetch={refetch} organization_id={organization_id} toaster={rest.toaster}>
  //     </CardVisibilityIntegration>
  //   },
  //   {
  //     buttonTitle: 'Preview',
  //     svgName: 'preview',
  //     component: () => <Grid overrides={{
  //       Grid: {
  //         style: () => ({
  //           paddingBottom: '64px',
  //           width: '100%',
  //           paddingTop: '29px',
  //           paddingLeft: '0px !important',
  //           paddingRight: '0px !important',
  //         }),
  //       },
  //     }} gridMaxWidth={'unset'}>
  //       {/* <Cell span={12}>
  //         <HeadingMedium style={{ marginBottom: '4px' }}>
  //           Card Preview
  //         </HeadingMedium>
  //         <ParagraphSmall>
  //           Preview the front and back side of the card
  //         </ParagraphSmall>
  //       </Cell> */}
  //       <Cell span={12} overrides={cellPaddingOverrides}>
  //         <div style={{ display: 'flex' }}>
  //           <Cell span={6}>
  //             <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 224px)' }}>
  //               <CardFrontPreview
  //                 image_src={getSrc(data.card.front_image_data || data.card.file_data)}
  //                 title={data.card.title}
  //                 sub_title={data.card.sub_title}
  //                 full_bleed={data.card.full_bleed}
  //               ></CardFrontPreview>
  //             </div>
  //           </Cell>
  //           <Cell span={6}>
  //             <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 224px)' }}>
  //               <PhoneDesign title="Card Back Preview" helpText="Preview of back side of the card"
  //               >
  //                 <PreviewElements
  //                   title={data.card.title}
  //                   cardBackData={appBasicControls.cardBackData}
  //                 ></PreviewElements>
  //               </PhoneDesign>
  //             </div>
  //           </Cell>
  //         </div>
  //       </Cell>
  //     </Grid>,
  //     isReady: true
  //   }
  // ];

  function alertUser() {
    console.log('You must select one answer as correct before continuing.')
    dispatch(
      showAlert({
        msg: 'You must select one answer as correct before continuing.',
        error: true,
      })
    );
  }

  const isToggleDisabled = React.useMemo(() => {
    if (isQuizType) {
      let isDisabled = isLoading;
      if (!isDisabled) {
        let isValid = checkIsValidToPublish(data,isQuizType);
        isDisabled = !isValid && currentPublishStatus === true ? false : !isValid;
      }
      return isDisabled;
    } else {
      return false
    }
  }, [isLoading, Deck])
  return (
    <>
      <StepsWizard
        STEPS={STEPS}
        current={current}
        onStepClick={onStepClick}
        isModal={isModal}
        version={'1'}
        onModalClose={onModalClose}
        title={data.card.title}
        subtitle={`Auto saved at ${moment(data.card.updated_at).format('MMMM Do YYYY, h:mm a')}`}
        menu={{
          isActive: true,
          // options: appBasicControls.cards.filter(el => (el.deck_id == rest.deckId && el.card_id != data.card.id)).map(el => {
          //   return {
          //     label: el.card.title,
          //     id: el.card_id
          //   }
          // }),
          options: filteredOptionMenu,
          onOptionClick: (option) => {
            const url = `/app/${organization_id}/groups/${rest.groupId}/channels/${rest.channelId}/deck/${rest.deckId}/card/${option.id}${isQuizType ? '/quiz' : ''}`
            localStorage.setItem('router_history', url);
            localStorage.setItem('deck_id', rest.deckId);
            localStorage.setItem('card_id', option.id);
            window.location.href = `${window.location.origin}${url}`;
          },
          noResultsMsg: 'No other cards in this deck'
        }}
        publishButton={{
          isActive: true,
          defaultStatus: Deck.published,
          status: currentPublishStatus,
          onTitle: 'Published',
          offTitle: 'Publish',
          isLoading: isLoading,
          disabled: isToggleDisabled,
          onChange: (status) => {
            const isValid = checkIsValidToPublish(data);
            if (!isValid && !Deck.published) {
              setCurrentPublishStatus(false);
              alertUser();
              return;
            }
            setIsLoading(true);
            let cardId = data.card.id;
            // const isQuizType = data.quiz_question_quiz_deck_by_question_id
            let updated = JSON.parse(JSON.stringify(isQuizType ? questionCards : cards)).map((el, index) => {
              if ((el.card_id || el.quiz_question_id) === cardId) {
                el.published = status;
              }
              return el;
            });
            console.log('updated', updated);
            setCurrentPublishStatus(status);
            if (isQuizType) {
              return updateQuizCard(cardId, status).then(() => {
                setIsLoading(false);
                dispatch(setQuestionCards(updated))
              });
            } else if (isCardStack) {
              return publishCardStackCard(cardStackId, status).then(() => {
                setIsLoading(false);
              })
            }else {
              if (status) return publishCard(cardId).then(() => {
                setIsLoading(false)
                dispatch(updateCards(updated));
              });
              draftCard(cardId).then(() => {
                setIsLoading(false);
                dispatch(updateCards(updated));
              });
            }
          }
        }}
      ></StepsWizard>
    </>
  )
}

export default WizardStyleBuilder;