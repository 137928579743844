import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { LabelSmall, ParagraphLarge } from 'baseui/typography';
import PhoneDesign from '../builder/phone-design';
import moment from 'moment';
import logo from '../sidebar/images/logo.png';
const style = {
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '20px'
}

const lockIconCss = {
    height: '42px',
    marginBottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative'
}

const timeCss = {
    paddingTop: '32px',
    color: 'white',
    fontSize: '60px',
    textAlign: 'center',
    fontWeight: 200
}

const dateCss = {
    paddingTop: '28px',
    color: 'white',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 300
}

const notificationCss = {
    marginTop: '48px',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '6px',
    padding: '8px',
    // height: '60px',
    width: '90%',
    left: '5%',
    position: 'relative'
}

const PushPreview = ({ feed }) => {
    let title = feed.communication.title;
    let body = feed.communication.message_body;
    let appName = 'Cardware';
    const [time, setTime] = React.useState(new Date());
    React.useEffect(() =>{
        // setInterval(() =>{setTime(new Date())}, 1000);
    }, [])
    return <Grid overlay={gridPaddingOverrides}>
        <Cell overrides={cellPaddingOverrides} span={12}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <PhoneDesign title="Push Notification" helpText="Preview of push Notification" width={450} height={880}>
                    <div
                        style={{
                            width: '100%',
                            background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)',
                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                            height: '100%'
                        }}
                    >
                        <div style={{ paddingTop: '64px' }}>
                            <img style={lockIconCss} src="https://img.icons8.com/metro/52/ffffff/lock-2.png" />
                        </div>
                        <div style={timeCss}>
                            {moment(time).format('hh:mm')}
                    </div>
                        <div style={dateCss}>
                        {moment(time).format('dddd, MMMM DD')}
                    </div>
                        <div style={notificationCss}>
                            <div style={{ width: '100%' }}>
                                <div style={{ fontSize: '16px', color: 'black', width: '50%', position: 'relative', float: 'left' }}>
                                    <img src={logo} style={{
                                        height: '11px',
                                        width: '10px',
                                        marginBottom: '0px',
                                        marginRight: '4px'
                                    }}></img>
                                    {appName}
                                </div>
                                <div style={{ fontSize: '16px', color: 'black', textAlign: 'right', position: 'relative', width: '50%', float: 'left' }}>2h ago</div>
                            </div>
                            <LabelSmall style={{ textTransform: 'capitalize', marginTop: '28px'}}>{title}</LabelSmall>
                            <ParagraphLarge style={{ marginBottom: '0px', marginTop: '0px' }}>{body}</ParagraphLarge>
                        </div>
                    </div>
                </PhoneDesign>
            </div>
        </Cell>
    </Grid>
};

export default PushPreview;