import * as React from 'react';
import { useStyletron } from 'baseui';
import { Cell, Grid } from 'baseui/layout-grid';
import { gridPaddingMarginOverrides, cellPaddingOverrides } from '../overrides';
import moment from 'moment';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import EditUser from './editUser';
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { LabelMedium } from 'baseui/typography';
import { render } from 'react-dom';


export const tableColumnKeys = {
  USER_PROFILE: 'user_profile',
  USER_NAME: 'user_name',
  USER_EMAIL: 'user_email',
  USER_CARDWARE_CONVERTED: 'user_cardware_converted',
  USER_ACTIVE: 'user_active',
  USER_SETTINGS: 'user_settings',
  USER_ACTIONS: 'user_actions'
}

const User = ({ columns = [], user, listMenuOptions, updateUser, errorInUserUpdate, isUpdateUserWithChannelLoading, channels, type, refetch, updatedUser, onAddOrUpdateModalClose, organization_id }) => {
  const [css, theme] = useStyletron();
  const [toggle, setToggle] = React.useState(user.approved || false);
  const [convertedToggle, setConvertedToggle] = React.useState(user.is_converted_to_cardware || false);
  const [onHover, setOnHover] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  React.useEffect(() => {
    console.log('----------UseEffect')
    setToggle(user.approved);
    setConvertedToggle(user.is_converted_to_cardware || false);
  }, [user])

  const onClose = () => {
    setIsEdit(false);
    onAddOrUpdateModalClose();
  };

  const cellOverride = {
    Cell: {
      style: () => ({
        paddingTop: '12px !important',
      }),
    },
  };

  const renderCell = (key) => {
    switch (key) {
      case tableColumnKeys.USER_PROFILE: return renderUserImage();
      case tableColumnKeys.USER_NAME: return renderUserName();
      case tableColumnKeys.USER_EMAIL: return renderUserEmail();
      case tableColumnKeys.USER_CARDWARE_CONVERTED: return renderConvertedUser();
      case tableColumnKeys.USER_ACTIVE: return renderUserActive();
      case tableColumnKeys.USER_SETTINGS: return renderUserSettings();
      case tableColumnKeys.USER_ACTIONS: return renderUserActions();
    }
  }

  const renderUserImage = () => {
    return (
      <div
        className={css({
          background: `url(${user.image ||
            'https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'})`,
          width: '50px',
          height: '50px',
          backgroundSize: 'cover !important',
          position: 'relative',
          float: 'left',
        })}
      ></div>
    )
  };

  const renderUserName = () => {
    return (
      <LabelMedium
        className={css({
          color: onHover ? 'white !important' : 'black !important',
          textTransform: 'capitalize',
        })}
      >
        {user.name}
      </LabelMedium>
    )
  };

  const renderUserEmail = () => {
    return (
      <LabelMedium
        className={css({
          color: onHover ? 'white !important' : 'black !important',
          textTransform: 'lowercase',
          fontWeight: 400,
        })}
      >
        {user.email}
      </LabelMedium>
    )
  }

  const renderConvertedUser = () => {
    if (user.is_connected_with_admin) {
      return <div style={{
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: '6px',
        paddingRight: '6px',
        lineHeight: '26px',
        width: 'fit-content',
        borderRadius: '4px',
        marginLeft: '-18px',
        fontSize: '14px'
      }}>ADMIN</div>;
    };
    return <div style={{
      backgroundColor: '#19AB27',
      color: 'white',
      paddingLeft: '6px',
      paddingRight: '6px',
      lineHeight: '26px',
      width: 'fit-content',
      borderRadius: '4px',
      marginLeft: '-18px',
      fontSize: '14px'
    }}>USER</div>;
  }

  const renderUserActive = () => {
    return (
      <StatefulTooltip
        content={() => (
          <div padding={'20px'}>{toggle ? 'Approved' : 'Not Approved'}</div>
        )}
        returnFocus
        autoFocus
        placement={PLACEMENT.bottom}
      >
        <div
          className={css({
            width: theme.sizing.scale1200,
            height: theme.sizing.scale1200,
            right: '16px',
            top: '16px',
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Toggle
            id="cheese-status"
            defaultChecked={toggle}
            icons={false}
            onChange={() => { }}
          />
          {/* <Checkbox
            checked={toggle}
            overrides={{
              ToggleTrack: {
                style: {
                  ...(toggle ? { background: 'green !important' } : {})
                }
              },
            }}
            checkmarkType={STYLE_TYPE.toggle}
            disabled
          ></Checkbox> */}
        </div>
      </StatefulTooltip>
    )
  }

  const renderUserSettings = () => {
    // if (user.is_connected_with_admin) {
    //   return <div style={{
    //     backgroundColor: 'black',
    //     color: 'white',
    //     paddingLeft: '6px',
    //     paddingRight: '6px',
    //     lineHeight: '26px',
    //     width: '78px',
    //     borderRadius: '4px',
    //     marginLeft: '-18px'
    //   }}>ADMIN</div>;
    // }
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: user.is_connected_with_admin ? 'not-allowed' : 'pointer', opacity: user.is_connected_with_admin ? 0.6 : 1 }}
        onClick={() => {
          if (user.is_connected_with_admin) return;
          setIsEdit(true)
        }}
      >
        <path
          d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
          fill={onHover ? 'white' : 'black'}
        />
      </svg>
    )
  }

  const renderUserActions = () => {
    return (
      <StatefulPopover
        placement={PLACEMENT.bottom}
        overrides={{
          Body: {
            style: {
              zIndex: 100,
            }
          }
        }}
        content={() => {
          if (user.is_connected_with_admin) return;
          return (
            <StatefulMenu
              items={listMenuOptions.map((el) => {
                return { label: el.display, id: el.id }
              })}
              onItemSelect={({ item }) => {
                listMenuOptions.find((el) => el.id == item.id).onClick(user);
              }}
            />
          );
        }}
        triggerType={TRIGGER_TYPE.click}
        returnFocus
        autoFocus
      >
        <svg
          width="29"
          height="7"
          viewBox="0 0 29 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: user.is_connected_with_admin ? 'not-allowed' : 'pointer', opacity: user.is_connected_with_admin ? 0.6 : 1 }}
        >
          <path
            d="M4.24992 0.0834961C2.37075 0.0834961 0.833252 1.621 0.833252 3.50016C0.833252 5.37933 2.37075 6.91683 4.24992 6.91683C6.12908 6.91683 7.66658 5.37933 7.66658 3.50016C7.66658 1.621 6.12908 0.0834961 4.24992 0.0834961ZM24.7499 0.0834961C22.8707 0.0834961 21.3333 1.621 21.3333 3.50016C21.3333 5.37933 22.8707 6.91683 24.7499 6.91683C26.6291 6.91683 28.1666 5.37933 28.1666 3.50016C28.1666 1.621 26.6291 0.0834961 24.7499 0.0834961ZM14.4999 0.0834961C12.6208 0.0834961 11.0833 1.621 11.0833 3.50016C11.0833 5.37933 12.6208 6.91683 14.4999 6.91683C16.3791 6.91683 17.9166 5.37933 17.9166 3.50016C17.9166 1.621 16.3791 0.0834961 14.4999 0.0834961Z"
            fill={onHover ? 'white' : 'black'}
          />
        </svg>
      </StatefulPopover>
    )
  }

  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      className={css({
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignContent: 'center',
        background: onHover
          ? theme.colors.primaryC
          : theme.colors.channelCardBackgroundColor,
        marginTop: '16px',
        marginBottom: '16px',
        display: 'flex',
        padding: '16px',
        color: onHover ? 'white' : 'black',
        borderRadius: '10px',
        transition: '0.8s',
      })}
    >
      <Grid overrides={gridPaddingMarginOverrides}>
        {
          columns.map(el => {
            return (
              <Cell span={el.span} overrides={cellOverride}>
                {renderCell(el.key)}
              </Cell>
            )
          })

        }
        {/* <Cell span={1}>
          <div
            className={css({
              background: `url(${user.image ||
                'https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'})`,
              width: '50px',
              height: '50px',
              backgroundSize: 'cover !important',
              position: 'relative',
              float: 'left',
            })}
          ></div>
        </Cell>

        <Cell span={2} overrides={cellOverride}>
          <LabelMedium
            className={css({
              color: onHover ? 'white !important' : 'black !important',
              textTransform: 'capitalize',
            })}
          >
            {user.name}
          </LabelMedium>
        </Cell>

        <Cell span={2.5} overrides={cellOverride}>
          <LabelMedium
            className={css({
              color: onHover ? 'white !important' : 'black !important',
              textTransform: 'lowercase',
              fontWeight: 400,
            })}
          >
            {user.email}
          </LabelMedium>
        </Cell>
        <Cell span={1} overrides={cellOverride}>
          <StatefulTooltip
            content={() => (
              <div padding={'20px'}>{convertedToggle ? 'User converted' : 'User not converted'}</div>
            )}
            returnFocus
            autoFocus
            placement={PLACEMENT.bottom}
          >
            <div
              className={css({
                width: theme.sizing.scale1200,
                height: theme.sizing.scale1200,
                right: '16px',
                top: '16px',
              })}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {/* <Toggle
                id="cheese-status"
                defaultChecked={toggle}
                icons={false}
                onChange={() => setToggle(!toggle)}
              /> 
              <Checkbox
                checked={convertedToggle}
                overrides={{
                  ToggleTrack: {
                    style: {
                      ...(convertedToggle ? { background: 'green !important' } : {})
                    }
                  },
                }}
                checkmarkType={STYLE_TYPE.toggle}
                disabled
              ></Checkbox>
            </div>
          </StatefulTooltip>
        </Cell>
        <Cell span={2} overrides={cellOverride}>
          <StatefulTooltip
            content={() => (
              <div padding={'20px'}>{toggle ? 'Approved' : 'Not Approved'}</div>
            )}
            returnFocus
            autoFocus
            placement={PLACEMENT.bottom}
          >
            <div
              className={css({
                width: theme.sizing.scale1200,
                height: theme.sizing.scale1200,
                right: '16px',
                top: '16px',
              })}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {/* <Toggle
                id="cheese-status"
                defaultChecked={toggle}
                icons={false}
                onChange={() => setToggle(!toggle)}
              /> */}
        {/* </Grid><Checkbox
                checked={toggle}
                overrides={{
                  ToggleTrack: {
                    style: {
                      ...(toggle ? { background: 'green !important' } : {})
                    }
                  },
                }}
                checkmarkType={STYLE_TYPE.toggle}
                disabled
              ></Checkbox>
            </div>
          </StatefulTooltip>
        </Cell>
        <Cell span={1} overrides={cellOverride}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // debugger;
              setIsEdit(true)
            }}
          >
            <path
              d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
              fill={onHover ? 'white' : 'black'}
            />
          </svg>
        </Cell>
        <Cell span={1} overrides={cellOverride}>
          <StatefulPopover
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  zIndex: 100,
                }
              }
            }}
            content={() => (
              <StatefulMenu
                items={listMenuOptions.map((el) => {
                  return { label: el.display, id: el.id }
                })}
                onItemSelect={({ item }) => {
                  listMenuOptions.find((el) => el.id == item.id).onClick(user);
                }}
              />
            )}
            triggerType={TRIGGER_TYPE.click}
            returnFocus
            autoFocus
          >
            <svg
              width="29"
              height="7"
              viewBox="0 0 29 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
            >
              <path
                d="M4.24992 0.0834961C2.37075 0.0834961 0.833252 1.621 0.833252 3.50016C0.833252 5.37933 2.37075 6.91683 4.24992 6.91683C6.12908 6.91683 7.66658 5.37933 7.66658 3.50016C7.66658 1.621 6.12908 0.0834961 4.24992 0.0834961ZM24.7499 0.0834961C22.8707 0.0834961 21.3333 1.621 21.3333 3.50016C21.3333 5.37933 22.8707 6.91683 24.7499 6.91683C26.6291 6.91683 28.1666 5.37933 28.1666 3.50016C28.1666 1.621 26.6291 0.0834961 24.7499 0.0834961ZM14.4999 0.0834961C12.6208 0.0834961 11.0833 1.621 11.0833 3.50016C11.0833 5.37933 12.6208 6.91683 14.4999 6.91683C16.3791 6.91683 17.9166 5.37933 17.9166 3.50016C17.9166 1.621 16.3791 0.0834961 14.4999 0.0834961Z"
                fill={onHover ? 'white' : 'black'}
              />
            </svg>
          </StatefulPopover>
        </Cell> */}
      </Grid>
      {isEdit && <EditUser
        isEditOpen={isEdit}
        updateUser={updateUser}
        isUpdateUserWithChannelLoading={isUpdateUserWithChannelLoading}
        errorInUserUpdate={errorInUserUpdate}
        appChannels={channels}
        user={user}
        type={type}
        refetch={refetch}
        onClose={onClose}
        updatedUser={updatedUser}
        organization_id={organization_id}
      >
      </EditUser>}
    </div>
  );
};

export default User;
