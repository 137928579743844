import * as React from 'react';
import { connect } from 'react-redux';
import Group from './mainPage';
import { navigate, Router } from '@reach/router';

const Groups = ({
  channels,
  organization_id,
  dispatch,
  channelGroups,
  defaultTheme,
  ...rest
}) => {
  let selectedGroupId = rest['*'];
  console.log(rest);
  let selectedGroup = channelGroups.find(el => el.id == selectedGroupId);
  if (channelGroups.length > 0 && !selectedGroup) {
    navigate(`/app/${organization_id}/groups/${channelGroups[0].id}`);
    return <></>;
  }
  return (
    <Group
      path="/app/:organization_id/groups/:groupId"
      channels={channels}
      channelGroups={channelGroups}
      selectedGroup={selectedGroup || {}}
      organization_id={organization_id}
      dispatch={dispatch}
      defaultTheme={defaultTheme}
    ></Group>
  );
};

const mapStateToProps = props => {
  let { channels, groups, theme } = props.appBasicControls;
  return {
    channels,
    channelGroups: groups,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(Groups);
