import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingXSmall,
} from 'baseui/typography';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import _ from 'lodash';
import { setIsLoading } from '../../redux/actions/appBasicControls';
import { useDispatch } from 'react-redux';

var onSaveDebounced;

export default ({ card, organization_id, toaster }) => {
  const [css, theme] = useStyletron();
  const { data: specifications } = useQuery(
    gql`
        query specifications_org {
            specifications_org(
            organization_id: "${organization_id}"
            ) {
                id
                name
                specification_values{
                name 
                id
                }
            }
        }
        `,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: specificationsByCard } = useQuery(
    gql`
        query card_specification_by_card {
            card_specification_by_card(card_id: "${card.id}") {
                specification_value_id
                specification_id
                id
            }
        }
    `,
    {
      fetchPolicy: 'network-only',
    }
  );

  // const [updateCardSpecification, { data: updatedData }] = useMutation(gql`
  //   mutation updateCardSpecification($input: [CardSpecificationUpdateInput]) {
  //     updateCardSpecification(input: $input) {
  //       id
  //     }
  //   }
  // `);

  const updateSpecMutation = gql`
    mutation updateCardSpecification($input: [CardSpecificationUpdateInput]) {
      updateCardSpecification(input: $input) {
        id
      }
    }
  `;

  // const [isLoading, setIsLoading] = React.useState(false);
  const [specs, setSpecs] = React.useState([]);
  const [cardSpecs, setCardSpecs] = React.useState([]);
  const dispatch = useDispatch();

  const onSave = (dataToSave) => {
    let input = dataToSave
      .filter(el => el.checked !== undefined)
      .map(el => {
        el.organization_id = organization_id;
        el.card_id = card.id;
        delete el.__typename;
        return el;
      });
    if (input.length == 0) return;
    const msg = 'Saving the card filters.';
    let toastKey = toaster.info(<>{msg}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    dispatch(setIsLoading(true));
    client.mutate({
      mutation: updateSpecMutation,
      variables: {
        input: input,
      },
    }).then(() => {
      toaster.update(toastKey, {
        children: <>Filters saved.</>,
      });
      setTimeout(() => {
        toaster.clear(toastKey);
      }, 1500);
    }).finally(() => {
      dispatch(setIsLoading(false));
    });
  };

  React.useEffect(() => {
    specifications && setSpecs(specifications.specifications_org);
    specificationsByCard &&
      setCardSpecs(specificationsByCard.card_specification_by_card);
  }, [specificationsByCard, specifications]);

  React.useEffect(() => {
    onSaveDebounced = onSaveDebounced ? onSaveDebounced : _.debounce(onSave, 1500);
  }, []);

  React.useEffect(() => {
    onSaveDebounced && onSaveDebounced(cardSpecs)
  }, [cardSpecs]);

  const getCheckedStatus = (specId, valueId) => {
    let isExists = cardSpecs.find(el => {
      return (
        el.specification_id == specId && el.specification_value_id == valueId
      );
    });
    if (!isExists) return false;
    if (isExists.checked === undefined) return true;
    return isExists.checked;
  };

  const markChecked = (specId, valueId, checked) => {
    let spec = cardSpecs.findIndex(el => {
      return (
        el.specification_id == specId && el.specification_value_id == valueId
      );
    });
    let cardSpecsCopy = JSON.parse(JSON.stringify(cardSpecs));
    if (spec == -1) {
      cardSpecsCopy.push({
        specification_value_id: valueId,
        specification_id: specId,
      });
      spec = cardSpecsCopy.length - 1;
    }
    cardSpecsCopy[spec].checked = checked;
    setCardSpecs(cardSpecsCopy);
  };

  return (
    <>
      <Grid overrides={gridPaddingOverrides}>
          <Cell overrides={cellPaddingOverrides} span={12}>
            <div
              className={css({
                // padding: '24px',
                marginBottom: '24px',
              })}
            >
              {!specificationsByCard || !specifications ? (
                <div
                  className={css({
                    position: 'absolute',
                    width: theme.sizing.scale1200,
                    height: theme.sizing.scale1200,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    marginTop: '52px'
                  })}
                >
                  <Spinner />
                </div>
              ) : (
                  <Grid overrides={gridPaddingOverrides}>
                    {specs.map((spec, i1) => {
                      return (
                        <div
                        style={{
                          position: 'relative',
                          float: 'left',
                          minWidth: '300px',
                          backgroundColor: theme.colors.primaryB,
                          marginRight: '24px',
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          borderRadius: '4px',
                          boxShadow: theme.lighting.shadow400,
                          marginBottom: '24px',
                        }}
                        >
                          <div
                            key={i1}
                            className={css({
                              marginBottom: '32px'
                            })}
                          >
                            <HeadingXSmall
                              style={{
                                marginBottom: '12px',
                                paddingLeft: '24px',
                                paddingRight: '24px',
                              }}
                            >
                              {spec.name}
                            </HeadingXSmall>
                            <hr style={{ height: '2px', margin: 0, marginTop: '0px', background: '#E2E2E2' }} />
                            {spec.specification_values.map((el, i2) => {
                              return (
                                <div
                                  key={i1 + '-' + i2}
                                  className={css({
                                    paddingTop: theme.sizing.scale300,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                  })}
                                >
                                  <Checkbox
                                    checked={getCheckedStatus(spec.id, el.id)}
                                    onChange={e =>
                                      markChecked(spec.id, el.id, e.target.checked)
                                    }
                                    labelPlacement={LABEL_PLACEMENT.right}
                                  >
                                    {`${el.name}`}
                                  </Checkbox>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                )}
            </div>
          </Cell>
      </Grid>
    </>
  );
};
