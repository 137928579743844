import { useEffect, useState } from 'react';
import { client as queryServerClient } from '../../apollo/client';
import gql from 'graphql-tag';

export default function useFeedBack(organization_id, feedBackPage, fromDashboard = false, setFeedbackPage) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [feedBacks, setFeedBacks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  let skip;
  let limit = fromDashboard ? 8 : 20;
  skip = (feedBackPage - 1) * limit;

  const archiveFeedback = (id) => {
    queryServerClient.query({
      query: gql`
        query archive_feedback($id: ID) {
          archive_feedback(id: $id)
        }
      `,
      variables: {id}
    }).then(() => {
      setFeedbackPage && setFeedbackPage(1);
      fetchFeedbacks();
    })
  };

  const fetchFeedbacks = () => {
    queryServerClient.query({
      query: gql` { card_feedbacks_org(organization_id: "${organization_id}", skip:${skip}, limit:${limit}) {
        id
        value
        user {
          id
          name
          image
        }
        deck{
          id
          title
        }
        card {
          id  
          title
        }
        category {
          id
          name
        }
        created_at
      } 
        card_feedbacks_org_count(organization_id: "${organization_id}")
      }
      `,
      fetchPolicy: 'network-only'
    }
    )
      .then((result) => {
        let _feedbacks = [...result.data.card_feedbacks_org];
        if (feedBackPage !== 1) {
          _feedbacks = [...feedBacks, ..._feedbacks];
        }
        setFeedBacks(_feedbacks);
        let totalavailabelefeedbacks = (result.data.card_feedbacks_org_count - (feedBackPage * limit)) <= 0 ? 0 : (result.data.card_feedbacks_org_count - (feedBackPage * limit));
        setHasMore(totalavailabelefeedbacks)
        setLoading(false)
      });
  }

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchFeedbacks();
  }, [feedBackPage]);
  return { loading, error, hasMore, feedBacks, archiveFeedback }
}
