import * as React from 'react';
import { Cell } from 'baseui/layout-grid';
import { Pagination } from "baseui/pagination";
import User from './userCard';
import UserHeader from './userHeader';
import { cellPaddingOverrides } from '../overrides';
import { ProgressBar, SIZE } from "baseui/progress-bar";

const UserList = ({
  columns,
  users,
  defaultTheme,
  listMenuOptions = [],
  updateUser,
  errorInUserUpdate,
  isUpdateUserWithChannelLoading,
  channels,
  type,
  refetch,
  updatedUser,
  onAddOrUpdateModalClose,
  organization_id,
  theme,
  totalPages, page, setPage, isDataLoading, isNoResult
}) => {
  const isInPageScope = (index) => {
    return true;
  }
  console.log(isNoResult);
  return (
    <>
      <div style={{ marginBottom: '64px' }}>
        {/* {isDataLoading && <ProgressBar
          value={10}
          infinite
          // size={SIZE.small}
          successValue={100}
        />} */}
        {users.filter((user, index) => isInPageScope(index)).map((user, index) => {
          return (
            <Cell span={12} key={index} overrides={cellPaddingOverrides}>
              {index === 0 && <UserHeader defaultTheme={defaultTheme} columns={columns} />}
              <User
                columns={columns}
                user={user}
                listMenuOptions={listMenuOptions}
                updateUser={updateUser}
                errorInUserUpdate={errorInUserUpdate}
                isUpdateUserWithChannelLoading={isUpdateUserWithChannelLoading}
                channels={channels}
                type={type}
                refetch={refetch}
                updatedUser={updatedUser}
                onAddOrUpdateModalClose={onAddOrUpdateModalClose}
                organization_id={organization_id}
              ></User>
            </Cell>
          );
        })}
      </div>
      {isNoResult && (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <UserHeader defaultTheme={defaultTheme} />
          <div>
            <p style={{ textAlign: 'center', color: defaultTheme === "light" ? 'black' : 'white' }}>No results found</p>
          </div>
        </div>
      )}
      {/* {users && users.length > 0 && ( */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', bottom: 0, width: '100%', background: theme.colors.primaryB, right: '0%' }}>
        <Pagination
          numPages={totalPages || 0}
          currentPage={page}
          onPageChange={({ nextPage }) => {
            setPage(
              Math.min(Math.max(nextPage, 1), totalPages || 0)
            );
          }}
        />
      </div>
      {/* )} */}
    </>
  );
};

export default UserList;
