import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import AreaChartElevated from '../common/graphs/AreaChartElevated';
import SunburstWithTooltips from '../common/graphs/SunburstWithTooltips';
import CustomRadialChart from '../common/graphs/RadialChart';
import BarChart from '../common/graphs/BarChart.js';
import Basic from '../common/graphs/basic';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { getTimeRange } from './utils';
import { DatePicker } from "baseui/datepicker";
import { SIZE } from "baseui/input";
import HelpQuestionMark from '../help/questionMarkHelp';

const TAB_STATUS = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
}

const GraphCard = ({ title, helpText = '', type, query = null, client, dataProcessor, has_time_period_intervals, getTpQuery, hide_tp_filters, include_testers, testers, users }) => {
  const [css, theme] = useStyletron();
  const [tab, _setTab] = React.useState(TAB_STATUS[Object.keys(TAB_STATUS)[0]]);
  const paddingLeftRightCss = {
    paddingLeft: theme.sizing.scale800,
    paddingRight: theme.sizing.scale800,
  };

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  const [startDate, setStartDate] = React.useState(
    (getTimeRange(TAB_STATUS.WEEKLY).start_time)
  );

  const [endDate, setEndDate] = React.useState(
    (getTimeRange(TAB_STATUS.WEEKLY).end_time)
  );

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);


  const setTab = (tab) => {
    _setTab(tab);
  };

  const [myData, setMyData] = React.useState([]);
  React.useEffect(() => {
    if (client && (query || getTpQuery)) {
      // setIsLoading(true);
      client.query({
        query: (has_time_period_intervals && getTpQuery) ? getTpQuery({ tab, startDate, endDate, include_testers, testers }) : query,
        variables: {
          testers: testers.map(el => el.device_id),
          include_testers
        }
      })
        .then((result) => {
          setMyData(dataProcessor(result, tab, users));
          setTimeout(() => {
            setIsLoading(false);
          }, 1000)
        })
        .catch(error => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000)
        });
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000)
    };
  }, [client, tab, startDate, endDate]);

  const graphWidth =
    windowWidth > 600 ? windowWidth / 2 - 115 : windowWidth - 100;
  const sizeData = {
    width: graphWidth > 0 ? graphWidth : null,
  };

  const ShowGraph = ({ query, client, dataProcessor, sizeData, type }) => {
    
    let request = {
      ...sizeData,
      data: myData,
      backgroundColor: 'rgb(226, 226, 226)',
      type,
      title
    };
    console.log('ShowGraph', request)
    switch (type) {
      case 'area':
        return <AreaChartElevated {...request} />;
      case 'burst':
        return <SunburstWithTooltips {...request} />;
      case 'radial':
        return <CustomRadialChart {...request} />;
      case 'horizontal-bar':
        return <BarChart {...request} chartType="horizontal" />;
      case 'vertical-bar':
        return <BarChart {...request} chartType="vertical" />;
      default:
        return <Basic {...request} />;
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        width="250px"
        height="60px"
        animation
        overrides={{
          Root: {
            style: {
              minHeight: '230px',
              width: '100%',
              overflow: 'hidden',
            },
          },
        }}
      />
    )
  };

  // console.log(rangeDate);

  if (myData.length == 0) return <></>;
  return (
    <>
      <div className="channelsDropDown" style={{ width: '100%' }}>
        <div
          className={css({
            position: 'relative',
            background: 'rgb(226, 226, 226)',
            border: '1px solid hsla(0, 0%, 0%, 0.2)',
            borderRadius: '5px',
            minHeight: '230px',
            overflow: 'hidden',
          })}
        >
          <div
            className={css({
              position: 'relative',
              ...paddingLeftRightCss,
              width: '100%',
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ padding: '20px', fontSize: '14px',  display: 'flex', alignItems: 'end'}}>
              <span>{title} </span>
              <HelpQuestionMark text={helpText} embedCss={{ marginLeft: '10px'}} />
            </div> 
              {has_time_period_intervals && <div style={{ display: 'flex', flexDirection: 'row' }}>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '16px', justifyContent: 'center', fontSize: '14px'}}>
                  Date Range
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '4px' }}>
                  <DatePicker
                    value={new Date(startDate)}
                    onChange={({ date }) => setStartDate(date.toISOString())}
                    size={SIZE.mini}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '4px', justifyContent: 'center', fontSize: '14px'}}>
                  -
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '16px' }}>
                  <DatePicker
                    value={new Date(endDate)}
                    onChange={({ date }) => setEndDate(date.toISOString())}
                    size={SIZE.mini}
                  />
                </div>


                {!hide_tp_filters && <StatefulButtonGroup
                  mode={MODE.radio}
                  initialState={{ selected: 0 }}
                >
                  {Object.keys(TAB_STATUS).map(el => {
                    return (
                      <Button onClick={() => setTab(TAB_STATUS[el])}>
                        {TAB_STATUS[el]}
                      </Button>
                    );
                  })}
                </StatefulButtonGroup>}
              </div>}
            </div>
            <hr />
            <ShowGraph
              query={query}
              client={client}
              dataProcessor={dataProcessor}
              sizeData={sizeData}
              type={type}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphCard;
