import * as React from 'react';
import { connect } from 'react-redux';
import Category from './category';

const Categories = ({
  appContentScopeId,
  tabFilter,
  category,
  theme,
  refreshQuizQuestions,
  home_component_app_content_scopes,
  ...props
}) => {
  
  const [filteredCategory, setCategory] = React.useState([]);
  const [home_component_app_content_scopes_filtered, setHomeComponents] = React.useState([]);

  React.useEffect(() => {
    setCategory(category
      .filter(el => el.app_content_scope_id === appContentScopeId)
      .filter((el) => {
          if (tabFilter === 'all') return el.category.archived != true;
          if (tabFilter === 'published') return el.category.published == true;
          return true;
      })
      .sort((a, b) => {
        let aPosition = a.position;
        let bPosition = b.position;
        if (a.isNew) aPosition = -1;
        return aPosition - bPosition;
      }));
      setHomeComponents(
        home_component_app_content_scopes
        .filter((el) => {
          if (tabFilter == 'all') return el.home_component.archived != true;
          if (tabFilter == 'published') return el.home_component.published == true;
          if (tabFilter == 'archived') return el.home_component.archived == true;
          return true;
        })
      );
  }, [category, tabFilter, appContentScopeId, home_component_app_content_scopes])

  if (!category) return <></>;
  
  return (
    <Category
      {...props}
      tabFilter={tabFilter}
      categoryList={filteredCategory}
      home_component_app_content_scopes={home_component_app_content_scopes_filtered}
      app_content_scope_id={appContentScopeId}
      refreshQuizQuestions={refreshQuizQuestions}
      defaultTheme={theme}
    />
  );
};

const mapStateToProps = props => {
  const { category, cards, decks, theme, isLoading, searchText, jwPlayerData, libraries } = props.appBasicControls;
  return { category, decks, theme, cards, isLoading, searchText, jwPlayerData, libraries };
};
export default connect(mapStateToProps)(Categories);
