import * as React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'baseui/skeleton'
import RightSidePageHeader from '../pageHeaders';
import Pass from './pass';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';

const TravelPass = ({ organization, user, tab_name }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [admin, setAdmin] = React.useState(null);

  React.useEffect(() => {
    client.query({
      query: gql`
        query me {
          me {
            id
            name
            email
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({data}) => {
      if (data.me) {
        setAdmin(data.me);
      }
    });
  }, [user]);

  const LoadingBar = () => {
    const TAB_STATUS = {
      GENERAL: 'Passes',
    };
    const pageTitlesAndDescriptions = {
      [TAB_STATUS.GENERAL]: {
        title: 'Passes',
        description: 'Manage users travel passes'
      },
    }
    return (
      <div style={{ width: '100%', }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <RightSidePageHeader
              icon={null}
              title={pageTitlesAndDescriptions[TAB_STATUS.GENERAL].title}
              headerDescription={pageTitlesAndDescriptions[TAB_STATUS.GENERAL].description}
            />
          </div>
          <Skeleton
            width="500px"
            height="35px"
            animation
            overrides={{
              Root: {
                style: {
                  marginRight: '60px',
                  marginTop: '1rem'
                },
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(5).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(3).fill('').map(item => <Skeleton
                  width="500px"
                  height="145px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500)
  })

  if (isLoading || !organization) {
    return <LoadingBar />
  }

  return (
    <Pass organization={organization} user={user} admin={admin} tab={tab_name}></Pass>
  );
}

const mapStateToProps = props => {
  let { organization, user } = props.appBasicControls;
  return {
    organization,
    user
  };
};
export default connect(mapStateToProps)(TravelPass);