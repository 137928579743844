import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { ButtonGroup } from 'baseui/button-group';
import Button from './full-size-button';
import { cellPaddingOverrides } from '../overrides';
import { useStyletron } from 'baseui';

const Step1 = ({ messageType, setMessageType }) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <Grid overrides={{
        Grid: {
          style: () => ({
            paddingTop: '0px',
            paddingBottom: '32px',
            width: '100%',
            paddingLeft: '0px',
            paddingTop: '29px'
          }),
        },
      }} gridMaxWidth={'unset'}>
        {/* <Cell span={12} overrides={cellPaddingOverrides} span={10}>
          <HeadingMedium style={{ marginBottom: '4px' }}>
            Select type of message?
          </HeadingMedium>
          <ParagraphSmall>
            This is the method in which your message is delivered.
            Choose one or more types that matches best for your
            audience.
          </ParagraphSmall>
        </Cell> */}
        <Cell span={12} overrides={cellPaddingOverrides} span={10}>
          <div
            className={css({
              // padding: '24px',
              // background: theme.colors.primaryB,
              marginBottom: '24px',
              // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            })}
          >
            <ButtonGroup
              mode="checkbox"
              selected={messageType}
              onClick={(event, index) => {
                if (!messageType.includes(index)) {
                  setMessageType([...messageType, index]);
                } else {
                  setMessageType(messageType.filter(value => value !== index));
                }
              }}
              overrides={{
                Root: {
                  style: { display: 'block' }
                },
              }}
            >
              <Button startEnhancer={(p) => <svg width="32" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9374 6.7L17.8958 7.7C19.7708 9.5 19.7708 12.3 17.8958 14.2L18.9374 15.2C21.5416 12.9 21.5416 9.1 18.9374 6.7ZM16.7499 8.8L15.7083 9.8C16.2291 10.5 16.2291 11.4 15.7083 12.1L16.7499 13.1C17.9999 11.9 17.9999 10.1 16.7499 8.8ZM12.5833 0H2.16659C1.02075 0 0.083252 0.9 0.083252 2V20C0.083252 21.1 1.02075 22 2.16659 22H12.5833C13.7291 22 14.6666 21.1 14.6666 20V2C14.6666 0.9 13.7291 0 12.5833 0ZM12.5833 19H2.16659V3H12.5833V19Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send message as a Push Notification</Button>
              <Button startEnhancer={(p) => <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H2.90909V20H0V0ZM5.81818 0H8.72727V20H5.81818V0ZM30.5455 0H13.0909C12.2909 0 11.6364 0.642857 11.6364 1.42857V18.5714C11.6364 19.3571 12.2909 20 13.0909 20H30.5455C31.3455 20 32 19.3571 32 18.5714V1.42857C32 0.642857 31.3455 0 30.5455 0ZM14.5455 17.1429L18.1818 12.6429L20.7855 15.7143L24.4218 11.1143L29.0909 17.1429H14.5455Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send message as a Newsroom Article</Button>
              <Button startEnhancer={(p) => <svg width="32" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6667 0.333252H2.33341C1.14175 0.333252 0.177581 1.30825 0.177581 2.49992L0.166748 15.4999C0.166748 16.6916 1.14175 17.6666 2.33341 17.6666H19.6667C20.8584 17.6666 21.8334 16.6916 21.8334 15.4999V2.49992C21.8334 1.30825 20.8584 0.333252 19.6667 0.333252ZM19.6667 4.66659L11.0001 10.0833L2.33341 4.66659V2.49992L11.0001 7.91659L19.6667 2.49992V4.66659Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send message as an email message</Button>
            </ButtonGroup>
          </div>
        </Cell>
      </Grid>
    </>
  )
}


export default Step1;