import React from 'react';
import BaseClass from './base';

class InputComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render() {
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 'normal'
          }}
        >
          {this.state.value || ''}
        </h5>
      </>
    );
  }
}

export default InputComponent;
