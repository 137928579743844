import * as React from 'react';
import {
  LabelMedium,
  HeadingSmall
} from 'baseui/typography';
// import { Button } from 'baseui/Button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Input } from "baseui/input";
import { addGroup, addChannel } from '../quries';
import { client } from '../apollo/client';
import Vimeo from '@u-wave/react-vimeo';
import { navigate } from 'gatsby';
import {setSelectedChannel} from '../redux/actions/appBasicControls';
import { debounce } from 'lodash';
import { KIND as ButtonKind } from "baseui/button";

const isOnBoardEnabled = process.env.ON_BOARD_ENABLED;

const ModalOverrides = {
  Dialog: {
    style: {
      minWidth: '40vw',
      // minHeight: 'vh',
      borderRadius: '4px',
      paddingBottom: '28px'
    },
  },
  Backdrop: {
    style: ({ $theme }) => ({
      backdropFilter: $theme.modalBackDropFilter,
    }),
  },
  Close: {
    style: ({ $theme }) => ({
      color: 'white',
      position: 'absolute',
      right: '10px',
      top: '10px'
    }),
  },
  Root: {
    style: {
      zIndex: 3,
      background: 'transparent',
      borderRadius: '8px'
    }
  },
};


const OnboardNewOrganization = ({ channels, groups, organization, isDataLoading, dispatch }) => {
  const [isOnboardActive, setIsOnBoardActive] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [groupName, setGroupName] = React.useState('Group Name');
  const [createdGroup, setCreatedGroup] = React.useState(null);
  const [createdChannel, setCreatedChannel] = React.useState(null);
  const [channelName, setChannelName] = React.useState('Channel Name');

  const debouncedSet = React.useCallback(
		debounce(() => {
      if (groups.length == 0) {
        setIsOnBoardActive(true);
        return;
      };
  
      if (groups.length > 0 && channels.length == 0) {
        setGroupName(groups[0].name);
        setStep(2);
        setIsOnBoardActive(true);
        return;
      }
    }, 1000),
		[], // will be created only once initially
  );
  
  const canOnBoardActive = () => {
    const isGroupExist = groups && Array.isArray(groups) && groups !== null ? groups.length > 0 : false;
    const isChannelExist = channels && Array.isArray(channels) && channels !== null ? channels.length > 0 : false;
    // debugger
    if (!isDataLoading && !isGroupExist && !isChannelExist && isOnBoardEnabled) {
        return true;
    }
    return false;
  }
  React.useEffect(() => {
    // if (groups.length == 0) {
    //   debouncedSet(true);
    //   return;
    // };

    // if (groups.length > 0 && channels.length == 0) {
    //   setGroupName(groups[0].name);
    //   setStep(2);
    //   debouncedSet(true);
    //   return;
    // }

    // if (groups.length > 0 && channels.length > 0) {
    //   debouncedSet(false);
    //   return;
    // }
    // if (!isOnBoardEnabled) return;

    const isSkipped = sessionStorage.getItem('__onBoardSkipped');
    // console.log(isSkipped, 'isSkipped');
    if (isSkipped) {
      return;
    };

    const isOnBoardActive = canOnBoardActive();
    if (!isOnBoardActive) return;
    debouncedSet();

  }, [channels, groups]);

  console.log('ONBOARD NEW ORGANIZATION');
  console.log('ONBOARD isOnboardActive', isOnboardActive, step, groups.length, channels.length);

  const createGroup = () => {
    if (!groupName) return;
    setIsLoading(true);
    client.mutate({
      mutation: addGroup,
      variables: {
        position: 0,
        name: groupName,
        organization_id: organization.id
      }
    }).then((result) => {
      setCreatedGroup(result.data.createAppContentScopeGroup)
      setStep(2);
      setIsLoading(false);
    })
  };


  const createChannel = ({}) => {
    if (!channelName) return;
    setIsLoading(true);
    client.mutate({
      mutation: addChannel,
      variables: {
        position: 0,
        name: channelName,
        organization_id: organization.id,
        app_content_scope_group_id: createdGroup.id
      }
    }).then((result) => {
      setCreatedChannel(result.data.createAppContentScope)
      setStep(3);
      setIsLoading(false)
    })
  }

  const onReady = () => {
    dispatch(setSelectedChannel(channels[0]));
    navigate(`/app/${organization.id}/groups/${createdGroup.id}/channels/${createdChannel.id}`);
    setIsOnBoardActive(false);
  };

  return <>
      {/* // STEP 1 */}
    <Modal
      isOpen={isOnboardActive && step === 1}
      closeable={false}
      overrides={ModalOverrides}
    >
      <ModalBody>
        <div style={{
          display: 'flex',
          marginTop: '36px'
        }}>
          <HeadingSmall>In order to get started with Cardware you need to first create a Group</HeadingSmall>
        </div>

        <div style={{
          display: 'flex',
          marginTop: '0px'
        }}>
          <LabelMedium>What is the name of your Group?</LabelMedium>
        </div>

        <div style={{
          marginTop: '16px'
        }}>
          <Input
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
            placeholder="Controlled Input"
            clearOnEscape
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton isLoading={isLoading} onClick={() => {
          sessionStorage.setItem('__onBoardSkipped', true);
          setIsOnBoardActive(false);
        }} kind={ButtonKind.tertiary}>Skip Tour</ModalButton>
        <ModalButton isLoading={isLoading} onClick={createGroup}>Next</ModalButton>
      </ModalFooter>
    </Modal>
    {/* // STEP 2 */}
    <Modal
      isOpen={isOnboardActive && step === 2}
      closeable={false}
      overrides={ModalOverrides}
    >
      <ModalBody>
        <div style={{
          display: 'flex',
          marginTop: '36px'
        }}>
          <HeadingSmall>Awesome! In order to get started with creating content for your <span style={{color: 'green'}}>{groupName}</span> group you need to first create a Channel</HeadingSmall>
        </div>

        <div style={{
          display: 'flex',
          marginTop: '0px'
        }}>
          <LabelMedium>What is the name of your Channel?</LabelMedium>
        </div>

        <div style={{
          marginTop: '16px'
        }}>
          <Input
            value={channelName}
            onChange={e => setChannelName(e.target.value)}
            placeholder="Controlled Input"
            clearOnEscape
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton isLoading={isLoading} onClick={createChannel}>Next</ModalButton>
      </ModalFooter>
    </Modal>
    {/* // STEP 3 */}
    <Modal
      isOpen={isOnboardActive && step === 3}
      closeable={false}
      overrides={ModalOverrides}
    >
      <ModalBody>
        <div style={{
          display: 'flex',
          marginTop: '36px'
        }}>
          <HeadingSmall>Congratulations you have now created your first Group and Channel. To add more click the menu icon in the top left</HeadingSmall>
        </div>

        <div style={{
          display: 'flex',
          marginTop: '0px'
        }}>
          <LabelMedium>Want to get started with creating more content in Cardware? Watch this short video below to learn some the basics from our Product Team.</LabelMedium>
        </div>    
        <div style={{
          marginTop: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {/* <ReactPlayer url={'https://vimeo.com/76979871'} width="100%" height="196px"></ReactPlayer> */}
          <Vimeo 
          // ref={videoPlayerRef}
          autoplay={false}
          // onPlay={e => {
          //   setPaused(false)
          //   setShowPoster(false)
          // }}
          // autopause={paused}
          video={'https://vimeo.com/549285217'}
          height={400}
          // width={'50vw'}
          // loop={does_loop} 
          controls 
          // responsive={!paused || isMobile}
        />
        </div>    
      </ModalBody>
      <ModalFooter>
        <ModalButton isLoading={isLoading} onClick={onReady}>I'm ready to start creating content</ModalButton>
      </ModalFooter>
    </Modal>
  </>;
};

const mapStateToProps = props => {
  const { organization, groups, channels } = props.appBasicControls;
  return { organization, groups, channels };
};

const enhancer = compose(
  connect(mapStateToProps),
  React.memo
)
export default enhancer(OnboardNewOrganization);