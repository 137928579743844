import * as React from 'react';
import BaseClass from './base';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import { Button, SHAPE } from 'baseui/button';
import { Textarea } from 'baseui/textarea';
import { Input } from "baseui/input";
import {Select} from 'baseui/select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
// import { Select, TYPE } from 'baseui/select';
// import { FileUploader } from 'baseui/file-uploader';
import AddContentButton from '../../addContentButton';
import { sourceByType } from '../../../util';
// import { getStoreKeyName } from '@apollo/client/utilities';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
// import { Accordion, Panel } from 'baseui/accordion';
import ImageUploadCXL from '../../imageUploaderCXL';
import { cardBackImageGuideline } from '../../help/imageSizeGuidelines.json';
import { getTimeDisplayFormat, isValidHttpUrl } from '../builderUtils/util';
import VideoListComponent from '../../common/VideoComponent'

import styled from '@emotion/styled';

import './videoGallery.css';

import svgIcons from './images';
import CustomInput from '../../common/input';
import _ from 'lodash';

const HeaderTitle = styled.h5`
  color: ${props => props.theme.colors.primaryA};
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const VideoSectionContainer = styled.div`
  margin-top: 11px;
`

const VideoInput = styled(Input)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const BtnImageSvg = styled.svg`
  position: relative;
  float: right;
  margin-right: 18px;
  cursor: pointer;
  margin-top: 6px;
`;

const VideoActionButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-90%);
`;

const VideoSrcOptionImg = styled.img`
  height: 26px;
  margin-bottom: 0;
`;

const VideoToggleContainer = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddAdditionalContainer = styled.div`
  width: 90%;
  max-width: 405px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 8%;
  display: flex;
  justify-content: center;
`;

const videoSrcType = [
  { label: 'Vimeo', id: 'Vimeo', icon: svgIcons.VimeoIcon },
  { label: 'Youtube', id: 'Youtube', icon: svgIcons.YoutubeIcon },
  { label: 'JWPlayer', id: 'JWPlayer', icon: svgIcons.JWPlyaerIcon },
  { label: 'URL', id: 'Url', icon: svgIcons.CloudUploadIcon },
];

const getSrc = data => {
  try {
    if (!data) return data;
    return sourceByType(data);
  } catch (e) {
    return null;
  }
};

const getPreviewImage = (file, dbImage) => {
  if (file) {
    return URL.createObjectURL(file[0]);
  };
  return getSrc(dbImage);
};

const svgIconsCss = {
  position: 'relative',
  float: 'right',
  marginRight: '18px',
  cursor: 'pointer',
};

const buttonStyle = (isSelected) => ({
  width: '116px',
  borderRadius: '20px',
  paddingBottom: '8px',
  paddingTop: '8px',
  backroundColor: isSelected == true ? '#FFFFFF !important' : 'transparent !important',
  border: isSelected == true ? '3px solid #EEEEEE !important' : '0px !important',
})

const VideoDurationInfoContainer = styled.div`
  display: flex;
`

const VideoDurationInfoInnerContainer = styled.div`
  flex: 1;
  align-items: center;
`

class VideoGalleryComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      progressAmount: 0,
      options: [],
      isLoading: false,
      isVideoSelected: false
    };
    this.setStateInternal = this.setStateInternal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.getMediaList();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  setStateInternal({ value, key, index }) {
    let { value: options } = this.state;
    // options[index][key] = value;
    this.setState({
      value: options.map((x, idx) => {
        if (idx === index) {
          return { ...x, [key]: value };
        }
        return x;
      }),
    });
  }

  onClick() {
    let options = this.state.value || [];
    options = options.map(el => ({ ...el }));
    options.push({
      cover_photo_data: '',
      cover_photo: '',
      title: '',
      video_url: '',
      video_type: '',
      loop_times: 0,
      description: '',
      duration: 0,
      does_loop: false,
      position: options.length,
    });
    this.setState({ value: options }, () => {
      const lastElement = `position_${this.state.value.length - 1}`;
      this.scrollToElemet(`#${lastElement}`);
    });
  }


  onMoveUp = (item) => {
    if (item.position - 1 < 0) return;
    let temp = this.state.value.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position - 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      value: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position - 1);
      this.scrollToElemet(ele)
    })
  }

  onMoveDowm = (item) => {
    let tempOptions = [...this.state.value] || []
    if (item.position + 1 >= tempOptions.length) return;
    let temp = tempOptions.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position + 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      value: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position + 1);
      this.scrollToElemet(ele)
    })
  }

  onDeleteItem = (item) => {
    let temp = this.state.value.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      value: updatedTemp
    })
  }


  scrollToElemet = (elementName) => {
    const lastContainer = document.querySelector(elementName);
    const scrollContainer = document.querySelector('.video_gallery_container');
    scrollContainer.scrollTo({
      top: lastContainer.offsetTop,
      behavior: 'auto'
    });
  }

  onImageDrop = (acceptedFiles, rejectedFiles, index) =>
    this.setStateInternal({
      value: acceptedFiles,
      key: 'cover_photo',
      index,
    })
  onImageDelete = (index) => {
    this.setStateInternal({
      value: null,
      key: 'cover_photo',
      index,
    })
    this.setStateInternal({
      value: null,
      key: 'cover_photo_data',
      index,
    })
  }

  /////////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////////////////////
  renderUpButton = (onBtnClick) => {
    const { css, theme } = this.props;
    return (
      <BtnImageSvg
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onBtnClick}
      >
        <path
          d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
          fill={theme.colors.mono100}
        />
      </BtnImageSvg >
    )
  }

  renderDowmButton = (onBtnClick) => {
    const { css, theme } = this.props;
    return (
      <BtnImageSvg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onBtnClick}
      >
        <path
          d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
          fill={theme.colors.mono100}
        />
      </BtnImageSvg>
    )
  }

  renderDeleteButton = (onBtnClick) => {
    const { css, theme } = this.props;
    return (
      <BtnImageSvg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onBtnClick}
      >
        <path
          d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
          fill={theme.colors.mono100}
        />
      </BtnImageSvg>
    );
  }

  getMediaList() {
    const jwPlayerData = this.props.jwPlayerData;
    if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
        const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
        const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/media?page_length=1000`;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
        };
        this.setState({ isLoading: true });
        fetch(apiUrl, requestOptions)
            .then(response => response.json())
            .then(async data => {
                if (data && data.media) {
                    const playerData = data.media.filter(i => i.status == 'ready')
                    let nextOptions = []
                    console.log(playerData);
                    for (let i = 0; i < playerData.length; i++) {
                      const item = playerData[i]
                      const apiUrl = "https://cdn.jwplayer.com/v2/media/"+item.id;
                      // const videoResult = await fetch(apiUrl)
                      // const videoData = await videoResult.json()
                      const url = '';
                      nextOptions.push({ id: item.id, label: item.metadata.title, description: item.metadata.description, url: url, duration: item.duration || null, tags: item.metadata.tags})
                    }
                    this.setState({ options: nextOptions });
                    this.setState({ isLoading: false });
                }
            })
            .catch(e => {
                this.setState({ options: []});
                this.setState({ isLoading: false });
            })
    }
}


  renderBtnsContainer = (item, index) => {
    const onClickUp = () => {
      this.onMoveUp(item)
    }

    const onClickDown = () => {
      this.onMoveDowm(item)
    }

    const onClickDelete = () => {
      this.onDeleteItem(item)
    }

    const { css } = this.props;

    return (
      <div className='video_item_btns_container'>
        <VideoActionButtonContainer>
          {this.renderDowmButton(onClickDown)}
          {this.renderUpButton(onClickUp)}
          {this.renderDeleteButton(onClickDelete)}
        </VideoActionButtonContainer>
      </div>
    )
  }

  renderTitle = (title) => {
    const { theme } = this.props;
    return (
      <HeaderTitle theme={theme}>
        {title}
      </HeaderTitle>
    )
  }

  renderVideoImage = (item = {}, index = 0) => {
    const onDrop = (acceptedFiles, rejectedFiles) => {
      this.onImageDrop(acceptedFiles, rejectedFiles, index)
    }

    const onDelete = () => {
      this.onImageDelete(index)
    }
    const coverUrl = getPreviewImage(item.cover_photo, item.cover_photo_data) || [];
    return (
      <>
        {this.renderTitle('Video Cover Photo')}
        <ImageUploadCXL
          showHint={false}
          previewUrls={coverUrl}
          isMultiple={false}
          style={{ background: 'transparent' }}
          onDrop={onDrop}
          onImageDelete={onDelete}
          showFullBleedSettings={false}
          imageSize={cardBackImageGuideline.size}
          imageSizeGuidelineURL={cardBackImageGuideline.url}
        ></ImageUploadCXL>

      </>
    )
  }

  renderSrcOption = (index, logo, isSelected, onClick) => {
    const { css } = this.props
    const buttonOverride = {
      BaseButton: {
        style: () => ({
          width: '116px',
          borderRadius: '20px',
          paddingBottom: '8px',
          paddingTop: '8px',
          backgroundColor: isSelected == true ? '#FFFFFF !important' : 'transparent !important',
          border: isSelected == true ? '3px solid #EEEEEE !important' : '0px !important',
        })
      }
    }
    return (
      <Button
        shape={SHAPE.pill}
        overrides={buttonOverride}
        onClick={onClick}
      >
        <VideoSrcOptionImg
          src={logo}
        />
      </Button>
    )
  }

  renderVideoTypeOptions = (videoType, index) => {
    return (
      <div className='video_source_container'>
        {this.renderTitle('Video Source')}
        <StatefulButtonGroup
          mode={MODE.radio}
          overrides={{
            Root: {
              style: () => ({
                justifyContent: 'space-between'
              })
            }
          }}
          initialState={{ selected: 0 }}
        >
          {
            videoSrcType.map((item, idx) => {
              const onChange = () => {
                this.setStateInternal({
                  value: item.id,
                  key: 'video_type',
                  index,
                })
              }
              const isSelected = videoType === item.id;
              return this.renderSrcOption(idx, item.icon, isSelected, onChange)
            })
          }
        </StatefulButtonGroup>
      </div>
    )
  }

  renderVideoUrl = (videoUrl, index) => {
    const onChange = e =>
      this.setStateInternal({
        value: e.target.value,
        key: 'video_url',
        index,
      })
    return (
      <VideoSectionContainer>
        {this.renderTitle('Video URL')}
        {(this.state.isVideoSelected && this.state.value[index].video_type == 'JWPlayer' || this.state.value[index].video_type == 'jwplayer') && (
          <VideoListComponent
              data={this.state.options || []}
              onClose={() => this.setState({isVideoSelected : false})}
              onSelectVideo={({id, url, tags}) => {
                // console.log(value);
                // console.log(this.state.selectedVideoIndex, index);
                // console.log(this.state.options);
                this.setStateInternal({value: id, key: 'video_url', index: this.state.selectedVideoIndex, isVideoSelected: false})
              }}
          />
        )}
    
        <CustomInput
          value={videoUrl}
          onChange={onChange}
          placeholder={'Enter Video URL'}
          error={videoUrl && !isValidHttpUrl(videoUrl)}
          clearOnEscape
          maxLength={125}
        />
        {(this.state.value[index].video_type == 'JWPlayer' || this.state.value[index].video_type == 'jwplayer') && (
          <Button onClick={e => this.setState({isVideoSelected : true, selectedVideoIndex: index})} style={{ marginTop: '1rem'}}>Select video</Button>
        )}
        {/* <VideoInput
          value={videoUrl}
          onChange={onChange}
          placeholder={'Enter Video URL'}
          error={videoUrl && !isValidHttpUrl(videoUrl)}
          clearOnEscape
        /> */}
      </VideoSectionContainer>
    )
  }

  renderVideoTitle = (videoTitle, index) => {
    const onChange = e =>
      this.setStateInternal({
        value: e.target.value,
        key: 'title',
        index,
      })
    return (
      <VideoSectionContainer>
        {this.renderTitle('Video Title')}
        <CustomInput
          value={videoTitle}
          onChange={onChange}
          placeholder={'Enter Video Title'}
          clearOnEscape
          maxLength={125}
        />
      
      </VideoSectionContainer>
    )
  }

  renderVideoDescription = (videoDescription, index) => {
    const onChange = e =>
      this.setStateInternal({
        value: e.target.value,
        key: 'description',
        index,
      })

    return (
      <VideoSectionContainer>
        {this.renderTitle('Video Description')}
        <Textarea
          value={videoDescription}
          onChange={onChange}
          placeholder={'Enter Vide Description'}
          clearOnEscape
        />
      </VideoSectionContainer>
    )
  }

  renderDurationContainer = (duration, index) => {
    const { css, theme } = this.props;
    const onChange = e => {
      const minArr = e.target.value;
      const minInMilli = parseInt(minArr) * 60;
      this.setStateInternal({
        value: 1 * minInMilli,
        key: 'duration',
        index,
      })
    }
    const durationInMins = duration / 60;
    return (
      <VideoDurationInfoInnerContainer>
        <HeaderTitle theme={theme}
          className={css({
            textAlign: 'center'
          })}
        >
          Video Duration(Mins)
        </HeaderTitle>
        <VideoInput
          value={durationInMins}
          onChange={onChange}
          placeholder={'Enter Duration'}
          clearOnEscape
          type="number"
        />
      </VideoDurationInfoInnerContainer>
    )
  }

  renderIfVideoLoop = (doesLoop, index) => {
    const { css, theme } = this.props;
    const onChange = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.setStateInternal({
        value: e.target.checked,
        key: 'does_loop',
        index,
      })
    }
    const onClick = e => {
      e.preventDefault();
      e.stopPropagation();
    }
    return (
      <VideoDurationInfoInnerContainer>
        <HeaderTitle
          theme={theme}
          className={css({
            textAlign: 'center'
          })}
        >
          Video Loop
        </HeaderTitle>
        <VideoToggleContainer>
          <Toggle
            id="video-loop-status"
            checked={doesLoop}
            icons={false}
            onClick={onClick}
            onChange={onChange}
          // overrides={{
          //   Root: {}
          // }}
          />
        </VideoToggleContainer>

      </VideoDurationInfoInnerContainer>
    )
  }

  renderNumberLoopContainer = (loopTimes, index) => {
    const { css, theme } = this.props;
    const onChange = e =>
      this.setStateInternal({
        value: 1 * e.target.value,
        key: 'loop_times',
        index,
      })

    return (
      <VideoDurationInfoInnerContainer>
        <HeaderTitle
          theme={theme}
          className={css({
            textAlign: 'center'
          })}
        >
          Number of Loops
        </HeaderTitle>
        <VideoInput
          value={loopTimes}
          onChange={onChange}
          placeholder={'Enter Loop Time'}
          clearOnEscape
          type="number"
        />
      </VideoDurationInfoInnerContainer>
    )
  }

  renderVideoDurationInfo = (item, index) => {
    const { css, theme } = this.props;
    return (
      <VideoDurationInfoContainer>
        {this.renderDurationContainer(item.duration, index)}
        {this.renderIfVideoLoop(item.does_loop, index)}
        {this.renderNumberLoopContainer(item.loop_times, index)}
      </VideoDurationInfoContainer>
    )
  }

  renderVideoList = (item, index) => {
    return (
      <div id={'position_' + index} key={'position_' + index} className='video_list_container'>
        <div className='video_item_container'>
          {this.renderBtnsContainer(item, index)}
          <div className='video_item_innner_container'>
            {this.renderVideoImage(item, index)}
            {this.renderVideoTypeOptions(item.video_type, index)}
            {this.renderVideoUrl(item.video_url, index)}
            {(this.state.value[index].video_type != 'JWPlayer' || this.state.value[index].video_type != 'jwplayer') && this.renderVideoTitle(item.video_title || item.title, index)}
            {this.renderVideoDescription(item.description, index)}
            {this.renderVideoDurationInfo(item, index)}
          </div>
        </div>
      </div>
    )
  }

  renderAddVideoButton = () => {
    const { css } = this.props;
    const btnOverrides = {
      BaseButton: {
        style: {
          color: '#4A4CA0',
          maxWidth: '356px',
          width: '100%',
          border: '3px solid #4D4B45',
          boxSizing: 'border-box',
          boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.17)',
          borderRadius: '20px',
          background: 'white',
          ':hover': {
            background: '#4A4CA0',
            color: 'white'
          }
        }
      }
    };

    const startEnhancer = () => (
      <img
        src={svgIcons.AddGradientIcon}
        className={css({
          width: '20px',
          height: '20px',
          marginBottom: '0'
        })}
      />
    )
    return (
      <AddAdditionalContainer>
        <Button
          onClick={this.onClick}
          overrides={btnOverrides}
          startEnhancer={startEnhancer}
        >
          Add additional video to playlist
        </Button>
      </AddAdditionalContainer>
    )
  }

  render() {
    const options = this.state.value || [];
    // if (this.state.options.length == 0) this.getMediaList()
    return (
      <>
        <div className='video_gallery_container'>
          {
            options && options.map(this.renderVideoList)
          }

          {this.renderAddVideoButton()}
          {/* <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
          }}
        >
          Videos
        </h5>
        <Accordion
          onChange={({ expanded }) => console.log(expanded)}
          overrides={{
            Root: {
              style: { marginLeft: '0px !important' },
            },
            Content: {
              style: { marginLeft: '0px !important' },
            },
          }}
        >
          {options.map((el, index) => {
            const selectionValue = [
              { label: 'Youtube', id: 'Youtube' },
              { label: 'Vimeo', id: 'Vimeo' },
              { label: 'URL', id: 'Url' },
            ].find(el1 => el1.id == el.video_type);
            return (
              <Panel title={el.title || `Video ${index + 1}`} key={index}>
                <div>
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                    }}
                  >
                    {'Video Title'}
                  </h5>
                  <Input
                    value={el.title}
                    onChange={e =>
                      this.setStateInternal({
                        value: e.target.value,
                        key: 'title',
                        index,
                      })
                    }
                    placeholder={'Enter Vide Title'}
                    clearOnEscape
                  />

                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Video Description'}
                  </h5>
                  <Input
                    value={el.description}
                    onChange={e =>
                      this.setStateInternal({
                        value: e.target.value,
                        key: 'description',
                        index,
                      })
                    }
                    placeholder={'Enter Vide Description'}
                    clearOnEscape
                  />

                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Video URL'}
                  </h5>
                  <Input
                    value={el.video_url}
                    onChange={e =>
                      this.setStateInternal({
                        value: e.target.value,
                        key: 'video_url',
                        index,
                      })
                    }
                    placeholder={'Enter Vide URL'}
                    error={el.video_url && !isValidHttpUrl(el.video_url)}
                    clearOnEscape
                  />
                  {el.video_url && !isValidHttpUrl(el.video_url) && (
                    <p style={{ color: 'red', fontSize: '0.6rem', margin: 0, paddingTop: '0.6rem' }}>Invalid web URL</p>
                  )}
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Video Type'}
                  </h5>
                  <Select
                    options={[
                      { label: 'Youtube', id: 'Youtube' },
                      { label: 'Vimeo', id: 'Vimeo' },
                      { label: 'URL', id: 'Url' },
                    ]}
                    onChange={({ option }) =>
                      this.setStateInternal({
                        value: option.id,
                        key: 'video_type',
                        index,
                      })
                    }
                    escapeClearsValue={true}
                    value={selectionValue}
                    placeholder={!selectionValue ? 'Select Video Type' : ''}
                    multi={false}
                    labelKey="label"
                    valueKey="id"
                    maxDropdownHeight="300px"
                    type={TYPE.search}
                  />
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Loop Time'}
                  </h5>
                  <Input
                    value={el.loop_times}
                    onChange={e =>
                      this.setStateInternal({
                        value: 1 * e.target.value,
                        key: 'loop_times',
                        index,
                      })
                    }
                    placeholder={'Enter Loop Time'}
                    clearOnEscape
                    type="number"
                  />
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Duration (In Seconds)'}
                  </h5>
                  <Input
                    value={el.duration}
                    onChange={e =>
                      this.setStateInternal({
                        value: 1 * e.target.value,
                        key: 'duration',
                        index,
                      })
                    }
                    placeholder={'Enter Duration'}
                    clearOnEscape
                    type="number"
                  />
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Video Type'}
                  </h5>
                  <Select
                    options={[
                      { label: 'Yes', id: true },
                      { label: 'No', id: false },
                    ]}
                    onChange={({ option }) =>
                      this.setStateInternal({
                        value: option.id,
                        key: 'does_loop',
                        index,
                      })
                    }
                    value={[
                      { label: 'Yes', id: true },
                      { label: 'No', id: false },
                    ].find(el1 => el1.id == el.does_loop)}
                    placeholder={'Does loop?'}
                    multi={false}
                    labelKey="label"
                    valueKey="id"
                    maxDropdownHeight="300px"
                    type={TYPE.search}
                  />
                  <h5
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Video Cover Photo'}
                  </h5>
                  <ImageUploadCXL
                    previewUrls={getPreviewImage(el.cover_photo, el.cover_photo_data) || []}
                    isMultiple={false}
                    style={{ background: 'transparent' }}
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.setStateInternal({
                        value: acceptedFiles,
                        key: 'cover_photo',
                        index,
                      })
                    }
                    onImageDelete={() => {
                      this.setStateInternal({
                        value: null,
                        key: 'cover_photo',
                        index,
                      })
                      this.setStateInternal({
                        value: null,
                        key: 'cover_photo_data',
                        index,
                      })
                    }}
                    showFullBleedSettings={false}
                    imageSize={cardBackImageGuideline.size}
                    imageSizeGuidelineURL={cardBackImageGuideline.url}
                  ></ImageUploadCXL>
                  {/* <GetPreviewFromStateData
                    value={el.cover_photo || el.cover_photo_data}
                  ></GetPreviewFromStateData> */}
          {/* <FileUploader
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.setStateInternal({
                        value: acceptedFiles,
                        key: 'cover_photo',
                        index,
                      })
                    }
                    value={el.cover_photo}
                    multiple={false}
                    accept={"image/*"}
                    overrides={{
                      FileDragAndDrop: {
                        style: props => ({
                          borderLeftColor: props.$isDragActive
                            ? '#5D64AB'
                            : 'rgb(203, 203, 203)',
                          borderRightColor: props.$isDragActive
                            ? '#5D64AB'
                            : 'rgb(203, 203, 203)',
                          borderTopColor: props.$isDragActive
                            ? '#5D64AB'
                            : 'rgb(203, 203, 203)',
                          borderBottomColor: props.$isDragActive
                            ? '#5D64AB'
                            : 'rgb(203, 203, 203)',
                          background: props.$isDragActive
                            ? '#5D64AB'
                            : ''
                        }),
                      },
                    }}
                  /> */}
          {/*  </div>
              </Panel>
            );
          })}
        </Accordion> */}

        </div>
        <Block
          marginTop="36px"
          marginBottom="16px"
          display="flex"
          justifyContent="flex-end"
        >
          <AddContentButton
            buttonText={'Add Videos'}
            buttonClickOverride={this.onClick}
          />
        </Block>
      </>
    );
  }
}

export default VideoGalleryComponent;
