import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
  ParagraphLarge,
} from 'baseui/typography';
import { connect } from 'react-redux';
import { ButtonGroup, MODE } from 'baseui/button-group';
import Button from './full-size-button';
import { Select, TYPE } from 'baseui/select';
import { cellPaddingOverrides } from '../overrides';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';


const Step2 = ({ groups, channels, channelsSelected, setSelectedChannels, audienceType, setAudienceType }) => {
  const [css, theme] = useStyletron();
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [filtered, setFiltered] = React.useState(channels);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = React.useState(false);
  const [isFilteringInGroup, setIsFilteringInGroup] = React.useState(false);
  React.useEffect(() => {
    if (audienceType == 0) {
      setSearchText('');
      setSelectedGroups(groups);
      setSelectedChannels(channels);
      setIsCheckboxDisabled(true);
    } else if (audienceType == 1 && isCheckboxDisabled === true) {
      setSelectedGroups([]);
      setSelectedChannels([]);
      setIsCheckboxDisabled(false);
    };
  }, [audienceType]);
  React.useEffect(() => {
    if (searchText == '') {
      setFiltered(channels);
    } else {
      let filterGroups = groups.filter(el => el.name.toLowerCase().includes(searchText.trim().toLocaleLowerCase()));
      let filterChannels = channels.filter(el => el.name.toLowerCase().includes(searchText.trim().toLocaleLowerCase()));
      if (filterChannels.length == 0 && filterGroups.length > 0) {
        // search by groups hit
        setIsFilteringInGroup(true);
        filterGroups.forEach((group) => {
          filterChannels = filterChannels.concat(channels.filter(el => el.app_content_scope_group_id == group.id))
        });
      } else {
        setIsFilteringInGroup(false);
      }
      setFiltered(filterChannels);
    }
  }, [searchText]);
  React.useEffect(() => {
    setSelectedGroups(groups.filter((el) => {
      let allChannels = getChannelsOfGroup(el.id);
      let selected = getChannelsOfGroup(el.id, channelsSelected);
      if (allChannels.length == selected.length) return true;
      return false;
    }))
  }, []);
  const getChannelsOfGroup = (id, data = filtered) => data.filter(el => el.app_content_scope_group_id == id);
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          marginLeft: '0px',
          paddingTop: '29px'
        }),
      },
    }} gridMaxWidth={'unset'}>
      {/* <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Select Audience
          </HeadingMedium>
        <ParagraphSmall>
          Select one or many channels below to designate who will see
          this message in there inbox, newsroom or push notification
          list.
          </ParagraphSmall>
      </Cell> */}
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            // padding: '24px',
            // background: theme.colors.primaryB,
            marginBottom: '24px',
            // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <ButtonGroup
            mode={MODE.radio}
            selected={audienceType}
            onClick={(event, index) => {
              setAudienceType(index);
            }}
          >
            <Button startEnhancer={(p) => <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 9.125C23.2825 9.125 25.1112 7.2825 25.1112 5C25.1112 2.7175 23.2825 0.875 21 0.875C18.7175 0.875 16.875 2.7175 16.875 5C16.875 7.2825 18.7175 9.125 21 9.125ZM10 9.125C12.2825 9.125 14.1112 7.2825 14.1112 5C14.1112 2.7175 12.2825 0.875 10 0.875C7.7175 0.875 5.875 2.7175 5.875 5C5.875 7.2825 7.7175 9.125 10 9.125ZM10 11.875C6.79625 11.875 0.375 13.4838 0.375 16.6875V20.125H19.625V16.6875C19.625 13.4838 13.2037 11.875 10 11.875ZM21 11.875C20.6012 11.875 20.1475 11.9025 19.6663 11.9438C21.2612 13.0988 22.375 14.6525 22.375 16.6875V20.125H30.625V16.6875C30.625 13.4838 24.2038 11.875 21 11.875Z" fill={p.$isSelected ? 'white' : 'black'} />
            </svg>}>Send to Entire Organization</Button>
            <Button startEnhancer={(p) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0208 15.0833H16.0004L15.6388 14.7346C16.9046 13.2621 17.6667 11.3504 17.6667 9.27083C17.6667 4.63375 13.9079 0.875 9.27083 0.875C4.63375 0.875 0.875 4.63375 0.875 9.27083C0.875 13.9079 4.63375 17.6667 9.27083 17.6667C11.3504 17.6667 13.2621 16.9046 14.7346 15.6388L15.0833 16.0004V17.0208L21.5417 23.4662L23.4662 21.5417L17.0208 15.0833ZM9.27083 15.0833C6.05458 15.0833 3.45833 12.4871 3.45833 9.27083C3.45833 6.05458 6.05458 3.45833 9.27083 3.45833C12.4871 3.45833 15.0833 6.05458 15.0833 9.27083C15.0833 12.4871 12.4871 15.0833 9.27083 15.0833Z" fill={p.$isSelected ? 'white' : 'black'} />
            </svg>
            }>Send to specific channels</Button>
          </ButtonGroup>
        </div>
      </Cell>
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          {/* {audienceType == 0 && <div style={{ marginBottom: '24px', display: 'flex' }}>
            <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setAudienceType(1)} src="https://img.icons8.com/metro/26/000000/lock-2.png" />
            <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Do you want to unlock this selection to send to specific channels?</ParagraphLarge>
          </div>}
          {audienceType == 1 && <div style={{ marginBottom: '24px', display: 'flex' }}>
            <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setAudienceType(0)} src="https://img.icons8.com/metro/26/000000/unlock-2.png" />
            <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Do you want to lock this selection to send to specific channels?</ParagraphLarge>
          </div>} */}
          {audienceType == 1 && <div style={{ marginBottom: '24px' }}>
            <Input
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              endEnhancer={<Search size="18px" />}
              placeholder="Search Channels"
            />
            {isFilteringInGroup && <ParagraphSmall>
              No matching channel found. So data is filtered on group match.
            </ParagraphSmall>}
          </div>}
          {groups.map((group, index) => {
            if (getChannelsOfGroup(group.id).length == 0) {
              return <></>;
            };
            return <Cell overrides={cellPaddingOverrides} span={12} key={index}>
              {index != 0 && <hr style={{ height: '2px', margin: 0, marginTop: '8px', marginBottom: '8px', background: '#E2E2E2' }} />}
              <div>
                <Checkbox
                  checked={selectedGroups.find(el => el.id == group.id)}
                  checkmarkType={STYLE_TYPE.default}
                  disabled={isCheckboxDisabled}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedGroups([...selectedGroups, group]);
                      let c = getChannelsOfGroup(group.id);
                      let channelsSelectedCopy = [...channelsSelected];
                      c.forEach((channel) => {
                        let isExists = channelsSelected.find(el => el.id == channel.id);
                        if (!isExists) channelsSelectedCopy.push(channel);
                      })
                      setSelectedChannels(channelsSelectedCopy);
                    } else {
                      let index = selectedGroups.findIndex(el => el.id == group.id);
                      let selectedGroupsCopy = [...selectedGroups];
                      selectedGroupsCopy.splice(index, 1);
                      setSelectedGroups(selectedGroupsCopy);
                      let c = getChannelsOfGroup(group.id);
                      let channelsSelectedCopy = [...channelsSelected];
                      c.forEach((channel) => {
                        let index = channelsSelectedCopy.findIndex(el => el.id == channel.id);
                        if (index > -1) {
                          channelsSelectedCopy.splice(index, 1);
                        }
                      });
                      setSelectedChannels(channelsSelectedCopy);
                    }
                  }
                  }
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <div style={{ color: theme.colors.primaryC, fontWeight: 600 }}>{group.name}</div>
                </Checkbox>
              </div>
              <div style={{ padding: '16px' }}>
                {getChannelsOfGroup(group.id).map((channel, index) => {
                  return <div key={index}>
                    <Checkbox
                      checked={channelsSelected.find(el => el.id == channel.id)}
                      checkmarkType={STYLE_TYPE.default}
                      disabled={isCheckboxDisabled}
                      onChange={e => {
                        if (e.target.checked) {
                          // debugger;
                          let channelsSelectedCopy = [...channelsSelected, channel];
                          let c = getChannelsOfGroup(channel.app_content_scope_group_id);
                          let c1 = getChannelsOfGroup(channel.app_content_scope_group_id, channelsSelectedCopy);
                          if (c.length == c1.length) {
                            let group = groups.find(el => el.id == channel.app_content_scope_group_id);
                            setSelectedGroups([...selectedGroups, group]);
                          }
                          setSelectedChannels(channelsSelectedCopy);
                        } else {
                          let channelsSelectedCopy = [...channelsSelected];
                          let index = channelsSelectedCopy.findIndex(el => el.id == channel.id);
                          channelsSelectedCopy.splice(index, 1);
                          let c1 = getChannelsOfGroup(channel.app_content_scope_group_id, channelsSelectedCopy);
                          let c2 = getChannelsOfGroup(channel.app_content_scope_group_id);
                          if (c1.length != c2.length) {
                            let index = selectedGroups.findIndex(el => el.id == group.id);
                            let selectedGroupsCopy = [...selectedGroups];
                            selectedGroupsCopy.splice(index, 1);
                            setSelectedGroups(selectedGroupsCopy);
                          }
                          setSelectedChannels(channelsSelectedCopy);
                        }
                      }
                      }
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      {`${channel.name}`}
                    </Checkbox>
                  </div>
                })}
              </div>
            </Cell>
          })}
        </div>
      </Cell>
    </Grid>
  )
};

const mapStateToProps = props => {
  let { groups } = props.appBasicControls;
  return {
    groups
  };
};
export default connect(mapStateToProps)(Step2);
