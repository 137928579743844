import React from 'react';
import { Router } from '@reach/router';
import Login from '../components/login';
import Settings from '../components/settings/index';

const Domain = ({}) => {
  return (
    <>
      <Router>
        <Login path="/domain"></Login>
        <Settings path="/domain/:sub_domain"></Settings>
      </Router>
    </>
  );
};

export default Domain;
