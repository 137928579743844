import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { connect } from 'react-redux';
import {Skeleton} from 'baseui/skeleton'
import Button from '../styledButton';
import ChannelsDropDown from '../channel/channelsDropdownV1';
import RightSidePageHeader from '../channel/channelHeader';
import Feeds from './feeds';
import { connectStatsData, getDetailPageAnalysis } from './api-data';
import * as apiData from './api-data';
import { HeadingMedium, HeadingSmall, HeadingXLarge, HeadingXSmall, HeadingXXLarge, LabelMedium, LabelSmall, LabelXSmall } from 'baseui/typography';
import { gridPaddingOverrides } from '../overrides';
import styled from '@emotion/styled';
import CustomModal from '../common/modal';
import moment from 'moment-timezone';
import { compose } from 'redux';
import Gallery from 'react-grid-gallery';
import { getSrc } from '../../util';
import { KIND, Tag, VARIANT } from 'baseui/tag';
import PreviewElementsV1 from '../builder/previewElementsV1';
import PhoneDesign from '../builder/phone-design';
import { createEditorCardsFromCardModules } from '../builder/builderUtils/helpers';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import ReactHighcharts from 'react-highcharts';


const TAB_STATUS = {
  SENT: 'Sent',
  ARCHIVES: 'Archives'
};

const modalCommStatStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center'
}

const PreviewElementContainer = styled.div`
  
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
`

const ConnectContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 10px 13px
`;

const ModelStatsGridStyle = {
  Grid: {
    style: () => ({
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
      margin: '0px !important',
      width: '100%',
    })
  }
};


const ModalBodyContainer = styled.div`
  width: 100%;
  max-height: 78vh;
  overflow: auto;
`;
const ModalSubBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ModalStatsColContainer = styled.div`
  background-color: ${props => props.color};
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
`;

const Connect = ({ channels, organization_id, _selectedChannel, defaultTheme, _groups, _channels }) => {
  const [css, theme] = useStyletron();
  const [tab, setTab] = React.useState(TAB_STATUS.SENT);
  const [filteredChannels, setFilteredChannels] = React.useState([]);
  const [filteredGroups, setFilteredGroups] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedFeed, setSelectedFeed] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const [options, setOptions] = React.useState(connectStatsData.options);
  React.useEffect(() => {
    setIsLoading(true)
    connectStatsData._get().then((stats) => {
      let o = JSON.parse(JSON.stringify(options));
      setOptions(o.map(e => {
        e.count = stats[e.key];
        return e;
      }));
      setIsLoading(false);
    });
    getDetailPageAnalysis();
  
  }, [])

  const ALL_CHANNELS = { id: '*', app_content_scope_group_id: '*', name: 'All Channels' };
  const [selectedChannel, setSelectedChannel] = React.useState(_selectedChannel);

  React.useEffect(() => {
    if (!_selectedChannel || !_selectedChannel.id) {
      setSelectedChannel(_channels[0]);
    } else {
      setSelectedChannel(_selectedChannel);
    }
  }, [_channels]);
  // React.useEffect(() => {
  //   if (!_selectedChannel) return;
  //   if (!selectedChannel || (Object.keys(selectedChannel).length === 0 && selectedChannel.constructor === Object)) {
  //     setSelectedChannel(_selectedChannel);
  //   }
  // }, [_selectedChannel]);


  React.useEffect(() => {
    // SET CHANNELS
    const updatedChannelsList = _channels.filter((c) => { return true }).sort((a, b) => a.position - b.position);
    setFilteredChannels(updatedChannelsList);
    // SET GROUPS
    const updatedGroupsList = _groups.filter((c) => { return true }).sort((a, b) => a.position - b.position);
    setFilteredGroups(updatedGroupsList);
  }, [_channels, _groups]);

  // if (!selectedChannel || !selectedChannel.id) return null;
  // -------------------------------------------> Getter Functions ------------------------------------------------ //
  const getChannelNamesText = (channelNames) => {
    return channelNames.map((channel, idx) => {
      let txt = channel.name;
      if (idx !== (channelNames.length - 1)) {
        txt = `${txt}, `;
      }
      return txt;
    })
  }

  // -------------------------------------------> Event Functions ------------------------------------------------ //

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFeed(undefined);
  }

  const onOpenModal = (prop) => {
    setIsModalOpen(true);
    setSelectedFeed(prop);
  }

  // -------------------------------------------> Render Functions ------------------------------------------------ //
  const RenderStatsContainer = () => {
    
    return (
      <div style={{ width: '100%', marginBottom: '2%' }}>
        <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'} >
          {
            options.map((stat) => (
              <Cell span={4}>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '6%',
                    paddingBottom: '4%',
                    textAlign: 'center',

                    backgroundColor: stat.type === apiData.communicationTypes.PUSH && '#4A4CA0' || stat.type === apiData.communicationTypes.EMAIL && '#EE4678' || stat.type === apiData.communicationTypes.NEWS && '#0D0E13' || '#c4c4c4',
                  }}
                >
                  <HeadingXXLarge
                    color='white'
                    style={{
                      marginBottom: '5%',
                      fontSize: '60px',
                    }}
                  >
                    {stat.count}
                  </HeadingXXLarge>
                  <HeadingXSmall
                    color='white'
                    style={{
                      fontSize: '14px',
                      marginBottom: 0,
                    }}
                  >
                    {stat.title}
                  </HeadingXSmall>
                </div>
              </Cell>
            ))
          }

        </Grid>
      </div>
    )
  }


  const renderModalHeader = () => {
    if (!selectedFeed || (Object.keys(selectedFeed).length <= 0)) return;
    const {
      communication
    } = selectedFeed || {};
    const {
      title,
      created_by_name,
      created_at,
      message_body
    } = communication || {};
    const createdBy = created_by_name || 'User';
    const createdAt = moment(created_at).format('llll')

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{ display: 'flex' }}
        >
          <HeadingXSmall
            style={{
              marginBottom: '4px',
              padding: 0
            }}
          >
            {title}
          </HeadingXSmall>
        </div>
        <LabelXSmall
          style={{
            marginBottom: '4px',
            marginTop: '4px'
          }}
        >
          {createdBy} created this at{' '}
          {createdAt}
        </LabelXSmall>
        <div>
          {communication.type_push_notification && renderFeedType(apiData.communicationTypes.PUSH)}
          {communication.type_email && renderFeedType(apiData.communicationTypes.EMAIL)}
          {communication.type_news && renderFeedType(apiData.communicationTypes.NEWS)}
        </div>
      </div>
    )
  }

  const renderFeedType = (name) => {
    const { communicationTypes } = apiData;
    return (
      <Tag
        kind={KIND.primary}
        closeable={false}
        variant={VARIANT.solid}
        overrides={{
          Tag: {
            style: () => ({
              margin: '0px !important',
            }),
          },
          Root: {
            style: () => ({
              paddingLeft: "15px",
              paddingRight: "15px",
              borderRadius: "1px",
              backgroundColor: name === communicationTypes.PUSH && '#4A4CA0' || name === communicationTypes.EMAIL && '#EE4678' || name === communicationTypes.NEWS && '#0D0E13' || '#c4c4c4'
            })
          }
        }}
      >
        {name}
      </Tag>
    )
  }

  const renderModalBody = () => {
    if (!selectedFeed || (Object.keys(selectedFeed).length <= 0)) return;
    const {
      communication
    } = selectedFeed || {};
    const {
      front_image,
      front_image_data,
      message_body,
      title,
      card_content_modules,
      type_push_notification
    } = communication || {};
    const icon = front_image || getSrc(front_image_data);
    const largeIcon =
      front_image || getSrc(front_image_data, 'small');

    const cardBeckOptions = editorBtnOptions.filter(
      el => el.addInNewCommunication
    )
    let cardBackData = createEditorCardsFromCardModules(card_content_modules.slice(), { options: cardBeckOptions })
    cardBackData.splice(0, 0, {
      "name": "Image",
      "display": "Image",
      "id": "button_2",
      "icon": {
        "type": {},
        "key": null,
        "ref": null,
        "props": {},
        "_owner": null,
        "_store": {}
      },
      "__typename": "ImageCardModule",
      "addInNewCommunication": true,
      "addInHomeComponent": true,
      "parent": "option_3",
      "inputs": [
        {
          "type": "FilePicker",
          "key": "file_data",
          "dataType": "String",
          "mutation": {
            "key": "file",
            "dataType": "Upload"
          },
          "value": front_image_data,
          "placeholder": "Drag files here",
          "multiple": false,
          "accept": "image/*"
        }
      ],
      "dId": "",
      "position": 0
    })
    return (
      <ModalBodyContainer>
        <ModalSubBodyContainer >
          <div
            style={{
              display: 'flex',
              flex: 0.6,
              flexDirection: 'column',
              // justifyContent: 'space-between',
              color: 'white'
            }}
          >
            {renderModalHeader()}
            {/* <LabelSmall
              style={{
                // marginTop: '15px',
                position: 'relative',
                float: 'left',
                width: '100%',
              }}
            >
              {message_body}
            </LabelSmall> */}
            <RenderModelStats />
          </div>
          <div
            style={{
              flex: 0.4
            }}
          >
            {
              cardBackData && cardBackData.length > 0 && (
                <PreviewElementContainer margin={'0px'}>
                  <PreviewElementsV1
                    max_height={'66vh'}
                    cardBackData={cardBackData}
                  ></PreviewElementsV1>
                </PreviewElementContainer>
              )
            }
          </div>

        </ModalSubBodyContainer>

      </ModalBodyContainer>
    )
  }

  const renderStatsColumn = (title, number, color) => {
    return (
      <Cell
        span={3}
      >
        <ModalStatsColContainer color={color}>
          <LabelXSmall
            className={css({
              color: 'white !important',
              marginBottom: '10px !important',
            })}
          >{title}
          </LabelXSmall>
          <LabelXSmall className={css({
            color: 'white !important'
          })}>{number}</LabelXSmall>
        </ModalStatsColContainer>
      </Cell>
    )
  }

  const RenderModelStats = () => {
    const [stats, setStats] = React.useState({});
    React.useEffect(() => {
      getDetailPageAnalysis().then(result => setStats(result))
    }, []);
    if (Object.keys(stats).length == 0) return <></>;
    return (
      <Grid
        gridMaxWidth="unset"
        overrides={ModelStatsGridStyle}
      >
        <Cell span={10}>
        <ReactHighcharts
          config={{
            chart: {
              type: 'column'
            },
            title: {
              text: 'Sent vs Conversion'
            },
            subtitle: {
              text: ''
            },
            xAxis: {
              categories: [
                'Sent',
                'Conversion'
              ],
              crosshair: true
            },
            credits: {
              enabled: false
            },
            yAxis: {
              // min: 0,
              title: {
                text: 'Count'
              }
            },
            tooltip: {
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                borderRadius: '4px'
              }
            },
            series: [{
              name: 'Push Notification',
              data: [stats.notification_sent, stats.notification_opened],
              color: '#4A4CA0'
            }, {
              name: 'Email',
              data: [stats.email_sent, stats.email_seen],
              color: '#EE4678'

            }, {
              name: 'Article',
              data: [stats.article_posted, stats.article_opened],
              color: '#0D0E13'
            }]
          }}
        ></ReactHighcharts>
        </Cell>
      </Grid >
    )
  }

  const LoadingBar = () => {
    return (
      <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {new Array(3).fill('').map(item => <Skeleton
            width="500px"
            height="145px"
            animation
            overrides={{
                Root: {
                    style: {
                      marginRight: '60px'
                    },
                },
            }}
          />)}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '2rem'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                  Root: {
                      style: {
                        marginRight: '25px'
                      },
                  },
              }}
            />)}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                  Root: {
                      style: {
                        marginRight: '10px',
                        borderRadius: '48px'
                      },
                  },
              }}
            />)}
          </div>
        </div>
        <div style={{marginTop: '2rem'}}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
          />))}
        </div>
        <div style={{ float: 'right'}}>
          <Skeleton
            width="500px"
            height="40px"
            animation
          />
        </div>
      </div>
    )
  }
  console.log('selecet channel', selectedChannel);
  return (
    <>
      <ConnectContainer>
        <CustomModal
          isOpen={isModalOpen}
          onCloseModal={onCloseModal}
          renderModelHeader={() => <></>}
          renderModelBody={renderModalBody}
        // renderModelFooter={renderModelStats}
        />
        <div
          style={{
            flexGrow: 1,
            maxWidth: '100%',
            // marginLeft: '350px', 
            // paddingLeft: '12px'
          }}
        >
          <Grid overrides={{
            Grid: {
              style: {
                padding: '10px 0px 0 7px !important'
              }
            }
          }} gridMaxWidth={'unset'}>
            <div style={{ display: 'flex', width: '100%', color: 'rgb(98, 70, 238)', marginBottom: '20px' }}>

              <div style={{ flexGrow: 1 }}>
                <RightSidePageHeader
                  icon={null}
                  title={""}
                  rightContent={
                    <StatefulButtonGroup
                      mode={MODE.radio}
                      initialState={{ selected: 0 }}
                    >
                      <Button onClick={() => setTab(TAB_STATUS.SENT)}>Sent</Button>
                      <Button onClick={() => setTab(TAB_STATUS.ARCHIVES)}>Archives</Button>
                    </StatefulButtonGroup>
                  }
                  headerDescription={""}
                ></RightSidePageHeader>
              </div>
            </div>
          </Grid>
          {(isLoading || !selectedChannel || !selectedChannel.id) ? (
            <LoadingBar />      
          ) : (
            <>
            <Cell
              span={12}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                  }),
                }
              }}
            >
              <RenderStatsContainer />
            </Cell>
            <Cell span={12} overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                }),
              }
            }}>
              <Feeds
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                tabFilter={tab}
                channels={channels}
                organization_id={organization_id}
                defaultTheme={defaultTheme}
                theme={theme}
                filteredChannels={filteredChannels}
                filteredGroups={filteredGroups}
                onDetailPress={onOpenModal}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </Cell>
            </>
          )}
        </div>
      </ConnectContainer>
    </>
  );
};

const mapStateToProps = props => {
  let { channels, groups, organization, selectedChannel } = props.appBasicControls;
  return {
    _channels: channels,
    _groups: groups,
    _organization: organization,
    _selectedChannel: selectedChannel
  };
};

const enhancer = compose(
  connect(mapStateToProps),
  React.memo
)
export default enhancer(Connect);
