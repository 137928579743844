import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
  ParagraphLarge,
  LabelSmall,
  LabelXSmall
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import { ButtonGroup } from 'baseui/button-group';
import Button from './../connect/full-size-button';
import { DatePicker } from "baseui/datepicker";
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Select } from "baseui/select";
import { Input } from "baseui/input";
import _ from 'lodash';
import gql from 'graphql-tag';
import { client } from '../../apollo/client';
import { createCardURLParams, createCardVisibilityParams, updateCardURLParams, updateCardVisibilityParams } from '../../quries';
import { Spinner } from 'baseui/spinner';
import { setIsLoading } from '../../redux/actions/appBasicControls';
import { useDispatch } from 'react-redux';

const updateCardMutation = gql`
mutation updateCard(
  $id: ID!
  $visible_time: Boolean
  $source_start_day: Int
  $source_end_day: Int
  $source_type: String
  $other_type: String
  $source_date: Date
  $custom_field_id: ID
) {
  updateCard(
    id: $id
    visible_time: $visible_time
    source_start_day: $source_start_day
    source_end_day: $source_end_day
    source_type: $source_type
    other_type: $other_type
    source_date: $source_date
    custom_field_id: $custom_field_id
  ) {
    title
    sub_title
    front_image_data
    visible_time
    source_start_day
    source_end_day
    source_type
    other_type
    source_date
    custom_field_id
  }
}
`;

const SmallLabelStyle = {
  marginTop: '16px',
  marginBottom: '8px'
};

const SourceOptions = [
  { label: 'Source Start Date - Date Picker', id: 'date' },
  { label: 'Other Integration', id: 'other' },
  { label: 'Values Based', id: 'key_value' }
];

const OtherSourceOptions = [
  { label: 'Salesforce', id: 'Salesforce' }
];

var onSaveDebounced;
const Step5 = ({ card, refetch, organization_id, toaster }) => {
  const [css, theme] = useStyletron();
  const [source, setSource] = React.useState(card.source_type || new Date().toString()); //date, other
  const [otherSource, setOtherSource] = React.useState(card.other_type || ''); // Salesforce
  const [customFieldId, setCustomFieldId] = React.useState(card.custom_field_id);

  const [sourceDate, setSourceDate] = React.useState(card.source_date);
  const [startDay, setStateDay] = React.useState(card.source_start_day);
  const [endDay, setEndDay] = React.useState(card.source_end_day);
  const [deliveryType, setDeliveryType] = React.useState(card.visible_time ? 1 : 0);

  const [customFields, setCustomFields] = React.useState([]);
  const [lastSavedData, setLastSavedData] = React.useState(null);

  const [cardUrlParams, setCardUrlParams] = React.useState([]);
  const [cardVisibilityParams, setCardVisibilityParams] = React.useState([]);
  const [savingParams, setSavingParams] = React.useState(false);
  const [savingVisibilityParams, setSavingVisibilityParams] = React.useState(false);
  const dispatch = useDispatch();

  const onSave = (dataToSave, refetch) => {
    // console.log(dataToSave);
    const msg = 'Saving the card timing and settings data.';
    let toastKey = toaster.info(<>{msg}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    dispatch(setIsLoading(true));
    client.mutate({
      mutation: updateCardMutation,
      variables: {
        id: card.id,
        ...dataToSave
      }
    }).then(() => {
      toaster.update(toastKey, {
        children: <>Card Data saved.</>,
      });
      setTimeout(() => {
        toaster.clear(toastKey);
      }, 1500);
      setLastSavedData(dataToSave);
      refetch();
    })
      .catch(error => {
        console.log('Card saving issue: '.error);
        toaster.update(toastKey, {
          children: <>Failed to save Card Data.</>,
        });
        setTimeout(() => {
          toaster.clear(toastKey);
        }, 1500);
      }).finally(() => {
        dispatch(setIsLoading(false));
      })
  };

  React.useEffect(() => {
    client.query({
      query: gql`
        query custom_fields{
          custom_field_by_org(organization_id: "${organization_id}"){
            integration_type
            field_name
            id
          }
        }
      `,
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      setCustomFields(data.custom_field_by_org);
    });
    fetchCardUrlParams();
    fetchCardVisibilityParams();
  }, []);

  const fetchCardUrlParams = () => {
    return client.query({
      query: gql`
        query card_url_params_by_card{
          card_url_params_by_card(card_id: "${card.id}") {
            key_id
            value_id
            has_custom_value
            custom_value
            id
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setCardUrlParams([...data.card_url_params_by_card,
      {
        key_id: null,
        value_id: null,
      }]);
      return;
    });
  };

  const fetchCardVisibilityParams = () => {
    return client.query({
      query: gql`
        query card_visibility_params_by_card{
          card_visibility_params_by_card(card_id: "${card.id}") {
            key_id
            value_id
            has_custom_value
            custom_value
            id
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setCardVisibilityParams([...data.card_visibility_params_by_card,
      {
        key_id: null,
        value_id: null,
      }]);
      return;
    });
  };

  console.log(cardUrlParams);

  React.useEffect(() => {
    if (onSaveDebounced) {
      onSaveDebounced = null;
    };
    onSaveDebounced = _.debounce(onSave, 1500);
  }, []);

  React.useEffect(() => {
    let dataToSave = {
      visible_time: deliveryType == 1 ? true : false,
      source_start_day: startDay,
      source_end_day: endDay,
      source_type: source,
      other_type: otherSource,
      source_date: sourceDate,
      custom_field_id: customFieldId
    };
    if (lastSavedData == null) return;
    if (_.isEqual({ dataToSave }, lastSavedData)) return;
    onSaveDebounced && onSaveDebounced(dataToSave, refetch);
  }, [source, otherSource, customFieldId, sourceDate, startDay, endDay, deliveryType]);

  React.useEffect(() => {
    let dataToSave = cardUrlParams.filter(el => el.isUpdated);
    if (dataToSave.length > 0) {
      saveParamData(dataToSave);
    };
  }, [cardUrlParams]);

  React.useEffect(() => {
    let dataToSave = cardVisibilityParams.filter(el => el.isUpdated);
    if (dataToSave.length > 0) {
      saveVisibilityParamData(dataToSave);
    };
  }, [cardVisibilityParams]);

  const saveParamData = async (dataToSave) => {
    setSavingParams(true);
    const msg = 'Saving the card timing and settings data.';
    let toastKey = toaster.info(<>{msg}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    dispatch(setIsLoading(true));
    await Promise.all(dataToSave.map(d => {
      let variables = {
        id: d.id,
        key_id: d.key_id,
        value_id: d.value_id,
        has_custom_value: d.has_custom_value,
        custom_value: d.custom_value,
        card_id: card.id,
        organization_id
      }
      if (d.id) {
        return client.mutate({
          mutation: updateCardURLParams,
          variables
        })
      }
      return client.mutate({
        mutation: createCardURLParams,
        variables
      })
    }));
    await fetchCardUrlParams();
    dispatch(setIsLoading(false));
    toaster.update(toastKey, {
      children: <>Card Data saved.</>,
    });
    setTimeout(() => {
      toaster.clear(toastKey);
    }, 1500);
    setSavingParams(false);
  };

  const saveVisibilityParamData = async (dataToSave) => {
    setSavingVisibilityParams(true);
    const msg = 'Saving the card visibility params';
    let toastKey = toaster.info(<>{msg}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    dispatch(setIsLoading(true));
    await Promise.all(dataToSave.map(d => {
      let variables = {
        id: d.id,
        key_id: d.key_id,
        value_id: d.value_id,
        has_custom_value: d.has_custom_value,
        custom_value: d.custom_value,
        card_id: card.id,
        organization_id
      }
      debugger
      if (d.id) {
        return client.mutate({
          mutation: updateCardVisibilityParams,
          variables
        })
      }
      return client.mutate({
        mutation: createCardVisibilityParams,
        variables
      })
    }));
    await fetchCardVisibilityParams();
    dispatch(setIsLoading(false));
    toaster.update(toastKey, {
      children: <>Card Data saved.</>,
    });
    setTimeout(() => {
      toaster.clear(toastKey);
    }, 1500);
    setSavingVisibilityParams(false);
  };

  React.useEffect(() => {
    let originalData = {
      visible_time: card.visible_time,
      source_start_day: card.source_start_day,
      source_end_day: card.source_end_day,
      source_type: card.source_type,
      other_type: card.other_type,
      source_date: card.source_date,
      custom_field_id: card.custom_field_id
    };
    setLastSavedData(originalData);
  }, []);

  const renderCardVisibilityParams = () => (
    cardVisibilityParams.map((param, index) => {
      return <div style={{ marginBottom: '16px' }} key={index}>
        <Grid overrides={gridPaddingOverrides}>
          <Cell span={6}>
            <Select
              options={customFields.filter(el => el.integration_type == 'Salesforce')}
              labelKey="field_name"
              valueKey="id"
              value={
                param.has_custom_value ? [{
                  id: param.custom_value,
                  field_name: param.custom_value
                }] : customFields.filter((el) => el.id == param.value_id)
              }
              creatable={false}
              backspaceRemoves={false}
              onChange={({ option }) => {
                console.log(option);
                let v = JSON.parse(JSON.stringify(cardVisibilityParams));
                if (option) {
                  // lets check if this option is created dynamically in Select then this is a custom value
                  if (option.isCreatable || option.id == option.field_name) {
                    v[index].value_id = null;
                    v[index].has_custom_value = true;
                    v[index].custom_value = option.id;
                  } else {
                    v[index].has_custom_value = false;
                    v[index].custom_value = '';
                    v[index].value_id = option.id;
                  };
                } else {
                  v[index].value_id = null;
                  v[index].has_custom_value = false;
                  v[index].custom_value = '';
                }
                if (v[index].key_id && (v[index].value_id || v[index].custom_value)) v[index].isUpdated = true;
                console.log(v);
                setCardVisibilityParams(v);
              }}
            ></Select>
            {param.has_custom_value && <LabelXSmall style={{ color: theme.colors.positive }}>CUSTOM VALUE</LabelXSmall>}
            {!param.has_custom_value && param.value_id && <LabelXSmall style={{ color: theme.colors.positive }}>SALESFORCE VALUE</LabelXSmall>}
          </Cell>
          <Cell span={6}>
            <Select
              options={customFields.filter(el => el.integration_type == 'Basic').filter(el => !cardVisibilityParams.find(el1 => el1.key_id == el.id))}
              labelKey="field_name"
              valueKey="id"
              value={customFields.filter((el) => el.id == param.key_id)}
              onChange={({ option }) => {
                let v = JSON.parse(JSON.stringify(cardVisibilityParams));
                if (option) {
                  v[index].key_id = option.id;
                } else v[index].key_id = null;
                if (v[index].key_id && v[index].value_id) v[index].isUpdated = true;
                setCardVisibilityParams(v);
              }}
            ></Select>
          </Cell>

        </Grid>
      </div>
    })

  )

  const renderCardVisibilityParamsContainer = () => {
    return (
      <Cell span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            position: 'relative'
          })}
        >
          <LabelSmall>{"Visibility Parameters"}</LabelSmall>
          <ParagraphSmall>These parameters will be passed to visibility</ParagraphSmall>
          <div style={{ marginBottom: '8px' }} key={'index1'}>
            <Grid overrides={gridPaddingOverrides}>
              {/* <Cell span={12}>
                <div style={{ display: 'flex' }}>
                  <img src="https://img.icons8.com/emoji/36/000000/person-tipping-hand.png" />
                  <LabelSmall style={{ margin: '8px', marginLeft: '16px' }}>You can create your custom value. Just start typing that value.</LabelSmall>
                </div>
              </Cell> */}
              <Cell span={6}><LabelSmall>{"KEY"}</LabelSmall></Cell>
              <Cell span={6}><LabelSmall>{"VALUE"}</LabelSmall></Cell>
            </Grid>
          </div>
          {renderCardVisibilityParams()}
          {savingVisibilityParams && <div style={{ display: 'flex', marginTop: '8px', marginLeft: '16px' }}>
            <Spinner size={24} /> <div style={{ marginLeft: '4px' }}>Saving data please wait...</div>
          </div>}
        </div>
      </Cell>
    )
  }

  console.log('visibility parms', cardUrlParams)
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingBottom: '64px',
          width: '100%',
          paddingTop: '29px',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }),
      },
    }} gridMaxWidth={'unset'}>
      {/* <Cell span={12}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Card Visibility Timing
          </HeadingMedium>
        <ParagraphSmall>
          Set card visibility timings to auto on/off card
          </ParagraphSmall>
      </Cell> */}
      <Cell span={12} span={10}>
        <div
          className={css({
            // padding: '24px',
            // background: theme.colors.primaryB,
            marginBottom: '24px',
            // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <ButtonGroup
            mode="checkbox"
            selected={deliveryType}
            onClick={(event, index) => {
              setDeliveryType(index);
            }}
          >
            <Button startEnhancer={(p) => <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5 3.125H24.125V0.375H21.375V3.125H7.625V0.375H4.875V3.125H3.5C1.9875 3.125 0.75 4.3625 0.75 5.875V27.875C0.75 29.3875 1.9875 30.625 3.5 30.625H25.5C27.0125 30.625 28.25 29.3875 28.25 27.875V5.875C28.25 4.3625 27.0125 3.125 25.5 3.125ZM25.5 27.875H3.5V10H25.5V27.875Z" fill={p.$isSelected ? 'white' : 'black'} />
            </svg>
            }>
              Disable Card Visibility
            </Button>
            <Button startEnhancer={(p) => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.875 9.915H15.5525L19.32 6.0375C15.5663 2.325 9.48879 2.1875 5.73504 5.9C1.98129 9.62625 1.98129 15.635 5.73504 19.3612C9.48879 23.0875 15.5663 23.0875 19.32 19.3612C21.19 17.5187 22.125 15.36 22.125 12.6375H24.875C24.875 15.36 23.665 18.8937 21.245 21.2862C16.4188 26.0712 8.58129 26.0712 3.75504 21.2862C-1.05746 16.515 -1.09871 8.76 3.72754 3.98875C8.55379 -0.7825 16.295 -0.7825 21.1213 3.98875L24.875 0.125V9.915ZM13.1875 7V12.8438L18 15.7037L17.01 17.3675L11.125 13.875V7H13.1875Z" fill={p.$isSelected ? 'white' : 'black'} />
            </svg>
            }>Enable Card Visibility</Button>
          </ButtonGroup>
        </div>
      </Cell>
      <Cell span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            position: 'relative'
          })}
        >
          {deliveryType == 0 && <div style={{ marginBottom: '24px', display: 'flex' }}>
            <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setDeliveryType(1)} src="https://img.icons8.com/metro/26/000000/lock-2.png" />
            <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Unlock this selection to enable card visibility</ParagraphLarge>
          </div>}
          {deliveryType == 1 && <div style={{ marginBottom: '24px', display: 'flex' }}>
            <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setDeliveryType(0)} src="https://img.icons8.com/metro/26/000000/unlock-2.png" />
            <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Lock this selection to disable card visibility</ParagraphLarge>
          </div>}
          <LabelSmall>{"Date & Time & Source Settings"}</LabelSmall>


          {/* SOURCE */}
          <LabelSmall style={SmallLabelStyle}>Type</LabelSmall>
          <Select
            options={SourceOptions}
            labelKey="label"
            valueKey="id"
            value={SourceOptions.filter((el) => el.id == source)}
            onChange={({ value }) => {
              if (value.length == 0) setSource('');
              else setSource(value[0].id);
            }}
          ></Select>


          {/* SOURCE DATE */}
          {source == 'date' && <><LabelSmall style={SmallLabelStyle}>Source Date</LabelSmall>
            <DatePicker
              value={[new Date(sourceDate)]}
              onChange={({ date }) =>
                setSourceDate(date)
              }
            ></DatePicker></>}


          {/* OTHER SOURCE */}
          {source == 'other' && <><LabelSmall style={SmallLabelStyle}>Source</LabelSmall>
            <Select
              options={OtherSourceOptions}
              labelKey="label"
              valueKey="id"
              value={OtherSourceOptions.filter((el) => el.id == otherSource)}
              onChange={({ value }) => {
                if (value.length == 0) setOtherSource('');
                else setOtherSource(value[0].id);
              }}
            ></Select></>}


          {/* FIELD */}
          {source == 'other' && <>
            <LabelSmall style={SmallLabelStyle}>Field</LabelSmall>
            <Select
              options={customFields.filter((el) => el.integration_type == otherSource)}
              labelKey="field_name"
              valueKey="id"
              value={customFields.filter((el) => el.id == customFieldId)}
              onChange={({ value }) => {
                if (value.length == 0) setCustomFieldId(null);
                else setCustomFieldId(value[0].id);
              }}
            ></Select>
          </>}


          {/* START DAY */}
          {source !== 'key_value' && <>
            <LabelSmall style={SmallLabelStyle}>Start Date</LabelSmall>
            <Input
              value={startDay || ''}
              onChange={e => setStateDay(1 * e.target.value)}
              placeholder=""
              type={"number"}
              clearOnEscape
            />
          </>
          }


          {/* END DAY */}
          {source !== 'key_value' && <>
            <LabelSmall style={SmallLabelStyle}>End Date</LabelSmall>
            <Input
              value={endDay || ''}
              onChange={e => setEndDay(1 * e.target.value)}
              placeholder=""
              type={"number"}
              clearOnEscape
            />
          </>
          }

          {/* DISABLED DIV  */}
          {deliveryType === 0 && <div style={{
            position: 'absolute',
            top: '54px',
            bottom: '0px',
            background: 'black',
            zIndex: 2,
            opacity: 0.2,
            width: '100%',
            left: 0,
            cursor: 'not-allowed'
          }}></div>}
        </div>
      </Cell>

      {/* <Cell span={12}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Advanced Settings
          </HeadingMedium>
        <ParagraphSmall>
          Set advance settings to the card
          </ParagraphSmall>
      </Cell> */}

      {source !== 'key_value' && <Cell span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            position: 'relative'
          })}
        >
          <LabelSmall>{"URL Parameters"}</LabelSmall>
          <ParagraphSmall>These parameters will be passed to all urls for different type of modules</ParagraphSmall>
          <div style={{ marginBottom: '8px' }} key={'index1'}>
            <Grid overrides={gridPaddingOverrides}>
              <Cell span={12}>
                <div style={{ display: 'flex' }}>
                  <img src="https://img.icons8.com/emoji/36/000000/person-tipping-hand.png" />
                  <LabelSmall style={{ margin: '8px', marginLeft: '16px' }}>You can create your custom value. Just start typing that value.</LabelSmall>
                </div>
              </Cell>
              <Cell span={6}><LabelSmall>{"KEY"}</LabelSmall></Cell>
              <Cell span={6}><LabelSmall>{"VALUE"}</LabelSmall></Cell>
            </Grid>
          </div>
          {cardUrlParams.map((param, index) => {
            return <div style={{ marginBottom: '16px' }} key={index}>
              <Grid overrides={gridPaddingOverrides}>
                <Cell span={6}>
                  <Select
                    options={customFields.filter(el => el.integration_type == 'Basic').filter(el => !cardUrlParams.find(el1 => el1.key_id == el.id))}
                    labelKey="field_name"
                    valueKey="id"
                    value={customFields.filter((el) => el.id == param.key_id)}
                    onChange={({ option }) => {
                      let v = JSON.parse(JSON.stringify(cardUrlParams));
                      if (option) {
                        v[index].key_id = option.id;
                      } else v[index].key_id = null;
                      if (v[index].key_id && v[index].value_id) v[index].isUpdated = true;
                      setCardUrlParams(v);
                    }}
                  ></Select>
                </Cell>
                <Cell span={6}>
                  <Select
                    options={customFields.filter(el => el.integration_type == 'Salesforce')}
                    labelKey="field_name"
                    valueKey="id"
                    value={
                      param.has_custom_value ? [{
                        id: param.custom_value,
                        field_name: param.custom_value
                      }] : customFields.filter((el) => el.id == param.value_id)
                    }
                    creatable
                    backspaceRemoves={false}
                    onChange={({ option }) => {
                      console.log(option);
                      let v = JSON.parse(JSON.stringify(cardUrlParams));
                      if (option) {
                        // lets check if this option is created dynamically in Select then this is a custom value
                        if (option.isCreatable || option.id == option.field_name) {
                          v[index].value_id = null;
                          v[index].has_custom_value = true;
                          v[index].custom_value = option.id;
                        } else {
                          v[index].has_custom_value = false;
                          v[index].custom_value = '';
                          v[index].value_id = option.id;
                        };
                      } else {
                        v[index].value_id = null;
                        v[index].has_custom_value = false;
                        v[index].custom_value = '';
                      }
                      if (v[index].key_id && (v[index].value_id || v[index].custom_value)) v[index].isUpdated = true;
                      console.log(v);
                      setCardUrlParams(v);
                    }}
                  ></Select>
                  {param.has_custom_value && <LabelXSmall style={{ color: theme.colors.positive }}>CUSTOM VALUE</LabelXSmall>}
                  {!param.has_custom_value && param.value_id && <LabelXSmall style={{ color: theme.colors.positive }}>SALESFORCE VALUE</LabelXSmall>}
                </Cell>
              </Grid>
            </div>
          })}
          {savingParams && <div style={{ display: 'flex', marginTop: '8px', marginLeft: '16px' }}>
            <Spinner size={24} /> <div style={{ marginLeft: '4px' }}>Saving data please wait...</div>
          </div>}
        </div>
      </Cell>}

      {source == 'key_value' && renderCardVisibilityParamsContainer()}
    </Grid>
  )
}


export default Step5;