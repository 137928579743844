import React, { Component } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import Card from './card';
import { navigate } from '@reach/router';
import AddCard from './addCard';
import AddQuizCard from './addQuizCard';
import CloneCard from './cloneCard';
import MoveCard from './moveCard';
import ArchiveCard from './archiveCard';
import UnArchiveCard from './unArchiveCard';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import ReactDOM from 'react-dom';
import { sourceByType } from '../../util';
import { showAlert, updateCards, setQuestionCards } from '../../redux/actions/appBasicControls';
let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
}

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'cover_medium_url');
};

class PortalAwareItem extends Component {
  render() {
    const {
      dragProvided,
      card,
      index,
      decks,
      deck,
      onCloneCard,
      tabFilter,
      organization_id,
      snapshot,
      onToggleClick,
      onMoveCard,
      onArchiveCard,
      onUnArchiveCard,
      onSettingsClick,
      isQuizType
    } = this.props;
    const usePortal = snapshot.isDragging;

    const op = () => { };
    const onCardClick = () => {
      onSettingsClick(card_id, isQuizType);
    };
    // console.log('crd crd crd crd crd', card);
    let title = isQuizType ? (card.quiz_question_data && card.quiz_question_data.question_text ? card.quiz_question_data.question_text : card.default_title) : card.card.title;
    let imageSrc = isQuizType ? null : getSrc(card.card.file_data || card.card.front_image_data);
    let card_id = card.card_id || card.quiz_question_id;
    let full_bleed = isQuizType ? (card.quiz_question_data ? card.quiz_question_data.full_bleed : false) : card.card.full_bleed;

    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        <Card
          key={index}
          title={title}
          imageSrc={imageSrc}
          toggle={card.published}
          onToggleClick={v => onToggleClick(v.target.checked, card_id)}
          onSettingsClick={() => {
            onSettingsClick(card_id, isQuizType);
          }}
          onMoveCard={deck => {
            onMoveCard(card, deck);
          }}
          onArchive={() => {
            onArchiveCard(card);
          }}
          onUnArchive={() => {
            onUnArchiveCard(card);
          }}
          onClone={() => {
            onCloneCard(card);
          }}
          decks={decks}
          tabFilter={tabFilter}
          deck={deck}
          showSettingsIcon={false}
          isDeck={false}
          onCardClick={onCardClick}
          isQuizType={isQuizType}
          full_bleed={full_bleed}
        ></Card>
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

export default ({
  cards,
  questionCards,
  originalCards,
  droppableId,
  type,
  categoryId,
  decks,
  orgId,
  tabFilter,
  deck,
  groupId,
  channelId,
  dispatch,
  isQuizType,
  defaultTheme,
  refreshQuizQuestions
}) => {
  cards = cards || [];
  const onClick = data => {
    const url = `/app/${orgId}/groups/${groupId}/channels/${channelId}/deck/${deck.deck_id}/card/${data.card_id}${isQuizType ? '/quiz' : ''}`
    localStorage.setItem('router_history', url);
    localStorage.setItem('deck_id', deck.deck_id);
    localStorage.setItem('card_id', data.card_id);
    navigate(url);
  };
  const [moveCardOpen, setMoveCardOpen] = React.useState(false);
  const [cloneCardOpen, setCloneCardOpen] = React.useState(false);
  const [archivedCardOpen, setArchiveCardOpen] = React.useState(false);
  const [unArchivedCardOpen, setUnArchiveCardOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [selectedDeck, setSelectedDeck] = React.useState(null);
  const [toggleStatus, setToggleStatus] = React.useState(false);

  const [updateCard, { data: updatedCard }] = useMutation(gql`
    mutation updateCard($id: ID!, $published: Boolean, $deck_id: ID) {
      updateCard(id: $id, published: $published, deck_id: $deck_id) {
        title
      }
    }
  `);

  const [updateQuizCard, { data: updatedQuizCard }] = useMutation(gql`
    mutation updateQuizQuestion($id: ID!, $published: Boolean) {
      updateQuizQuestion(id: $id, published: $published) {
        id
      }
    }
  `);

  React.useEffect(() => {
    if (updatedCard) {
      dispatch(
        showAlert({
          msg: `Successfully ${toggleStatus ? '' : 'un'} published card!`,
          error: false,
        })
      );
    }
  }, [updatedCard])

  React.useEffect(() => {
    if (updatedQuizCard) {
      dispatch(
        showAlert({
          msg: `Successfully ${toggleStatus ? '' : 'un'} published quiz card!`,
          error: false,
        })
      );
    }
  }, [updatedQuizCard]);

  const checkIsValidToPublish = (data) => {
    const {
      quiz_question_type = '',
      quiz_question_data = {}
    } = data || {};
    let isValid = true;
    if (quiz_question_type === 'QuizOptionQuestion') {
      const {
        options = '[]'
      } = quiz_question_data || {};
      const optionsJson = JSON.parse(options);
      if (Array.isArray(optionsJson)) {
        const selectedOption = optionsJson.find(el => {
          if (el.correct === true) {
            return el;
          }
        });
        if (!selectedOption) {
          isValid = false
        }
      }
    }
    return isValid;
  }

  function alertUser() {
    console.log('You must select one answer as correct before continuing.')
    dispatch(
      showAlert({
        msg: 'You must select one answer as correct before continuing.',
        error: true,
      })
    );
  }
  
  const onToggleClick = (value, card_id) => {
    setToggleStatus(value);
    let currentCard = {};
    let updated = JSON.parse(JSON.stringify(isQuizType ? questionCards : originalCards)).map((el, index) => {
      if ((el.card_id || el.quiz_question_id) === card_id) {
        console.log(el);
        currentCard = el;
        el.published = value;
      }
      return el;
    });
    const isValid = checkIsValidToPublish(currentCard)
    
    if (!isValid) {
      alertUser();
      return;
    };

    dispatch(showAlert({
      msg: `${value ? 'Publishing' : 'Unpublishing'} Card`
    }));

    if (isQuizType) {
      dispatch(setQuestionCards(updated));
      return updateQuizCard({
        variables: {
          id: card_id,
          published: value,
        }
      })
    } else {
      dispatch(updateCards(updated));
      updateCard({
        variables: {
          id: card_id,
          published: value,
          deck_id: deck.deck_id
        },
      });
    }
  };

  const onSettingsClick = (card_id, isQuizType) => {
    onClick({
      card_id, isQuizType
    });
  };

  const onMoveCard = (card, deck) => {
    setSelectedCard(card);
    setSelectedDeck(deck);
    setMoveCardOpen(true);
  };

  const onCloneCard = card => {
    setSelectedCard(card);
    setCloneCardOpen(true);
  };

  const onArchiveCard = card => {
    setSelectedCard(card);
    setArchiveCardOpen(true);
  };

  const onUnArchiveCard = card => {
    setSelectedCard(card);
    setUnArchiveCardOpen(true);
  };

  const onCancel = () => {
    setMoveCardOpen(false);
    setCloneCardOpen(false);
    setArchiveCardOpen(false);
    setUnArchiveCardOpen(false);
    setSelectedDeck(null);
    setSelectedCard(null);
  };

  const onCloseAddCardModal = () => {
    let container = document.querySelector('#card-list-container')
    // position: clientRect.y > 400 ? 'top' : 'bottom'
    console.log('container.y --->' + window.screen.height / 2, container.getBoundingClientRect());

    const appContainer = document.querySelector('#app-container');
    if (container && appContainer) {
      container = container.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      let space = container.top + container.height - viewHeight + 52;
      appContainer.scrollTo({
        top: appContainer.scrollTop + space,
        behavior: 'auto'
      })

    }
  }

  const AddCardContainer = styled.div`
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    height: 351px;
    top: -58px;
    box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
    background: ${defaultTheme === 'light' ? 'white' : '#0D0E13'};
    padding-left: 25px;
  `;
  return (
    <>
      <Droppable
        droppableId={droppableId}
        type={type}
        direction="horizontal"
        isCombineEnabled={false}
      >
        {dropProvided => (
          <div
            // className="scrolls"
            className="scrolls_v1"
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            style={{
              display: 'flex', minHeight: '270px', marginRight: '220px', scrollbarWidth: 'thin',
              scrollbarColor: defaultTheme === "dark" ? 'black #282828' : "white black"
            }}
          >
            {cards
              .sort((a, b) => {
                let aPosition = a.position;
                let bPosition = b.position;
                // if (a.isNew) aPosition = -1;
                return aPosition - bPosition;
              })
              .map((card, index) => (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(dragProvided, snapshot) => (
                    <>
                      <PortalAwareItem
                        dragProvided={dragProvided}
                        card={card}
                        deck={deck}
                        decks={decks}
                        index={index}
                        tabFilter={tabFilter}
                        snapshot={snapshot}
                        onMoveCard={onMoveCard}
                        onCloneCard={onCloneCard}
                        onArchiveCard={onArchiveCard}
                        onUnArchiveCard={onUnArchiveCard}
                        onToggleClick={onToggleClick}
                        onSettingsClick={onSettingsClick}
                        isQuizType={isQuizType}
                      />
                    </>
                  )}
                </Draggable>
              ))}
            {tabFilter !== "archived" && (
              <AddCardContainer>
                {isQuizType ? <AddQuizCard
                  cardStyle="Version1"
                  deck_id={deck.deck_id}
                  position={cards.length}
                  organization_id={orgId}
                  defaultTheme={defaultTheme}
                  refreshQuizQuestions={refreshQuizQuestions}
                  groupId={groupId}
                  channelId={channelId}
                  cards={cards}
                  // onCloseModal={onCloseAddCardModal}
                  modalProps={{ returnFocus: false }}
                /> :
                  <AddCard
                    defaultTheme={defaultTheme}
                    cardStyle="Version1"
                    cards={cards}
                    deck={deck}
                    orgId={orgId}
                    // onCloseModal={onCloseAddCardModal}
                    modalProps={{ returnFocus: false }}
                  />}
              </AddCardContainer>
            )}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
      {moveCardOpen && (
        <MoveCard
          deck={selectedDeck}
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
        />
      )}
      {archivedCardOpen && (
        <ArchiveCard
          deck={selectedDeck}
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
          refreshQuizQuestions={refreshQuizQuestions}
        />
      )}
      {unArchivedCardOpen && (
        <UnArchiveCard
          deck={selectedDeck}
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
          refreshQuizQuestions={refreshQuizQuestions}
        />
      )}
      {cloneCardOpen && (
        <CloneCard
          card={selectedCard}
          onCancel={onCancel}
          dispatch={dispatch}
        />
      )}
    </>
  );
};
