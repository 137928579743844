import * as React from 'react';
import { connect } from 'react-redux';
import {Skeleton} from 'baseui/skeleton'
import { getIntakeData as getIntakeDataQuery } from '../../quries';
import { client } from '../../apollo/client';
import Table from './table';
import moment from 'moment';
import ImageViewer from '../imageViewerCXL';

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};


const IntakeTable = ({ organization_id, close }) => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [isImageViewerOpen, setIsImageViewerOpen] = React.useState(false);
  const onImageViewerClose = () => setIsImageViewerOpen(false);
  const [previewUrl, setPreviewUrl] = React.useState(null);

  React.useEffect(() => {
      loadData(page);
  }, []);

  const loadData = (page) => {
    if (loading) return;
    setPage(page);
    setLoading(true);
    return client.query({
      query: getIntakeDataQuery(organization_id, page)
    }).then((result) => {
      setLoading(false);
      setData(data.concat(result.data.intake_forms_org_paginated.rows));
      setCount(result.data.intake_forms_org_paginated.count);
      if (result.data.intake_forms_org_paginated.count == 0) close();
    });
  };

  const loadMoreData = () => {
    return loadData(++page);
  }

  const onImageClick = (src) => {
    setPreviewUrl(src);
    setIsImageViewerOpen(true);
  }

  const columns = [
    {
      display: 'Model Name',
      key: 'modal_name'
    },
    {
      display: 'Product Serial Number',
      key: 'product_serial_number'
    },
    {
      display: 'Sales Rep',
      key: 'sales_rep_first_name'
    },
    {
      display: 'Sales Date',
      key: 'sales_date',
      formatter: (v) => moment(v).format('YYYY-MM-DD')
    },
    {
      display: 'Invoice Name',
      key: 'invoice_number'
    },
    {
      display: 'Created By',
      key: 'posted_by_user',
      formatter: (v) => v ? (v.name ? v.name.trim() : v.email.split('@')[0]) : ''
    },
    {
      display: 'Invoice',
      key: 'invoice_photo',
      formatter: (v) => <img src={v} style={{cursor: 'pointer', width: '30px', height: '30px', marginTop: '18px'}} onClick={() => {
        onImageClick(v)
      }}></img>
    }
  ];

  if (loading) {
    return (
      <div style={{marginTop: '2rem'}}>
        {new Array(4).fill('').map((item, index) => (
          <Skeleton
            width="100%"
            height="94px"
            animation
            overrides={{
              Root: {
                style: {
                  marginBottom: '15px',
                },
              },
            }}
        />))}
      </div>
    )
  }

  return <>
    <Table data={data} count={count} columns={columns} loadMoreData={loadMoreData}></Table>
    {isImageViewerOpen && <ImageViewer src={previewUrl} isOpen={isImageViewerOpen} onClose={onImageViewerClose}></ImageViewer>}
  </>;
};

export default connect(mapStateToProps)(IntakeTable);