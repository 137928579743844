import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

const getSubForGroupUpdate = id => {
  return gql`
    subscription OnGroupUpdate {
        appContentScopeGroupUpdated(id:"${id}"){
            name
            organization_id
            position
            public
            id
            isDeleted
            primary_color
            secondary_color
            deck_cover_radius_top_left
            deck_cover_radius_top_right
            deck_cover_radius_bottom_left
            deck_cover_radius_bottom_right
            deck_shadow_radius_top_left
            deck_shadow_radius_top_right
            deck_shadow_radius_bottom_left
            deck_shadow_radius_bottom_right
            archived
      }
    }
  `;
};

const getSubForGroupCreate = orgId => {
  return gql`
    subscription OnGroupCreate {
        appContentScopeGroupCreated(orgId:"${orgId}"){
          name
          organization_id
          position
          public
          id
          isNew
          primary_color
          secondary_color
          deck_cover_radius_top_left
          deck_cover_radius_top_right
          deck_cover_radius_bottom_left
          deck_cover_radius_bottom_right
          deck_shadow_radius_top_left
          deck_shadow_radius_top_right
          deck_shadow_radius_bottom_left
          deck_shadow_radius_bottom_right
          archived
      }
    }
  `;
};

class SubscribeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.updateSubs = null;
  }

  componentDidMount() {
    const primaryDataByOrg = this.props.primaryDataByOrg;
    // Here we are going to subscribe for category, decks, cards
    console.log('SUB FOR GROUP CREATE');
    this.createSub = this.props.subscribeToMore({
      document: getSubForGroupCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
      updateQuery: (data1, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.appContentScopeGroupCreated
        ) {
          let c = JSON.parse(JSON.stringify(this.props.groups || []));
          data1 = JSON.parse(JSON.stringify(data1));
          c.push(subscriptionData.data.appContentScopeGroupCreated);
          data1.app_content_scope_groups_org = c;
          return data1;
        }
      },
      onError: error => console.log(error),
    });
  }

  componentWillUnmount() {
    console.log('[SUBSCRIBE] UNMOUNTED GROUP CREATE');
    this.createSub && this.createSub();
  }

  render() {
    // unsubscribe
    this.updateSubs && this.updateSubs.map(el => el());
    // subscribe
    this.updateSubs = (this.props.groups || []).map((el, index) => {
      return this.props.subscribeToMore({
        document: getSubForGroupUpdate(el.id),
        variables: { id: el.id },
        updateQuery: (data1, { subscriptionData }) => {
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.appContentScopeGroupUpdated
          ) {
            let { appContentScopeGroupUpdated } = subscriptionData.data;
            let c = JSON.parse(JSON.stringify(this.props.groups || []));
            data1 = JSON.parse(JSON.stringify(data1));
            if (appContentScopeGroupUpdated.isDeleted) {
              c.splice(index, 1)
            } else {
              c[index] = appContentScopeGroupUpdated;
            }
            data1.app_content_scope_groups_org = c;
            return data1;
          }
        },
        onError: error => console.log(error),
      });
    });

    return <></>;
  }
}

const mapStateToProps = props => {
  const { groups } = props.appBasicControls;
  return { groups };
};

export default connect(mapStateToProps)(SubscribeGroup);
