import * as React from 'react';
import { useStyletron } from 'baseui';
import { Cell, Grid } from 'baseui/layout-grid';
import { gridPaddingMarginOverrides } from '../overrides';

import { LabelMedium } from 'baseui/typography';

const UserHeader = ({ defaultTheme, columns = [] }) => {
  const [css, theme] = useStyletron();
  const cellOverride = {
    Cell: {
      style: () => ({
        paddingTop: '6px !important',
        paddingLeft: '0 !important',
        paddingRight: '0 !important'
      }),
    },
  };
  const headerButtonStyle = {
    color: defaultTheme === "dark" ? 'white !important' : 'black !important',
    textTransform: 'capitalize',
  }
  return (
    <div
      className={css({
        position: 'relative',
        width: '100%',
        height: 'auto',
        background: theme.colors.primaryB,
        marginTop: '16px',
        marginBottom: '16px',
        display: 'flex',
        padding: '16px',
        color: 'white',
        borderRadius: '0px',
      })}
    >
      <Grid overrides={gridPaddingMarginOverrides}>
        {
          columns.map((el) => {
            return (
              <Cell span={el.span} overrides={cellOverride}>
                <LabelMedium
                  className={css(headerButtonStyle)}
                >
                  {el.name}
                </LabelMedium>
              </Cell>
            )
          })
        }
      </Grid>
    </div>
  );
};

export default UserHeader;
