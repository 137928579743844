import * as React from 'react';
import { connect } from 'react-redux';
import Dashboard from './dashboard';
import {getAnalyticsGraphQLClient} from '../../apollo/client';

const DashboardWrapper = ({ organization, organization_id, dispatch }) => {
  const [graphQLClient, setGraphQLClient] = React.useState(null);
  React.useEffect(() => {
    if (!organization) return;
    if (!organization.analytic_integration) return;
    let client = getAnalyticsGraphQLClient(organization.analytic_integration.schema_name);
    setGraphQLClient(client);
  }, [organization]);
  if (!organization) return <></>;
  // if (!organization.analytic_integration || !organization.analytic_integration.schema_name) {
  //   return <>No Integration found. Goto Settings to add a integration</>;
  // };
  // if (!graphQLClient) return <>Loading client...</>;
  return <Dashboard dispatch={dispatch} client={graphQLClient} organization_id={organization_id}></Dashboard>;
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};
export default connect(mapStateToProps)(DashboardWrapper);
