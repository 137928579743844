import React from 'react';
import BaseClass from './base';
import {Select} from 'baseui/select';


class InputComponent extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            options: [],
            isLoading: false
        };
    }

    getJWPlayerList() {
        const jwPlayerData = this.props.jwPlayerData;
        if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
            const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
            const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/playlists/`;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
            };
            this.setState({ isLoading: true });
            fetch(apiUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data && data.playlists) {
                    const nextOptions = data.playlists.map(item => ({ id: item.metadata.title, label: item.metadata.title, description: item.metadata.description}))
                    this.setState({ options: nextOptions });
                    this.setState({ isLoading: false });
                    }
                });
            }
    }

    render() {
        if (this.state.options.length == 0) this.getJWPlayerList()
        const selectValue = this.state.options.filter(i => i.id == this.state.value).length > 0 ? this.state.options.filter(i => i.id == this.state.value) : [{id: this.state.value, label: this.state.value, isCreatable: true}];
        return (
            <>
                <h5
                    style={{
                        color: this.props.theme.colors.primaryA,
                        marginTop: this.props.theme.sizing.scale1200,
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '16px'
                    }}
                >
                    {this.props.title || ''}
                </h5>
                    <>
                        <Select
                            isLoading={this.state.isLoading}
                            creatable
                            options={this.state.options}
                            onChange={({value}) => {
                                if (value.length > 0) {
                                    this.setState({value: value ? value[0].id : null})}
                                }
                            }
                            labelKey="label"
                            valueKey="id"
                            value={selectValue}
                        />
                    </>
            </>
        );
    }
}

export default InputComponent;