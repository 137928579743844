import gql from "graphql-tag";

export const tours = gql`
  query Tours {
    tours {
      id
      name
      title
      subtitle
      text
      link
      archived
      position
      version
    }
  }
`;

export const user_tours = gql`
  query user_tours($userId: ID $version: String) {
    user_tours(user_id: $userId version: $version) {
      id
      name
      title
      subtitle
      text
      link
      archived
      position
      version
    }
  }  
`;

export const createTour = gql`
  mutation createTour($name: String, $title: String, $subtitle: String, $text: String, $link: String, $archived: Boolean, $position: Int, $version: String) {
    createTour(name: $name, title: $title, subtitle: $subtitle, text: $text, link: $link, archived: $archived version: $version) {
      id
      name
      title
      subtitle
      text
      link
      archived
      position
      version
    }
  }
`;

export const createTourStatus = gql`
  mutation CreateTourStatus($tourModelId: ID, $userId: ID, $seen: Boolean) {
    createTourStatus(tour_model_id: $tourModelId, user_id: $userId, seen: $seen) {
      id
      tour_model_id
      user_id
      seen
    }
  }
`;

export const updateTourStatus = gql`
  mutation UpdateTourStatus($updateTourStatusId: ID, $tourModelId: ID, $userId: ID, $seen: Boolean) {
    updateTourStatus(id: $updateTourStatusId, tour_model_id: $tourModelId, user_id: $userId, seen: $seen) {
      id
      tour_model_id
      user_id
    }
  }
`;