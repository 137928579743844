import * as React from 'react';
import { useStyletron } from 'baseui';
import { HeadingXLarge } from 'baseui/typography';
import {Skeleton} from 'baseui/skeleton'
import {client as queryServerClient } from '../../apollo/client';
import HelpQuestionMark from '../help/questionMarkHelp';

const DataCard = ({ description, title,  helpText = '', query, client, queryFromQueryServer, dataProcessor, include_testers, testers }) => {
  const [css, theme] = useStyletron();
  const paddingLeftRightCss = {
    paddingLeft: theme.sizing.scale800,
    paddingRight: theme.sizing.scale800,
  };
  const [isLoading, setIsLoading] = React.useState(true)

  const [myData, setMyData] = React.useState({});
  React.useEffect(() => {
    let _client = client;
    if (queryFromQueryServer) _client = queryServerClient;
    if (_client && query) {
      _client.query({
        query,
        variables: {
          include_testers,
          testers: testers.map(el => el.device_id),
        }
      })
      .then((result) => { 
        setMyData(dataProcessor(result));
        setTimeout(() => {
          setIsLoading(false);
        }, 2000)
      });
    } else {
      setMyData({
        value: '0',
        cValue: '0'
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  }, [client]);

  if (isLoading) {
    return (
      <Skeleton
        width="250px"
        height="60px"
        animation
        overrides={{
          Root: {
              style: {
                minHeight: '250px',
                width: '100%'
              },
          },
        }}
      />
    )
  }

  return (
    <>
      <div className="channelsDropDown">
        <div
          className={css({
            position: 'relative',
            background: 'rgb(226, 226, 226)',
            border: '1px solid hsla(0, 0%, 0%, 0.2)',
            borderRadius: '5px',
            minHeight: '250px',
          })}
        >
          <div
            className={css({
              position: 'relative',
              ...paddingLeftRightCss,
              width: '100%',
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            <div style={{ padding: '20px', fontSize: '14px',  display: 'flex', alignItems: 'end'}}>
              <span>{title} </span>
              <HelpQuestionMark text={helpText} embedCss={{ marginLeft: '10px'}} />
            </div> 
            
            <hr />
            <div style={{ margin: '20px 20px 0' }}>
              <HeadingXLarge style={{ color: 'black' }}>{myData.value}</HeadingXLarge>
            </div>
            <div style={{ padding: '0 20px 10px', fontSize: '14px' }}>
              <span style={{ whiteSpace: 'pre-line'}}>{description.replace('{{cValue}}', myData.cValue || '')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCard;
