import React, { useEffect, useState } from 'react';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwesomeSlider from 'react-awesome-slider';
import { getSrc } from '../../util';
import CarouselSliderElement from './CarouselSliderElement';


const AutoplaySlider = withAutoplay(AwesomeSlider);

const CarouselSlider = ({ sliders }) => {
  return (
    <AutoplaySlider
      play={false}
      cancelOnInteraction={false}
      interval={4500}
      bullets={false}
      buttons={false}
      animation="openAnimation"
    // fillParent={true}
    >
      {sliders && sliders.map((slide, index) => {
        const image = getSrc(slide.image);
        return CarouselSliderElement({
          key: index,
          title: slide.title,
          description: slide.description,
          background_color: slide.background_color,
          button_text: slide.button_text,
          button_url: slide.button_url,
          image: image
        })
      }
      )}
    </AutoplaySlider>
  )
}

export default CarouselSlider;