import * as React from 'react';
import { FileUploader } from 'baseui/file-uploader';
import BaseClass from './base';
import { sourceByType } from '../../../util';
import { getStoreKeyName } from '@apollo/client/utilities';
import HelpQuestionMark from '../../help/questionMarkHelp';

const getSrc = data => {
  try {
    if (!data) return data;
    return sourceByType(data);
  } catch (e) {
    return null;
  }
};

const getType = data => {
  try {
    if (!data) return '';
    return (
      JSON.parse(data).mimetype || JSON.parse(data).full.metadata.mime_type
    );
  } catch (e) {
    return '';
  }
};

const getName = data => {
  try {
    if (!data) return '';
    return JSON.parse(data).filename || JSON.parse(data).full.metadata.filename;
  } catch (e) {
    return '';
  }
};

class GetPreviewFromStateData extends React.Component {

  render() {
    let data = this.props.value;
    if (!data) return <></>;
    let isFileData = false;
    let isMultiple = false;
    let isFormattedFileData = false;
    if (typeof data == 'string') data = [{ file_data: data }];
    if (Array.isArray(data)) isMultiple = true;

    if (data[0] && data[0].path && data[0].size) isFileData = true;
    if (data[0] && data[0].file) {
      isFileData = true;
      isFormattedFileData = true;
    };
    let paths = data.map(el => {
      if (isFormattedFileData) el = el.file;
      if (isFileData)
        return {
          src: URL.createObjectURL(el),
          type: el.type.includes('image') ? 'view' : 'download',
          name: el.name,
        };
      return {
        src: getSrc(el.file_data),
        type: getType(el.file_data).includes('image') ? 'view' : 'download',
        name: getName(el.file_data),
      };
    });

    return (
      <>
        {paths.map(file => {
          if (file.type == 'view')
            return (
              <img
                src={file.src}
                style={{
                  height: '100px',
                  position: 'relative',
                  float: 'left',
                  marginRight: '20px',
                }}
              ></img>
            );
          return (
            <a href={file.src} target="_blank">
              {file.name}
            </a>
          );
        })}
      </>
    );
  }
}

const helpText = "Here you can add an image or screen capture to a video, making your videos look more professional."

class FileUploaderComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      progressAmount: 0,
    };
  }

  render() {
    const {root} = this.props;
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            display: "flex",
            alignItems: "center",
            marginTop: '1rem'
          }}
        >
          {this.props.title || ''}
          {this.props.title.toLowerCase() === "video cover photo" && <HelpQuestionMark text={helpText} button={false} />}
        </h5>
        <GetPreviewFromStateData
          value={this.state.value}
        ></GetPreviewFromStateData>
        <FileUploader
          onDrop={(acceptedFiles, rejectedFiles) => {
            console.log('drooped file', acceptedFiles);
            this.setState({
              value: acceptedFiles,
              progressAmount: 100,
            });
          }}
          value={this.state.value}
          multiple={this.props.multiple || false}
          accept={this.props.accept}
          overrides={{
            FileDragAndDrop: {
              style: props => ({
                borderLeftColor: props.$isDragActive
                  ? '#5D64AB'
                  : 'rgb(203, 203, 203)',
                borderRightColor: props.$isDragActive
                  ? '#5D64AB'
                  : 'rgb(203, 203, 203)',
                borderTopColor: props.$isDragActive
                  ? '#5D64AB'
                  : 'rgb(203, 203, 203)',
                borderBottomColor: props.$isDragActive
                  ? '#5D64AB'
                  : 'rgb(203, 203, 203)',
                background: props.$isDragActive
                ? '#5D64AB'
                : ''
              }),
            },
          }}
          // progressAmount={this.state.progressAmount}
          // progressMessage={
          //   this.state.progressAmount
          //     ? (this.state.progressAmount != 100 ? `Uploading... ${this.state.progressAmount}% of 100%` : `Uploaded ${this.state.value.length} Files`)
          //     : ''
          // }
        />
      </>
    );
  }
}

export default FileUploaderComponent;
