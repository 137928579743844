import { SET_CARDS, SET_CARD_BACK_DATA, UPDATE_CARD_BACK_DATA, SET_DECKS, SET_CREATED_CATEGORY, SET_CREATED_COMPONENT, SET_TOUR_POP_STATUS } from './types';

export const setCardBackData = data => ({ type: SET_CARD_BACK_DATA, data });
export const updateCardBackData = data => ({ type: UPDATE_CARD_BACK_DATA, data });
export const setPrimaryDataByOrg = data => ({
  type: 'SET_PRIMARY_DATA_BY_ORG',
  data,
});

export const setGroups = data => ({ type: 'SET_GROUPS', data });
export const setIsLoading = data => ({ type: 'SET_LOADING', data });
export const setChannels = data => ({ type: 'SET_CHANNELS', data });
export const setCategory = data => ({ type: 'SET_CAT', data });
export const setDecks = data => ({ type: SET_DECKS, data });
export const updateDeck = data => ({ type: 'UPDATE_DECK', data });
export const setCards = cards => ({ type: SET_CARDS, cards });
export const updateCards = cards => ({ type: 'UPDATE_CARDS', cards });
export const setOrg = data => ({ type: 'SET_ORG', data });
export const setOrgSettings = data => ({ type: 'SET_ORG_SETTINGS', data });
export const setJWPlayerData = data => ({ type: 'SET_JWPLAYER_DATA', data})
export const setLibraryData = data => ({ type: 'SET_LIBRARY_DATA', data})
export const setSelectedChannel = data => ({
  type: 'SET_SELECTED_CHANNEL',
  data,
});
export const setSelectedGroup = data => ({
  type: 'SET_SELECTED_GROUP',
  data,
});
export const toggleTheme = data => ({ type: 'TOGGLE_THEME', data });
export const setSearch = data => ({ type: 'SET_SEARCH_TEXT', data });
export const setSearchPreview = data => ({ type: 'SET_SEARCH_PREVIEW', data });
export const setBreadCrumb = data => ({ type: 'SET_BREADCRUMB', data });
export const showAlert = data => ({ type: 'SHOW_ALERT', data });
export const setQuestionCards = data => ({type: 'SET_QUESTION_CARDS', data})
export const setUser = data => ({type: 'SET_USER', data});

export const setCreatedCategory = data => ({type: SET_CREATED_CATEGORY, data});
export const setCreatedComponent = data => ({type: SET_CREATED_COMPONENT, data});
export const setIsTourPopStatus = data => ({type: SET_TOUR_POP_STATUS, data});
